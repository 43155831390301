import React from 'react';
import { Route, Redirect } from "react-router-dom";
import { admin1, admin2, adminSuper, detail_user } from '../api';

export default function PrivateRoute({ component: Component, ...rest }) {
  let isAuthenticated = false;


  // const token = detail_user.id
  // const admin = detail_user.admin_type;
  const tokenVerify = localStorage.getItem('tokenVerify');
  // if (token || admin || tokenVerify) {
  if (tokenVerify) {
    isAuthenticated = true
  }

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
            <>
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location }
                }}
              />
              {/* {localStorage.clear()} */}
              {localStorage.removeItem('token')}
              {localStorage.removeItem('tokenVerify')}
            </>
          )
      }
    />
  );
}