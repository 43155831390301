import React, { Component } from 'react'
import { Modal, Button, Input, message, InputNumber, Icon, Form } from 'antd';
import {  MDBIcon } from 'mdbreact';
import { apilogin, options_mounth, fontpdf } from '../api'
import pdfMake from "pdfmake/build/pdfmake";
import img_logo_kpru_base64 from '../img/img_logo_kpru_base64';



pdfMake.fonts = fontpdf


 
export default class PrintPDFexpenditureGroup extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            dataprint: [],
            payslip_month: '',
            payslip_year: '',
            payslipall: [],
            payrevenueslipall: [],
            history_salary: [],
            payslip_month2: '',

        }
        this.loaddata = this.loaddata.bind(this)
        this.printPDF = this.printPDF.bind(this)
    }




 

    loaddata() {
    
        this.setState({ loading: true })
       
        this.setState({
            dataprint: this.props.dataprint,
            payslip_month: this.props.payslip_month,
            payslip_year: (Number(this.props.payslip_year) + 543),
        });

        this.setState({
            payslipall: this.props.data,
            loading: false
        })
      
        options_mounth.map((data)=>{
            if(data.value === this.props.payslip_month){
                this.setState({
                    payslip_month2: data.label,
                });
            }
        })


          setTimeout(() => {
             this.printPDF()
         }, 1000);

    }



    printPDF() {

        var rows = [];
        var count = this.state.payslipall.length

        rows.push([{ text: 'ลำดับ', alignment: 'center', bold: true }, { text: 'ชื่อ-สกุล', alignment: 'center', bold: true }, { text: 'ประเภท', alignment: 'center', bold: true }, { text: 'จำนวน/บาท (ใน)', alignment: 'center', bold: true }, { text: 'จำนวน/บาท (นอก)', alignment: 'center', bold: true }]);
        for (let i = 0; i < count; i++) { // ลูป


            if (this.state.payslipall[i].customers_name === null) {
                rows.push([
                    //   {
                    //       text: i+1, alignment: 'center',  fontSize: 14
                    //   },
                    {
                        text: 'ไม่มีรายการ', fontSize: 14
                    },
                    {
                        text: '-', fontSize: 14
                    }


                ]);
            } else {
                rows.push([
                    {
                        text: i + 1, alignment: 'center', fontSize: 14
                    },
                    {
                        text: this.state.payslipall[i].customers_pname + '' + this.state.payslipall[i].customers_name , fontSize: 14
                    },
                     {
                        text: this.state.payslipall[i].customer_type_name  , fontSize: 14, alignment: 'center'
                    },
                    // {
                    //     text: "0", fontSize: 14, alignment: 'center'
                    // }
                    {
                        text: Number(this.state.payslipall[i].total_in), fontSize: 14, alignment: 'center'
                    },
                    {
                        text: Number(this.state.payslipall[i].total_out), fontSize: 14, alignment: 'center'
                    },
                 


                ]);
            }

        }




        var docDefinition = {
            info: {
                title: 'kpru',
            },

            content: [
                {
                    image: img_logo_kpru_base64,
                    width: 50,
                    alignment: 'center'
                },

                { text: 'สรุปรายการค่าใช้จ่าย มหาวิทยาลัยราชภัฏกำแพงเพชร', fontSize: 15, alignment: 'center', bold: true },
                { text: 'ประจำเดือน ' + this.state.payslip_month2 + ' ' + this.state.payslip_year, fontSize: 15, alignment: 'center', bold: true },
                { text: 'ประเภท ' + this.props.labaltype + ' (' + this.props.nametype + ')', fontSize: 15, alignment: 'center', bold: true },
               // { text: 'รวมทั้งหมด ' + this.state.payslipall[0].payslip_revenue.toLocaleString(navigator.language, { minimumFractionDigits: 0 }) + '  บาท', fontSize: 15, alignment: 'center', bold: true },
                //{ text: 'รวมทั้งหมด 555  บาท', fontSize: 15, alignment: 'center', bold: true },

                //ตาราง
                {
                    columns: [
                        { width: '5%', text: '' },
                        {
                            //  widths: [ '20%', '90%',  '20%'],

                            layout: 'Column/row spans',
                            table: {
                                body: rows,
                                widths: ['10%', '35%', '20%','15%', '20%'],
                            }
                        },
                        { width: '5%', text: '' }
                    ]
                }

            ],
            defaultStyle: {
                font: 'THSarabunNew',
                // bold: true
            }
        };


        pdfMake.createPdf(docDefinition).open()



    }


    render() {
        return (

            <>
                {/* <div className="p-2 col-example"><MDBBtn   color="primary" size="sm"  onClick={this.printPDF.bind(this)}><MDBIcon icon="file-pdf" /> PDF</MDBBtn></div> */}
                {this.state.loading === true ? <>
                 <Button size="small" onClick={this.loaddata}> <Icon type="loading" /> &nbsp; loading.. </Button>&nbsp;
                    
                </> : <>
                     <Button type="primary" style={{height:'100%'}} size="large" onClick={this.loaddata}> <MDBIcon icon="print" /><br/>PDF  <br/> พิมพ์ใบสรุปยอด</Button>&nbsp;  
                </>}

            </>
        )
    }
}
