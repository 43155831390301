import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LoginPages from '../Pages/LoginPages';
import PageNotFoundPages from '../Pages/PageNotFoundPages.js';
import MainPages from '../Pages/MainPages';
import PrivateRoute from '../guard/auth';
import PrivateRouteUser from '../guard/authuser';
import CheckREF from '../Pages/CheckREF';


class Routers extends Component {
   
    render() {
        return (
            <Router  >
                <Switch>

                    <Route exact path="/login" component={LoginPages} />
                    <Route exact path="/CheckREF/:ref" component={CheckREF} />
                    <Route exact path="/CheckREF" component={CheckREF} />
                    <PrivateRoute exact path="/admin" component={MainPages} />
                    <PrivateRoute exact path="/admin/customers" component={MainPages} />
                    <PrivateRoute exact path="/admin/bar" component={MainPages} />
                    <PrivateRoute exact path="/admin/customers_manage" component={MainPages} />
                    <PrivateRoute exact path="/admin/customers_managetax" component={MainPages} />
                    <PrivateRoute exact path="/admin/slip" component={MainPages} />
                    <PrivateRoute exact path="/admin/payslip_manage" component={MainPages} />
                   
                    <PrivateRoute exact path="/admin/payslip_list_manage" component={MainPages} />
                    <PrivateRoute exact path="/admin/PaySlipExpensetype" component={MainPages} />
                    <PrivateRoute exact path="/admin/PayslipRevenueType" component={MainPages} />
                    <PrivateRoute exact path="/admin/Excelreportipay" component={MainPages} />
                    <PrivateRoute exact path="/admin/PrintPDF" component={MainPages} />
                    <PrivateRoute exact path="/admin/Payslip_export_import_data" component={MainPages} />
                    <PrivateRoute exact path="/admin/PageShowListSalaryEdit" component={MainPages} />
                    <PrivateRoute exact path="/admin/PaySlipManageUpdateDataEx" component={MainPages} />
                    <PrivateRoute exact path="/admin/PaySlipManageUpdateDataRe" component={MainPages} />
                    <PrivateRoute exact path="/admin/PaySlipManageUpdateDataSalary" component={MainPages} />
                    <PrivateRoute exact path="/admin/PaySlipExpensetypeReport" component={MainPages} />
                    <PrivateRoute exact path="/admin/PageListHistoryPrintAdmin" component={MainPages} />
                    <PrivateRoute exact path="/admin/PageNotify" component={MainPages} />
                    <PrivateRoute exact path="/admin/PagesHowtoConvertPDF" component={MainPages} />

                    <PrivateRouteUser exact path="/Page_list_salary" component={MainPages} />
                    <PrivateRouteUser exact path="/PageKTB" component={MainPages} />
                    <PrivateRouteUser exact path="/PageTAX" component={MainPages} />
                    <PrivateRouteUser exact path="/" component={MainPages} />
                    <PrivateRouteUser exact path="/FormPage" component={MainPages} />
                    <PrivateRouteUser exact path="/FormPagetax" component={MainPages} />
                    <PrivateRouteUser exact path="/PageListHistoryPrint" component={MainPages} />
                    <PrivateRoute exact path="/admin/FormPageList" component={MainPages} />
                    <Route component={PageNotFoundPages} />

                


                  
                </Switch>
            </Router>

        );
    }
}

export default Routers;