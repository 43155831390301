import React, { Component } from 'react'
import ReactExport from "react-export-excel";
import { Modal, Button, Input, message, InputNumber, Icon, Form, Spin } from 'antd';
import { apilogin } from '../api'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class Excelreportdata extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            load: false,
            ipayall: [],
        }
        this.get_dataipay = this.get_dataipay.bind(this)

    }



    get_dataipay() {
        //  console.log(this.props.data);


        // if (this.props.typeid === '3') {
        //     this.setState({ loading: true })
        //     this.setState({ load: true })
        //      setTimeout(() => {
        //     apilogin.post('getdata/get_dataipay',
        //     ({
        //       bank_id:this.props.data.bank_id,
        //       payslip_month:this.props.payslip_month,
        //       payslip_year:this.props.payslip_year,
        //       typeid:this.props.typeid,
        //   })
        // )

        //   .then(res => {
        //     console.log(res.data)
        //     const getdata = res.data.map((data, index) => ({
        //         key: data.customers_citizent,
        //         index: index + 1,
        //         customers_citizent: data.customers_citizent,
        //         account_number: data.account_number=== null? "xxxxx" : data.account_number,
        //         customers_name: data.customers_pname + "" +data.customers_name + " " + data.customers_lname,
        //         Salary: data.Salary,
        //         bank_id: data.bank_id,
        //         nulldata:"xxxxx",
        //        // bank_name: data.bank_name,
        //     })
        //     )
        //     this.setState({
        //         ipayall: getdata,
        //         loading: false,
        //         load: false,
        //     })
        //    //  console.log(res.data);
        //   //  this.setState({ ipayall: res.data, loading: false, load: false })

        //   }) 
        // }, 500);

        // } else {
        this.setState({ loading: true })
        this.setState({ load: true })
        setTimeout(() => {
            apilogin.post('getdata/get_dataipay',
                ({
                    bank_id: this.props.data.bank_id,
                    payslip_month: this.props.payslip_month,
                    payslip_year: this.props.payslip_year,
                    typeid: this.props.typeid,
                    customers_group: this.props.customers_group,
                })
            )

                .then(res => {

                    const getdata = res.data.map((data, index) => ({
                        key: data.customers_citizent,
                        index: index + 1,
                        customers_citizent: data.customers_citizent,
                        account_number: data.account_number === null ? "xxxxx" : data.account_number,
                        customers_name: data.customers_pname + "" + data.customers_name + " " + data.customers_lname,
                        Salary: data.Salary,
                        payslip_revenue: data.payslip_revenue,
                        payslip_expenditure: data.payslip_expenditure,
                        // total:Number(data.Salary+data.payslip_revenue)-Number(data.payslip_expenditure) ,
                        total:data.total,
                        bank_id: data.bank_id,
                        nulldata: "xxxxx",
                        // bank_name: data.bank_name,
                    })
                    )
                    this.setState({
                        ipayall: getdata,
                        loading: false,
                        load: false,
                    })
                    //   console.log(res.data);
                    //  this.setState({ ipayall: res.data, loading: false, load: false })

                })
        }, 500);

        // }


    }



    render() {

        const dataSet1 = [
            {
                name: "Johson",
                amount: 30000,
                sex: 'M',
                is_married: true
            },
            {
                name: "Monika",
                amount: 355000,
                sex: 'F',
                is_married: false
            },
            {
                name: "John",
                amount: 250000,
                sex: 'M',
                is_married: false

            },
            {
                name: "Josef",
                amount: 450500,
                sex: 'M',
                is_married: true
            }
        ];

        // const dataSet2 = [
        //     {
        //         name: "Johnson",
        //         total: 25,
        //         remainig: 16
        //     },
        //     {
        //         name: "Josef",
        //         total: 25,
        //         remainig: 7
        //     }
        // ];
        return (
            <>

                {this.state.loading === true ? <Button size="small" onClick={this.get_dataipay} > {this.state.load === true ? <><Spin />loading</> : <> &nbsp; Load Data</>}</Button> :
                    <ExcelFile filename={this.props.data.bank_name + this.props.payslip_month + this.props.payslip_year} element={<Button size="small"> <Icon type="check" style={{ color: '#009900' }} /> &nbsp;EXPORT IPAY</Button>}>
                        <ExcelSheet data={this.state.ipayall} name={this.props.data.bank_name}>
                            <ExcelColumn label="Receiving Bank Code" style={{ rgb: "009900" }} value="bank_id" />
                            <ExcelColumn label="Receiving A/C No." value="account_number" />
                            <ExcelColumn label="Receiver Name" value="customers_name" />
                            <ExcelColumn label="Transfer Amount" value="total" />
                            {/* <ExcelColumn label="Transfer Amount" value={(col)=>(col.Salary+col.payslip_revenue)-col.payslip_expenditure} />  */}
                            {/* <ExcelColumn label="Transfer Amount" value={(col) => Number(col.Salary) + Number(col.payslip_revenue)} /> */}
                            <ExcelColumn label="Citizen ID/Tax ID" value="customers_citizent" />
                            <ExcelColumn label="Reference No./ DDA Ref 2" value="nulldata" />
                            <ExcelColumn label="Email" value="nulldata" />
                            <ExcelColumn label="Mobile No." value="nulldata" />
                        </ExcelSheet>
                    </ExcelFile>
                }
                {/* <ExcelSheet data={dataSet2} name="Leaves">
                <ExcelColumn label="Name" value="name"/>
                <ExcelColumn label="Total Leaves" value="total"/>
                <ExcelColumn label="Remaining Leaves" value="remaining"/>
            </ExcelSheet> */}


            </>
        )
    }
}
