import React, { Component } from 'react'
//import XLSX from 'xlsx';
import * as XLSX from 'xlsx'
//import { read, writeFileXLSX } from "xlsx";
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBIcon } from 'mdbreact';
import { Button, Icon } from 'antd';
import ExcelReader from '../Components/excel_to_json/ExcelReader';
import { apilogin } from '../api';
import Texty from 'rc-texty';

export default class Bttn_import_excel_all_salary extends Component {
  

    constructor(props) {
        super(props)
        this.state = {
            modal: false,
            salary:[],
            statusupload : false,
            statusuploadfull : false,
            count_insert_ok: 0,
            count_insert_err: 0,
            load_txt:"",
        }
        this.handleFile = this.handleFile.bind(this) 
        this.readUploadFile = this.readUploadFile.bind(this) 
    }

  

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }


     readUploadFile = (e) => {
        e.preventDefault();
        if (e.target.files) {
            const reader = new FileReader();
          //  console.log(e.target.files);
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);
              //  console.log(json);
                this.setState({
                    salary: json,
                    statusupload: true
                });
              
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
        
    }

    handleFile() {
        let salarydata = []
        let count_insert_ok = 0
        let count_insert_err = 0
        

        this.state.salary.map((data) => {
            let yy = {
                ID_citizen: data.ID_citizen,
                salary: data.salary,
                month: data.month,
                year: data.year,
                ID_TYPE: data.ID_TYPE
            }
            salarydata.push(yy)
        })





        for (let i = 0; i < salarydata.length; i++) {
            console.log(salarydata[i].ID_citizen)
            console.log(salarydata[i].salary)

            setTimeout(() => {
               
              let  sql_insert = ` UPDATE tb_history_salary set history_salary_salary = '${salarydata[i].salary}' WHERE  customers_citizent = '${salarydata[i].ID_citizen}' AND history_salary_year = '${salarydata[i].year}' AND history_salary_month = '${salarydata[i].month}' AND customers_type = '${salarydata[i].ID_TYPE}'  ; `

                apilogin.post('insert_salary/import_list_payslip_update', ({ sql_insert: sql_insert }))
                
                  .then((res) => {


                    if (res.data.affectedRows === 1) {
                      count_insert_ok = count_insert_ok + 1
                      this.setState({ count_insert_ok: this.state.count_insert_ok + 1 })
                    }
                    else {
                      count_insert_err = count_insert_err + 1
                      this.setState({ count_insert_err: this.state.count_insert_err + 1 })
                    }
                    if (count_insert_ok === salarydata.length - 1 ) {
                      this.setState({ load_txt: "นำเข้าข้อมูลเสร็จสิ้น" })
                      this.setState({ statusuploadfull: true })
                    } 
                  })
              }, i * 25);


        }

    }
    
    

    render() {
        console.log(this.state.salary)
        return (
            <>

                <Button onClick={this.toggle}><Icon type="fund" /> นำเข้าข้อมูลเงินเดือน</Button>

                <MDBModal size="lg" isOpen={this.state.modal} toggle={this.toggle} centered>
                    <MDBModalHeader toggle={this.toggle}><strong><Icon type="fund" /> นำเข้าข้อมูลเฉพาะเงินเดือน</strong></MDBModalHeader>
                    <MDBModalBody className="text-left">
                             {/* <ExcelReader 
                               payslip_month={this.props.payslip_month}
                               payslip_year={this.props.payslip_year}
                               data={this.props.data}
                               customers_type={this.props.customers_type}
                             /> */}

                             
<form>
    <label htmlFor="upload">Upload File</label>
    <input
        type="file"
        name="upload"
        id="upload"
        onChange={this.readUploadFile}
    />
</form>
                <div className="mt-3 mb-5 text-center">
                {this.state.statusupload === true ?<>
                    <Button size="large" color="primary" onClick={this.handleFile} ><MDBIcon far icon="play-circle" />&nbsp; เริ่มอัพโหลดข้อมูล</Button>
                </>:<></>}

                {this.state.statusuploadfull === true ?<>
                    <div className="texty-demo green-text mt-0 mb-3" style={{ fontSize: '22px' }}>
                          <MDBIcon icon="check-double" /> <Texty > อัพเดตสำเร็จ</Texty>
                        </div>
                </>:<></>}



                </div>


                    </MDBModalBody>
                </MDBModal>

            </>
        )
    }
}
