import React, { Component } from 'react'
import { Popconfirm, message, Button } from 'antd';
import { apilogin } from '../api';


function confirm(e) {
    console.log(e);
    message.success('Click on Yes');
}

function cancel(e) {
    console.log(e);
    message.error('Click on No');
}
export default class Bttn_customer_del extends Component {
    constructor(props) {
        super(props)
        this.state = {
           
        }
       this.onOK=this.onOK.bind(this)
    }
    onOK(){
        apilogin.post('insert_customers/delete_data_for_edit_customers', ({
            customers_citizent: this.props.customers_citizent,
            customers_type_id: this.props.customers_type_id,
        }))
            .then(res => {
                    this.props.updatedata_props()
            });
       
    }
    render() {
        return (
            <>
                <Popconfirm
                    title="คุณแน่ใจใช่ไหมที่จะลบข้อมูลนี้?"
                    onConfirm={this.onOK}
                    okText="ยืนยัน"
                    cancelText="ยกเลิก"
                >
                    <Button  icon="delete" type="dashed" size="small" >ลบ</Button>
                </Popconfirm>
            </>
        )
    }
}
