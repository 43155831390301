import React, { Component } from 'react'
import { apilogin, theme, mouth, year, detail_user, adminSuper, admin1, admin2, Salary_BG } from '../api'
import { Table, Input, Icon, InputNumber, message, notification, Spin, Popconfirm } from 'antd'
import { MDBContainer, MDBCardBody, MDBCardHeader, MDBCard, MDBRow, MDBCol, MDBIcon } from 'mdbreact'
import Highlighter from 'react-highlight-words';
import Bttn_customer_add_expenditure from '../Components/Bttn_customer_add_expenditure';
import NumberFormat from 'react-number-format';
import Select from 'react-select'
import Bttn_customer_add_revenue from '../Components/Bttn_customer_add_revenue';
import PrintPDF from './PrintPDF';
import Bttn_export_excel_all_person from '../Components/Bttn_export_excel_all_person';
import Bttn_import_excel_all_person from '../Components/Bttn_import_excel_all_person';

import Bttn_upload_file from '../Components/Bttn_upload_file';
import Bttn_upload_file_main from '../Components/Bttn_upload_file_main';
import { Autocomplete, Alert } from '@material-ui/lab';
import { TextField, Select as SelectM, MenuItem, FormControl, InputLabel, Button, Card, CardContent, Divider } from '@material-ui/core';
import MUIDataTable from "mui-datatables";


export default class PaySlipManage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            options_years: [],
            loading: false,
            search: '',

            payslip_month: '',
            payslip_year: '',
            customers_type: "",

            // payslip_month: '10',
            // payslip_year: '2020',
            // customers_type: "4",
            def_customers_type: { value: "4", label: "พนักงานมหาลัย", admin_level: '2' },

            search_check: false,

            nameuser: '',
            admin_type: '',

            customer_type: null,
            // customer_type: [],
            customer_type_1: [],
            customer_type_2: [],
            total_salary:0,
        }
        this.get_customer_status = this.get_customer_status.bind(this)
        this.updatedata = this.updatedata.bind(this)
        this.updatedata_props = this.updatedata_props.bind(this)
        this.onMouthChange = this.onMouthChange.bind(this)
        this.onYearsChange = this.onYearsChange.bind(this)
    }


    onMouthChange(event) {
        // this.setState({ payslip_month: event.value })
        this.setState({ payslip_month: event.target.value })
    }
    onYearsChange(event) {
        //  this.setState({ payslip_year: event.value })
        this.setState({ payslip_year: event.target.value })
    }

    get_customer_status() {
        if (this.state.payslip_month === "") {
            message.warning("กรุณาเลือกเดือน")
        }
        else if (this.state.payslip_year === "") {
            message.warning("กรุณาเลือกปี")
        }
        else if (this.state.customers_type === "") {
            message.warning("กรุณาเลือกประเภท")
        }
        else {
            this.setState({ loading: true })

            apilogin.get('getdata/customer_manage_level/' + this.state.payslip_year + '/' + this.state.payslip_month + '/' + this.state.customers_type)
                .then(res => {
                    if (res.data === "") {
                        message.warning("ไม่พบข้อมูล")
                    }
                    else {
                        this.updatedata(res.data)
                    }
                })

            apilogin.get('getdata/customer_manage_level_only_salary/' + this.state.payslip_year + '/' + this.state.payslip_month + '/' + this.state.customers_type)
                .then(res => {
                //    console.log(res.data[0].history_salary_salary)
                   this.setState({total_salary:res.data[0].history_salary_salary})
                })

        }

    }

    updatedata(data) {
        const getdata = data.map((data, index) => ({
            key: data.customers_citizent,
            index: index + 1,
            customers_citizent: data.customers_citizent,
            customers_pname: data.customers_pname,
            customers_name: data.customers_name + " " + data.customers_lname,
            customers_fname: data.customers_name,
            customers_lname: data.customers_lname,
            customer_type_name: data.customer_type_name,
            customer_type_id: data.customer_type_id,
            customer_type_name: data.customer_type_name,
            customer_status_name: data.customer_status_name,
            // payslip_revenue: Number(data.payslip_revenue) + data.Salary,
            history_salary_salary: Number(data.history_salary_salary),
            payslip_revenue: Number(data.history_salary_salary + data.payslip_revenue),
            payslip_expenditure: Number(data.payslip_expenditure),
            payslip_expenditure_out: Number(data.payslip_expenditure_out),
            payslip_total: Number((data.payslip_revenue + data.history_salary_salary) - data.payslip_expenditure),
            bank_name: data.bank_name,
            Salary: data.Salary,
        })
        )
        this.setState({
            data: getdata,
            search_check: true,
            loading: false,
        })
    }

    updatedata_props() {
        this.setState({ loading: true })
        setTimeout(() => {
            this.get_customer_status();
        }, 0);
    }

    componentDidMount() {
        // this.get_customer_status()
        this.setState({
            // payslip_month: '07',
            // customers_type: "4",

            payslip_month: mouth,
            payslip_year: year,
        })
        if (detail_user.nameuser !== "") {
            this.setState({ nameuser: detail_user.nameuser })
            this.setState({ admin_type: detail_user.admin_type })

            apilogin.get('getdata/customer_type')
                .then(res => {
                    // console.log(res.data)
                    let customer_type = []
                    let customer_type_1 = []
                    let customer_type_2 = []

                    let cc = []
                    res.data.map((data) => {
                        let yy = {
                            value: data.customer_type_id,
                            label: data.customer_type_name,
                            admin_level: data.admin_level,
                        }
                        cc.push(yy)
                    })


                    cc.map((data) => {
                        customer_type.push(data)
                        if (data.admin_level === "1") {
                            customer_type_1.push(data)
                        }
                        else if (data.admin_level === "2") {
                            customer_type_2.push(data)
                        }
                    })

                    let list = []
                    if (this.state.admin_type === adminSuper) {
                        list = customer_type
                    } else if (this.state.admin_type === admin1) {
                        list = customer_type_1
                    } else if (this.state.admin_type === admin2) {
                        list = customer_type_2
                    }
                    this.setState({
                        customer_type: list,
                        // customer_type: customer_type,
                        // customer_type_1: customer_type_1,
                        // customer_type_2: customer_type_2,
                    })

                })
        }
        else {
            message.error('ไม่พบข้อมูลผู้ใช้ กรุณาล็อคอินใหม่')
        }
        apilogin.get('getdata/getyear')
            .then(res => {
                let year = []
                res.data.map((data) => {
                    let yy = {
                        value: data.name_year,
                        label: "ปี " + data.name_year_th,
                    }
                    year.push(yy)
                })
                this.setState({
                    options_years: year
                });
            })
            .catch((err) => {
                notification.error({
                    message: 'โหลดรายการปีไม่สำเร็จ ' + err,
                })
            })




    }


    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`ค้นหา`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    variant="contained"
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
            </Button>
                <Button variant="outlined" onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        ),
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    render() {

        const options_mounth = [
            { value: '01', label: 'มกราคม' },
            { value: '02', label: 'กุมภาพันธ์' },
            { value: '03', label: 'มีนาคม' },
            { value: '04', label: 'เมษายน' },
            { value: '05', label: 'พฤษภาคม' },
            { value: '06', label: 'มิถุนายน' },
            { value: '07', label: 'กรกฎาคม' },
            { value: '08', label: 'สิงหาคม' },
            { value: '09', label: 'กันยายน' },
            { value: '10', label: 'ตุลาคม' },
            { value: '11', label: 'พฤศจิกายน' },
            { value: '12', label: 'ธันวาคม' },
        ]




        let { sortedInfo, filteredInfo } = this.state;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};

        const columns = [
            {
                title: 'ลำดับ',
                dataIndex: 'index',
                key: 'index',
                width: 60,
                className: theme,
                render: text => <div className="text-center">{text}</div>,
            },
            {
                title: 'ชื่อ-นามสกุล',
                dataIndex: 'customers_name',
                key: 'customers_name',
                width: 200,
                className: theme,
                ...this.getColumnSearchProps('customers_name'),
                render: text => <div className="text-left">{text}</div>,
            },

            // {
            //     title: 'ประเภทบุคลากร',
            //     dataIndex: 'customer_type_name',
            //     key: 'customer_type_name',
            //     width: 200,

            //     onFilter: (value, record) => record.customer_type_name.indexOf(value) === 0,
            //     render: text => <div className="text-center">{text}</div>,
            // },
            {
                title: <div className={theme === "light" ? "green-text " : theme} ><Icon type="arrow-up" /> เงินเดือน</div>,
                dataIndex: 'history_salary_salary',
                key: 'history_salary_salary',
                width: 150,
                className: theme,
                sorter: (a, b) => a.history_salary_salary - b.history_salary_salary,
                render: text =>
                    <div className={theme === "light" ? "green-text " : theme}>
                        {Number(text).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                    </div>
            },
            {
                title: <div className={theme === "light" ? "green-text " : theme}><Icon type="arrow-up" /> รายรับ</div>,
                dataIndex: 'payslip_revenue',
                key: 'payslip_revenue',
                width: 100,
                className: theme,
                sorter: (a, b) => a.payslip_revenue - b.payslip_revenue,
                render: text =>
                    <div className={theme === "light" ? "green-text " : theme}>
                        {Number(text).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                    </div>
            },

            {
                title: <div className={theme === "light" ? "red-text " : theme}><Icon type="arrow-down" /> รายจ่าย/ใน</div>,
                dataIndex: 'payslip_expenditure',
                key: 'payslip_expenditure',
                width: 110,
                className: theme,
                sorter: (a, b) => a.payslip_expenditure - b.payslip_expenditure,
                render: text =>
                    <div className={theme === "light" ? "red-text " : theme}>
                        {Number(text).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                    </div>
            },
            {
                title: <div className={theme === "light" ? "red-text " : theme}><Icon type="arrow-down" /> รายจ่าย/นอก</div>,
                dataIndex: 'payslip_expenditure_out',
                key: 'payslip_expenditure_out',
                width: 110,
                className: theme,
                sorter: (a, b) => a.payslip_expenditure_out - b.payslip_expenditure_out,
                render: text =>
                    <div className={theme === "light" ? "red-text " : theme}>
                        {Number(text).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                    </div>
            },

            {
                title: <div className={theme === "light" ? "blue-text " : theme}><Icon type="snippets" /> ยอดสุทธิ</div>,
                dataIndex: 'payslip_total',
                key: 'payslip_total',
                className: theme,
                sorter: (a, b) => a.payslip_total - b.payslip_total,
                width: 110,
                render: text =>
                    <div className={theme === "light" ? "blue-text " : theme}>
                        {Number(text).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                    </div>
            },
            // {

            {
                title: 'จัดการ',
                key: 'manage',
                width: 310,
                className: theme,
                render: (text, record) => (
                    <div className="text-center">
                        <Bttn_customer_add_expenditure pop={this.props} data={this.state} customers_citizent={record.customers_citizent} customer_type_id={record.customer_type_id} updatedata_props={this.updatedata_props} /> &nbsp;
                        <Bttn_customer_add_revenue pop={this.props} data={this.state} customers_citizent={record.customers_citizent} customer_type_id={record.customer_type_id} updatedata_props={this.updatedata_props} /> &nbsp;
                        {/* {record.payslip_total === 0 || record.payslip_expenditure === 0? */}
                        {record.payslip_total === 0 ?
                            <Button variant="outlined" size="small" disabled>  {this.state.loading === true ? <Icon type="loading" /> : <><MDBIcon icon="print" />&nbsp;พิมพ์</>} </Button>
                            : <PrintPDF
                                showicon={true}
                                dataprint={record}
                                payslip_month={this.state.payslip_month}
                                payslip_year={this.state.payslip_year}

                            />}

                        {record.customer_type_id === 6 || record.customer_type_id === 2 ? <> &nbsp;
                            <a target="_blank"
                                href={"https://e-payslip.kpru.ac.th/print_slip/" + record.customers_citizent + "-" + this.state.payslip_year + "-" + this.state.payslip_month + ".pdf"}>
                                <Button variant="outlined" size="small"><MDBIcon icon="print" />&nbsp; พิมพ์นอกระบบ </Button>
                            </a>

                        </> : null}

                        &nbsp;
                        <Bttn_upload_file_main
                            customers_name={record.customers_name}
                            customers_citizent={record.customers_citizent}
                            customer_type={record.customer_type_id}
                            payslip_month={this.state.payslip_month}
                            payslip_year={this.state.payslip_year}
                        />

                    </div>
                ),
            },


        ]


        const columns_mui_all = [


            {
                name: "index",
                label: <div className="text-center" >#</div>,
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        let i = tableMeta.rowIndex
                        return (
                            <>
                                <div className="text-center">
                                    {tableMeta.rowIndex + 1}
                                </div>
                            </>
                        );
                    }
                }
            },
            
 {
                name: "customers_citizent",
                // label: "ปี",
                label: <div className="text-left" >เลขบัตร ปชช.</div>,
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div className="text-left">
                                {value}
                            </div>
                        );
                    }
                }
            },
            
            {
                name: "customers_name",
                // label: "ปี",
                label: <div className="text-left" >ชื่อ-นามสกุล</div>,
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div className="text-left">
                                {value}
                            </div>
                        );
                    }
                }
            },

            {
                name: "customer_type_name",
                // label: "รายรับ",
                label: <div className="text-center" >ประเภทบุคลากร</div>,
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div className="text-center">
                                {value}
                            </div>
                        );
                    }
                }
            },

            {
                name: "history_salary_salary",
                label: <div className={"" + theme === "light" ? "green-text text-right " : " text-right " + theme}>เงินเดือน</div>,
                options: {
                    filter: false,
                    sort: false,
                    // sortDirection: 'asc',
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div className={"" + theme === "light" ? "green-text text-right " : " text-right " + theme}>
                                {Number(value).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                            </div>
                        );
                    }
                }
            },

            {
                name: "payslip_revenue",
                label: <div className={"" + theme === "light" ? "green-text text-right " : " text-right " + theme}>รายรับ</div>,
                options: {
                    filter: false,
                    sort: false,
                    // display:'text-left',
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div className={"" + theme === "light" ? "green-text text-right " : " text-right " + theme}>
                                {Number(value).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                            </div>
                        );
                    }
                }
            },

            {
                name: "payslip_expenditure",
                // label: "รายจ่าย",
                label: <div className={"" + theme === "light" ? "red-text text-right " : " text-right " + theme}>รายจ่าย</div>,
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div className={"" + theme === "light" ? "red-text text-right " : " text-right " + theme}>
                                {Number(value).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                            </div>
                        );
                    }
                }
            },

            {
                name: "payslip_expenditure_out",
                // label: "รายจ่าย",
                label: <div className={"" + theme === "light" ? "red-text text-right " : " text-right " + theme}>รายจ่าย/นอก</div>,
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div className={"" + theme === "light" ? "red-text text-right " : " text-right " + theme}>
                                {Number(value).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                            </div>
                        );
                    }
                }
            },
            {
                name: "payslip_total",
                // label: "รายจ่าย",
                label: <div className={"" + theme === "light" ? "blue-text text-right " : " text-right " + theme}>ยอดสุทธิ</div>,
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div className={"" + theme === "light" ? "blue-text text-right " : " text-right " + theme}>
                                {Number(value).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                            </div>
                        );
                    }
                }
            },


            {
                name: "manage",
                label: <div className="text-center" >จัดการ</div>,
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        let i = tableMeta.rowIndex
                        let record = this.state.data
                        return (
                            <>
                                <div className="text-center">

                                    <Bttn_customer_add_expenditure pop={this.props} data={this.state} customers_citizent={record[i].customers_citizent} customer_type_id={record[i].customer_type_id} updatedata_props={this.updatedata_props} /> &nbsp;
                                    <Bttn_customer_add_revenue pop={this.props} data={this.state} customers_citizent={record[i].customers_citizent} customer_type_id={record[i].customer_type_id} updatedata_props={this.updatedata_props} /> &nbsp;
                                   {record[i].payslip_total === 0 ?
                                        <Button variant="outlined" size="small" disabled>  {this.state.loading === true ? <Icon type="loading" /> : <><MDBIcon icon="print" />&nbsp;พิมพ์</>} </Button>
                                        : <PrintPDF
                                            showicon={true}
                                            dataprint={record[i]}
                                            payslip_month={this.state.payslip_month}
                                            payslip_year={this.state.payslip_year}

                                        />}

                                    {/* {record[i].customer_type_id === 6 || record[i].customer_type_id === 2 ? <> &nbsp;
                                       <a target="_blank"
                                            href={"https://e-payslip.kpru.ac.th/print_slip/" + record[i].customers_citizent + "-" + this.state.payslip_year + "-" + this.state.payslip_month + ".pdf"}>
                                            <Button variant="outlined" size="small"><MDBIcon icon="print" />&nbsp; พิมพ์นอกระบบ </Button>
                                        </a>
                                    </> : null} */}

                                    &nbsp;
                                   <Bttn_upload_file_main
                                        customers_name={record[i].customers_name}
                                        customers_citizent={record[i].customers_citizent}
                                        customer_type={record[i].customer_type_id}
                                        payslip_month={this.state.payslip_month}
                                        payslip_year={this.state.payslip_year}
                                    />

                                </div>
                            </>
                        );
                    }
                }
            },
        ];

        return (
            // <div className="d-flex justify-content-center mt-5 text-center">
            <div className="mt-5 text-center ml-4 mr-4">
                {/* // <MDBContainer className="mt-5 mb-5">  */}

                {/* <Card elevation="3"
                    style={{
                        // minWidth: "72rem",
                        // minHeight: "40rem",
                        backgroundColor: Salary_BG

                    }} >
                    <CardContent>
                        <Icon type="contacts" /> จัดการเงินเดือน
                    </CardContent>
                </Card><br /> */}


                <MDBCard
                    // color={Salary_BG}
                    style={{
                        // minWidth: "72rem",
                        minHeight: "40rem",
                        background: "red"

                    }} className={theme} >
                    <MDBCardHeader className={"mt-2 form-header rgba-indigo-light rounded -hoverable " + theme} >
                        <h2 className={theme} style={{ textAlign: 'center', fontWeight: 'bold' }} >
                            <Icon type="contacts" /> จัดการเงินเดือน
                         </h2>
                    </MDBCardHeader>

                    <MDBCardBody className="bodytable">


                        <MDBRow className="text-center">
                            {/* <MDBCol sm="1">
                                            </MDBCol> */}
                            <MDBCol xl="3" className="mb-1 text-left">
                              
                                {this.state.customer_type === null ? <>
                                    <Spin />
                                </> : <>
                                        {/* <Select
                                            className={"light"}
                                            // className={"select-"+theme}
                                            options={this.state.customer_type}
                                            // options={this.state.admin_type === "super" ? this.state.customer_type :
                                            //     this.state.admin_type === "adminlevel1" ? this.state.customer_type_1 :
                                            //         this.state.admin_type === "adminlevel2" ? this.state.customer_type_2 : null}
                                            //value={this.state.customer_type[Number(this.state.customers_type)]}
                                            defaultInputValue={this.state.customers_type}
                                            placeholder={<span><MDBIcon icon="users" /> &nbsp; เลือกประเภทบุคลากร</span>} onChange={(e) => {
                                                // console.log(e)
                                                this.setState({ customers_type: e.value })
                                            }} name="customers_type" /> */}

                                        <Autocomplete
                                            // loading={this.state.customer_type === null ? true : false}
                                            size="small"
                                            onChange={(e, newValue) => {
                                                if (newValue !== null) {
                                                    // console.log(newValue.value)
                                                    this.setState({ customers_type: newValue.value })
                                                }
                                            }}
                                            name="customers_type"
                                            className={"mb- "}
                                            options={this.state.customer_type}
                                            getOptionLabel={(option) => String(option.label)}
                                            // value={this.state.def_customers_type}
                                            // value={this.state.customer_type[Number(this.state.customers_type)]}
                                            renderInput={(params) => <TextField variant="outlined"  {...params} label="ประเภทบุคลากร" />}
                                        />
                                    </>}


                            </MDBCol>
                            <MDBCol xl="3" className="mb-1 text-left">
                                {/* <Select options={options_mounth}
                                    className={"light"}
                                    defaultInputValue={this.state.payslip_month}
                                    placeholder={<span><MDBIcon far icon="calendar-alt" /> &nbsp; เลือกเดือน</span>} 
                                    onChange={this.onMouthChange} name="payslip_month" /> */}

                                <select
                                    className={"form-control " + theme} name="payslip_month"
                                    onChange={this.onMouthChange} value={this.state.payslip_month}
                                >
                                    <option value="">เลือกเดือน</option>
                                    {options_mounth.map((data) => {
                                        return <option value={data.value}> {data.label}</option>
                                    })}
                                </select>


                            </MDBCol>
                            <MDBCol xl="3" className="mb-1 text-center">
                                {/* <Select
                                    className={"light"}
                                    options={this.state.options_years}
                                    defaultInputValue={this.state.payslip_year}
                                    placeholder={<span><MDBIcon icon="chart-line" /> &nbsp; เลือกปี</span>} onChange={this.onYearsChange} name="payslip_year" /> */}


                                <select
                                    className={"form-control " + theme} name="payslip_year"
                                    onChange={this.onYearsChange} value={this.state.payslip_year}
                                >
                                    <option value="">เลือกปี</option>
                                    {this.state.options_years.map((data) => {
                                        return <option value={data.value}> {data.label}</option>
                                    })}
                                </select>

                            </MDBCol>
                            <MDBCol xl="3" className="text-center mb-1" style={{ marginTop: '0.0rem' }}>
                                <Button fullWidth variant="contained" block icon="search" size="large" color="primary" onClick={this.get_customer_status}>
                                    ค้นหา
                                  </Button>
                            </MDBCol>
                        </MDBRow>





                        {this.state.search_check === true ? <>
                            {/* <div className="mt-3 text-right">
                                        {this.state.loading === true ? <><Button size="">Loading..</Button></> : <>
                                            <Bttn_export_excel_all_person
                                                nameexcel="export ข้อมูลเงินเดือน "
                                                payslip_month={this.state.payslip_month}
                                                payslip_year={this.state.payslip_year}
                                                data={this.state.data}
                                                customers_type={this.state.customers_type}
                                            />

                                            <Bttn_import_excel_all_person
                                                nameexcel="import ข้อมูลเงินเดือน"
                                                payslip_month={this.state.payslip_month}
                                                payslip_year={this.state.payslip_year}
                                                data={this.state.data}
                                                customers_type={this.state.customers_type}
                                            />
                                        </>}
                                    </div> */}
                            <div className="mt-3 text-right">
                              
                                   
                                         <PrintPDF
                                            print_gen_all={true}
                                            showicon={true}
                                            dataprint={this.state.data}
                                            payslip_month={this.state.payslip_month}
                                            payslip_year={this.state.payslip_year}

                                        />
                            </div>



                            {/* <Table dataSource={this.state.data} columns={columns}
                                pagination={{ pageSize: 25 }}
                                bordered
                                size="small"
                                loading={this.state.loading}
                                rowClassName={theme}
                                className={"mt-3 time-table-row-select " + theme}
                            /> */}
                            <div className="text-right mt-2" >
                            
                           รวมเงินเดือน <br/>
                           {Number(this.state.total_salary).toLocaleString(navigator.language, { minimumFractionDigits: 2 })} บาท
                           
                            </div>
                            <br />
                            <Divider/>
                            <MUIDataTable
                                data={this.state.data}
                                columns={columns_mui_all}
                                options={{
                                    draggable: true,
                                    responsive: "simple",
                                    filterType: 'dropdown',
                                    selectableRows: 'none',
                                    viewColumns: false,
                                    rowsPerPage: 20,
                                    rowsPerPageOptions: [10, 20, 50, 100],
                                    filter: false,
                                    // pagination: false,
                                    // search: false,
                                    download: false,
                                    print: false,
                                }}
                            />

                        </>
                            :
                            <div className="text-center">

                            </div>
                        }

                    </MDBCardBody>
                </MDBCard>

                {/* */}

                {
                    message.config({
                        top: 60,
                        duration: 1,
                    })
                }

                {/* </MDBContainer> */}
            </div>
        )
    }
}
