import React, { Component } from 'react'
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBInput, MDBIcon, MDBTable, MDBTableBody, MDBTableHead, } from 'mdbreact';
import Axios from 'axios'
import { Alert, Form, Input, Button, Select, Icon, message, Tag, Badge, Tooltip, Divider   } from 'antd';
import { apilogin, theme, detail_user } from '../api'

export default class Modeltaxforadmin extends Component {
       
           constructor(props) {
                    super(props);
            this.state = {
             
                modal: false,
                idc:'',
                onTextChangefile1:'',
                filecid:null,
                namedoc:'',
                tax_year:'2566',
                filecomment1:null,
                data:[],
                currentDateTime: new Date().toISOString().slice(0, 10),
              
            }
      
      this.onTextChange = this.onTextChange.bind(this);
      this.onTextChangefile1 = this.onTextChangefile1.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.ssid = this.ssid.bind(this);
      this.resetdata = this.resetdata.bind(this);
      this.resetdata = this.resetdata.bind(this);
      this.get_show = this.get_show.bind(this);

      }

          toggle = () => {
            this.setState({
            modal: !this.state.modal
            });
            this.ssid()
            this.get_show()
            }


           ssid() {
            this.setState({ idc:this.props.idc}) 
            this.setState({ nameuser:this.props.nameuser})    
               }


           resetdata() {
            this.setState({ namedoc:''}) 
            this.setState({ filecomment1:null})   
            this.setState({ value:''})  
            
            }


            componentDidMount() {
            this.setState({ idc:this.props.idc}) 
            this.setState({ nameuser:this.props.nameuser}) 
            this.get_show()
                    
            }


        onTextChange = (e) => {
            this.setState({ [e.target.name]: e.target.value })
        }

        onTextChangefile1 = event => {  
            // console.log(event.target.files[0])
            this.setState({filecid: event.target.files[0]}); 
         //  this.setState({filecid: event.target.value}); 
         }

         handleChange(event) {  
            console.log(event.target.files[0])
              this.setState({filecomment1: event.target.files[0]}); 
       }

        onTextChangefile1 = event => {  
                // console.log(event.target.files[0])
                this.setState({filecid: event.target.files[0]}); 
             //  this.setState({filecid: event.target.value}); 
         }

         get_show() {
         //   Toast.loading('Loading..')
           // this.setState({ loading: true })
            let sent = {
                id: this.props.idc,
                tax_year: this.state.tax_year
            }
            apilogin.post('getdata/gettax', sent)
                .then(res2 => {
                    // console.log(res2)
                    this.setState({ data: res2.data })
    
                })
    
    
        }

       

        handleSubmit() {
            // alert('A name was submitted: ' + this.state.value);
         
            if (this.state.namedoc === '' || this.state.filecomment1 === null) {
                message.error("กรุณาเพิ่มชื่อเอกสาร หรือไฟล์")
            }else{
                  setTimeout(() => {
                        
                            const datafrm = new FormData(); //สร้างฟอร์มสำหรับการส่งข้อมูล
                            datafrm.append("tax_idc", this.state.idc);//2
                            datafrm.append("tax_name", this.state.namedoc);//3
                            datafrm.append("avatar", this.state.filecomment1);//41
                            datafrm.append("tax_year", this.state.tax_year);//3
                        
                        Axios.post("https://kpruinnovation.kpru.ac.th/tax/uploadtax2.php",datafrm,{
                            headers: {
                                'content-type': 'multipart/form-data'
                            }
                        })
                        
                        .then(res => {
                            console.warn(res);
                            console.log("response: ", res)
                            if (res.data.length !== 0) {
                            // this.showitem = true;
                            console.log(res)
                            console.log(res.data.length) 
                            }else{
                            // window.location.href = '/'
                            }
  
                            if (res.status = 'success999') {
                            message.success("อัพโหลไฟล์สำเร็จ")
                            this.get_show();
                            this.resetdata();
                            //  this.toggle()
                            // window.location.href = '/adminfaculty'
                            }else{
                            message.error("อัพโหลไฟล์ไม่สำเร็จ!")
                            }
                        })
                        
                        }, 2000);
            }

                      
   
          }
              



    render() {
        console.log(this.state.filecomment1)
        return (
            <MDBContainer>
            <MDBBtn onClick={this.toggle} size="sm">หลักฐานลดหย่อนภาษี</MDBBtn>
            <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
              <MDBModalHeader toggle={this.toggle}>เพิ่มหลักฐานลดหย่อนภาษี</MDBModalHeader>
              <MDBModalBody className="text-left">
              <h3>คุณ{this.props.nameuser}</h3>

              {/* <form onSubmit={this.submit}> */}

              {/* <label className="text-left">ชื่อเอกสาร</label><label className="red-text text-left">*</label>
                 <input  className="form-control" onChange={this.onTextChange} name="namedoc"
                  value={this.state.namedoc} placeholder="กรุณาระบุ ชื่อเอกสารลดหย่อนภาษี" required />

                <MDBInput   group   type="file" validate error="wrong" accept="application/pdf"
                success="right"  name="file" value={this.state.value} onChange={this.handleChange}/>

                  <div className="mt-3 text-center">
                     
                        <MDBBtn color="primary" onClick={this.handleSubmit} >บันทึก</MDBBtn>
                  </div> */}
              {/* </form> */}

              {/* <hr/> */}
            <p></p>
            <Divider />
            <p>เอกสารลดหย่อยภาษี</p>
              <MDBTable bordered small>
                                <MDBTableHead>
                                    <tr>
                                    <th>ลำดับ</th>
                                    <th>ชื่อเอกสาร</th>
                                    <th>ปี</th>
                                    <th>ไฟล์</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                    {this.state.data.map((data,index) =>{ 
                                        const cid2 = "https://kpruinnovation.kpru.ac.th/tax/uploadtax/"+data.taxfile;
                                    return  <tr>
                                                <td>{index+1}</td>
                                                <td>{data.tax_name}</td>
                                                <td>{data.tax_year}</td>
                                                <td>
                                                {
                                                <MDBBtn
                                                                href={cid2}
                                                                target="_blank"
                                                                color="secondary"
                                                               
                                                                 >ดูไฟล์หลักฐาน</MDBBtn>
                                                                  }
                                                </td>
                                            </tr>
                                    } )}
                                   
                                    
                                </MDBTableBody>
                             </MDBTable>

              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={this.toggle}>Close</MDBBtn>
                {/* <MDBBtn color="primary">Save changes</MDBBtn> */}
              </MDBModalFooter>
            </MDBModal>
          </MDBContainer>
        )
    }
}
