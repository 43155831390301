import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routers from "./Routers/Routers";
import MessengerFacebook from "./Components/MessengerFacebook";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { theme, Salary_BG } from "./api";
import { bgcolor } from "@material-ui/system";


function App() {
  const THEME = createMuiTheme({
    overrides: {
      MUIDataTableBodyCell: {
        root: {
          // backgroundColor: Salary_BG
        }
      },
    },
    palette: {
      type: theme,
    },
    typography: {
      "fontFamily": `"Sarabun", sans-serif`,
      "fontSize": 16,
      "fontWeightLight": 300,
      "fontWeightRegular": 400,
      "fontWeightMedium": 500,

    },
  });

  useEffect(() => {
    document.body.style.backgroundColor = Salary_BG
  }, [])

  return (

    <div 
    // className="App"

    >
      <MuiThemeProvider theme={THEME}>
        <Router>
          <Routers />
        </Router>
        <MessengerFacebook />
      </MuiThemeProvider>
    </div>

  );
}

export default App;
