import React from "react";
import { MDBRow, MDBCol, MDBBtn, MDBCard, MDBAlert } from "mdbreact";
import { MDBInput, MDBIcon } from "mdbreact/dist/mdbreact.esm";
import { apilogin, admin1, admin2, adminSuper, theme, detail_user } from "../api";
import { message, notification } from "antd";
import PDF_print_form from "../Components/PDF_print_form";
import MaskedInput from 'antd-mask-input'
import { Alert } from "@material-ui/lab";
import { Grid, Paper, Card, CardHeader, CardContent, CircularProgress, Backdrop, Typography, Button } from '@material-ui/core';
import { Lottie_Success, Lottie_check } from "../Components/MyLottie";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import Toast from 'light-toast';
import Axios from 'axios'


class FormPagetax extends React.Component {

    constructor(props) {
        super(props);
this.state = {
    loadSave: false,
    check_have: false,
    user_citizent:'',
    user_pname: '',
    user_fname: '',
    user_lname: '',
    user_position: '',
    user_office: '',
    user_belong: '',
    user_department: '',
    user_add_no: '',
    user_add_soi: '',
    user_add_road: '',
    user_add_tumbon: '',
    user_add_amphoe: '',
    user_add_province: '',
    user_add_code: '',
    user_add_phone: '',
    user_emp: '',
    user_bank_name: '',
    user_bank_branch: '',
    user_bank_type: '',
    user_bank_number: '',
    user_phone_number: '',
    user_email: '',
    user_date_add: '',
    user_time_add: '',


    tax_citizen:'',
    taxcustomers_type:'',
    tax_name: '',
    tax_lname: '',
    tax_address:'',
    tax_room:'',
    tax_fool:'',
    tax_homename:'',
    tax_homenum:'',
    tax_moonum:'',
    tax_soy:'',
    tax_yak:'',
    tax_road:'',
    tax_tum:'',
    tax_am:'',
    tax_pi:'',
    tax_code:'',
    tax1_status:'',
    tax2:'',
    tax3:'',
    tax31:'',
    tax31citizen:'',
    tax31citizen2:'',
    tax31money:'',
    tax32:'',
    tax32citizen:'',
    tax32citizen2:'',
    tax32money:'',
    tax411:'',
    tax411citizen:'',
    tax412:'',
    tax412citizen:'',
    tax411money:'',
    tax421:'',
    tax421citizen:'',
    tax422:'',
    tax422citizen:'',
    tax421money:'',
    tax51:'',
    tax51money:'',
    tax611:'',
    tax612:'',
    tax621:'',
    tax622:'',
    tax611money:'',
    tax7money:'',
    tax8money:'',
    tax9money:'',
    tax10:'',
    tax10money:'',
    tax11:'',
    tax11money:'',
    tax12money:'',
    tax13money:'',
    tax14money:'',
    tax15:'',
    tax15money:'',
    taxyear:'2566',

    Buttonupdate:false,
    tax_id:'',

    tax611c:false,
    
  
}

this.submitHandler = this.submitHandler.bind(this);
this.submitupdate = this.submitupdate.bind(this);

}
    
    onClick = (nr) => () => {
        this.setState({
            tax1_status: nr
        });
    }

    onClick2 = (nr) => () => {
        this.setState({
            tax2: nr
        });
    }

    // onClick41 = (nr) => () => {
    //     // this.setState({
    //     //     tax421: nr
    //     // });
    // }

    onClick411(e) {
        console.log(e.target.checked) 
        if (e.target.checked === true) {
            this.setState({
                tax411: 1
            });
        } else {
            this.setState({
                tax411: ''
            });
        }
    }

    onClick412(e) {
        console.log(e.target.checked) 
        if (e.target.checked === true) {
            this.setState({
                tax412: 1
            });
        } else {
            this.setState({
                tax412: ''
            });
        }
    }

    onClick421(e) {
        console.log(e.target.checked) 
        if (e.target.checked === true) {
            this.setState({
                tax421: 1
            });
        } else {
            this.setState({
                tax421: ''
            });
        }
    }

    onClick422(e) {
        console.log(e.target.checked) 
        if (e.target.checked === true) {
            this.setState({
                tax422: 1
            });
        } else {
            this.setState({
                tax422: ''
            });
        }
    }

    onClick611(e) {
        console.log(e.target.checked) 
        if (e.target.checked === true) {
            this.setState({
                tax611: 1
            });
        } else {
            this.setState({
                tax611: ''
            });
        }
    }

    onClick612(e) {
        console.log(e.target.checked) 
        if (e.target.checked === true) {
            this.setState({
                tax612: 1
            });
        } else {
            this.setState({
                tax612: ''
            });
        }
    }

    onClick621(e) {
        console.log(e.target.checked) 
        if (e.target.checked === true) {
            this.setState({
                tax621: 1
            });
        } else {
            this.setState({
                tax621: ''
            });
        }
    }

    onClick622(e) {
        console.log(e.target.checked) 
        if (e.target.checked === true) {
            this.setState({
                tax622: 1
            });
        } else {
            this.setState({
                tax622: ''
            });
        }
    }



    ongo() {
        if (detail_user.admin_type === admin1 || detail_user.admin_type === admin2 || detail_user.admin_type === adminSuper) {
            this.props.history.push({ pathname: '/FormPageList' })
        }
        else {
            this.props.history.push({ pathname: '/PageTAX' })
        }
    }

    submitHandler() {
     //   event.preventDefault();
      //  event.target.className += " was-validated";
console.log('มาแล้ว')
        // var date = new Date().getDate(); //Current Date
        // var month = new Date().getMonth() + 1; //Current Month
        // var year = new Date().getFullYear(); //Current Year
        // var hours = new Date().getHours(); //Current Hours
        // var min = new Date().getMinutes(); //Current Minutes
        // var sec = new Date().getSeconds(); //Current Seconds

  setTimeout(() => {
     //   let user_date_add = year + '-' + month + '-' + date
      //  let user_time_add = hours + ':' + min + ':' + sec
        // let data = {
        //     tax_citizen: this.state.tax_citizen,
        //     tax_name: this.state.tax_name,
        //     tax_lname: this.state.tax_lname,
        //     tax_address: this.state.tax_address,
        //     tax_room: this.state.tax_room,
        //     tax_fool: this.state.tax_fool,
        //     tax_homename: this.state.tax_homename,
        //     tax_homenum: this.state.tax_homenum,
        //     tax_moonum: this.state.tax_moonum,
        //     tax_soy: this.state.tax_soy,
        //     tax_yak: this.state.tax_yak,
        //     tax_road: this.state.tax_road,
        //     tax_tum: this.state.tax_tum,
        //     tax_am: this.state.tax_am,
        //     tax_pi: this.state.tax_pi,
        //     tax_code: this.state.tax_code,
        //     tax1_status: this.state.tax1_status,
        //     tax2: this.state.tax2,
        //     tax3: this.state.tax3,
        //     tax31: this.state.tax31,
        //     tax31citizen: this.state.tax31citizen,
        //     tax31money: this.state.tax31money,
        //     tax32: this.state.tax32,
        //     tax32citizen: this.state.tax32citizen,
        //     tax32money: this.state.tax32money,
        //     tax411: this.state.tax411,
        //     tax411citizen: this.state.tax411citizen,
        //     tax412: this.state.tax412,
        //     tax412citizen: this.state.tax412citizen,
        //     tax411money: this.state.tax411money,
        //     tax421: this.state.tax421,
        //     tax421citizen: this.state.tax421citizen,
        //     tax422: this.state.tax422,
        //     tax422citizen: this.state.tax422citizen,
        //     tax421money: this.state.tax421money,
        //     tax51: this.state.tax51,
        //     tax51money: this.state.tax51money,
        //     tax611: this.state.tax611,
        //     tax612: this.state.tax612,
        //     tax621: this.state.tax621,
        //     tax622: this.state.tax622,
        //     tax611money: this.state.tax611money,
        //     tax7money: this.state.tax7money,
        //     tax8money: this.state.tax8money,
        //     tax9money: this.state.tax9money,
        //     tax10: this.state.tax10,
        //     tax10money: this.state.tax10money,
        //     tax11: this.state.tax11,
        //     tax11money: this.state.tax11money,
        //     tax12money: this.state.tax12money,
        //     tax13money: this.state.tax13money,
        //     tax14money: this.state.tax14money,
        //     tax15: this.state.tax15,
        //     tax15money: this.state.tax15money,
        //     taxyear: this.state.taxyear,

        // }

        const datafrm = new FormData(); //สร้างฟอร์มสำหรับการส่งข้อมูล
        datafrm.append("tax_citizen", this.state.tax_citizen);//2
        datafrm.append("taxcustomers_type", this.state.taxcustomers_type);//2
        datafrm.append("tax_name", this.state.tax_name);//3
        datafrm.append("tax_lname", this.state.tax_lname);//41
        datafrm.append("tax_address", this.state.tax_address);//3
        datafrm.append("tax_room", this.state.tax_room);//3
        datafrm.append("tax_fool", this.state.tax_fool);//3
        datafrm.append("tax_homename", this.state.tax_homename);//3
        datafrm.append("tax_homenum", this.state.tax_homenum);//3
        datafrm.append("tax_moonum", this.state.tax_moonum);//3
        datafrm.append("tax_soy", this.state.tax_soy);//3
        datafrm.append("tax_yak", this.state.tax_yak);//3
        datafrm.append("tax_road", this.state.tax_road);//3
        datafrm.append("tax_tum", this.state.tax_tum);//3
        datafrm.append("tax_am", this.state.tax_am);//3
        datafrm.append("tax_pi", this.state.tax_pi);//3
        datafrm.append("tax_code", this.state.tax_code);//3
        datafrm.append("tax1_status", this.state.tax1_status);//3
        datafrm.append("tax2", this.state.tax2);//3
        datafrm.append("tax3", this.state.tax3);//3
        datafrm.append("tax31", this.state.tax31);//3
        datafrm.append("tax31citizen", this.state.tax31citizen);//3
        datafrm.append("tax31citizen2", this.state.tax31citizen2);//3
        datafrm.append("tax31money", this.state.tax31money);//3
        datafrm.append("tax32", this.state.tax32);//3
        datafrm.append("tax32citizen", this.state.tax32citizen);//3
        datafrm.append("tax32citizen2", this.state.tax32citizen2);//3
        datafrm.append("tax32money", this.state.tax32money);//3
        datafrm.append("tax411", this.state.tax411);//3
        datafrm.append("tax411citizen", this.state.tax411citizen);//3
        datafrm.append("tax412", this.state.tax412);//3
        datafrm.append("tax412citizen", this.state.tax412citizen);//3
        datafrm.append("tax411money", this.state.tax411money);//3
        datafrm.append("tax421", this.state.tax421);//3
        datafrm.append("tax421citizen", this.state.tax421citizen);//3
        datafrm.append("tax422", this.state.tax422);//3
        datafrm.append("tax422citizen", this.state.tax422citizen);//3
        datafrm.append("tax421money", this.state.tax421money);//3
        datafrm.append("tax51", this.state.tax51);//3
        datafrm.append("tax51money", this.state.tax51money);//3
        datafrm.append("tax611", this.state.tax611);//3
        datafrm.append("tax612", this.state.tax612);//3
        datafrm.append("tax621", this.state.tax621);//3
        datafrm.append("tax622", this.state.tax622);//3
        datafrm.append("tax611money", this.state.tax611money);//3
        datafrm.append("tax7money", this.state.tax7money);//3
        datafrm.append("tax8money", this.state.tax8money);//3
        datafrm.append("tax9money", this.state.tax9money);//3
        datafrm.append("tax10", this.state.tax10);//3
        datafrm.append("tax10money", this.state.tax10money);//3
        datafrm.append("tax11", this.state.tax11);//3
        datafrm.append("tax11money", this.state.tax11money);//3
        datafrm.append("tax12money", this.state.tax12money);//3
        datafrm.append("tax13money", this.state.tax13money);//3
        datafrm.append("tax14money", this.state.tax14money);//3
        datafrm.append("tax15", this.state.tax15);//3
        datafrm.append("tax15money", this.state.tax15money);//3
        datafrm.append("taxyear", this.state.taxyear);//3


       
        Axios.post("https://kpruinnovation.kpru.ac.th/tax/uploadtax3.php",datafrm,{
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
        
        .then(res => {
            console.log(res)
            Swal.fire({
                type: 'success',
                title: 'บันทึกข้อมูลสำเร็จ!',
                text: 'ข้อมูลของคุณได้ถูกบันทึกแล้ว',
                icon: 'success',
            }).then((result) => {
                if (result.value) {
                    this.ongo()
                }
            })
           
        })
        .catch((res => {
            this.setState({ loadSave: false })
            Swal.fire({
                type: 'error',
                title: 'บันทึกข้อมูลไม่สำเร็จ!',
                text: 'เกิดข้อผิดพลาดระหว่างการบันทึกข้อมูล',
                icon: 'error',
            }).then((result) => {
                if (result.value) {

                }
            })
        }))

      



       }, 2000);
       

       

    };

    submitupdate() {
        //   event.preventDefault();
         //  event.target.className += " was-validated";
     console.log('มาแล้ว')
     
   
     setTimeout(() => {
        
   
           const datafrm = new FormData(); //สร้างฟอร์มสำหรับการส่งข้อมูล
           datafrm.append("tax_id", this.state.tax_id);//2
           datafrm.append("tax_citizen", this.state.tax_citizen);//2
           datafrm.append("tax_address", this.state.tax_address);//3
           datafrm.append("tax_room", this.state.tax_room);//3
           datafrm.append("tax_fool", this.state.tax_fool);//3
           datafrm.append("tax_homename", this.state.tax_homename);//3
           datafrm.append("tax_homenum", this.state.tax_homenum);//3
           datafrm.append("tax_moonum", this.state.tax_moonum);//3
           datafrm.append("tax_soy", this.state.tax_soy);//3
           datafrm.append("tax_yak", this.state.tax_yak);//3
           datafrm.append("tax_road", this.state.tax_road);//3
           datafrm.append("tax_tum", this.state.tax_tum);//3
           datafrm.append("tax_am", this.state.tax_am);//3
           datafrm.append("tax_pi", this.state.tax_pi);//3
           datafrm.append("tax_code", this.state.tax_code);//3
           datafrm.append("tax1_status", this.state.tax1_status);//3
           datafrm.append("tax2", this.state.tax2);//3
           datafrm.append("tax3", this.state.tax3);//3
           datafrm.append("tax31", this.state.tax31);//3
           datafrm.append("tax31citizen", this.state.tax31citizen);//3
           datafrm.append("tax31citizen2", this.state.tax31citizen2);//3
           datafrm.append("tax31money", this.state.tax31money);//3
           datafrm.append("tax32", this.state.tax32);//3
           datafrm.append("tax32citizen", this.state.tax32citizen);//3
           datafrm.append("tax32citizen2", this.state.tax32citizen2);//3
           datafrm.append("tax32money", this.state.tax32money);//3
           datafrm.append("tax411", this.state.tax411);//3
           datafrm.append("tax411citizen", this.state.tax411citizen);//3
           datafrm.append("tax412", this.state.tax412);//3
           datafrm.append("tax412citizen", this.state.tax412citizen);//3
           datafrm.append("tax411money", this.state.tax411money);//3
           datafrm.append("tax421", this.state.tax421);//3
           datafrm.append("tax421citizen", this.state.tax421citizen);//3
           datafrm.append("tax422", this.state.tax422);//3
           datafrm.append("tax422citizen", this.state.tax422citizen);//3
           datafrm.append("tax421money", this.state.tax421money);//3
           datafrm.append("tax51", this.state.tax51);//3
           datafrm.append("tax51money", this.state.tax51money);//3
           datafrm.append("tax611", this.state.tax611);//3
           datafrm.append("tax612", this.state.tax612);//3
           datafrm.append("tax621", this.state.tax621);//3
           datafrm.append("tax622", this.state.tax622);//3
           datafrm.append("tax611money", this.state.tax611money);//3
           datafrm.append("tax7money", this.state.tax7money);//3
           datafrm.append("tax8money", this.state.tax8money);//3
           datafrm.append("tax9money", this.state.tax9money);//3
           datafrm.append("tax10", this.state.tax10);//3
           datafrm.append("tax10money", this.state.tax10money);//3
           datafrm.append("tax11", this.state.tax11);//3
           datafrm.append("tax11money", this.state.tax11money);//3
           datafrm.append("tax12money", this.state.tax12money);//3
           datafrm.append("tax13money", this.state.tax13money);//3
           datafrm.append("tax14money", this.state.tax14money);//3
           datafrm.append("tax15", this.state.tax15);//3
           datafrm.append("tax15money", this.state.tax15money);//3
           datafrm.append("taxyear", this.state.taxyear);//3
   
   
          
           Axios.post("https://kpruinnovation.kpru.ac.th/tax/updatetaxset.php",datafrm,{
               headers: {
                   'content-type': 'multipart/form-data'
               }
           })
           
           .then(res => {
               console.log(res)
               Swal.fire({
                   type: 'success',
                   title: 'อัพเดทข้อมูลสำเร็จ!',
                   text: 'ข้อมูลของคุณได้ถูกบันทึกแล้ว',
                   icon: 'success',
               }).then((result) => {
                   if (result.value) {
                       this.ongo()
                   }
               })
              
           })
           .catch((res => {
               this.setState({ loadSave: false })
               Swal.fire({
                   type: 'error',
                   title: 'บันทึกข้อมูลไม่สำเร็จ!',
                   text: 'เกิดข้อผิดพลาดระหว่างการบันทึกข้อมูล',
                   icon: 'error',
               }).then((result) => {
                   if (result.value) {
   
                   }
               })
           }))
   
         
   
   
   
          }, 2000);
          
       };

    changeHandler = event => {
        // console.log(event.target)
        if (event.target.name === "tax31") {
          //  console.log("jkjj")
            const vv = event.target.value ;
            const total1 = vv * 30000 ;
            this.setState({ tax31money: total1 });
            console.log(total1)
        }
        if (event.target.name === "tax32") {
          //  console.log("jkjj")
            const vv2 = event.target.value ;
            const total2 = vv2 * 60000 ;
            this.setState({ tax32money: total2 });
        }



        this.setState({ [event.target.name]: event.target.value });
    };

    componentDidMount() {
        Toast.loading('Loading..')
        window.scrollTo({ top: 0, behavior: 'smooth' })
        let user = {
            user_citizent: detail_user.Username
        }
        let admin = detail_user.admin_type
        if (admin) {
            user = {
                user_citizent: localStorage.getItem('Username')
            }
        }
        apilogin.post('getdata/get_user_detail_data', user)
            .then((res => {
                // console.log(res)

                if (res.data.length >= 1) {
                    // message.success('ok')
                    this.setState(res.data[0])
                    this.setState({tax_name : res.data[0].user_fname})
                    this.setState({tax_lname : res.data[0].user_lname})
                    this.setState({tax_citizen : res.data[0].user_citizent})
                    this.setState({taxcustomers_type : res.data[0].customers_type})

                    this.setState({tax_tum : res.data[0].user_add_tumbon})
                    this.setState({tax_am : res.data[0].user_add_amphoe})
                    this.setState({tax_pi : res.data[0].user_add_province})
                    this.setState({tax_code : res.data[0].user_add_code})
                    this.setState({ check_have: true })
                    setTimeout(() => {Toast.hide()}, 200);

                  
                }
                else {
                    try {

                        this.setState({
                            user_citizent: detail_user.Username,
                            user_pname: detail_user.prefix_name,
                            user_fname: detail_user.frist_name,
                            user_lname: detail_user.last_name,
                            user_position: detail_user.rank_name,
                            user_office: detail_user.organization_name,
                            user_add_phone: detail_user.telephone,
                            user_phone_number: detail_user.telephone,
                            user_emp: "1",
                        })
                        setTimeout(() => {Toast.hide()}, 200);
                    } catch (error) {
                        setTimeout(() => {Toast.hide()}, 200);
                    }
                    // message.info('กรุณากรอกข้อมูลให้ครบถ้วนก่อนใช้งาน')
                    notification.info({
                        message: 'กรุณากรอกข้อมูลนี้ให้ครบก่อนใช้งาน',
                        icon: <MDBIcon style={{ color: 'blue' }} icon="user-edit" />
                    })
                }
            }))
            .catch((res => {
                message.error('โหลดไม่สำเร็จ')
                setTimeout(() => {Toast.hide()}, 200);
            }))



            apilogin.post('getdata/get_user_detail_tax', user)
            .then((res => {
                if (res.data.length >= 1) {
                        console.log(res.data[0].tax_citizen)
                        this.setState({
            tax_id: res.data[0].tax_id,
            tax_address: res.data[0].tax_address,
            tax_room: res.data[0].tax_room,
            tax_fool: res.data[0].tax_fool,
            tax_homename: res.data[0].tax_homename,
            tax_homenum: res.data[0].tax_homenum,
            tax_moonum: res.data[0].tax_moonum,
            tax_soy: res.data[0].tax_soy,
            tax_yak: res.data[0].tax_yak,
            tax_road: res.data[0].tax_road,
            tax_tum: res.data[0].tax_tum,
            tax_am: res.data[0].tax_am,
            tax_pi: res.data[0].tax_pi,
            tax_code: res.data[0].tax_code,
            tax1_status: res.data[0].tax1_status,
            tax2: res.data[0].tax2,
            tax3: res.data[0].tax3,
            tax31: res.data[0].tax31,
            tax31citizen: res.data[0].tax31citizen,
            tax31money: res.data[0].tax31money,
            tax32: res.data[0].tax32,
            tax32citizen: res.data[0].tax32citizen,
            tax32money: res.data[0].tax32money,
            tax411: res.data[0].tax411,
            tax411citizen: res.data[0].tax411citizen,
            tax412: res.data[0].tax412,
            tax412citizen: res.data[0].tax412citizen,
            tax411money: res.data[0].tax411money,
            tax421: res.data[0].tax421,
            tax421citizen: res.data[0].tax421citizen,
            tax422: res.data[0].tax422,
            tax422citizen: res.data[0].tax422citizen,
            tax421money: res.data[0].tax421money,
            tax51: res.data[0].tax51,
            tax51money: res.data[0].tax51money,
            tax611: res.data[0].tax611,
            tax612: res.data[0].tax612,
            tax621: res.data[0].tax621,
            tax622: res.data[0].tax622,
            tax611money: res.data[0].tax611money,
            tax7money: res.data[0].tax7money,
            tax8money: res.data[0].tax8money,
            tax9money: res.data[0].tax9money,
            tax10: res.data[0].tax10,
            tax10money: res.data[0].tax10money,
            tax11: res.data[0].tax11,
            tax11money: res.data[0].tax11money,
            tax12money: res.data[0].tax12money,
            tax13money: res.data[0].tax13money,
            tax14money: res.data[0].tax14money,
            tax15: res.data[0].tax15,
            tax15money: res.data[0].tax15money,

            Buttonupdate : true,

            
                         
                        })


                }else{

                }





            }))
            .catch((res => {
                message.error('โหลดไม่สำเร็จ')
                setTimeout(() => {Toast.hide()}, 200);
            }))







    }

    render() {
 
        // console.log(this.state.tax_name)
        // console.log(this.state.tax_lname)
        // console.log(this.state.tax_address)
        // console.log(this.state.tax_room)
        // console.log(this.state.tax_fool)
        // console.log(this.state.tax_homename)
        // console.log(this.state.tax_homenum)
        // console.log(this.state.tax_moonum)
        // console.log(this.state.tax_soy)
        // console.log(this.state.tax_yak)
        // console.log(this.state.tax_road)
        // console.log(this.state.tax_tum)
        // console.log(this.state.tax_am)
        // console.log(this.state.tax_pi)
        // console.log(this.state.tax_code)
        // console.log(this.state.tax1_status)
        // console.log(this.state.tax2)
        // console.log(this.state.tax3)
        // console.log(this.state.tax31)
        // console.log(this.state.tax31citizen)
        // console.log(this.state.tax31money)
        // console.log(this.state.tax32)
        // console.log(this.state.tax32citizen)
        // console.log(this.state.tax32money)
        // console.log(this.state.tax411)
        // console.log(this.state.tax411citizen)
        // console.log(this.state.tax412)
        // console.log(this.state.tax412citizen)
        // console.log(this.state.tax411money)
        // console.log(this.state.tax421)
        // console.log(this.state.tax421citizen)
        // console.log(this.state.tax422)
        // console.log(this.state.tax422citizen)
        // console.log(this.state.tax421money)
        // console.log(this.state.tax51)
        // console.log(this.state.tax51money)
        // console.log(this.state.tax611)
        // console.log(this.state.tax612)
        // console.log(this.state.tax621)
        // console.log(this.state.tax622)
        // console.log(this.state.tax611money)
        // console.log(this.state.tax7money)
        // console.log(this.state.tax8money)
        // console.log(this.state.tax9money)
        // console.log(this.state.tax10)
        // console.log(this.state.tax10money)
        // console.log(this.state.tax11)
        // console.log(this.state.tax11money)
        // console.log(this.state.tax12money)
        // console.log(this.state.tax13money)
        // console.log(this.state.tax14money)
        // console.log(this.state.tax15)
        // console.log(this.state.tax15money)
        // console.log(this.state.taxyear)





        return (
            <div className="container mt-5 mb-5 animated fadeIn">



                {/* <MDBAlert color="primary mt-2 mb-2" >
                    หากกรอกและบันทึกข้อมูลนี้แล้ว ให้พิมพ์แบบฟอร์มนี้ แล้วเตรียมสำเนาหน้าสมุดบัญชี จากนั้นนำมาส่งที่งานการเงิน
                </MDBAlert> */}

                {/* <Alert className="mt-2 mb-2" severity="error">  หากกรอกและบันทึกข้อมูลนี้แล้ว ให้พิมพ์แบบฟอร์มนี้ แล้วเตรียมสำเนาหน้าสมุดบัญชี จากนั้นนำมาส่งที่งานการเงิน</Alert> */}

                <Card className={"container mt-2 "}>


                    <form
                        // className="needs-validation"
                        onSubmit={this.submitHandler}
                    //noValidate
                    >

                        <div className="text-center mt-5">
                            <h4 className={theme}><MDBIcon fab icon="wpforms" /> แบบแจ้งรายการเพื่อหักลดหย่อน </h4>
                        </div>

                        {/* <PDF_print_form data={this.state} check_have={this.state.check_have} /> */}




                        <h5 className={"mt-5 " + theme}><MDBIcon icon="user-alt" /> ข้อมูลทั่วไป </h5>
                        <MDBRow>

                            <MDBCol md="4" className="mb-3">
                                <label> เลขประจำตัวประชาชน </label>
                                <input
                                    disabled={this.state.check_have === true ? true : false}
                                    value={this.state.user_citizent}
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    name="user_citizent"
                                    placeholder="ระบุเลขประจำตัวประชาชน"
                                    required
                                />
                            </MDBCol>


                            {/* <MDBCol md="4" className="mb-3">
                                <label> กระทรวง</label>
                                <input
                                    value={this.state.user_department}
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    name="user_department"
                                    placeholder="ระบุกระทรวง"
                                    required
                                />
                            </MDBCol> */}

                            </MDBRow>


                        <MDBRow>

                            {/* <MDBCol md="4" className="mb-3">
                                <label> คำนำหน้า</label>
                                <input
                                    value={this.state.user_pname}
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    name="user_pname"
                                    placeholder="ระบุคำนำหน้า"
                                    required
                                />
                            </MDBCol> */}

                            <MDBCol md="4" className="mb-3">
                                <label > ผู้มีเงินได้ชื่อ</label>
                                <input
                                    value={this.state.tax_name}
                                    name="tax_name"
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    placeholder="ระบุผู้มีเงินได้ชื่อ"
                                    
                                />
                            </MDBCol>

                            <MDBCol md="4" className="mb-3">
                                <label > ชื่อสกุล</label>
                                <input
                                    value={this.state.tax_lname}
                                    name="tax_lname"
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    placeholder="ระบุชื่อสกุล"
                                    
                                />
                            </MDBCol>
                        </MDBRow>


                        <MDBRow>
                        <MDBCol md="4" className="mb-3">
                            <label> ที่อยู่ : อาคาร</label>
                            <input
                                value={this.state.tax_address}
                                onChange={this.changeHandler}
                                type="text"
                                className={"form-control " + theme}
                                name="tax_address"
                                placeholder="ระบุที่อยู่"
                                required
                            />
                        </MDBCol>

                        <MDBCol md="2" className="mb-3">
                            <label > ห้องเลขที่</label>
                            <input
                                value={this.state.tax_room}
                                name="tax_room"
                                onChange={this.changeHandler}
                                type="text"
                                className={"form-control " + theme}
                                placeholder="ระบุห้องเลขที่"
                                required
                            />
                        </MDBCol>

                        <MDBCol md="2" className="mb-3">
                            <label > ชั้น</label>
                            <input
                                value={this.state.tax_fool}
                                name="tax_fool"
                                onChange={this.changeHandler}
                                type="text"
                                className={"form-control " + theme}
                                placeholder="ระบุชั้น"
                                required
                            />
                        </MDBCol>

                        <MDBCol md="4" className="mb-3">
                            <label > หมู่บ้าน</label>
                            <input
                                value={this.state.tax_homename}
                                name="tax_homename"
                                onChange={this.changeHandler}
                                type="text"
                                className={"form-control " + theme}
                                placeholder="ระบุหมู่บ้าน"
                                required
                            />
                        </MDBCol>

                        </MDBRow>



                        <MDBRow>
                        <MDBCol md="2" className="mb-3">
                            <label> เลขที่</label>
                            <input
                                value={this.state.tax_homenum}
                                onChange={this.changeHandler}
                                type="text"
                                className={"form-control " + theme}
                                name="tax_homenum"
                                placeholder="ระบุเลขที่"
                                required
                            />
                        </MDBCol>

                        <MDBCol md="2" className="mb-3">
                            <label > หมู่ที่</label>
                            <input
                                value={this.state.tax_moonum}
                                name="tax_moonum"
                                onChange={this.changeHandler}
                                type="text"
                                className={"form-control " + theme}
                                placeholder="ระบุหมู่ที่"
                                required
                            />
                        </MDBCol>

                        <MDBCol md="4" className="mb-3">
                            <label > ตรอก/ซอย</label>
                            <input
                                value={this.state.tax_soy}
                                name="tax_soy"
                                onChange={this.changeHandler}
                                type="text"
                                className={"form-control " + theme}
                                placeholder="ระบุตรอก/ซอย"
                                required
                            />
                        </MDBCol>

                        <MDBCol md="2" className="mb-3">
                            <label > แยก</label>
                            <input
                                value={this.state.tax_yak}
                                name="tax_yak"
                                onChange={this.changeHandler}
                                type="text"
                                className={"form-control " + theme}
                                placeholder="ระบุแยก"
                                required
                            />
                        </MDBCol>

                        <MDBCol md="2" className="mb-3">
                            <label > ถนน</label>
                            <input
                                value={this.state.tax_road}
                                name="tax_road"
                                onChange={this.changeHandler}
                                type="text"
                                className={"form-control " + theme}
                                placeholder="ระบุถนน"
                                required
                            />
                        </MDBCol>

                        </MDBRow>




                        <MDBRow>
                            <MDBCol md="4" className="mb-3">
                                <label> ตำบล</label>
                                <input
                                    value={this.state.tax_tum}
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    name="tax_tum"
                                    placeholder="ระบุตำบล"
                                    required
                                />
                            </MDBCol>

                            <MDBCol md="4" className="mb-3">
                                <label > อำเภอ</label>
                                <input
                                    value={this.state.tax_am}
                                    name="tax_am"
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    placeholder="ระบุอำเภอ"
                                    required
                                />

                            </MDBCol>
                            <MDBCol md="4" className="mb-3">
                                <label > จังหวัด</label>
                                <input
                                    value={this.state.tax_pi}
                                    name="tax_pi"
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    placeholder="ระบุจังหวัด"
                                    required
                                />
                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol md="4" className="mb-3">
                                <label> รหัสไปรษณีย์</label>
                                <input
                                    value={this.state.tax_code}
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    name="tax_code"
                                    placeholder="ระบุรหัสไปรษณีย์"
                                    required
                                />
                            </MDBCol>

                         
                        </MDBRow>

                        <hr/> 

                        <h5 className={"mt-5 " + theme}><MDBIcon icon="map-marked-alt" /> ข้อมูลลดหย่อน </h5>
                        
                        {/* ข้อ1 */}
                        <MDBRow>
                            <MDBCol md="3" className="mb-3">
                                <label>1.สถานภาพ</label>
                                <div className={"mt-2 mb-2 " + theme}>
                                <MDBInput gap onClick={this.onClick("1")} checked={this.state.tax1_status === "1" ? true : false} label="โสด" type="radio"
                                    id="radio1" />
                                <MDBInput gap onClick={this.onClick("2")} checked={this.state.tax1_status === "2" ? true : false} label="สมรส" type="radio"
                                    id="radio2" />
                                </div>
                            </MDBCol>

                            <MDBCol md="3" className="mb-3">
                            <label></label>
                                <div className={"mt-2 mb-2 " + theme}>
                                <MDBInput gap onClick={this.onClick("3")} checked={this.state.tax1_status === "3" ? true : false} label="หม้าย" type="radio"
                                    id="radio3" />
                                <MDBInput gap onClick={this.onClick("4")} checked={this.state.tax1_status === "4" ? true : false} label="ตายระหว่างปีภาษี" type="radio"
                                    id="radio4" />
                                </div>
                            </MDBCol>
                            <MDBCol md="3" className="mb-3">
                            <label>สถานภาพการสมรส</label>
                                <div className={"mt-2 mb-2 " + theme}>
                                    <MDBInput gap onClick={this.onClick("5")} checked={this.state.tax1_status === "5" ? true : false} label="สมรสและอยู่ร่วมกันตลอดปีภาษี" type="radio"
                                        id="radio5" />
                                    <MDBInput gap onClick={this.onClick("6")} checked={this.state.tax1_status === "6" ? true : false} label="หย่าระหว่างปีภาษี" type="radio"
                                        id="radio6" />
                                </div>
                            </MDBCol>
                            <MDBCol md="3" className="mb-3">
                            <label></label>
                                <div className={"mt-2 mb-2 " + theme}>
                                        <MDBInput gap onClick={this.onClick("7")} checked={this.state.tax1_status === "7" ? true : false} label="สมรสระหว่างปีภาษี" type="radio"
                                            id="radio7" />
                                        <MDBInput gap onClick={this.onClick("8")} checked={this.state.tax1_status === "8" ? true : false} label="ตายระหว่างปีภาษี" type="radio"
                                            id="radio8" />
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <hr/> 
                        {/* ข้อ2 */}

                        <MDBRow>
                            <MDBCol md="4" className="mb-3">
                                <label> 2.สถานะการมีเงินได้ของคู่สมรส</label>
                                <div className={"mt-2 mb-2 " + theme}>
                                        <MDBInput gap onClick={this.onClick2("1")} checked={this.state.tax2 === "1" ? true : false} label="มีเงินได้" type="radio"
                                            id="radio9" />
                                        <MDBInput gap onClick={this.onClick2("2")} checked={this.state.tax2 === "2" ? true : false} label="ไม่มีเงินได้" type="radio"
                                            id="radio10" />
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <hr/> 
                        {/* ข้อ3 */}    

                        <MDBRow>
                            <MDBCol md="4" className="mb-3">
                                <label> 3.จำนวนบุตรรวม</label>
                                <input
                                    value={this.state.tax3}
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    name="tax3"
                                    placeholder="ระบุจำนวนบุตร"
                                    
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>

                        <MDBCol md="4" className="mb-3">
                            <label> บุตร คนละ 30,000 บาทมีสิทธินำมาหักลดหย่อนจำนวน/คน</label>
                            <input
                                value={this.state.tax31}
                                onChange={this.changeHandler}
                                type="text"
                                className={"form-control " + theme}
                                name="tax31"
                                placeholder="ระบุจำนวนบุตร"
                                
                            />
                        </MDBCol>

                        <MDBCol md="4" className="mb-3">
                            <label > เลขประจำตัวประชาชนบุตรคนที่1</label>
                           
                            <input
                                value={this.state.tax31citizen}
                                name="tax31citizen"
                                onChange={this.changeHandler}
                                type="text"
                                className={"form-control " + theme}
                                placeholder="ระบุเลขประจำตัวประชาชน"
                                length="13"
                                maxlength="13"
                            />
                           
                             <label > เลขประจำตัวประชาชนบุตรคนที่2</label>
                            <input
                                value={this.state.tax31citizen2}
                                name="tax31citizen2"
                                onChange={this.changeHandler}
                                type="text"
                                className={"form-control " + theme}
                                placeholder="ระบุเลขประจำตัวประชาชน"
                                maxlength="13"
                            />
                        </MDBCol>

                        <MDBCol md="4" className="mb-3">
                            <label > จำนวนเงิน</label>
                            <input
                                value={this.state.tax31 >='1' ? this.state.tax31money : ''}
                                name="tax31money"
                              //  onChange={this.changeHandler}
                                type="text"
                                className={"form-control " + theme}
                                placeholder="ระบุจำนวนเงิน"
                                
                            />
                        </MDBCol>
                        </MDBRow>

                        <MDBRow>
                        <MDBCol md="4" className="mb-3">
                            <label> บุตร (ตั้งแต่คนที่สองเป็นต้นไป ที่เกิดในหรือหลังปี พ.ศ. 2561) คนละ 60,000 บาท มีสิทธินำมาหักลดหย่อนจำนนวน/คน</label>
                            <input
                                value={this.state.tax32}
                                onChange={this.changeHandler}
                                type="text"
                                className={"form-control " + theme}
                                name="tax32"
                                placeholder="ระบุจำนวนบุตร"
                                
                            />
                        </MDBCol>

                        <MDBCol md="4" className="mb-3">
                            <label > เลขประจำตัวประชาชนบุตรคนที่1</label>
                            <input
                                value={this.state.tax32citizen}
                                name="tax32citizen"
                                onChange={this.changeHandler}
                                type="text"
                                className={"form-control " + theme}
                                placeholder="ระบุเลขประจำตัวประชาชน"
                                maxlength="13"
                                
                            />
                              <label > เลขประจำตัวประชาชนบุตรคนที่2</label>
                            <input
                                value={this.state.tax32citizen2}
                                name="tax32citizen2"
                                onChange={this.changeHandler}
                                type="text"
                                className={"form-control " + theme}
                                placeholder="ระบุเลขประจำตัวประชาชน"
                                maxlength="13"
                                
                            />
                        </MDBCol>

                        <MDBCol md="4" className="mb-3">
                            <label > จำนวนเงิน</label>
                            <input
                                value={this.state.tax32money}
                                name="tax32money"
                                onChange={this.changeHandler}
                                type="text"
                                className={"form-control " + theme}
                                placeholder="ระบุจำนวนเงิน"
                                
                            />
                        </MDBCol>
                        </MDBRow>
                   <hr/>
                    {/* ข้อ4 */}    
                   <label>4.ค่าอุปการะเลี้ยงดู</label>
                    <MDBRow>

                        <MDBCol md="4" className="mb-3">
                            <div class="custom-control custom-checkbox">
                                {this.state.tax411 === "1"?<> <input type="checkbox" class="custom-control-input"  id="defaultUnchecked" defaultChecked={true} onChange={e => this.onClick411(e)}   /></>:<>
                                <input type="checkbox" class="custom-control-input"  id="defaultUnchecked" defaultChecked={false} onChange={e => this.onClick411(e)}   />
                                </>}
                               
                                <label class="custom-control-label" for="defaultUnchecked">บิดา</label>
                            </div>
                            <label > เลขประจำตัวประชาชน</label>
                            <input
                                value={this.state.tax411citizen}
                                name="tax411citizen"
                                onChange={this.changeHandler}
                                type="text"
                                className={"form-control " + theme}
                                placeholder="ระบุเลขประจำตัวประชาชน"
                                maxlength="13"
                                
                            />
                        </MDBCol>

                        <MDBCol md="4" className="mb-3">
                           <div class="custom-control custom-checkbox">
                           {this.state.tax412 === "1"?<><input type="checkbox" class="custom-control-input" id="defaultUnchecked1" defaultChecked={true} onChange={e => this.onClick412(e)} /></>:<>
                           <input type="checkbox" class="custom-control-input" id="defaultUnchecked1" defaultChecked={false} onChange={e => this.onClick412(e)} />
                           </>}
                                
                                <label class="custom-control-label" for="defaultUnchecked1">มารดา (ของผู้มีเงินได้หักได้คนละ 30,000 บาท)</label>
                            </div>
                             <label > เลขประจำตัวประชาชน</label>
                            <input
                                value={this.state.tax412citizen}
                                name="tax412citizen"
                                onChange={this.changeHandler}
                                type="text"
                                className={"form-control " + theme}
                                placeholder="ระบุเลขประจำตัวประชาชน"
                                maxlength="13"
                                
                            />
                        </MDBCol>
                      

                        <MDBCol md="4" className="mb-3">
                            <label > จำนวนเงิน</label>
                            <input
                                value={this.state.tax411money}
                                name="tax411money"
                                onChange={this.changeHandler}
                                type="text"
                                className={"form-control " + theme}
                                placeholder="ระบุจำนวนเงิน"
                                
                            />
                        </MDBCol>
                        </MDBRow>  

                        <MDBRow>

                                <MDBCol md="4" className="mb-3">
                                    <div class="custom-control custom-checkbox">
                                    {this.state.tax421 === "1"?<> <input type="checkbox" class="custom-control-input" id="defaultUnchecked3" defaultChecked={true}  onChange={e => this.onClick421(e)} /></>:<>
                                    <input type="checkbox" class="custom-control-input" id="defaultUnchecked3" defaultChecked={false}  onChange={e => this.onClick421(e)} />
                                    </>}
                        
                                        <label class="custom-control-label" for="defaultUnchecked3">บิดา</label>
                                    </div>
                                    <label > เลขประจำตัวประชาชน</label>
                                    <input
                                        value={this.state.tax421citizen}
                                        name="tax421citizen"
                                        onChange={this.changeHandler}
                                        type="text"
                                        className={"form-control " + theme}
                                        placeholder="ระบุเลขประจำตัวประชาชน"
                                        maxlength="13"
                                        
                                    />
                                </MDBCol>

                                <MDBCol md="4" className="mb-3">
                                <div class="custom-control custom-checkbox">
                                {this.state.tax422 === "1"?<> <input type="checkbox" class="custom-control-input" id="defaultUnchecked4" defaultChecked={true} onChange={e => this.onClick422(e)}/></>:<>
                                <input type="checkbox" class="custom-control-input" id="defaultUnchecked4" defaultChecked={false} onChange={e => this.onClick422(e)}/>
                                </>}
                                       
                                        <label class="custom-control-label" for="defaultUnchecked4">มารดา (ของคู่สมรสที่ไม่มีเงินได้หักได้คนละ 30,000 บาท)</label>
                                    </div>
                                    <label > เลขประจำตัวประชาชน</label>
                                    <input
                                        value={this.state.tax422citizen}
                                        name="tax422citizen"
                                        onChange={this.changeHandler}
                                        type="text"
                                        className={"form-control " + theme}
                                        placeholder="ระบุเลขประจำตัวประชาชน"
                                        maxlength="13"
                                        
                                    />

                                </MDBCol>
                              

                                <MDBCol md="4" className="mb-3">
                                    <label > จำนวนเงิน</label>
                                    <input
                                        value={this.state.tax421money}
                                        name="tax421money"
                                        onChange={this.changeHandler}
                                        type="text"
                                        className={"form-control " + theme}
                                        placeholder=""
                                        
                                    />
                                </MDBCol>
                                </MDBRow>   
                       <hr/>
                        {/* ข้อ5 */}    
                   <label>5.ค่าอุปการะเลี้ยงดูคนพิการหรือคนทุพพลภาพ คนละ 60,000 บาท</label>
                   <MDBRow>
                        <MDBCol md="4" className="mb-3">
                            <label> รวมทั้งสิ้นจำนวน/คน</label>
                            <input
                                value={this.state.tax51}
                                onChange={this.changeHandler}
                                type="text"
                                className={"form-control " + theme}
                                name="tax51"
                                placeholder="ระบุจำนวนบุตร"
                                
                            />
                        </MDBCol>


                        <MDBCol md="4" className="mb-3">
                            <label > จำนวนเงิน</label>
                            <input
                                value={this.state.tax51money}
                                name="tax51money"
                                onChange={this.changeHandler}
                                type="text"
                                className={"form-control " + theme}
                                placeholder="ระบุจำนวนเงิน"
                                
                            />
                        </MDBCol>
                 </MDBRow>        
                        <hr/>
                            {/* ข้อ6 */}    
                   <label>6.เบี้ยประกันสุขภาพ </label>
                 
                    <MDBRow>

                        <MDBCol md="3" className="mb-3">
                            <div class="custom-control custom-checkbox">
                                {this.state.tax611 === "1" ?<> <input type="checkbox" class="custom-control-input" id="defaultUnchecked61" defaultChecked={true} onChange={e => this.onClick611(e)}  /></>:<>
                                <input type="checkbox" class="custom-control-input" id="defaultUnchecked61" defaultChecked={false} onChange={e => this.onClick611(e)}  />
                                </>}
                               
                                <label class="custom-control-label" for="defaultUnchecked61">บิดา</label>
                            </div>
                            <div class="custom-control custom-checkbox">
                                {this.state.tax621 === "1"?<> <input type="checkbox" class="custom-control-input" id="defaultUnchecked63"  defaultChecked={true} onChange={e => this.onClick621(e)}  /></>:<>
                                <input type="checkbox" class="custom-control-input" id="defaultUnchecked63"  defaultChecked={false} onChange={e => this.onClick621(e)}  />
                                </>}
                               
                                <label class="custom-control-label" for="defaultUnchecked63">บิดา</label>
                            </div>
                        </MDBCol>

                        <MDBCol md="3" className="mb-3">
                            <div class="custom-control custom-checkbox">
                                {this.state.tax612 ==="1" ? <> <input type="checkbox" class="custom-control-input" id="defaultUnchecked62" defaultChecked={true}  onChange={e => this.onClick612(e)}  /></>:<>
                                <input type="checkbox" class="custom-control-input" id="defaultUnchecked62" defaultChecked={false}  onChange={e => this.onClick612(e)}  />
                                </>}
                               
                                <label class="custom-control-label" for="defaultUnchecked62">มารดา ของผู้มีเงินได้</label>
                            </div>
                           <div class="custom-control custom-checkbox">
                           {this.state.tax622 ==="1" ? <> <input type="checkbox" class="custom-control-input" id="defaultUnchecked64" defaultChecked={true}  onChange={e => this.onClick622(e)}/></>:<>
                           <input type="checkbox" class="custom-control-input" id="defaultUnchecked64" defaultChecked={false}  onChange={e => this.onClick622(e)}/>
                           </>}
                               
                                <label class="custom-control-label" for="defaultUnchecked64">มารดา คู่สมรสที่ไม่มีเงินได้</label>
                            </div>
                        </MDBCol>
                       
                        <MDBCol md="3" className="mb-3">
                            <label > จำนวนเงิน</label>
                            <input
                                value={this.state.tax611money}
                                name="tax611money"
                                onChange={this.changeHandler}
                                type="text"
                                className={"form-control " + theme}
                                placeholder="ระบุจำนวนเงิน"
                                
                            />
                        </MDBCol>
                        </MDBRow>  
                        <hr/>  
                            {/* ข้อ7 */}   
                        <MDBRow>
                            <MDBCol md="6" className="mb-3">
                                <label>7. เบี้ยประกันชีวิตที่จ่ายภายในปีภาษี</label>
                                <label><i>(ลดหย่อนได้ตามที่จ่ายจริงแต่ไม่เกิน 10,000 บาท ส่วนที่เกิน 10,000 บาท ได้รับยกเว้นเงินได้หลังหักใช้จ่ายแต่ไม่เกิน 90,000 บาท กรณีคู่สมรสไม่มีเงินได้และความเป็นสามีภริยามีอยู่ตลอดปีภาษีหักลดหน่อยของคู่สมรสได้อีก ตามจำนวนที่จ่ายจริง แต่ไม่เกิน 10,000 บาท กรณีความเป็นสามีภริยามิได้มีอยู่ตลอดปีภาษีจะนำเบี้ยประกันชีวิตของคู่สมรสมาหักไม่ได้)</i></label>
                            
                            </MDBCol>


                            <MDBCol md="4" className="mb-3">
                                <label > จำนวนเงิน</label>
                                <input
                                    value={this.state.tax7money}
                                    name="tax7money"
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    placeholder="ระบุจำนวนเงิน"
                                    
                                />
                            </MDBCol>
                        </MDBRow>  
                        <hr/>  
                            {/* ข้อ8 */}  
                        <MDBRow>
                            <MDBCol md="6" className="mb-3">
                                <label>8. เบี้ยประกันสุขภาพที่จ่ายภายในปีภาษี</label>
                                <label><i>(ลดหย่อนได้ตามที่จ่ายจริงแต่ไม่เกิน 15,000 บาท และเมื่อรวมกับค่าเบี้ยประกันชีวิตสำหรับกรมธรรม์ประกันชีวิตที่มีกำหนดเวลาตั้งแต่ 10 ปีขึ้นไป ต้องไม่เกิน 100,000 บาท )</i></label>
                            
                            </MDBCol>


                            <MDBCol md="4" className="mb-3">
                                <label > จำนวนเงิน</label>
                                <input
                                    value={this.state.tax8money}
                                    name="tax8money"
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    placeholder="ระบุจำนวนเงิน"
                                    
                                />
                            </MDBCol>
                        </MDBRow>  
                        <hr/>  
                            {/* ข้อ9 */}  
                        <MDBRow>
                            <MDBCol md="6" className="mb-3">
                                <label>9. เงินสะสมที่จ่ายเข้ากองทุนสำรองเลี้ยงชีพ หรือกองทุนการออมแห่งชาติ หรือกองทุน กบข. หรือกองทุนสงเคราะห์ครูโรงเรียนเอกชน <i>(หักได้ไม่เกิน 500,000 บาท) </i>ภายในปีภาษี</label>
                 
                            </MDBCol>


                            <MDBCol md="4" className="mb-3">
                                <label > จำนวนเงิน</label>
                                <input
                                    value={this.state.tax9money}
                                    name="tax9money"
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    placeholder="ระบุจำนวนเงิน"
                                    
                                />
                            </MDBCol>
                        </MDBRow>  
                        <hr/>  
                          {/* ข้อ10 */} 
                            <label>10. ค่าซื้อหน่วยลงทุนในกองทุนรวมเพื่อการเลี้ยงชีพ</label>
                            <label><i>(เฉพาะส่วนที่ไม่เกินร้อยละ 15 ของเงินได้ โดยเมื่อรวมกับเงินสะสมที่จ่ายเข้ากองทุนสำรองเลี้ยงชีพ หรือกองทุนการออมแห่งชาติ หรือกองทุน กบข.หรือกองทุนสงเคราะห์ครูโรงเรียนเอกชน แล้วไม่เกิน 500,000 บาท )</i> ภายในปีภาษี</label>
                          <MDBRow>
                            <MDBCol md="6" className="mb-3">
                            <label > ชื่อผู้ขายหน่วยลงทุน</label>
                                <input
                                    value={this.state.tax10}
                                    name="tax10"
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    placeholder="ระบุชื่อผู้ขายหน่วยลงทุน"
                                    
                                />
                 
                            </MDBCol>


                            <MDBCol md="4" className="mb-3">
                                <label > จำนวนเงิน</label>
                                <input
                                    value={this.state.tax10money}
                                    name="tax10money"
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    placeholder="ระบุจำนวนเงิน"
                                    
                                />
                            </MDBCol>
                        </MDBRow>  
                        <hr/>  
                         {/* ข้อ11 */} 
                         <label>11. ค่าซื้อหน่วยลงทุนในกองทุนรวมหุ้นระยะยาว</label>
                            <label><i>(เฉพาะส่วนที่ไม่เกินร้อยละ 15 ของเงินได้ แต่ไม่เกิน 500,000 บาท )</i> ภายในปีภาษี</label>
                          <MDBRow>
                            <MDBCol md="6" className="mb-3">
                            <label > ชื่อผู้ขายหน่วยลงทุน</label>
                                <input
                                    value={this.state.tax11}
                                    name="tax11"
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    placeholder="ระบุชื่อผู้ขายหน่วยลงทุน"
                                    
                                />
                 
                            </MDBCol>


                            <MDBCol md="4" className="mb-3">
                                <label > จำนวนเงิน</label>
                                <input
                                    value={this.state.tax11money}
                                    name="tax11money"
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    placeholder="ระบุจำนวนเงิน"
                                    
                                />
                            </MDBCol>
                        </MDBRow>  
                        <hr/>  
                             {/* ข้อ12 */}  
                        <MDBRow>
                            <MDBCol md="6" className="mb-3">
                                <label>12. ดอกเบี้ยเงินกู้ผืมเพื่อซื้อ เซ่าซื้อ หรือสร้างอาคารที่อยู่อาศัย <i>(ตามจำนวนที่จ่ายจริงแต่ไม่เกิน 100,000 บาท) </i></label>
                 
                            </MDBCol>


                            <MDBCol md="4" className="mb-3">
                                <label > จำนวนเงิน</label>
                                <input
                                    value={this.state.tax12money}
                                    name="tax12money"
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    placeholder="ระบุจำนวนเงิน"
                                    
                                />
                            </MDBCol>
                        </MDBRow>  
                        <hr/>  
                        {/* ข้อ13 */}  
                        <MDBRow>
                            <MDBCol md="6" className="mb-3">
                                <label>13. เงินสมทบกองทุนประกันสังคมภายในปีภาษี</label>
                 
                            </MDBCol>


                            <MDBCol md="4" className="mb-3">
                                <label > จำนวนเงิน</label>
                                <input
                                    value={this.state.tax13money}
                                    name="tax13money"
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    placeholder="ระบุจำนวนเงิน"
                                    
                                />
                            </MDBCol>
                        </MDBRow>  
                        <hr/>  
                        {/* ข้อ14 */}  
                        <MDBRow>
                            <MDBCol md="6" className="mb-3">
                                <label>14. เงินบริจาคสนับสนุนการศึกษา</label>
                 
                            </MDBCol>


                            <MDBCol md="4" className="mb-3">
                                <label > จำนวนเงิน</label>
                                <input
                                    value={this.state.tax14money}
                                    name="tax14money"
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    placeholder="ระบุจำนวนเงิน"
                                    
                                />
                            </MDBCol>
                        </MDBRow>  
                        <hr/> 

                         {/* ข้อ15 */} 
                         <label>15. เงินบริจาคอื่นๆ</label>
                          <MDBRow>
                            <MDBCol md="6" className="mb-3">
                            <label > ระบุ</label>
                                <input
                                    value={this.state.tax15}
                                    name="tax15"
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    placeholder="ระบุอื่นๆ"
                                    
                                />
                 
                            </MDBCol>


                            <MDBCol md="4" className="mb-3">
                                <label > จำนวนเงิน</label>
                                <input
                                    value={this.state.tax15money}
                                    name="tax15money"
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    placeholder="ระบุจำนวนเงิน"
                                    
                                />
                            </MDBCol>
                        </MDBRow>   
                        <hr/> 



{/* 

                        <h5 className={"mt-5 " + theme}> <MDBIcon icon="university" /> ข้อมูลลดหย่อน</h5>

                        <div className={"mt-2 mb-2 " + theme}>
                            <MDBInput gap onClick={this.onClick("1")} checked={this.state.user_emp === "1" ? true : false} label="เป็นข้าราชการ ลูกจ้าง พนักงานราชการของหน่วยงาน" type="radio"
                                id="radio1" />
                            <MDBInput gap onClick={this.onClick("2")} checked={this.state.user_emp === "2" ? true : false} label="เป็นบุคคลภายนอก เพื่อเข้าบัญชีเงินฝากธนคาร" type="radio"
                                id="radio2" />
                        </div> */}




                      



                      

                        {/* <MDBAlert color="primary mt-5" >
                            หากกรอกและบันทึกข้อมูลนี้แล้ว ให้พิมพ์แบบฟอร์มนี้ แล้วเตรียมสำเนาหน้าสมุดบัญชี จากนั้นนำมาส่งที่งานการเงิน
                        </MDBAlert> */}
                         <Alert className="mt-5" severity="info">  ตรวจสอบความถูกต้องครบถ้วนของข้อมูลก่อนบันทึกข้อมูล</Alert>
                        {/* <Alert className="mt-5" severity="info">  หากกรอกและบันทึกข้อมูลนี้แล้ว ให้พิมพ์แบบฟอร์มนี้ แล้วเตรียมสำเนาหน้าสมุดบัญชี จากนั้นนำมาส่งที่งานการเงิน</Alert> */}
 {this.state.Buttonupdate === false ?<>
 
  <div className="text-center mt-5 mb-5">
                               
                            <Button variant="contained" color="primary" onClick={this.submitHandler}>
                                {this.state.loadSave === true ? <>
                                    กำลังบันทึกข้อมูล..
                            </> : <>
                                        บันทึกข้อมูล
                            </>}

                            </Button>
                        </div>
 </> :<>
 <div className="text-center mt-5 mb-5">
                               
                               <Button variant="contained" color="primary" onClick={this.submitupdate}>
                                   {this.state.loadSave === true ? <>
                                       กำลังบันทึกข้อมูล..
                               </> : <>
                                           บันทึกข้อมูล.
                               </>}
   
                               </Button>
                           </div>
 </>}
                       

                    </form>
                </Card>



            </div>
        );
    }
}

export default FormPagetax;