import React, { Component } from 'react';
//import XLSX from 'xlsx';
import * as XLSX from 'xlsx'
import { read, writeFileXLSX } from "xlsx";
import { SheetJSFT } from './types';
import { Icon, Input, notification, Progress, Button } from 'antd';
import { apilogin } from '../../api';
import { MDBIcon, MDBContainer } from 'mdbreact';
import img_load from '../../img/loading.gif'
import TweenOne from 'rc-tween-one';
import Children from 'rc-tween-one/lib/plugin/ChildrenPlugin';
import Texty from 'rc-texty';
import { message, Modal } from 'antd';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import 'antd/dist/antd.css'
import Text from 'antd/lib/typography/Text';


const options_mounth = [
  { value: '01', label: 'มกราคม' },
  { value: '02', label: 'กุมภาพันธ์' },
  { value: '03', label: 'มีนาคม' },
  { value: '04', label: 'เมษายน' },
  { value: '05', label: 'พฤษภาคม' },
  { value: '06', label: 'มิถุนายน' },
  { value: '07', label: 'กรกฎาคม' },
  { value: '08', label: 'สิงหาคม' },
  { value: '09', label: 'กันยายน' },
  { value: '10', label: 'ตุลาคม' },
  { value: '11', label: 'พฤศจิกายน' },
  { value: '12', label: 'ธันวาคม' },

  { value: '1', label: 'มกราคม' },
  { value: '2', label: 'กุมภาพันธ์' },
  { value: '3', label: 'มีนาคม' },
  { value: '4', label: 'เมษายน' },
  { value: '5', label: 'พฤษภาคม' },
  { value: '6', label: 'มิถุนายน' },
  { value: '7', label: 'กรกฎาคม' },
  { value: '8', label: 'สิงหาคม' },
  { value: '9', label: 'กันยายน' },

]


const { TextArea } = Input;
TweenOne.plugins.push(Children);

class ExcelReader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: {},
      data: [],
      cols: [],
      data_error: [],
      load: false,
      show: false,
      success: null,
      success_insert: null,
      load_insert: false,

      success1: 0,
      error1: 0,
      count_all: 0,

      update_count: 0,
      update_count_err: 0,
      allupdate: 0,

      count_insert_ok: 0,
      count_insert_err: 0,
      count_insert_all: 0,

      load_percent: 0,

      animation: null,
      formatMoney: false,
      visible: false,
      go_update: false,
      load_txt: "",
    }

    this.handleFile = this.handleFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    //  this.convert1 = this.convert1.bind(this);
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  hideModal = () => {
    this.setState({
      visible: false,
    });
  };

  handleChange(e) {
    const files = e.target.files;
    if (files && files[0]) this.setState({
      file: files[0],
      show: true
    });
  };



  handleFile() {
    
    this.setState({
      success: null,
      success_insert: null,
      update_count: 0,
      update_count_err: 0,
      allupdate: 0,
    })
    this.setState({ load: true })
    this.setState({ load_percent: 1 })



    this.setState({ load_txt: "กำลังอ่านไฟล์" })


    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      // console.log(e)
      try {
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws);

        let data_excel = data
        //console.log(data_excel)

        this.setState({ load_txt: "กำลังตรวจสอบไฟล์" })
        let id_check_all = data_excel.length
        let id_check_true = 0
        let id_check_false = 0

        let month_check_true = 0
        let month_check_false = 0

        let year_check_true = 0
        let year_check_false = 0
        for (let i = 0; i < data_excel.length; i++) {
          if (String(data_excel[i].ID_TYPE) === String(this.props.customers_type)) { id_check_true = id_check_true + 1 }
          else { id_check_false = id_check_false + 1 }

          if (String(data_excel[i].month) === String(this.props.payslip_month)) { month_check_true = month_check_true + 1 }
          else { month_check_false = month_check_false + 1 }


          if (String(data_excel[i].year) === String(this.props.payslip_year)) { year_check_true = year_check_true + 1 }
          else { year_check_false = year_check_false + 1 }
        }

        if (id_check_true === id_check_all && month_check_true === id_check_all && year_check_true === id_check_all) {
          
          //  ข้อมูลไฟล์ถูกต้อง
    
          this.setState({ load_txt: "กำลังโหลดรายการรายจ่าย" })


          apilogin.post('getdata/excel_export_expenditure', ({ customers_type: this.props.customers_type, }))
            .then((res1) => {
              this.setState({ load_txt: "กำลังโหลดรายการรายรับ" })
              apilogin.post('getdata/excel_export_revenue', ({ customers_type: this.props.customers_type, }))
                .then((res2) => {
                  this.setState({ load_txt: "กำลังรวมรายการรายจ่าย" })

                  let expenditure = res1.data
                  let revenue = res2.data

                  let DATA_ex = []


                  for (let d = 0; d < data_excel.length; d++) {
                    for (let i = 0; i < Object.keys(data_excel[d]).length; i++) {
                      if (Object.keys(data_excel[d])[i] !== "undefined") {
                        if (expenditure.length >= 1) {
                          for (let ex = 0; ex < expenditure.length; ex++) {
                            let txt = expenditure[ex].expenditure_name.replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                              .replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                              .replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                              .replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                              .replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                            if (Object.keys(data_excel[d])[i] === txt) {
                              let key = "data_excel[" + d + "]." + Object.keys(data_excel[d])[i]
                              let sum = 0

                              sum =eval(key)
                              if (sum === 0 || sum === "" || sum === null || sum === undefined || sum === " ") {
                                sum = 0
                              }
                              if (sum !== 0) {
                                let ee = {
                                  payslip_year: data_excel[d].year,
                                  payslip_month: data_excel[d].month,
                                  payslip_citizent: data_excel[d].ID_citizen,
                                  customers_type: data_excel[d].ID_TYPE,
                                  expenditure_id: expenditure[ex].expenditure_id,
                                  expenditure_name: expenditure[ex].expenditure_name,
                                  revenue_id: "0",
                                  revenue_name: "",
                                  payslip_total: sum,
                                }
                                DATA_ex.push(ee)
                              }
                            }

                          }
                        } else {
                          message.info('ไม่พบรายจ่าย')
                        }


                        //----------------------------
                        this.setState({ load_txt: "กำลังรวมรายการรายรับ" })
                        if (revenue.length >= 1) {
                          for (let ex = 0; ex < revenue.length; ex++) {

                            let txt = revenue[ex].revenue_name.replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                              .replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                              .replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                              .replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                              .replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")

                            if (Object.keys(data_excel[d])[i] === txt) {
                              let key = "data_excel[" + d + "]." + Object.keys(data_excel[d])[i]
                              let sum = 0

                              sum =eval(key)

                              if (sum === 0 || sum === "" || sum === null || sum === undefined || sum === " ") {
                                sum = 0
                              }
                              if (sum !== 0) {
                                let ee = {
                                  payslip_year: data_excel[d].year,
                                  payslip_month: data_excel[d].month,
                                  payslip_citizent: data_excel[d].ID_citizen,
                                  customers_type: data_excel[d].ID_TYPE,
                                  revenue_id: revenue[ex].revenue_id,
                                  revenue_name: revenue[ex].revenue_name,
                                  expenditure_id: "0",
                                  expenditure_name: "",
                                  payslip_total: sum,
                                }
                                DATA_ex.push(ee)
                              }
                            }
                          }
                        } else {
                          message.info('ไม่พบรายรับ')
                        }
                      }
                    }
                  }

                  this.setState({
                    count_all: DATA_ex.length,
                    load_percent: 40,
                  })
                  // console.log(DATA_ex)
                  // console.log(DATA_ex)


                  //--------------เช็คค่าที่มีอยู่แล้ว
                  this.setState({ load_txt: "กำลังตรวจสอบข้อมูลที่มีอยู่แล้ว" })
                  let sql_update = ""
                  let sql_update_array = []
                  let data_insert = []
                  let data_found = []

                  apilogin.post('insert_salary/import_list_payslip_check_data', ({
                    payslip_month: this.props.payslip_month,
                    payslip_year: this.props.payslip_year,
                  }))
                    .then((res3) => {
                      let data_check = res3.data
                      if (data_check.length >= 1) {
                        this.setState({ load_txt: "พบข้อมูลที่มีอยู่แล้ว กำลังรวบรวม" })
                        for (let e = 0; e < DATA_ex.length; e++) {

                          for (let c = 0; c < data_check.length; c++) {

                            if (data_check[c].payslip_year === DATA_ex[e].payslip_year
                              && data_check[c].payslip_month === DATA_ex[e].payslip_month
                              && data_check[c].payslip_citizent === DATA_ex[e].payslip_citizent
                              && String(data_check[c].payslip_revenue) === String(DATA_ex[e].revenue_id)
                              && String(data_check[c].payslip_expenditure) === String(DATA_ex[e].expenditure_id)
                            ) {
                             
                              sql_update = ` UPDATE tb_payslip set payslip_total = '${DATA_ex[e].payslip_total}' WHERE  payslip_year = '${DATA_ex[e].payslip_year}' AND payslip_month = '${DATA_ex[e].payslip_month}' AND payslip_citizent = '${DATA_ex[e].payslip_citizent}' AND customers_type = '${DATA_ex[e].customers_type}' AND payslip_revenue = '${DATA_ex[e].revenue_id}' AND payslip_expenditure ='${DATA_ex[e].expenditure_id}' ; `
                              
                              sql_update_array.push(sql_update)

                              let found = {
                                payslip_year: DATA_ex[e].payslip_year,
                                payslip_month: DATA_ex[e].payslip_month,
                                payslip_citizent: DATA_ex[e].payslip_citizent,
                                customers_type: DATA_ex[e].customers_type,
                                expenditure_id: DATA_ex[e].expenditure_id,
                                expenditure_name: DATA_ex[e].expenditure_name,
                                revenue_id: DATA_ex[e].revenue_id,
                                revenue_name: DATA_ex[e].revenue_name,
                              }
                              data_found.push(found)
                            }
                          }
                        }

                      } else {
                        this.setState({ load_txt: "ไม่พบข้อมูลที่มีอยู่แล้ว" })
                      }


                      this.setState({ load_txt: "กำลังแยกไฟล์ข้อมูลที่มีอยู่แล้ว กับที่ยังไม่มีอยู่" })
                      let check = null
                      for (let e = 0; e < DATA_ex.length; e++) {
                        check = null
                        for (let c = 0; c < data_found.length; c++) {
                          if (data_found[c].payslip_year === DATA_ex[e].payslip_year
                            && data_found[c].payslip_month === DATA_ex[e].payslip_month
                            && data_found[c].payslip_citizent === DATA_ex[e].payslip_citizent
                            && data_found[c].customers_type === DATA_ex[e].customers_type
                            && String(data_found[c].revenue_id) === String(DATA_ex[e].revenue_id)
                            && String(data_found[c].expenditure_id) === String(DATA_ex[e].expenditure_id)
                          ) {
                            check = e
                          }
                        }
                        if (check === null) {
                          data_insert.push(DATA_ex[e])
                        }
                      }


                      if (data_insert.length >= 1) {
                        this.setState({ load_txt: "กำลังบันทึกข้อมูลรายการใหม่" })
                        this.setState({ count_insert_all: data_insert.length })
                        this.setState({ load_insert: true })
                        let count_insert_ok = 0
                        let count_insert_err = 0

                        for (let i = 0; i < data_insert.length; i++) {
                          setTimeout(() => {
                            let sql_insert = `insert into tb_payslip (payslip_year, payslip_month, payslip_citizent, customers_type,  payslip_revenue, payslip_expenditure, payslip_total) values `
                            sql_insert = sql_insert + `('${data_insert[i].payslip_year}', '${data_insert[i].payslip_month}',  '${data_insert[i].payslip_citizent}',  '${data_insert[i].customers_type}' ,'${data_insert[i].revenue_id}' , '${data_insert[i].expenditure_id}','${data_insert[i].payslip_total}' ) `

                            apilogin.post('insert_salary/import_list_payslip_update', ({ sql_insert: sql_insert }))
                              .then((res) => {
                                if (res.data.affectedRows === 1) {
                                  count_insert_ok = count_insert_ok + 1
                                  this.setState({ count_insert_ok: this.state.count_insert_ok + 1 })
                                }
                                else {
                                  count_insert_err = count_insert_err + 1
                                  this.setState({ count_insert_err: this.state.count_insert_err + 1 })
                                }
                                if (count_insert_ok === data_insert.length - 1 && data_found.length === 0) {
                                  this.setState({ load_txt: "นำเข้าข้อมูลเสร็จสิ้น" })
                                  this.setState({ success_insert: true, load: false })
                                } 
                              })
                          }, i * 25);


                        }




                        //////------------------------ สำหรับรวบ sql เป็นข้อความเดียวกันทั้งหมด สะดวกและเร็ว แต่ว่าถ้าข้อมูลเยอะไป จะส่งไปที่ api ไม่ได้
                        // let sql_insert = `insert into tb_payslip (payslip_year, payslip_month, payslip_citizent, customers_type,  payslip_revenue, payslip_expenditure, payslip_total) values `

                        // for (let i = 0; i < data_insert.length; i++) {
                        //   sql_insert = sql_insert + `('${data_insert[i].payslip_year}', '${data_insert[i].payslip_month}',  '${data_insert[i].payslip_citizent}',  '${data_insert[i].customers_type}' ,'${data_insert[i].revenue_id}' , '${data_insert[i].expenditure_id}','${data_insert[i].payslip_total}' ) `
                        //   if (i !== data_insert.length - 1) {
                        //     sql_insert = sql_insert + `,`
                        //   }
                        // }
                        // apilogin.post('insert_salary/import_list_payslip_update', ({ sql_insert: sql_insert }))
                        //   .then((res) => {
                        //     if (res.data.affectedRows === (data_insert.length)) {
                        //       this.setState({ success: true, load: false })
                        //       this.setState({ load_txt: "นำเข้าข้อมูลเสร็จสิ้น" })
                        //     }
                        //     else {
                        //       this.setState({ success: false, load: false })
                        //       this.setState({ load_txt: "บันทึกไม่สำเร็จ" })
                        //     }
                        //   })
                        //   .catch(err => {
                        //     console.log(err)
                        //     console.log(sql_insert)
                        //     notification.info({
                        //       message: 'เพิ่มไม่สำเร็จ',
                        //       description:'ข้อมูลมีขนาดใหญ่เกินไป กรุณาติดต่อผู้ดูแลระบบ'
                        //       // icon: <MDBIcon style={{ color: 'green' }} icon="check" />
                        //     })
                        //     this.setState({ load: false })
                        //   })
                      }
                      else {
                        notification.info({
                          message: 'ไม่พบข้อมูลรายการใหม่',
                          // icon: <MDBIcon style={{ color: 'green' }} icon="check" />
                        })
                        this.setState({ load: false })
                      }



                      let success = 0
                      let err = 0
                      this.setState({ allupdate: sql_update_array.length })
                      if (data_found.length >= 1) {

                        Swal.fire({
                          title: 'พบข้อมูลที่มีอยู่แล้ว ' + data_found.length + ' รายการ',
                          text: "ต้องการแทนที่เลยหรือไม่!",
                          type: 'warning',
                          showCancelButton: true,
                          confirmButtonColor: '#d333085d6',
                          cancelButtonColor: '#d33',
                          confirmButtonText: 'แทนที่',
                          cancelButtonText: 'ข้าม',
                          padding: '0.90rem',
                        }).then((result) => {
                          if (result.value) {
                            this.setState({ load_txt: "กำลังอัพเดตรายการที่มีอยู่แล้ว" })
                            this.setState({ go_update: true })
                            for (let i = 0; i < sql_update_array.length; i++) {
                              setTimeout(() => {
                                apilogin.post('insert_salary/import_list_payslip_update_data', ({ sql_insert: sql_update_array[i] }))

                                  .then((res) => {
                                    if (res.data.affectedRows === 1) {
                                      success = success + 1
                                      this.setState({ update_count: this.state.update_count + 1 })
                                    }
                                    else {
                                      err = err + 1
                                      this.setState({ update_count_err: this.state.update_count_err + 1 })
                                    }
                                    if (success === sql_update_array.length - 1) {
                                      this.setState({ load_txt: "อัพเดตสำเร็จ" })
                                      this.setState({ success_insert: true, load: false })
                                    }
                                  })
                                  .catch(err => { console.log(err) })
                              }, 25 * i);
                            }


                          }
                          else {
                            this.setState({ load_txt: "นำเข้าข้อมูลเสร็จสิ้น" })
                            this.setState({ success: true, load: false })
                          }
                        })
                      }
                      else {
                        // notification.info({
                        //   message: 'ไม่มีรายการที่ต้องอัพเดต',
                        //   // icon: <MDBIcon style={{ color: 'green' }} icon="check" />
                        // })
                        this.setState({ load: false })
                      }

                    })
                })
            })
        }
        else {
          notification.error({
            message: 'ข้อมูลไฟล์ไม่ตรงกับที่เลือกไว้',
          })
          this.setState({ load: false })
        }



      } catch (error) {
        notification.error({
          message: 'ไม่สามารถอ่านไฟล์ได้',
        })
        this.setState({ load: false })
      }




    }







    if (rABS) {
      reader.readAsBinaryString(this.state.file);
    } else {
      reader.readAsArrayBuffer(this.state.file);
    };
  }

  componentDidMount() {
    options_mounth.map((data) => {
      if (data.value === this.props.payslip_month) {
        this.setState({ mounth: data.label })
        // return <>{data.value}</>
      }
    })
  }
  render() {



    return (
      <div>
        <Text strong style={{ fontSize: '18px' }}>
          ปี {Number(this.props.payslip_year) + 543} เดือน {this.state.mounth}  <br />
          ประเภท {this.props.data[0].customer_type_name}
        </Text>
        <br /><br />
        <label htmlFor="file">อัพโหลดไฟล์ excel</label>

        <input type="file" className="form-control" id="file" accept={SheetJSFT} onChange={this.handleChange} />
        <br />

        {this.state.show === true ? <>
          <div className="mt-3 mb-5 text-center">
            {this.state.load === true ? <>
              <Button color="primary" size="large" ><Icon type="loading" /> &nbsp; {this.state.load_txt}</Button>
            </>
              : <>
                <Button size="large" color="primary" onClick={this.handleFile}><MDBIcon far icon="play-circle" />&nbsp; เริ่มอัพโหลดข้อมูล</Button>
              </>}
          </div>
        </> : <></>}

        <br />


        {this.state.show === true ? <>
          <div
            style={{
              display: 'flex',
              height: '100%',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <div className="text-center mt-1" style={{ width: '100%' }}>
              <TweenOne
                animation={this.state.animation}
                style={{
                  fontSize: 56
                  , marginBottom: 0
                }}
              >

                {this.state.load_insert === true ?
                  <>

                    <img src={img_load} width="100px" /><br />
                    <Text style={{fontSize:'16px'}}>กำลังนำเข้าข้อมูล</Text> {Number((((this.state.count_insert_ok + this.state.count_insert_err) / this.state.count_insert_all) * 100), 2).toLocaleString(navigator.language, { maximumFractionDigits: 2 })}%<br />
                   
                    {this.state.success_insert !== null ? <>
                      {this.state.success_insert === this.state.count_insert_all ? <>
                        <div className="texty-demo green-text mt-0 mb-3" style={{ fontSize: '22px' }}>
                          <MDBIcon icon="check-double" /> <Texty > นำเข้าสำเร็จ</Texty>
                        </div>
                      </> : <>
                          {this.state.success_insert === false ? <>
                            <div className="texty-demo red-text mt-0 mb-3" style={{ fontSize: '22px' }}>
                              <Icon type="close-circle" />
                              <Texty > นำเข้าไม่สำเร็จ</Texty>
                            </div>
                          </> : null}

                        </>}
                    </> : null}


                    <br/><br/>


                  </>
                  : <></>}


                {this.state.go_update === true ?
                  <>
                 
                    <img src={img_load} width="100px" /><br />
                   <Text style={{fontSize:'16px'}}>อัพเดตข้อมูล</Text>  {Number((((this.state.update_count + this.state.update_count_err) / this.state.allupdate) * 100), 2).toLocaleString(navigator.language, { maximumFractionDigits: 2 })}%<br />
                 
                    {this.state.success === true ?
                      <>
                        <div className="texty-demo green-text mt-0 mb-3" style={{ fontSize: '22px' }}>
                          <MDBIcon icon="check-double" /> <Texty > อัพเดตสำเร็จ</Texty>
                        </div>
                      </>
                      : <>
                        {this.state.success === false ? <>
                          <div className="texty-demo red-text mt-0 mb-3" style={{ fontSize: '22px' }}>
                            <Icon type="close-circle" />
                            <Texty > อัพเดตไม่สำเร็จ</Texty>
                          </div>
                        </> : null}

                      </>}

                  </> : null}
              </TweenOne>

            </div>
          </div>
        </>
          : null}
      </div>

    )
  }
}

export default ExcelReader;