import React, { Component } from 'react'
import { MDBNavItem, MDBContainer, MDBCol, MDBRow, MDBIcon } from 'mdbreact/dist/mdbreact.esm';
import { IconButton, Badge, MenuItem, Menu, Divider, Typography, Box, Paper, Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Tooltip } from "@material-ui/core";
import NotificationsIcon from '@material-ui/icons/Notifications';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { theme, detail_user, apilogin } from '../api';
import Bttn_view_alert from './Bttn_view_alert';


import moment from 'moment';
import 'moment/locale/th';


export default class Bttn_alert extends Component {
    state = {
        modal: false,
        open1: '',
        data: {}
    };

    onAccept = (data) => {
        if (data.status_read !== "1") {
            let datasent = {
                alert_id: data.alert_id,
                customers_citizent: detail_user.Username
            }
            apilogin.post('getdata/alert_user_read', datasent)
                .then((res) => {
                    this.props.get_alert()
                    this.closemodal()
                    this.setState({ open1: null })

                })
        } else {
            this.closemodal()
        }
    }

    showmodal = (v) => {
        this.setState({ open1: null })
        this.setState({ modal: true })
        this.setState({ data: v })
    }
    closemodal = (v) => {
        this.setState({ modal: false })
    }

    componentDidMount() {
        let list_alert = this.props.list_alert_all.alert_list

        let datashow = []
        for (let i = 0; i < list_alert.length; i++) {
            if (list_alert[i].alert_force_show === "1" && list_alert[i].status_read === null) {
                datashow.push(list_alert[i])
            }
        }

        if (datashow.length >= 1) {
            this.showmodal(datashow[0])
        }


    }

    render() {

        let list_alert = this.props.list_alert_all.alert_list
        let alert_cont = this.props.list_alert_all.alert_cont

        return (
            <>
                <Tooltip title={"การแจ้งเตือนข่าวสาร"}>
                    <IconButton
                        onClick={(event) => this.setState({ open1: event.currentTarget })}
                        color="inherit">
                        <Badge badgeContent={alert_cont} color="secondary">
                            <NotificationsIcon className={"font-" + theme}
                            // style={{ fontSize: '20px' }}
                            />
                        </Badge>
                    </IconButton>
                </Tooltip>


                <Menu
                    style={{ marginTop: '2rem', minWidth: '1000px' }}
                    anchorEl={this.state.open1}
                    keepMounted
                    open={Boolean(this.state.open1)}
                    onClose={() => this.setState({ open1: null })}
                >

                    <div className="text-center font18 mt-0" >รายการแจ้งเตือน</div>
                    <Divider />
                    <MDBContainer>
                        {list_alert.length >= 1 ? <>
                            {list_alert.map((data) => {
                                return <>
                                    <a onClick={() => this.showmodal(data)}>

                                        <div className="d-inline-block text-truncate mt-2" style={{ maxWidth: "250px" }}>
                                            <FiberManualRecordIcon
                                                style={{
                                                    color: data.status_read === "1" ? "grey" : 'green'
                                                    , fontSize: '16px'
                                                    , fontWeight: 'bolder'
                                                }}
                                            /> &nbsp;
                                      <text style={{ fontSize: '16px', fontWeight: 'bolder' }}>{data.alert_name}</text>
                                            <br />
                                            &emsp; &nbsp;
                                        <text style={{ fontSize: '14px' }} >{data.alert_text}</text>

                                            <br />

                                            <text style={{ fontSize: '14px' }} >
                                                &emsp; &nbsp; {moment(data.alert_date + " " + data.alert_time).fromNow()}<br />
                                            </text>

                                        </div>

                                    </a>
                                    <br />
                                </>
                            })}
                        </> : <>
                                ไม่พบการแจ้งเตือนใหม่
                    </>}

                    </MDBContainer>



                </Menu>

                {this.state.modal === true ? <>
                    <Bttn_view_alert
                        get_alert={this.props.get_alert}
                        closemodal={this.closemodal}
                        user={true}
                        modal={this.state.modal}
                        list_alert={this.state.data}
                    />
                </> : null}



            </>
        )
    }
}
