import React, { useState, useContext, useEffect, Fragment } from 'react'
import { MDBModal, MDBContainer } from 'mdbreact';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle, MDBCardText, MDBCol, MDBRow, MDBIcon } from 'mdbreact';
import Toast from 'light-toast';
import { Spin, message, Skeleton, Icon } from 'antd';

import { Button, IconButton, Dialog, DialogTitle, DialogContent, TextField, FormControl, InputLabel, Select, MenuItem, CircularProgress, Snackbar, Chip } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { theme, apilogin } from '../api';
// import Upload from 'rc-upload';
import EditIcon from '@material-ui/icons/Edit';
import LinkIcon from '@material-ui/icons/Link';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AddIcon from '@material-ui/icons/Add';

export default function Bttn_gen_link(props) {

    const [modal, setmodal] = useState(false)
    const [load, setload] = useState(false)
    const [loadf, setloadf] = useState(true)


    const [datasent, setudata] = useState({

        ////////////////////////////////////
        ////////////////////////////////////
        url_name: "",
        url: "",

        ////////////////////////////////////

    })



    const useStyles = makeStyles((theme2) => ({
        root: {
            '& label.Mui-focused': {
                color: theme === "dark" ? 'white' : '',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: theme === "dark" ? 'white' : '',
            },
        },
    }));
    const classes = useStyles();


    let onshowmodal = () => {
        setmodal(!modal)
    }

    let onChange = (e) => {
        setudata({
            ...datasent,
            [e.target.name]: e.target.value
        })
    }

    let onCopy = (v) => {
        let url_web = `<a target="_blank" href="${datasent.url}" > ${datasent.url_name}  </a> `
        navigator.clipboard.writeText(url_web)
        message.success("คัดลอกข้อความแล้ว")
        setmodal(!modal)
        if (v == "add") {
            props.geturl(url_web)
        }

    }






    let formm = (
        <Fragment>


            <TextField fullWidth
                className={"mb-3 "}
                label="หัวข้อ"
                value={datasent.url_name}
                name="url_name"
                onChange={onChange}
                type="text"
            />

            <TextField fullWidth
                className={"mb-3 "}
                label="ลิ้งค์ หรือ url"
                value={datasent.url}
                name="url"
                onChange={onChange}
                type="text"
            // type="text"
            />

            <br />
            <Chip
                style={{ fontSize: '10px' }}
                icon={<LinkIcon />}
                label={`<a target="_blank" href="${datasent.url}" > ${datasent.url_name}  </a> `}
                variant="outlined"
            />



            <div className="text-center mt-5 mb-3 mr-2">
                <Button
                    disabled={datasent.url_name !== "" && datasent.url !== "" ? false : true}
                    variant="contained"
                    onClick={() => {
                        onCopy("copy")
                    }}
                    color="primary"><FileCopyIcon/> คัดลอก  </Button>
                  &emsp;
                <Button
                    disabled={datasent.url_name !== "" && datasent.url !== "" ? false : true}
                    variant="contained"
                    onClick={() => {
                        onCopy("add")
                    }}
                    color="primary"><AddIcon/> เพิ่ม  </Button>
            </div>



        </Fragment>
    )

    return (
        <>

            <Button
                onClick={onshowmodal}
                size="small"
                variant="outlined"
            // color="primary"
            ><LinkIcon /> เพิ่มลิงค์ หรือ url </Button>


            <Dialog
                fullWidth={true}
                maxWidth={"sm"} //xs
                open={modal}
                onClose={onshowmodal}
                aria-labelledby="max-width-dialog-title"
            >
                <div className={" d-flex justify-content-between font-" + theme}>
                    <DialogTitle id="alert-dialog-title">{<text className={"font-" + theme}>เพิ่มลิ้งค์ หรือ url </text>}</DialogTitle>
                    <IconButton className={"font-" + theme} onClick={() => setmodal(false)} color="primary" >x </IconButton>
                </div>

                <DialogContent dividers >
                    {formm}
                </DialogContent>

            </Dialog>

        </>
    )
}
