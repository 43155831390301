import React, { Component } from 'react'
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { CardHeader, Divider, Icon, IconButton, ButtonGroup } from '@material-ui/core';
import { MDBIcon } from 'mdbreact/dist/mdbreact.esm';
import { apilogin } from '../api';
import MUIDataTable from "mui-datatables";
import Bttn_Add_notify from '../Components/Bttn_Add_notify';
import { Popconfirm, Checkbox, Switch, message } from 'antd';
import Toast from 'light-toast';
import DeleteIcon from '@material-ui/icons/Delete';
import Bttn_view_alert from '../Navs/Bttn_view_alert';



export default class PageNotify extends Component {

    state = {
        list_alert: [],
        load: true,
    }
    get_alert = () => {
        Toast.loading('Loading..')
        apilogin.get('getdata/get_alert_for_adminnn')
            .then((res) => {
                this.setState({
                    list_alert: res.data,
                })
                setTimeout(() => {
                    Toast.hide();
                }, 300);
            })
            .catch((err) => {
                message.error('โหลดไม่สำเร็จ ' + err)
            })
    }

    onCheckbox = (e, v) => {
        // console.log(e)
        // console.log(v.alert_id)
        if (e === true) {
            e = "1"
        } else {
            e = "0"
        }
        let datasent = {
            alert_id: v.alert_id,
            alert_status: e,
        }
        apilogin.post('getdata/update_alertadmin', datasent)
            .then((res) => {
                this.get_alert()
            })
    }


    componentDidMount() {
        this.get_alert()
    }


    render() {

        const columns11 = [

            {
                name: "alert_name",
                label: <div className="text-left" >หัวข้อ</div>,
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        let i = tableMeta.rowIndex
                        return (
                            <>
                                <div className="text-left">
                                    {value}
                                </div>
                            </>
                        );
                    }
                }
            },

            {
                name: "alert_text",
                // label: "ปี",
                label: <div className="text-left" >รายละเอียด</div>,
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div className="text-left d-inline-block text-truncate" style={{ maxWidth: "250px" }} >
                                {value}
                            </div>
                        );
                    }
                }
            },

            {
                name: "alert_date",
                // label: "ปี",
                label: <div className="text-center" >วันที่เพิ่ม</div>,
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        let i = tableMeta.rowIndex
                        return (
                            <div className="text-center">
                                {value} {this.state.list_alert[i].alert_time}
                            </div>
                        );
                    }
                }
            },

            {
                name: "alert_status",
                label: <div className="text-center" >สถานะ</div>,
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        let i = tableMeta.rowIndex

                        if (value === "1") {
                            value = true
                        } else { value = false }

                        return (
                            <div className="text-center">
                                {/* {value} */}
                                {/* <Checkbox value={value} name="alert_status" onChange={this.onCheckbox}>Checkbox</Checkbox> */}
                                <Switch checked={value} name="alert_status" onChange={(e) => this.onCheckbox(e, this.state.list_alert[i])} />
                            </div>
                        );
                    }
                }
            },

            {
                name: "alert_force_show",
                label: <div className="text-center" >บังคับแสดงผล</div>,
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        let i = tableMeta.rowIndex

                        if (value === "1") {
                            value = true
                        } else { value = false }

                        return (
                            <div className="text-center">
                                <Checkbox name="user_status_sent" checked={value}
                                    onChange={(e) => {
                                        let cc = "0"
                                        if (e.target.checked === true) {
                                            cc = "1"
                                        }
                                        let datasent = {
                                            alert_force_show: cc,
                                            alert_id: this.state.list_alert[i].alert_id
                                        }
                                        apilogin.post('getdata/update_status_alert_force_show_foradminn', datasent)
                                            .then((res) => {
                                                this.get_alert()
                                            })


                                    }}
                                />
                            </div>
                        );
                    }
                }
            },

            {
                name: "alert_id",
                label: <div className="text-center" >จัดการ</div>,
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        let i = tableMeta.rowIndex
                        return (
                            <div className="text-center">
                                <ButtonGroup color="primary" aria-label="outlined primary button group">
                                    <Bttn_view_alert view_admin={true} list_alert={this.state.list_alert[i]} />
                                    <Bttn_Add_notify dataedit={this.state.list_alert[i]} edit={true} get_alert={this.get_alert} />
                                    <Popconfirm
                                        title="ยืนยันการลบ?"
                                        onConfirm={() => {
                                            apilogin.post('getdata/delete_alertadmin', ({ alert_id: value }))
                                                .then((res) => {
                                                    this.get_alert()
                                                })
                                        }}
                                        okText="ยืนยัน"
                                        cancelText="ยกเลิก"
                                    >
                                        <IconButton size="small" ><DeleteIcon /></IconButton>
                                    </Popconfirm>

                                </ButtonGroup>



                            </div>
                        );
                    }
                }
            },


        ];


        return (
            <div
                className="container mt-5"
            // className="mr-3 ml-3"
            >

                <Card elevation="3" >
                    <CardHeader title={<><MDBIcon far icon="bell" /> รายการแจ้งเตือน</>} />
                    <Divider />
                    <CardContent>
                        <div className="text-right mb-2" >
                            <Bttn_Add_notify get_alert={this.get_alert} />
                        </div>

                        <Divider />

                        <MUIDataTable
                            data={this.state.list_alert}
                            columns={columns11}
                            options={{
                                filterType: 'dropdown',
                                selectableRows: 'none',
                                viewColumns: false,
                                filter: false,
                                // pagination: false,
                                // search: false,
                                download: false,
                                print: false,
                            }}
                        />
                    </CardContent>

                </Card>


            </div>
        )
    }
}
