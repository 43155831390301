import React, { Component } from 'react'
import { Modal, Input, message, InputNumber, Icon, Form, notification, Popconfirm } from 'antd';
import { MDBContainer, MDBCard, MDBCardBody, MDBCardHeader, MDBBtn, MDBIcon } from 'mdbreact';
import { apilogin, detail_user, options_mounth, fontpdf } from '../api'

import kpru_background from '../img/kpru_background.png';
import pdfMake from "pdfmake/build/pdfmake";
import ThaiBaht from 'thai-baht-text'
import { Button, Dialog, DialogContent } from '@material-ui/core';
import { Com_login, Lottie_Success } from '../Components/MyLottie';
import img_logo_kpru_base64 from '../img/img_logo_kpru_base64';
import img_logo_kpru_background_base64 from '../img/img_logo_kpru_background_base64.js';

// pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = fontpdf
//const logo = "https://mis.kpru.ac.th/images/pic_emp_50/000322.jpg"
export default class PrintPDF extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dataprint: [],
            payslip_month: '',
            img_person: '',
            payslip_year: '',
            payslipall: [],
            payrevenueslipall: [],
            history_salary: [],
            payslip_month2: '44',
            loading: false,
            kpru_background: '',
            logo: '',
            load_all_start: null,
            cont_all: 0,
            current: 0,
            current_have: 0,
            current_not_use: 0,
            modal: false,

        }
        this.loaddata = this.loaddata.bind(this)
        this.load_print_all_data = this.load_print_all_data.bind(this)


    }

    onshowmodal = () => {
        this.setState({ modal: !this.state.modal })
    }
    onclose = () => {
        this.setState({
            modal: false,
            cont_all: 0,
            current: 0,
            current_have: 0,
            current_not_use: 0,
        })
    }

    //เพิ่มlogo
  

    load_data_api = (check, dataprint) => {
        apilogin.post('getdata/get_slip_expenditure',
            ({
                customers_citizent: dataprint.customers_citizent,
                payslip_month: this.props.payslip_month,
                payslip_year: this.props.payslip_year,
                customer_type_id: dataprint.customer_type_id,
            }))

            .then(res1 => {
                // this.setState({ payslipall: res1.data })
                let payslipall = res1.data
                apilogin.post('getdata/get_slip_revenue',
                    ({
                        customers_citizent: dataprint.customers_citizent,
                        payslip_month: this.props.payslip_month,
                        payslip_year: this.props.payslip_year,
                        customer_type_id: dataprint.customer_type_id,
                    }))

                    .then(res2 => {
                        // this.setState({ payrevenueslipall: res2.data })
                        let payrevenueslipall = res2.data
                        apilogin.post('getdata/get_history_salary',
                            ({
                                customers_citizent: dataprint.customers_citizent,
                                payslip_month: this.props.payslip_month,
                                payslip_year: this.props.payslip_year,
                                customer_type_id: dataprint.customer_type_id,
                            }))

                            .then(res3 => {
                                // this.setState({ history_salary: res3.data })
                                let salary = 0
                                try {
                                    salary = res3.data[0].history_salary_salary
                                } catch (error) { salary = 0 }

                                setTimeout(() => {
                                    this.printPDF(check, dataprint, salary, payslipall, payrevenueslipall)
                                }, 1000);
                            })


                    })
            })



        options_mounth.map((data) => {
            if (data.value === this.props.payslip_month) {
                this.setState({
                    payslip_month2: data.label,
                });
            }
        })
    }

    loaddata(check, dataprint) {
        if (check !== "load") {
            this.setState({ loading: true });
        }
        // let dataprint = this.props.dataprint
        this.setState({
            dataprint: dataprint,
            payslip_month: this.props.payslip_month,
            payslip_year: (Number(this.props.payslip_year) + 543),
        });
        let sent = {
            payslip_month: this.props.payslip_month,
            payslip_year: this.props.payslip_year,
            customers_citizent: dataprint.customers_citizent
        }
        apilogin.post('getdata/checkhistoryprint', sent)
            .then((res) => {
                let check = res.data
                if (check.length >= 1) {
                    this.setState({ current_have: this.state.current_have + 1 })

                    if (this.props.print_gen_all !== true) {
                        window.open('https://e-payslip.kpru.ac.th/ref-slip/' + check[0].print_file_name + '.pdf', "_blank")
                        this.setState({ loading: false });
                    }


                }
                else {
                    this.load_data_api(check, dataprint)

                    // if (this.state.kpru_background === "") {
                    //     image2base64(kpru_background)
                    //         .then((response) => {
                    //             this.setState({ kpru_background: response })
                    //             image2base64(logo)
                    //                 .then((response) => {
                    //                     this.setState({ logo: response })
                    //                     setTimeout(() => {
                    //                         this.load_data_api(check, dataprint)
                    //                     }, 400);

                    //                 })

                    //                 .catch(err => {
                    //                     notification.open({
                    //                         message: 'โหลดไม่สำเร็จ',
                    //                         icon: <MDBIcon style={{ color: 'red' }} icon="times" />,
                    //                         description:
                    //                             'เกิดข้อผิดพลาดระหว่างการโหลดรูปภาพ ' + err,
                    //                     });
                    //                     this.setState({ loading: true })
                    //                     // console.log(err)
                    //                 })
                    //         })
                    // } else {
                    //     this.load_data_api(check, dataprint)
                    // }




                }
            })
    }


    load_print_all_data(dataprint) {


        this.setState({
            load_all_start: true,
            cont_all: dataprint.length,
            modal: true
        })
        for (let i = 0; i < dataprint.length; i++) {
            setTimeout(() => {
                if (dataprint[i].payslip_total >= 1) {
                    this.loaddata('close', dataprint[i])
                } else {
                    this.setState({ current_not_use: this.state.current_not_use + 1 })
                }
            }, 600 * i);
        }
    }



    componentDidMount() {

        this.setState({
            // dataprint: this.props.dataprint,
            payslip_month: this.props.payslip_month,
            payslip_year: (Number(this.props.payslip_year) + 543),
        });

    }



    printPDF(check, dataprint, salary, payslipall, payrevenueslipall) {


        let logo = img_logo_kpru_base64
        let kpru_background = img_logo_kpru_background_base64


        // console.log(kpru_background)
        this.setState({ loading: false })



        let gen_string = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < 25; i++) {
            gen_string += characters.charAt(Math.floor(Math.random() * charactersLength));
            let n = 5
            if (i === 4 || i === 12 || i === 18) {
                gen_string += '-'
            }
        }



        var rows = [];
        var count = payslipall.length
        let lineheight = 0.7
        for (let i = 0; i < count; i++) { // ลูป
            if (payslipall[i].payslip_total === null) {
                rows.push([
                    //   {
                    //       text: i+1, alignment: 'center',  fontSize: 14
                    //   },
                    {
                        text: payslipall[i].expenditure_name, fontSize: 12, lineHeight: lineheight
                    },
                    {
                        text: '0.00', fontSize: 12, alignment: 'right', lineHeight: lineheight
                    }


                ]);
            } else {
                rows.push([
                    //   {
                    //       text: i+1, alignment: 'center',  fontSize: 14
                    //   },
                    {
                        text: payslipall[i].expenditure_name, fontSize: 12, lineHeight: lineheight
                    },
                    {
                        text: payslipall[i].payslip_total.toLocaleString(navigator.language, { minimumFractionDigits: 2 }), fontSize: 12, alignment: 'right', lineHeight: lineheight
                    }


                ]);
            }

        }





        //รายรับ
        var rowsrevenue = [];
        var countrevenue = payrevenueslipall.length
        try {
            rowsrevenue.push([
                //   {
                //       text: i+1, alignment: 'center',  fontSize: 14
                //   },
                {
                    text: 'เงินเดือน', fontSize: 12, lineHeight: lineheight
                },

                {

                    text: salary.toLocaleString(navigator.language, { minimumFractionDigits: 2 }), fontSize: 12, alignment: 'right', lineHeight: lineheight
                }

            ]);
        } catch (error) {
            rowsrevenue.push([
                //   {
                //       text: i+1, alignment: 'center',  fontSize: 14
                //   },
                {
                    text: 'เงินเดือน', fontSize: 12, lineHeight: lineheight
                },

                {

                    text: '0.00', fontSize: 12, alignment: 'right', lineHeight: lineheight
                }

            ]);
        }




        for (let i = 0; i < countrevenue; i++) { // ลูป
            if (payrevenueslipall[i].payslip_total === null) {
                rowsrevenue.push([
                    //   {
                    //       text: i+1, alignment: 'center',  fontSize: 14
                    //   },
                    {
                        text: payrevenueslipall[i].revenue_name, fontSize: 12, lineHeight: lineheight
                    },
                    {
                        text: '0.00', fontSize: 12, alignment: 'right', lineHeight: lineheight
                    }


                ]);
            } else {
                rowsrevenue.push([
                    //   {
                    //       text: i+1, alignment: 'center',  fontSize: 14
                    //   },
                    {
                        text: payrevenueslipall[i].revenue_name, fontSize: 12, lineHeight: lineheight
                    },
                    {
                        text: payrevenueslipall[i].payslip_total.toLocaleString(navigator.language, { minimumFractionDigits: 2 }), fontSize: 12, alignment: 'right', lineHeight: lineheight
                    }


                ]);
            }

        }

        let moneyText = ThaiBaht(dataprint.payslip_total)

        let typename = ""
        if (dataprint.customers_citizent === '1629900314177' || dataprint.customers_citizent === '1640900035130' || dataprint.customers_citizent === '3501300314991' || dataprint.customers_citizent === '1620600002813' || dataprint.customers_citizent === '3640300266374' || dataprint.customers_citizent === '3649900007971' || dataprint.customers_citizent === '3150400517012' || dataprint.customers_citizent === '3469900098596' || dataprint.customers_citizent === '3580100698310'  || dataprint.customers_citizent === '1720200015551'  || dataprint.customers_citizent === '3100602762042') {
            typename = "พนักงานมหาวิทยาลัย"
        } else {
            typename = dataprint.customer_type_name
        }

        var docDefinition = {
            info: {
                title: 'kpru',
            },
            background: [
                {
                    image: kpru_background,
                    width: 400,
                    alignment: 'center',
                    margin: [0, 160],
                }
            ],

            content: [

                {
                    image: logo,
                    width: 50,
                    alignment: 'center'
                },

                { text: 'รายการหักเงินเดือน มหาวิทยาลัยราชภัฏกำแพงเพชร', fontSize: 15, alignment: 'center', bold: true },
                { text: 'ประเภท ' + typename  + ' ประจำเดือน' + this.state.payslip_month2 + ' ' + this.state.payslip_year, fontSize: 15, alignment: 'center', bold: true },
                { text: dataprint.customers_pname + dataprint.customers_name, fontSize: 15, alignment: 'center', bold: true },
                //ตาราง
                {
                    columns: [
                        { width: '0%', text: '' },
                        {
                            //  widths: [ '20%', '90%',  '20%'],

                            layout: {
                                hLineWidth: function (i, node) {
                                    return (i === 0 || i === node.table.body.length) ? 0.1 : 0.1;
                                },
                                vLineWidth: function (i, node) {
                                    return (i === 0 || i === node.table.widths.length) ? 0.1 : 0.1;
                                },
                                hLineColor: function (i, node) {
                                    return (i === 0 || i === node.table.body.length) ? 'gray' : 'gray';
                                },
                                vLineColor: function (i, node) {
                                    return (i === 0 || i === node.table.widths.length) ? 'gray' : 'gray';
                                },

                            },
                            table: {
                                body: [
                                    [{ text: 'รายรับ', alignment: 'center', bold: true }, { text: 'รายจ่าย', alignment: 'center', bold: true }],
                                    [
                                        //รายรับ
                                        {
                                            layout: 'headerLineOnly',
                                            table: {
                                                body: rowsrevenue,
                                                widths: ['80%', '20%'],
                                                margin: [0, 0, 0, 5],
                                                alignment: 'right',
                                            },
                                        },
                                        //รายจ่าย
                                        {
                                            layout: 'headerLineOnly',
                                            table: {
                                                body: rows,
                                                widths: ['80%', '20%'],
                                                margin: [0, 0, 0, 5],
                                            }
                                        }],

                                    [{
                                        text: 'รวมรายรับ : ' + dataprint.payslip_revenue.toLocaleString(navigator.language, { minimumFractionDigits: 2 }), alignment: 'center', style: 'tableHeader', bold: true
                                    }
                                        , {
                                        text: 'รวมรายจ่าย: ' + dataprint.payslip_expenditure.toLocaleString(navigator.language, { minimumFractionDigits: 2 }), alignment: 'center', style: 'tableHeader', bold: true
                                    },],
                                    [{ text: 'คงเหลือ: ' + dataprint.payslip_total.toLocaleString(navigator.language, { minimumFractionDigits: 2 }), style: 'tableHeader', colSpan: 2, alignment: 'center', bold: true }, {}],
                                    [{ text: moneyText, style: 'tableHeader', colSpan: 2, alignment: 'center', bold: true }, {}],

                                ],
                                widths: ['50%', '50%'],

                            }
                        },
                        { width: '0%', text: '' }

                    ]

                },
                // { text: 'ggg ' },
                { text: 'หมายเลขอ้างอิง : ' + gen_string, alignment: 'right' },
                { qr: 'https://e-payslip.kpru.ac.th/CheckREF/' + gen_string, fit: '60', alignment: 'right' },


            ],
            styles: {
                headerLineOnly: {
                    lineHeight: lineheight
                },
            },
            defaultStyle: {
                font: 'THSarabunNew',
                // bold: true
            }
        };

        //--------------------------------------------------------------------------สำหรับเปิดเทส แล้วไม่ให้ save
        // pdfMake.createPdf(docDefinition).open('')
        // if (check === "load") {
        //     pdfMake.createPdf(docDefinition).download('payslip-' + this.props.payslip_year + '-' + this.props.payslip_month + '-' + this.state.dataprint.customers_citizent)
        // } else {
        //     pdfMake.createPdf(docDefinition).open('')
        // }
        //--------------------------------------------------------------------------สำหรับเปิดเทส แล้วไม่ให้ save



        //--------------------------------------------------------------------------เปิดเมื่อเทสเสร็จแล้ว
        let print_by = ""
        if (detail_user.admin_type) {
            print_by = "admin"
        } else {
            print_by = detail_user.nameuser
        }

        if (this.props.print_gen_all === true) {
            print_by = dataprint.customers_name
        }

        var Base64PDF = pdfMake.createPdf(docDefinition).getBase64((data64) => {
            // console.log("in000")
            let fullbase64 = data64
            let sent = {
                pdf: fullbase64,
                gen_string: gen_string,
                customers_citizent: dataprint.customers_citizent,
                customer_type_id: dataprint.customer_type_id,
                payslip_year: this.props.payslip_year,
                payslip_month: this.state.payslip_month,
                customers_name: dataprint.customers_name,
                print_by: print_by,
            }
            apilogin.post('getdata/uploadpdfgen', sent)
                .then((res) => {
                    this.setState({ current: this.state.current + 1 })
                  
                    if (this.props.print_gen_all !== true) {
                        window.open('https://e-payslip.kpru.ac.th/ref-slip/' + gen_string + '.pdf', "_blank")
                    }

                })
        });
        //--------------------------------------------------------------------------เปิดเมื่อเทสเสร็จแล้ว
    }



    render() {

        // current_not_use
        let total = (this.state.current + this.state.current_have + this.state.current_not_use) - this.state.cont_all

        return (

            <>

                {/* */}


                {this.props.print_gen_all === true ? <>
                    <Popconfirm
                        title="คุณแน่ใจใช่ไหมที่จะเจน pdf ทั้งหมด ของรายการนี้"
                        onConfirm={() => {
                            this.load_print_all_data(this.props.dataprint)
                        }}
                        okText="ยืนยัน"
                        cancelText="ยกเลิก"
                    >
                        <Button variant="outlined" size="small">
                            &nbsp; GEN PDF ทั้งหมด
                    </Button>
                    </Popconfirm>


                </> : <>
                        <Button variant="outlined" size="small"
                            disabled={this.props.dataprint.payslip_total === 0 ? true : false}
                            onClick={() => {
                                this.loaddata('open', this.props.dataprint)
                            }}
                        >
                            {this.state.loading === true ? <>
                                <Icon type="loading" />
                            </> : <> <MDBIcon icon="print" />&nbsp;      พิมพ์
                                </>}
                        </Button>
                    </>}








                <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    fullWidth={true}
                    maxWidth={"xs"} //xs
                    open={this.state.modal}
                    onClose={this.onclose}
                    aria-labelledby="max-width-dialog-title"
                >

                    <DialogContent dividers >
                        <div className="text-center font18" >
                            {total === 0 ?
                                <>อัพโหลดสำเร็จ</> : <>กำลังอัพโหลด</>}
                            <br />
                            {this.state.load_all_start === true ? <>

                                {this.state.current + this.state.current_have + this.state.current_not_use} / {this.state.cont_all}
                                <br />
                                <div className="text-left mt-3" >
                                    รายการสถานะ<br />
                                    ทั้งหมด {this.state.cont_all} รายการ<br />
                                    รายการใหม่ {this.state.current} รายการ <br />
                                    มีอยู่แล้ว {this.state.current_have} รายการ <br />
                                    ไม่มีรายการรายรับรายจ่าย {this.state.current_not_use} รายการ <br />
                                </div>

                                {total === 0 ? <>

                                    <div className="text-center mt-3" >
                                        <Lottie_Success />
                                        <br />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={this.onclose} >ออก</Button>
                                    </div>
                                </> : <> </>}

                            </> : <></>}
                        </div>


                    </DialogContent>

                </Dialog>

            </>
        )
    }
}
