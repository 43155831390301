import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { Menulist } from './ListMenu';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MenuItem } from '@material-ui/core';
import dropdownStyle from "./style/dropdownStyle";
import { Lottie_salary } from '../Components/MyLottie';
import { theme, detail_user } from '../api';

import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Toast from 'light-toast';
import MyAvatar from './MyAvatar';
import MenuIcon from '@material-ui/icons/Menu';
import FaceIcon from '@material-ui/icons/Face';

const useStyles = makeStyles((theme) => ({
    ...dropdownStyle(theme),
    root: {
        width: '100%',
        fontSize: '14px',
        // color: '#757575',
        backgroundColor: theme.palette.background.paper,
    },

    nested: {
        paddingLeft: theme.spacing(4),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        color: '#757575'
        // fontWeight: theme.typography.fontWeightRegular,
    },
    list: {
        width: "500",
    },
    fullList: {
        width: 'auto',
    },
    fontsmall: {
        fontSize: '12px'
    },
    fontColor: {
        color: '#212121'
    },
    bottomPush: {
        position: "fixed",
        bottom: 0,
        textAlign: "center",
        paddingBottom: 10,
    }

}))


export default function SideBar(props) {
    const classes = useStyles();

    let onGo = (to) => {
        props.history.push(to)
        props.onChangeSideBar()

    }


    let href = window.location.href.split('/')
    let href_main = href[3]
    let href_admin = href[4]
    let menu = ""
    if (href_admin === "customers"
        || href_admin === "customers_manage"
        || href_main === "FormPageList") {
        menu = "2"

    } else if (href_admin === "payslip_manage"
        || href_admin === "payslip_list_manage"
        || href_admin === "PaySlipExpensetype"
        || href_admin === "PayslipRevenueType"
        || href_admin === "Payslip_export_import_data"
        || href_admin === "PaySlipManageUpdateDataEx"
        || href_admin === "PaySlipManageUpdateDataRe"
        || href_admin === "PaySlipManageUpdateDataSalary"
    ) {
        menu = "1"
    }

    else if (href_admin === "PaySlipExpensetypeReport"
        || href_admin === "Excelreportipay"
        || href_admin === "PageShowListSalaryEdit"
        || href_admin === "PageListHistoryPrintAdmin"
        || href_admin === "PageNotify"
    ) {
        menu = "3"
    }

    return (
        <div>
            <React.Fragment key={'left'}>
                <Drawer anchor={'left'} open={props.openSideBar} onClose={props.onChangeSideBar}

                >
                    <div className="text-right" >
                        <Button onClick={props.onChangeSideBar} >x</Button>
                    </div>
                    <div className="text-center font18 mt-0 mb-5" >
                        <Lottie_salary />
                        ระบบเงินเดือน <br />
                        E-payslip KPRU
                     </div>
                    <Divider />
                    <div
                        className={clsx(classes.list)}
                        role="presentation"
                    >
                        <div className="text-left font18 mt-2 mb-0" > MENU </div>


                        <List className={classes.fontColor} >
                            {Menulist.map((data) => {
                                return <>
                                    {data.subMenu ? <>

                                        <Accordion defaultExpanded={menu === data.menu} >
                                            <AccordionSummary
                                                key={1}
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"

                                            >
                                                <Typography
                                                >{data.icon} &emsp; {data.name}</Typography>
                                            </AccordionSummary>
                                            {data.subMenu.map((data2) => {
                                                return <>
                                                    <MenuItem
                                                        selected={href_admin === data2.path.replace('/admin/', '').replace('/', '') ? true : false}
                                                        className={classes.dropdownItem}
                                                        onClick={() => onGo(data2.path)} >
                                                        &emsp; {data2.icon} {data2.name}
                                                    </MenuItem>
                                                </>
                                            })}
                                            <br />
                                        </Accordion>
                                    </> : <>
                                            <ListItem button
                                                className={theme === "dark" ? "white-text" : classes.fontColor}
                                                onClick={() => onGo(data.path)} >
                                                <ListItemIcon className={theme === "dark" ? "white-text" : classes.fontColor} > {data.icon} </ListItemIcon>
                                                <ListItemText primary={data.name} />
                                            </ListItem>
                                        </>}

                                </>
                            })}



                        </List>





                    </div>
                    <br />
                    <Divider />
                    <List >
                        <div className="text-left font18 mt-2 mb-2" >
                            ผู้ใช้งาน
                     </div>
                        <ListItem button >
                            <ListItemIcon className={theme === "dark" ? "white-text" : classes.fontColor} >  <MyAvatar /> </ListItemIcon>
                            <ListItemText primary={detail_user.nameuser} />

                        </ListItem>

                        <ListItem button
                            onClick={() => {
                                localStorage.removeItem('tokenVerify')
                                Toast.success('ออกจากระบบสำเร็จ', 400)
                                onGo('/login')

                            }} >
                            <ListItemIcon className={theme === "dark" ? "white-text" : classes.fontColor} >  <ExitToAppIcon /> </ListItemIcon>
                            <ListItemText primary={"ออกจากระบบ"} />
                        </ListItem>
                    </List>
                </Drawer>
            </React.Fragment>

        </div >
    );
}

