import React, { useState, useContext, useEffect } from 'react'
import { MDBModal, MDBContainer } from 'mdbreact';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle, MDBCardText, MDBCol, MDBRow, MDBIcon } from 'mdbreact';
import Toast from 'light-toast';
import { Spin, message, Skeleton, Icon } from 'antd';

import { Button, IconButton, Dialog, DialogTitle, DialogContent, TextField, FormControl, InputLabel, Select, MenuItem, CircularProgress, Snackbar, Card, CardContent, CardHeader, Paper } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { theme, apilogin } from '../api';
// import Upload from 'rc-upload';
import EditIcon from '@material-ui/icons/Edit';
import Bttn_gen_link from './Bttn_gen_link';
import parse from 'html-react-parser';
import { Lottie_notify } from './MyLottie';

export default function Bttn_Add_notify(props) {

    const [modal, setmodal] = useState(false)
    const [load, setload] = useState(false)
    const [loadf, setloadf] = useState(true)


    const [datasent, setudata] = useState({

        ////////////////////////////////////
        ////////////////////////////////////
        alert_name: "",
        alert_text: "",

        ////////////////////////////////////

    })


    const useStyles = makeStyles((theme2) => ({
        root: {
            '& label.Mui-focused': {
                color: theme === "dark" ? 'white' : '',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: theme === "dark" ? 'white' : '',
            },
        },
    }));
    const classes = useStyles();


    let onshowmodal = () => {
        if (modal === false) {
            if (props.dataedit) {
                setudata({
                    ...props.dataedit,
                })
            }

        }
        setmodal(!modal)

    }

    let onChange = (e) => {
        setudata({
            ...datasent,
            [e.target.name]: e.target.value
        })
    }

    let onSubmit = () => {
        if (props.edit === true) {
            apilogin.post('getdata/update_alertadmin_data', datasent)
                .then((res) => {
                    setmodal(false)
                    props.get_alert()
                })
        } else {
            apilogin.post('getdata/insertalertadmin', datasent)
                .then((res) => {
                    setmodal(false)
                    props.get_alert()
                })
        }


    }

    useEffect(() => {
        setloadf(false)
    }, [])




    let formm = (
        <>
            <Spin
                spinning={loadf === true ? true : false}
                tip={<text className={"font-" + theme} >Loading..</text>} indicator={<Icon className={"font-" + theme} style={{ fontSize: 24 }} spin />} >
                <form className={classes.root} >

                    <TextField fullWidth
                        className={"mb-3 "}
                        label="หัวข้อ"
                        value={datasent.alert_name}
                        name="alert_name"
                        onChange={onChange}
                        type="text"
                    />

                    <TextField fullWidth
                        rows={4}
                        multiline
                        className={"mb-3 "}
                        label="รายละเอียด"
                        value={datasent.alert_text}
                        name="alert_text"
                        onChange={onChange}
                        type="text-area"
                    // type="text"
                    />






                    <div className="text-center mt-3 mb-3 mr-2">
                        <Button
                            variant="contained"
                            onClick={() => {
                                onSubmit()
                            }}
                            color="primary"> {props.loadsubmit === true ? <><Spin /> กำลังบันทึก..</> : <>บันทึก</>}   </Button>
                    </div>




                </form>
            </Spin>

        </>
    )

    return (
        <>



            {props.edit === true ? <>
                <IconButton onClick={onshowmodal}
                    size="small"
                ><EditIcon /></IconButton>

            </> : <>
                    <Button
                        onClick={onshowmodal}
                        size="small"
                        variant="contained"
                        color="primary"
                    > เพิ่มการแจ้งเตือน </Button>
                </>}



            <Dialog
                fullWidth={true}
                // maxWidth={"sm"} //xs
                maxWidth={"lg"} //xs
                open={modal}
                onClose={onshowmodal}
                aria-labelledby="max-width-dialog-title"
            >
                <div className={" d-flex justify-content-between font-" + theme}>
                    <DialogTitle id="alert-dialog-title">{<text className={"font-" + theme}>เพิ่มการแจ้งเตือน </text>}</DialogTitle>
                    <IconButton className={"font-" + theme} onClick={() => setmodal(false)} color="primary" >x </IconButton>
                </div>

                <DialogContent dividers >
                    <MDBRow>



                        <MDBCol
                            // style={{ padding: '0rem' }}
                            md="6" >
                            <Paper elevation="3" >
                                <CardHeader title="ตัวอย่างการแจ้งเตือน" />
                                <CardContent>
                                    <MDBRow>
                                        <MDBCol style={{ padding: '0rem' }} md="3" ><Lottie_notify /></MDBCol>
                                        <MDBCol md="9" className="mt-3" >
                                            <div className="font18" >{datasent.alert_name === "" ? "ระบุหัวข้อ" : datasent.alert_name} </div>
                                            <br />
                                            {datasent.alert_text === ""?"ระบุรายละเอียด":<>  {parse("<div>" + datasent.alert_text + "</div>")}</>}
                                          
                                        </MDBCol>
                                    </MDBRow>
                                </CardContent>
                            </Paper>

                        </MDBCol>

                        <MDBCol  md="6" >
                            <div className="text-right mb-3" >
                                <Bttn_gen_link geturl={(v) => {
                                    setudata({
                                        ...datasent,
                                        alert_text: datasent.alert_text + v
                                    })
                                }} />
                            </div>
                            {formm}
                        </MDBCol>

                    </MDBRow>



                </DialogContent>

            </Dialog>

        </>
    )
}
