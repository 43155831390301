import React, { Component } from 'react'
import { Modal, Input, message, InputNumber, Icon } from 'antd';
import { apilogin, theme } from '../api';
import Text from 'antd/lib/typography/Text';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import { BrowserRouter as Link } from "react-router-dom";
import { Checkbox } from 'antd'
import { Button, Dialog, DialogTitle, DialogContent, IconButton, Typography, DialogActions } from '@material-ui/core';

export default class Bttn_customer_add_expenditure extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            list_expenditure_price: [],
            list_expenditure: [],
            list: [],
            value: [],
            expenditure_id: null,
            expenditure_price: null,
            payslip_status_out: null,
        }
        this.onChange = this.onChange.bind(this)
        this.updateList = this.updateList.bind(this)
        this.goto = this.goto.bind(this)
        this.onChange_out = this.onChange_out.bind(this)
    }

    showModal = () => {
        this.setState({ visible: true })
        this.postid()

    };

    onChange_out = v => e => {

        let check = ""
        if (e.target.checked === true) {
            check = "1"
        } else {
            check = "0"
        }
        let payslip_expenditure = e.target.name
        // console.log(e.target.name)
        // console.log(check)
        // console.log(v)

        let data = {
            payslip_year: this.props.data.payslip_year,
            payslip_month: this.props.data.payslip_month,
            payslip_citizent: this.props.customers_citizent,
            customer_type_id: this.props.customer_type_id,
            payslip_expenditure: payslip_expenditure,
            payslip_status_out: check,
        }

        apilogin.post('insert_salary/insert_list_out_slip', data)
            .then(res => {

            })
            .catch((err) => {
                message.error("บันทึกไม่สำเร็จ")
            })

        console.log(data)
        // this.setState({
        //     payslip_status_out: check,
        //     expenditure_id: e.target.name,
        // })

        // if (this.state.expenditure_price === null) {
        //     this.setState({ expenditure_price: v })
        // } else {
        //     this.setState({ expenditure_price: this.state.expenditure_price })
        // }


        // setTimeout(() => {
        //     this.updateList()
        // }, 50);
    }

    handleOk = e => {
        // console.log(this.state.list_expenditure_price)
        // alert(JSON.stringify(this.props.customers_citizent))
        // 
        apilogin.post('insert_salary/insert_list_slip', ({
            payslip_year: this.props.data.payslip_year,
            payslip_month: this.props.data.payslip_month,
            payslip_citizent: this.props.customers_citizent,
            list_expenditure_price: this.state.list_expenditure_price,
            customer_type_id: this.props.customer_type_id,
            // payslip_status_out: this.state.payslip_status_out,
            payslip_revenue: 0
        }))
            .then(res => {
                // console.log(res.data)
                if (res.data === true) {
                    this.setState({ visible: false });
                    this.props.updatedata_props()
                }
                else { message.error("บันทึกไม่สำเร็จ") }
            });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    postid() {
        this.setState({
            list_expenditure: [],
            list_expenditure_price: [],
            loading: true
        })
        apilogin.post('insert_salary/get_list_for_add_expenditure', ({
            customer_type_id: this.props.customer_type_id,
            payslip_year: this.props.data.payslip_year,
            payslip_month: this.props.data.payslip_month,
            payslip_citizent: this.props.customers_citizent
        }))
            .then(res => {
                if (res.data.length >= 1) {
                    const getdata = res.data.map((data, index) => ({
                        key: data.expenditure_id,
                        index: index + 1,
                        expenditure_id: data.expenditure_id,
                        expenditure_name: data.expenditure_name,
                        expenditure_price: data.payslip_total,
                        payslip_status_out: data.payslip_status_out,
                    }))
                    this.setState({ list_expenditure: getdata })
                    // this.setState({ list_expenditure_price: getdata })
                }
                this.setState({ loading: false })
            })
    }

    onChange = (name, c) => e => {
        // console.log(c)
        // console.log(name+" "+c+" "+e)
        //console.log(name+" "+v+" "+e)

        //console.log(name, value)
        this.setState({
            expenditure_id: name,
            expenditure_price: e
        })

        // if(this.state.payslip_status_out === null){
        //     this.setState({ payslip_status_out: c })
        // }else{
        //     this.setState({ payslip_status_out: this.state.payslip_status_out })
        // }
    }


    updateList(e) {

        const lists = this.state.list_expenditure_price;
        var crck = false;
        if (lists.length !== 0) {
            const lists = this.state.list_expenditure_price;
            lists.map((values, index) => {
                if (values.expenditure_id === this.state.expenditure_id) {
                    crck = !crck;
                    let items = this.state.list_expenditure_price;
                    let item = { ...items[index] };
                    item.expenditure_price = this.state.expenditure_price;
                    // item.payslip_status_out = this.state.payslip_status_out;
                    items[index] = item;
                    this.setState({ list_expenditure_price: items });
                }
            })

            if (!crck) {
                lists.push({
                    expenditure_id: this.state.expenditure_id,
                    expenditure_price: this.state.expenditure_price,
                    // payslip_status_out: this.state.payslip_status_out,
                });
                this.setState({ list_expenditure_price: lists })
            }

        } else {
            const lists = this.state.list_expenditure_price;
            lists.push({
                expenditure_id: this.state.expenditure_id,
                expenditure_price: this.state.expenditure_price,
                // payslip_status_out: this.state.payslip_status_out,
            });
            this.setState({ list_expenditure_price: lists })
        }
        this.setState({ loading: false })
        this.setState({
            expenditure_id: null,
            expenditure_price: null,
            // payslip_status_out: null,
        })
    }

    goto() {
        try {
            this.props.pop.history.replace({ pathname: '/admin/payslip_list_manage' });
        } catch (error) {
            window.location.href = "/admin/payslip_list_manage"
        }
    }

    render() {
        return (
            <>
                <Button variant="outlined" size="small" onClick={this.showModal}>
                    <MDBIcon icon="dollar-sign" /> &nbsp; เพิ่มรายจ่าย
                </Button>



                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"} //xs
                    open={this.state.visible}
                    onClose={this.handleCancel}
                    aria-labelledby="max-width-dialog-title"
                >
                    <div className={" d-flex justify-content-between font-" + theme}>
                        <DialogTitle id="alert-dialog-title">{<text className={"font-" + theme}>เพิ่มรายจ่าย </text>}</DialogTitle>
                        <IconButton className={"font-" + theme} onClick={this.handleCancel} color="primary" >x </IconButton>
                    </div>

                    <DialogContent dividers >

                        {this.state.loading === true ? <div className="text-center">
                            กำลังโหลดรายการ...
                        </div> : <>
                                <MDBRow>
                                    <MDBCol sm="1" style={{ paddingTop: '0.4rem' }}> </MDBCol>
                                    <MDBCol sm="4" style={{ paddingTop: '0.0rem' }}></MDBCol>
                                    <MDBCol className="text-right" sm="2" style={{ paddingRight: '0.4rem' }}>นอก </MDBCol>
                                    <MDBCol sm="3"> </MDBCol>
                                    <MDBCol sm="2" style={{ paddingTop: '0.0rem' }}> </MDBCol>
                                </MDBRow>
                                {this.state.list_expenditure.map((data) => {
                                    // {this.state.list_expenditure_price.map((data) => {
                                    return <>
                                        <div className="mt-2">



                                            <MDBRow>
                                                <MDBCol sm="1" style={{ paddingTop: '0.4rem' }}> </MDBCol>

                                                <MDBCol sm="5" style={{ paddingTop: '0.0rem' }}>
                                                    <Typography strong style={{ textAlign: 'left', fontSize: '16px' }}> {data.expenditure_name} </Typography>
                                                </MDBCol>

                                                <MDBCol sm="1" style={{ paddingTop: '0.0rem' }}>
                                                    <Checkbox
                                                        defaultChecked={data.payslip_status_out === "1" ? true : false}
                                                        name={data.expenditure_id}
                                                        onChange={this.onChange_out(data.expenditure_price)}
                                                    >
                                                    </Checkbox>
                                                </MDBCol>

                                                <MDBCol sm="3">
                                                    <InputNumber
                                                        name={data.expenditure_id}
                                                        defaultValue={Number(data.expenditure_price).toFixed(2)}
                                                        onChange={this.onChange(data.expenditure_id, data.payslip_status_out)}
                                                        onBlur={this.updateList}
                                                        min={0} max={500000}
                                                    />
                                                </MDBCol>

                                                <MDBCol sm="2" style={{ paddingTop: '0.0rem' }}>
                                                    <Typography strong style={{ textAlign: 'center', fontSize: '16px' }}> บาท </Typography>
                                                </MDBCol>

                                            </MDBRow>
                                        </div>

                                    </>
                                })}

                            </>}
                    </DialogContent>
                    <DialogActions>

                        <Button variant="outlined" onClick={this.handleCancel} >ยกเลิก</Button>
                        <Button variant="contained" color="primary" onClick={this.handleOk} >บันทึก</Button>
                    </DialogActions>

                </Dialog>



                {/* <Modal
                    centered
                    title={<><strong> <Icon type="plus-square" />  เพิ่มรายจ่าย</strong> </>}
                    visible={this.state.visible}
                    okText="บันทึก"
                    cancelText="ยกเลิก"
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    size="large"
                >
                     {this.state.loading === true ? <div className="text-center">
                            กำลังโหลดรายการ...
                        </div> : <>
                                <MDBRow>
                                    <MDBCol sm="1" style={{ paddingTop: '0.4rem' }}> </MDBCol>
                                    <MDBCol sm="4" style={{ paddingTop: '0.0rem' }}></MDBCol>
                                    <MDBCol className="text-right" sm="2" style={{ paddingRight: '0.5rem' }}>นอก </MDBCol>
                                    <MDBCol sm="3"> </MDBCol>
                                    <MDBCol sm="2" style={{ paddingTop: '0.0rem' }}> </MDBCol>
                                </MDBRow>
                                {this.state.list_expenditure.map((data) => {
                                    // {this.state.list_expenditure_price.map((data) => {
                                    return <>
                                        <div className="mt-2">



                                            <MDBRow>
                                                <MDBCol sm="1" style={{ paddingTop: '0.4rem' }}> </MDBCol>

                                                <MDBCol sm="5" style={{ paddingTop: '0.0rem' }}>
                                                    <Typography strong style={{ textAlign: 'left', fontSize: '16px' }}> {data.expenditure_name} </Typography>
                                                </MDBCol>

                                                <MDBCol sm="1" style={{ paddingTop: '0.0rem' }}>
                                                    <Checkbox
                                                        defaultChecked={data.payslip_status_out === "1" ? true : false}
                                                        name={data.expenditure_id}
                                                        onChange={this.onChange_out(data.expenditure_price)}
                                                    >
                                                    </Checkbox>
                                                </MDBCol>

                                                <MDBCol sm="3">
                                                    <InputNumber
                                                        name={data.expenditure_id}
                                                        defaultValue={Number(data.expenditure_price).toFixed(2)}
                                                        onChange={this.onChange(data.expenditure_id, data.payslip_status_out)}
                                                        onBlur={this.updateList}
                                                        min={0} max={500000}
                                                    />
                                                </MDBCol>

                                                <MDBCol sm="2" style={{ paddingTop: '0.0rem' }}>
                                                    <Typography strong style={{ textAlign: 'center', fontSize: '16px' }}> บาท </Typography>
                                                </MDBCol>

                                            </MDBRow>
                                        </div>

                                    </>
                                })}

                            </>}
                </Modal> */}
            </>
        )
    }
}
