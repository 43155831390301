import React, { Component } from 'react'
import { Modal, Button, Input, Icon, Checkbox, InputNumber, message } from 'antd';
import { apilogin } from '../api';
import Text from 'antd/lib/typography/Text';
// import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
// import img_load from '../img/loading.gif'
// import Texty from 'rc-texty';

export default class Bttn_Auto_Fund extends Component {
    constructor(props) {
        super(props)
        this.state = {

            visible: false,
            customers_status: "",
            replace: true,
            percent: 3,

            load: false,
            show: false,
            success: null,
            success_insert: null,
            load_insert: false,


            update_count: 0,
            update_count_err: 0,
            allupdate: 0,

            count_insert_ok: 0,
            count_insert_err: 0,
            count_insert_all: 0,

            load_percent: 0,

            animation: null,
            formatMoney: false,
            visible: false,
            go_update: false,
            load_txt: "",
        }
        this.onChange = this.onChange.bind(this)
    }




    showModal = () => {
      //  console.log(this.props.expenditure_id)  
        this.setState({
            visible: true,
        });
    };

    handleOk = e => {
       
        this.setState({
            load: true,
            show: false,
            success: null,
            success_insert: null,
            load_insert: false,


            update_count: 0,
            update_count_err: 0,
            allupdate: 0,

            count_insert_ok: 0,
            count_insert_err: 0,
            count_insert_all: 0,

            load_percent: 0,

            animation: null,
            formatMoney: false,
            go_update: false,
            load_txt: "",
        })
        apilogin.post('getdata/getsalaryauto', ({
            payslip_month: this.props.payslip_month,
            payslip_year: this.props.payslip_year,
            customers_type_id: this.props.customers_type_id,
        }))
            .then(res => {
                let data = res.data
                let percent = this.state.percent

                let emp = []
                for (let i = 0; i < data.length; i++) {
                    let total = (data[i].history_salary_salary * percent) / 100
                    let max = (15000 * percent) / 100
                    total = total.toFixed(2)

                    // if (total >= max) {
                    //     total = max
                    // }
                    let cc = {
                        customers_citizent: data[i].customers_citizent,
                        customers_type: data[i].customers_type,
                        history_salary_month: data[i].history_salary_month,
                        history_salary_year: data[i].history_salary_year,
                        total: total
                    }
                    emp.push(cc)
                }
                apilogin.post('getdata/get_check_social_security', ({
                    payslip_month: this.props.payslip_month,
                    payslip_year: this.props.payslip_year,
                    customers_type_id: this.props.customers_type_id,
                    expenditure_id: this.props.expenditure_id,
                }))
                    .then(res => {
                        let check_list = res.data
                        let update = []
                        let update_sql_list = []
                        let update_sql_string = ""
                        for (let l = 0; l < check_list.length; l++) {
                            for (let c = 0; c < emp.length; c++) {
                                if (check_list[l].payslip_citizent === emp[c].customers_citizent) {
                                    let sql_update = ` UPDATE tb_payslip set payslip_total = '${emp[c].total}' WHERE   payslip_year = '${emp[c].history_salary_year}' AND payslip_month = '${emp[c].history_salary_month}' AND payslip_citizent = '${emp[c].customers_citizent}'  AND customers_type = '${emp[c].customers_type}' AND payslip_revenue = '0' AND payslip_expenditure ='${this.props.expenditure_id}' ; `
                                    update_sql_string = update_sql_string + ` UPDATE tb_payslip set payslip_total = '${emp[c].total}' WHERE   payslip_year = '${emp[c].history_salary_year}' AND payslip_month = '${emp[c].history_salary_month}' AND payslip_citizent = '${emp[c].customers_citizent}'  AND customers_type = '${emp[c].customers_type}' AND payslip_revenue = '0' AND payslip_expenditure ='${this.props.expenditure_id}' ; 
                                       `

                                    update_sql_list.push(sql_update)
                                    update.push(emp[c])
                                }

                            }

                        }
                      

                        let check = null
                        let data_insert = []
                        let data_sql_insert = []
                        let insert_sql_string = ""
                        for (let e = 0; e < emp.length; e++) {
                            check = null
                            for (let c = 0; c < update.length; c++) {
                                if (emp[e].customers_citizent === update[c].customers_citizent) {
                                    check = e
                                }
                            }
                            if (check === null) {
                                let sqlinsert = ` INSERT INTO tb_payslip (payslip_year, payslip_month, payslip_citizent, customers_type , payslip_revenue , payslip_expenditure , payslip_total)
                                VALUES ('${emp[e].history_salary_year}', '${emp[e].history_salary_month}', '${emp[e].customers_citizent}', '${emp[e].customers_type}','0','${this.props.expenditure_id}','${emp[e].total}'); `

                                insert_sql_string = insert_sql_string + ` INSERT INTO tb_payslip (payslip_year, payslip_month, payslip_citizent, customers_type , payslip_revenue , payslip_expenditure , payslip_total)
                                VALUES ('${emp[e].history_salary_year}', '${emp[e].history_salary_month}', '${emp[e].customers_citizent}', '${emp[e].customers_type}','0','${this.props.expenditure_id}','${emp[e].total}'); 
                                `

                                data_sql_insert.push(sqlinsert)

                                data_insert.push(emp[e])

                            }
                        }

                        this.setState({ count_insert_all: data_sql_insert.length })
                        this.setState({ allupdate: update_sql_list.length })


                        if (data_sql_insert.length >= 1) {
                            this.setState({ load_insert: true })
                            for (let i = 0; i < data_sql_insert.length; i++) {
                                setTimeout(() => {
                                    apilogin.post('getdata/update_social_security', ({
                                        sql: data_sql_insert[i],
                                    }))
                                        .then(res => {
                                            this.setState({ count_insert_ok: this.state.count_insert_ok + 1 })
                                            if (this.state.count_insert_ok === data_insert.length - 1 && update_sql_list.length === 0) {
                                                // this.setState({ load_txt: "นำเข้าข้อมูลเสร็จสิ้น" })
                                                this.setState({ success_insert: true, load: false })
                                                message.success("เพิ่มข้อมูลสำเร็จ")
                                                this.props.get_customer_status()
                                            } 
                                        })
                                }, 25 * i);

                            }
                        }

                        if (this.state.replace === true) {
                            if (update_sql_list.length >= 1) {
                                this.setState({ go_update: true })
                                for (let i = 0; i < update_sql_list.length; i++) {
                                    setTimeout(() => {
                                        apilogin.post('getdata/update_social_security', ({
                                            sql: update_sql_list[i],
                                        }))
                                            .then(res => {
                                                this.setState({ update_count: this.state.update_count + 1 })
                                                if (i === update_sql_list.length - 1) {
                                                    message.success("อัพเดตข้อมูลสำเร็จ")
                                                    this.setState({ load: false })
                                                    this.props.get_customer_status()
                                                }
                                            })
                                    }, 25 * i);
                                }
                            }
                        }


                        if (update_sql_list.length === 0 && data_sql_insert.length === 0) {
                            message.success("ยังไม่ได้อัพเดตเงินเดือนบุคลากร")
                            this.setState({ load: false })
                        } else if (this.state.replace === false && data_sql_insert.length === 0) {
                            message.success("ไม่พบข้อมูลที่ต้องอัพเดต")
                            this.setState({ load: false })
                        }

                      
                    })
            });




    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };


    onChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        })
    }
    render() {
       
        return (
            <>
                <Button icon="edit" size="small" onClick={this.showModal}>
                    คำนวณกองทุนสำรองเลี้ยงชีพพนักงานอัตโนมัติ
                </Button>
                <Modal
                    centered
                    title={<><strong>คำนวณกองทุนสำรองเลี้ยงชีพพนักงานอัตโนมัติ</strong> </>}
                    visible={this.state.visible}
                    okText="ยืนยัน"
                    cancelText="ยกเลิก"
                    footer={false}
                    // onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    ปี {this.props.payslip_year} เดือน {this.props.payslip_month} <br />
                    ประเภท {this.props.nametype}       <br />
                    เปอร์เซนต์ <InputNumber min={0} max={100} onChange={(e) => this.setState({ percent: e })} value={this.state.percent} /> % <br /><br />
                    การคำนวณ (เงินเดือนล่าสุด * {this.state.percent} %) /100 <br />

                    <Checkbox checked={this.state.replace} onChange={(e) => this.setState({ replace: e.target.checked })} >อัพเดตและแทนที่</Checkbox>


                    <br /> <br />
                    {this.state.load_insert === true ?
                        <>

                            <Text style={{ fontSize: '16px' }}>เพิ่มข้อมูล</Text> {Number((((this.state.count_insert_ok + this.state.count_insert_err) / this.state.count_insert_all) * 100), 2).toLocaleString(navigator.language, { maximumFractionDigits: 2 })}% {Number((((this.state.count_insert_ok + this.state.count_insert_err) / this.state.count_insert_all) * 100), 2).toLocaleString(navigator.language, { maximumFractionDigits: 2 }) === "100" ? "สำเร็จ" : "กรุณารอสักครู่"}<br />
                            {this.state.success_insert !== null ? <>

                            </> : null}
                        </>
                        : <></>}


                    {this.state.go_update === true ?
                        <>
                            <Text style={{ fontSize: '16px' }}>อัพเดตข้อมูล</Text>  {Number((((this.state.update_count + this.state.update_count_err) / this.state.allupdate) * 100), 2).toLocaleString(navigator.language, { maximumFractionDigits: 2 })}% {Number((((this.state.update_count + this.state.update_count_err) / this.state.allupdate) * 100), 2).toLocaleString(navigator.language, { maximumFractionDigits: 2 }) === "100" ? "สำเร็จ" : "กรุณารอสักครู่"}<br />
                        </> : null}

                    <div className="text-center mt-3">
                        <Button disabled={this.state.load} type="primary" onClick={this.handleOk}> {this.state.load === true ? "กำลังโหลด" : "เริ่มการเพิ่มข้อมูล"}</Button>
                    </div>
                </Modal>
            </>
        )
    }
}
