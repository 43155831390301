import React, { Component } from 'react'
import { apilogin, theme, detail_user } from '../api'
import { Table, Input, Icon, Card, Button, Tabs } from 'antd'
import { MDBContainer, MDBCardBody, MDBCardHeader, MDBCard } from 'mdbreact'
import Highlighter from 'react-highlight-words';
import Bttn_customer_edit from '../Components/Bttn_customer_edit';
import Bttn_customer_del from '../Components/Bttn_customer_del';
import Bttn_customer_account_bank from '../Components/Bttn_customer_account_bank';
import Bttn_customer_salary_edit from '../Components/Bttn_customer_salary_edit';
import Select from 'react-select'
import { MDBIcon } from 'mdbreact/dist/mdbreact.esm';
import Modeltaxforadmin from './Modeltaxforadmin';
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const { TabPane } = Tabs;


export default class CustomersPagesManagetax extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            datatax:[],
            loading: false,
            show: false,
            search: '',
            key_update_customers_type: "1",

            customer_type: [],
            customer_type_1: [],
            customer_type_2: [],
            customers_type_id: "",
            customers_type_name: "",
        }

        this.updatedata = this.updatedata.bind(this)
        this.updatedata_props = this.updatedata_props.bind(this)
        this.update_customers_type = this.update_customers_type.bind(this)
    }

    update_customers_type(key) {
        this.setState({ key_update_customers_type: key, loading: true })
        apilogin.post('insert_customers/customer_type_list', ({ customers_type: key }))
            .then(res => {
                this.updatedata(res.data)
            })

         apilogin.post('getdata/excel_export_tax', ({ customers_type: key }))
            .then(res => {
              //  this.updatedata(res.data)
              this.setState({ datatax : res.data });
            }) 

    }


    updatedata(data) {

        const getdata = data.map((data, index) => ({
            key: data.customers_citizent,
            index: index + 1,
            customers_citizent: data.customers_citizent,
            customers_pname: data.customers_pname,
            customers_name: data.customers_name + " " + data.customers_lname,
            customers_lname: data.customers_lname,
            customer_type_name: data.customer_type_name,
            customer_status_name: data.customer_status_name,
            bank_name: data.bank_name,
            bank_id: data.bank_id,
        })
        )
        this.setState({
            data: getdata,
            loading: false,
            show: true,
        })
    }

    updatedata_props() {
        this.setState({ loading: true })
        setTimeout(() => {
            this.update_customers_type(this.state.key_update_customers_type)
        }, 300);
    }

    componentDidMount() {
        // this.update_customers_type("1")

        this.setState({ nameuser: detail_user.nameuser })
        this.setState({ admin_type: detail_user.admin_type })

        apilogin.get('getdata/customer_type')
            .then(res => {

                let customer_type = []
                let customer_type_1 = []
                let customer_type_2 = []

                let cc = []
                res.data.map((data) => {
                    let yy = {
                        value: data.customer_type_id,
                        label: data.customer_type_name,
                        admin_level: data.admin_level,
                    }
                    cc.push(yy)
                })


                cc.map((data) => {
                    customer_type.push(data)
                    if (data.admin_level === "1") {
                        customer_type_1.push(data)
                    }
                    else if (data.admin_level === "2") {
                        customer_type_2.push(data)
                    }
                })

                this.setState({
                    customer_type: customer_type,
                    customer_type_1: customer_type_1,
                    customer_type_2: customer_type_2,
                })

            })
    }


    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`ค้นหา`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
            </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        ),
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    render() {




        const columns = [
            {
                title: 'ลำดับ',
                dataIndex: 'index',
                key: 'index',
                className:theme,
                render: text => <div className={"text-center "}>{text}</div>,

            },
            {
                title: 'เลขบัตรประชาชน',
                dataIndex: 'customers_citizent',
                key: 'customers_citizent',
                className:theme,
                ...this.getColumnSearchProps('customers_citizent'),
                render: text => <div className={"text-left "}>{text}</div>,
                sorter: (a, b) => a.customers_citizent.length - b.customers_citizent.length,
            },
            {
                title: 'ชื่อ-นามสกุล',
                dataIndex: 'customers_name',
                key: 'customers_name',
                className:theme,
                ...this.getColumnSearchProps('customers_name'),
                sorter: (a, b) => a.customers_name.length - b.customers_name.length,
                render: text => <div className={"text-left " }>{text}</div>,
            },

            // {
            //     title: 'ประเภทบุคลากร',
            //     dataIndex: 'customer_type_name',
            //     key: 'customer_type_name',

            //     render: text => <div className="text-center">{text}</div>,
            //     ...this.getColumnSearchProps('customer_type_name'),
            //     sorter: (a, b) => a.customer_type_name.length - b.customer_type_name.length,
            // },
            // {
            //     title: 'สถานะ',
            //     dataIndex: 'customer_status_name',
            //     key: 'customer_status_name',

            //     render: text => <div className="text-center">{text}</div>,
            //     ...this.getColumnSearchProps('customer_status_name'),
            //     sorter: (a, b) => a.customer_status_name.length - b.customer_status_name.length,
            // },

            // {
            //     title: 'ธนาคาร',
            //     dataIndex: 'bank_name',
            //     key: 'bank_name',
            //     className:theme,
            //     ...this.getColumnSearchProps('bank_name'),
            //       render: text => <div className={"text-center " }>{text}</div>,
            //     sorter: (a, b) => a.bank_name.length - b.bank_name.length,
            // },
            { 
                title: 'จัดการ',
                key: 'manage',
                className:theme,
                render: (text, record) => (
                    <div className="text-center">
                        <Modeltaxforadmin idc={record.customers_citizent} nameuser={record.customers_name} />
                      
                        {/* <Bttn_customer_edit customers_citizent={record.customers_citizent} updatedata_props={this.updatedata_props} customers_type_id={this.state.customers_type_id} /> */}
                        {/* &nbsp;
                        <Bttn_customer_account_bank customers_citizent={record.customers_citizent} updatedata_props={this.updatedata_props} bank_id={record.bank_id} customers_type_id={this.state.customers_type_id} />
                        &nbsp;
                        <Bttn_customer_salary_edit customers_citizent={record.customers_citizent} updatedata_props={this.updatedata_props} data={record} customers_type_id={this.state.customers_type_id} />
                        &nbsp;
                        <Bttn_customer_del customers_citizent={record.customers_citizent} updatedata_props={this.updatedata_props} customers_type_id={this.state.customers_type_id} /> */}


                    </div>
                ),
            },
            // {
            //     title: 'จัดการบัญชี',
            //     key: 'manage',
            //     render: (text, record) => (
            //         <div className="text-center">
            //             <Bttn_customer_edit customers_citizent={record.customers_citizent} updatedata_props={this.updatedata_props} />

            //         </div>
            //     ),
            // },

        ]
        let tabledata
        tabledata = (
            <>
                <Table  dataSource={this.state.data} columns={columns}
                    pagination={{ pageSize: 25 }}
                    bordered
                    size="small"
                    loading={this.state.loading}
                    rowClassName={theme}
                    className={"time-table-row-select "+theme}
                   
                />
            </>
        )
        return (
            <div>
                <MDBContainer className="mt-5 mb-5">
                    <div className="d-flex justify-content-center mt-5 text-center">
                        <MDBCard style={{ width: "72rem", minHeight: "40rem" }}  className={theme} >
                            <MDBCardHeader className={"form-header rgba-indigo-light rounded -hoverable "+theme} >
                                <h2 className={theme} style={{ textAlign: 'center', fontWeight: 'bold' }} >
                                    <Icon type="idcard" /> รายการบุคลากรลดหย่อนภาษี
                                </h2>
                            </MDBCardHeader>

                            <MDBCardBody className="bodytable text-left">
                                <Select  className={"light"}
                                    options={this.state.admin_type === "super" ? this.state.customer_type :
                                        this.state.admin_type === "adminlevel1" ? this.state.customer_type_1 :
                                            this.state.admin_type === "adminlevel2" ? this.state.customer_type_2 : null}
                                    //defaultInputValue={this.state.customers_type_id}
                                    placeholder={<span><MDBIcon icon="users" /> &nbsp; เลือกประเภทบุคลากร</span>}
                                    onChange={(e) => {
                                        this.update_customers_type(e.value)

                                        this.setState({
                                            customers_type_id: e.value,
                                            customers_type_name: e.label
                                        })

                                    }} name="customers_type_id" />
                                <br />
                                {this.state.datatax.length != 0 ?<> 
                                 <ExcelFile filename="df" element={<Button size="default"> 
                                   <Icon type="check" style={{ color: '#009900' }} /> &nbsp; <MDBIcon className='animated headShake delay-0s infinite' far icon="hand-point-right" /> &nbsp; คลิกเพื่อโหลดข้อมูลลดหย่อน</Button>}>
                                            <ExcelSheet data={this.state.datatax} name="dddd">
                                            <ExcelColumn label="ลำดับ" value="order" />
                                            <ExcelColumn label="เลขประจำตัวประชาชน" value="tax_citizen" />
                                            <ExcelColumn label="ชื่อ" value="tax_name"/>
                                            <ExcelColumn label="นามสกุล" value="tax_lname"/>
                                            <ExcelColumn label="ที่อยู่ อาคาร" value="tax_address"/>
                                            <ExcelColumn label="ห้องเลขที่" value="tax_room"/>
                                            <ExcelColumn label="ชั้นที่" value="tax_fool"/>
                                            <ExcelColumn label="หมู่บ้าน" value="tax_homename"/>
                                            <ExcelColumn label="เลขที่" value="tax_homenum"/>
                                            <ExcelColumn label="หมู่ที่" value="tax_moonum"/>
                                            <ExcelColumn label="ซอย" value="tax_soy"/>
                                            <ExcelColumn label="แยก" value="tax_yak"/>
                                            <ExcelColumn label="ถนน" value="tax_road"/>
                                            <ExcelColumn label="ตำบล" value="tax_tum"/>
                                            <ExcelColumn label="อำเภอ" value="tax_am"/>
                                            <ExcelColumn label="จังหวัด" value="tax_pi"/>
                                            <ExcelColumn label="รหัสไปรษณีย์" value="tax_code"/>
                                            <ExcelColumn label="1.สถานะภาพ" value="tax1_status"/>
                                            <ExcelColumn label="2.สถานะการมีเงินได้คู่สมรถ" value="tax2"/>
                                            <ExcelColumn label="3.จำนวนบุตรรวม" value="tax3"/>
                                            <ExcelColumn label="บุตร" value="tax31"/>
                                            <ExcelColumn label="เลขบัตร" value="tax31citizen"/>
                                            <ExcelColumn label="จำนวนเงิน" value="tax31money"/>
                                            <ExcelColumn label="บุตร2" value="tax32"/>
                                            <ExcelColumn label="เลขบัตร" value="tax32citizen"/>
                                            <ExcelColumn label="จำนวนเงิน" value="tax32money"/>
                                            <ExcelColumn label="4.บิดา" value="tax411"/>
                                            <ExcelColumn label="เลขบัตรบิดา" value="tax411citizen"/>
                                            <ExcelColumn label="มารดา" value="tax412citizen"/>
                                            <ExcelColumn label="จำนวนเงิน" value="tax411money"/>
                                            <ExcelColumn label="บิดา" value="tax421"/>
                                            <ExcelColumn label="เลขบัตรบิดา" value="tax421citizen"/>
                                            <ExcelColumn label="มารดา" value="tax422"/>
                                            <ExcelColumn label="เลขบัตรมารดา" value="tax422citizen"/>
                                            <ExcelColumn label="จำนวนเงิน" value="tax421money"/>
                                            <ExcelColumn label="5.ค่าอุปการะ" value="tax51"/>
                                            <ExcelColumn label="จำนวนเงิน" value="tax51money"/>
                                            <ExcelColumn label="6.เบี้ยประกัน" value="tax611"/>
                                            <ExcelColumn label="มารดา" value="tax612"/>
                                            <ExcelColumn label="บิดา" value="tax621"/>
                                            <ExcelColumn label="มารดา" value="tax622"/>
                                            <ExcelColumn label="จำนวนเงิน" value="tax611money"/>
                                            <ExcelColumn label="7.เบี้ยประกันชีวิต" value="tax7money"/>
                                            <ExcelColumn label="8.เบี้ยประกันสุขภาพ" value="tax8money"/>
                                            <ExcelColumn label="9.เงินสะสม" value="tax9money"/>
                                            <ExcelColumn label="10.ค่าซื้อหน่วยลงทุน" value="tax10"/>
                                            <ExcelColumn label="จำนวนเงิน" value="tax10money"/>
                                            <ExcelColumn label="11.ค่าซื้อหน่วยลงทุนรวมหุ้น" value="tax11"/>
                                            <ExcelColumn label="จำนวนเงิน" value="tax11money"/>
                                            <ExcelColumn label="12.ดอกเบี้ยเงินกู้ยืม" value="tax12money"/>
                                            <ExcelColumn label="13.เงินสมทบกองทุน" value="tax13money"/>
                                            <ExcelColumn label="14.เงินบริจาค" value="tax14money"/>
                                            <ExcelColumn label="15.เงินบริจาค" value="tax15"/>
                                            <ExcelColumn label="จำนวนเงิน" value="tax15money"/>
                                            <ExcelColumn label="ปี" value="taxyear"/>
                                            </ExcelSheet> 
                                    </ExcelFile>
                                
                                </> :<></> } 
                                   
                                    <br />


                                {this.state.show === true ? <>  {tabledata} </> : null}


                                {/* <Tabs defaultActiveKey="1" onChange={this.update_customers_type} >
                                    <TabPane tab="ลูกจ้างชั่วคราว" key="1">
                                        {tabledata}
                                    </TabPane>
                                    <TabPane tab="ลูกจ้างประจำ" key="2">
                                        {tabledata}
                                    </TabPane>
                                    <TabPane tab="พนักงานราชการ" key="3">
                                        {tabledata}
                                    </TabPane>
                                    <TabPane tab="พนักงานมหาวิทยาลัย" key="4">
                                        {tabledata}
                                    </TabPane>
                                    <TabPane tab="อาจารย์ประจำสัญญาจ้าง" key="5">
                                        {tabledata}
                                    </TabPane>
                                </Tabs> */}
                            </MDBCardBody>

                           
                        </MDBCard>
                    </div>
                </MDBContainer>

            </div>
        )
    }
}
