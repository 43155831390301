import React, { Component } from 'react'
import { Dialog, DialogTitle, IconButton, DialogContent, DialogActions, Button, TextField, Paper } from '@material-ui/core';
import { theme, apilogin, url, options_mounth } from '../api';
import { Upload, message } from 'antd';
import { MDBContainer } from 'mdbreact';


export default class Bttn_upload_file extends Component {
    state = {
        file_name: '',
        file_base64: '',
        modal: false,
        checkpdf: null,
        pdf: []
    }

    componentDidMount() {
        try {
            if (this.props.customer_type === 6 || this.props.customer_type === 2) {
                let mouth_name = ""

                for (let i = 0; i < options_mounth.length; i++) {
                    if (this.props.payslip_month === options_mounth[i].value)
                        mouth_name = options_mounth[i].label
                }
                this.setState({ file_name: 'สลิปเงินเดือน ' + mouth_name + ' ' + Number(Number(this.props.payslip_year) + 543) })
            }
        } catch (error) { }
    }


    getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }



    ChangeFile = info => {
        let checkpdf = this.state.checkpdf
        if (checkpdf === true) {
            let fileList2 = [...info.fileList];
            fileList2 = fileList2.slice(-1);
            // setpdf(fileList2) // จำเป็นต้องใส่ไว้ก่อน
            this.setState({ pdf: fileList2 })

            if (info.file.status === 'done') {

                try {
                    this.getBase64(info.file.originFileObj, File64 => {
                        message.success(`${info.file.name} ไฟล์พร้อมสำหรับการอัพโหลด`)
                        // console.log(File64)
                        setTimeout(() => {
                            this.setState({ file_base64: File64 })
                            // setudata({
                            //     ...datasent,
                            //     File64: File64
                            // })
                            // props.getFormAddCourse({
                            //     ...datasent,
                            //     File64: File64
                            // })
                        }, 200);

                    })
                } catch (error) {
                    alert("ไฟล์ไม่รองรับ")
                }
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        } else { this.setState({ pdf: [] }) }
    };

    beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'application/pdf'
        if (!isJpgOrPng) {
            alert('You can only upload PDF file!')
            this.setState({ checkpdf: false })
        } else { this.setState({ checkpdf: true }) }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            alert('PDF must smaller than 5MB!')
            // setcheckpdf(false)
            this.setState({ checkpdf: false })
        } else { this.setState({ checkpdf: true }) }
        return isJpgOrPng && isLt2M;
    }

    onSent = () => {
        // customers_citizent={record.customers_citizent}
        // payslip_month={this.state.payslip_month}
        // payslip_year={this.state.payslip_year}

        let datasent = {
            file_name: this.state.file_name,
            file_base64: this.state.file_base64,
            customers_citizent: this.props.customers_citizent,
            customer_type: this.props.customer_type,
            payslip_month: this.props.payslip_month,
            payslip_year: this.props.payslip_year,
        }
        // console.log(datasent)
        apilogin.post('getdata/uploadpdffile', datasent)
            .then((res) => {

                this.props.getdata()
                this.setState({
                    file_name: '',
                    file_base64: '',
                    checkpdf: null,
                    pdf: []
                })
                message.success('บันทึกสำเร็จ')
            })

    }
    render() {
        return (
            <>



                <Paper elevation="3" >
                    <MDBContainer >

                        <div className="mt-3 mb-3" >
                            <br />
                            &nbsp;ไฟล์ PDF <br />
                            <Upload
                                fileList={this.state.pdf}
                                action={url + '/getdata/uploadpdf'}
                                onChange={this.ChangeFile}
                                beforeUpload={this.beforeUpload}
                                multiple={true}
                            >
                                <Button variant="outlined"   > คลิกเพื่อเลือกไฟล์ </Button>
                            </Upload>

                            {/* <input placeholder="ชื่อไฟล์" value={this.state.file_name} /> */}

                            <TextField fullWidth
                                className={"mb-3 mt-3"}
                                label="ตั้งชื่อไฟล์"
                                value={this.state.file_name}
                                name="CREDIT"
                                onChange={(e) => this.setState({ file_name: e.target.value })}
                                type="text"
                            />

                        </div>
                        <div className="text-left mt-2 mb-2" >
                            <Button variant="contained" color="primary" onClick={this.onSent} >บันทึก</Button>
                        </div>
                        <br />
                    </MDBContainer>
                </Paper>



            </>
        )
    }
}
