import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Card, CardHeader, CardContent } from '@material-ui/core';
import { Menulist } from '../Navs/ListMenu';
import { Lottie_edit, Lottie_printer } from '../Components/MyLottie';
import { theme } from '../api';
import { Alert } from '@material-ui/lab';
import Mybreadcrumbs from '../Navs/Mybreadcrumbs';
// import { theme } from '../api';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PDF_print_form from '../Components/PDF_print_form';

//เว็บ
//https://www.freepik.com/search?dates=any&format=search&page=1&query=login&sort=popular



// const useStyles = makeStyles((theme) => ({
//     root: {

//         display: 'flex',
//         flexWrap: 'wrap',
//         minWidth: 300,
//         width: '100%',
//         flexGrow: 1,

//     },

// }));


export default function PageKTB(props) {
    // const classes = useStyles();


    // console.log(props.history)





    const styleCard = {
        marginTop: "1rem",
        minHeight: '250px',
        minWidth: '350px',
    }
    // let classCard = "animated fadeIn mt-5 cascading-admin-card card-custom custom-text card-"// + theme
    let classCard = "animated fadeIn mt-5 cascading-admin-card card-custom custom-text card-"// + theme





    return (


        <>
            {/* <div className={"animated fadeIn  "} > */}

            <div
            className={"animated fadeIn container " }//+ classes.root
            >
                <div className="text-center mt-5 container" >
                    <CardHeader className={"mb-4 font-" + theme} title={"แบบแจ้งข้อมูลการรับเงินผ่านระบบ KTB Corporate Online"} />
                </div>

             
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Grid container justify="center" spacing={2}>

                            <Grid item>
                                <a onClick={() => { props.history.push("/FormPage") }}>
                                    <Card elevation="3" className={classCard} style={styleCard} >
                                        <div className="text-center mt-2 " >
                                            <CardContent>
                                                <Lottie_edit />
                                            </CardContent>
                                            <CardHeader className="mb-4" title={<>กรอกแบบฟอร์ม</>} />
                                        </div>
                                    </Card>
                                </a>
                            </Grid>

 


                            <Grid item>
                                <PDF_print_form main={true} check="view" />
                            </Grid>


                            <Grid item>
                                <PDF_print_form main={true} check="load" />
                            </Grid>


                        </Grid>
                    </Grid>
                </Grid>
                <Alert className="mt-5 container" severity="info">  หากกรอกและบันทึกข้อมูลนี้แล้ว ให้พิมพ์แบบฟอร์มนี้ แล้วเตรียมสำเนาหน้าสมุดบัญชี จากนั้นนำมาส่งที่งานการเงิน สำหรับคนที่ยังไม่ได้ส่งเท่านั้น</Alert>

            </div >
        </>
    );
}
