import React, { Component } from 'react'
import { apilogin, theme, detail_user } from '../api'
import { Table, Input, Icon, Card, Button, Tabs } from 'antd'
import { MDBContainer, MDBCardBody, MDBCardHeader, MDBCard, MDBIcon } from 'mdbreact'
import Highlighter from 'react-highlight-words';
import Bttn_customer_edit from '../Components/Bttn_customer_edit';
import Bttn_customer_del from '../Components/Bttn_customer_del';
import Bttn_customer_account_bank from '../Components/Bttn_customer_account_bank';
import Text from 'antd/lib/typography/Text';
import Bttn_list_expenditure_add from '../Components/Bttn_list_expenditure_add';
import Bttn_list_expenditure_del from '../Components/Bttn_list_expenditure_del';
import Bttn_list_revenue_add from '../Components/Bttn_list_revenue_add';
import Bttn_list_revenue_del from '../Components/Bttn_list_revenue_del';
import Bttn_list_expenditure_edit from '../Components/Bttn_list_expenditure_edit';
import Bttn_list_revenue_edit from '../Components/Bttn_list_revenue_edit';
import Select from 'react-select'



const { TabPane } = Tabs;



export default class Payslip_list_manage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            list_expenditure: [],
            list_revenue: [],
            loading: false,
            search: '',
            key_update_customers_type_expenditure: '1',
            key_update_customers_type_revenue: '1',

            customer_type: [],
            customer_type_1: [],
            customer_type_2: [],
            customers_type_id: "",
            customers_type_name: "",

            show1: false,
            show2: false,
        }
        this.get_list_expenditure = this.get_list_expenditure.bind(this)

        this.updatedata_props = this.updatedata_props.bind(this)
        this.update_customers_type_expenditure = this.update_customers_type_expenditure.bind(this)
        this.update_customers_type_revenue = this.update_customers_type_revenue.bind(this)
    }

    update_customers_type_expenditure(key) {
        this.setState({ key_update_customers_type_expenditure: key, loading: true })
        apilogin.post('getdata/get_list_expenditure', ({ customers_type: key }))

            .then(res => {
                const getdata = res.data.map((data, index) => ({
                    key: data.expenditure_id,
                    index: index + 1,
                    customer_type_name: data.customer_type_name,
                    customer_type_id: data.customer_type_id,
                    expenditure_id: data.expenditure_id,
                    expenditure_name: data.expenditure_name,
                })
                )
                this.setState({
                    list_expenditure: getdata,
                    loading: false,
                    show1: true,
                })
            })

    }
    update_customers_type_revenue(key) {
        this.setState({ key_update_customers_type_revenue: key, loading: true })
        apilogin.post('getdata/get_list_revenue', ({ customers_type: key }))

            .then(res => {
                const getdata = res.data.map((data, index) => ({
                    key: data.revenue_id,
                    index: index + 1,
                    customer_type_name: data.customer_type_name,
                    customer_type_id: data.customer_type_id,
                    revenue_id: data.revenue_id,
                    revenue_name: data.revenue_name,
                })
                )
                this.setState({
                    list_revenue: getdata,
                    loading: false,
                    show2: true,
                })
            })

    }
    get_list_expenditure() {
        this.setState({ loading: true })
        this.update_customers_type_expenditure("1")
    }

    get_list_revenue() {
        this.setState({ loading: true })
        this.update_customers_type_revenue("1")
    }


    updatedata_props() {
        this.setState({ loading: true })
        setTimeout(() => {
            this.update_customers_type_expenditure(this.state.key_update_customers_type_expenditure)
            this.update_customers_type_revenue(this.state.key_update_customers_type_revenue)
        }, 500);
    }

    componentDidMount() {
        this.setState({ nameuser: detail_user.nameuser })
        this.setState({ admin_type: detail_user.admin_type })

        apilogin.get('getdata/customer_type')
            .then(res => {

                let customer_type = []
                let customer_type_1 = []
                let customer_type_2 = []

                let cc = []
                res.data.map((data) => {
                    let yy = {
                        value: data.customer_type_id,
                        label: data.customer_type_name,
                        admin_level: data.admin_level,
                    }
                    cc.push(yy)
                })


                cc.map((data) => {
                    customer_type.push(data)
                    if (data.admin_level === "1") {
                        customer_type_1.push(data)
                    }
                    else if (data.admin_level === "2") {
                        customer_type_2.push(data)
                    }
                })

                this.setState({
                    customer_type: customer_type,
                    customer_type_1: customer_type_1,
                    customer_type_2: customer_type_2,
                })

            })

        // this.get_list_expenditure()
        // this.get_list_revenue()


    }


    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`ค้นหา`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
            </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        ),
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    render() {




        const columns = [
            {
                title: 'ลำดับ',
                dataIndex: 'index',
                key: 'index',
                className:theme,
                render: text => <div className="text-center">{text}</div>,

            },
            {
                title: 'ชื่อรายการ',
                dataIndex: 'expenditure_name',
                key: 'expenditure_name',
                className:theme,
                ...this.getColumnSearchProps('expenditure_name'),
                sorter: (a, b) => a.expenditure_name.length - b.expenditure_name.length,
            },
            // {
            //     title: 'ประเภทบุคลากร',
            //     dataIndex: 'customer_type_name',
            //     key: 'customer_type_name',

            //     ...this.getColumnSearchProps('customer_type_name'),
            //     sorter: (a, b) => a.customer_type_name.length - b.customer_type_name.length,
            // },

            {
                title: 'จัดการ',
                key: 'manage',
                className:theme,
                render: (text, record) => (
                    <div className="text-center">
                        {/* <Button icon="edit">แก้ไข</Button>&nbsp; */}
                        <Bttn_list_expenditure_edit data_expenditure={record} updatedata_props={this.updatedata_props} />
                        {/* <Bttn_list_expenditure_del expenditure_id={record.expenditure_id} updatedata_props={this.updatedata_props} /> */}
                    </div>
                ),
            },


        ]
        const columns_revenue = [
            {
                title: 'ลำดับ',
                dataIndex: 'index',
                key: 'index',
                className:theme,
                render: text => <div className="text-center">{text}</div>,

            },
            {
                title: 'ชื่อรายการ',
                dataIndex: 'revenue_name',
                key: 'revenue_name',
                className:theme,
                ...this.getColumnSearchProps('revenue_name'),
                sorter: (a, b) => a.revenue_name.length - b.revenue_name.length,
            },
            // {
            //     title: 'ประเภทบุคลากร',
            //     dataIndex: 'customer_type_name',
            //     key: 'customer_type_name',

            //     ...this.getColumnSearchProps('customer_type_name'),
            //     sorter: (a, b) => a.customer_type_name.length - b.customer_type_name.length,
            // },

            {
                title: 'จัดการ',
                key: 'manage',
                className:theme,
                render: (text, record) => (
                    <div className="text-center">
                        {/* <Button icon="edit">แก้ไข</Button>&nbsp; */}
                        <Bttn_list_revenue_edit data_revenue={record} updatedata_props={this.updatedata_props} />&nbsp;
                        {/* <Bttn_list_revenue_del revenue_id={record.revenue_id} updatedata_props={this.updatedata_props} /> */}
                    </div>
                ),
            },


        ]

        let table_expenditure
        table_expenditure = (
            <>
                <Table dataSource={this.state.list_expenditure} columns={columns}
                    pagination={{ pageSize: 25 }}
                    bordered
                    size="small"
                    loading={this.state.loading}
                    rowClassName={theme}
                    className={"mt-3 time-table-row-select "+theme}
                />
            </>
        )
        let table_revenue
        table_revenue = (
            <>
                <Table dataSource={this.state.list_revenue} columns={columns_revenue}
                    pagination={{ pageSize: 25 }}
                    bordered
                    size="small"
                    loading={this.state.loading}
                    rowClassName={theme}
                    className={"mt-3 time-table-row-select "+theme}
                />
            </>
        )
        return (
            <div>
                <MDBContainer className="mt-5 mb-5">
                    <div className="d-flex justify-content-center mt-5 text-center">
                        <MDBCard style={{ width: "72rem", minHeight: "40rem" }} className={theme} >
                            <MDBCardHeader className={"form-header rgba-indigo-light rounded -hoverable "+theme} >
                                <h2 className={theme} style={{ textAlign: 'center', fontWeight: 'bold' }} >
                                <Icon type="file-text" /> รายการรายรับ-รายจ่าย
                                </h2>
                            </MDBCardHeader>

                            <MDBCardBody className="bodytable text-left">


                                <Tabs defaultActiveKey="1" className={theme} style={{minHeight:'40rem'}}>
                                    <TabPane tab={<span className={theme} ><MDBIcon icon="dollar-sign"  /> รายจ่าย</span>} key="1">
                                        <div className="text-right mt-2 mb-2">
                                            <Bttn_list_expenditure_add updatedata_props={this.updatedata_props} />
                                        </div>
                                        <Select
                                        className={"light"}
                                            options={this.state.admin_type === "super" ? this.state.customer_type :
                                                this.state.admin_type === "adminlevel1" ? this.state.customer_type_1 :
                                                    this.state.admin_type === "adminlevel2" ? this.state.customer_type_2 : null}
                                            //defaultInputValue={this.state.customers_type_id}
                                            placeholder={<span><MDBIcon icon="users" /> &nbsp; เลือกประเภทบุคลากร</span>}
                                            onChange={(e) => {
                                                this.update_customers_type_expenditure(e.value)

                                                this.setState({
                                                    customers_type_id: e.value,
                                                    customers_type_name: e.label
                                                })

                                            }} name="customers_type_id" />
                                        <br />
                                        {this.state.show1 ? table_expenditure:<></>}
                                        {/* {table_expenditure} */}
                                     
                                    </TabPane>

                                    <TabPane tab={<span className={theme} ><MDBIcon icon="hand-holding-usd" /> รายรับ</span>} key="2" >
                                        <div className="text-right mt-2 mb-2">
                                            <Bttn_list_revenue_add updatedata_props={this.updatedata_props} />
                                        </div>
                                        <Select
                                        className={"light"}
                                            options={this.state.admin_type === "super" ? this.state.customer_type :
                                                this.state.admin_type === "adminlevel1" ? this.state.customer_type_1 :
                                                    this.state.admin_type === "adminlevel2" ? this.state.customer_type_2 : null}
                                            //defaultInputValue={this.state.customers_type_id}
                                            placeholder={<span><MDBIcon icon="users" /> &nbsp; เลือกประเภทบุคลากร</span>}
                                            onChange={(e) => {
                                                this.update_customers_type_revenue(e.value)

                                                this.setState({
                                                    customers_type_id: e.value,
                                                    customers_type_name: e.label
                                                })

                                            }} name="customers_type_id" />
                                        <br />
                                        {/* {table_revenue} */}
                                        {this.state.show2 ? table_revenue:<></>}
                                     
                                    </TabPane>
                                </Tabs>


                            </MDBCardBody>
                        </MDBCard>
                    </div>
                </MDBContainer>

            </div>
        )
    }
}
