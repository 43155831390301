import { Form, Icon, Input, Row, Col, Card, notification, message } from "antd";
import React, { Component } from "react";
import Background from '../assets/images/bg.png';
import support from '../img/support.png';

import { api, apilogin, theme } from '../api';
import { setTimeout } from "timers";
import { MDBIcon, MDBCard, MDBCardBody, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBBtn } from "mdbreact";
import { Helmet } from "react-helmet";
import Text from "antd/lib/typography/Text";
import Axios from "axios";
import DarkModeToggle from "../Components/DarkMode/DarkModeToggle";
import { Button } from "@material-ui/core";
import Toast from 'light-toast';
var base64 = require('base-64');


class LoginPages extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Username: "",
      Password: "",
      loading: true,
      loadinglogin: false,
      login_error: true,
      login_error2: true,
      login_error3: true,

      modalpdpa: false,
      admin_type:'',
      admin_typepdpa:'',
      encodedcitizen:'',
      url:'',
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.togglepdpa = this.togglepdpa.bind(this)
    this.getpdpa = this.getpdpa.bind(this)
  }


  componentDidMount() {
    let theme_check = localStorage.getItem('darkMode')
    if (theme_check === "true") {
    }
    else {
      localStorage.setItem("darkMode", "false")
    }
  }

  togglepdpa = () => {
    this.setState({
      modalpdpa: !this.state.modalpdpa
    });
  }

  getpdpa() {



    setTimeout(() => {

      // if (this.state.admin_type === 2) {
      //    localStorage.setItem('admin_type', 2);
      // } else if (this.state.admin_type === 3) {
      //   localStorage.setItem('admin_type', 3);
      // } else if (this.state.admin_type === 4) {
      //   localStorage.setItem('admin_type', 4);
      // } else if (this.state.admin_type === 5) {
      //   localStorage.setItem('admin_type', 5);
      // } else if (this.state.admin_type === 6) {
      //   localStorage.setItem('admin_type', 6);
      // } 

      window.location.href = 'https://arit.kpru.ac.th/ap/account/?nu=pdpaAgree&code='+this.state.encodedcitizen+'&status='+this.state.admin_typepdpa+'&url='+this.state.url
       //ภายใน
      //   Axios.get('https://arit.kpru.ac.th/ap/account/?nu=pdpaAgree&code='+this.state.encodedcitizen+'&status='+this.state.admin_typepdpa+'&url='+this.state.url)
      //  .then(res => {
      //    console.log(res);
      //  //  this.setState({ bankname: res.data, loading: false })
      //   // this.setState({ thschedule: res.data})
    
     
      //  }) 
     }, 0);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit_back = event => {


    event.preventDefault()

    this.setState({
      loading: false,
      login_error: true,
      login_error2: true,
      login_error3: true,
    })



    api.post('getdata/getuseradmin',
      ({
        Username: this.state.Username,
        Password: this.state.Password,
      })
    )
      .then((res) => {
        if (res.data.length !== 0) {
          //message.success("เข้าระบบสำเร็จ")

          if (res.data[0].admin_type === 'super') {
            this.setState({ loading: true })
            localStorage.setItem('nameuser', res.data[0].admin_name + ' ' + res.data[0].admin_lname)
            localStorage.setItem('admin_type', res.data[0].admin_type)
            window.location.href = '/admin'

          }
          else if (res.data[0].admin_type === 'adminlevel1') {
            this.setState({ login_error3: true })
            localStorage.setItem('nameuser', res.data[0].admin_name + ' ' + res.data[0].admin_lname)
            localStorage.setItem('admin_type', res.data[0].admin_type)
            window.location.href = '/admin'
          }
          else if (res.data[0].admin_type === 'adminlevel2') {
            this.setState({ login_error3: true })

            localStorage.setItem('nameuser', res.data[0].admin_name + ' ' + res.data[0].admin_lname)
            localStorage.setItem('admin_type', res.data[0].admin_type)
            // localStorage.setItem('picuser', data[0].picture);
            window.location.href = '/admin'
          }
          //ผู้ดูแลระบบ
          else {
            this.openNotificationWithIcon();
          }

          // let timeout = new Date().getTime()
          // let timeout4hr = (timeout + 14400000*2)
          // localStorage.setItem('JOB_USER_token', timeout4hr)
          localStorage.setItem('id', res.data[0].admin_type)

        }//ปิดif
        else {

          api.post('https://mua.kpru.ac.th/FrontEnd_Mis/login/login/',
            ({
              txtemail: this.state.Username,
              txtpass: this.state.Password
            })
          )
            .then((res) => {
              // console.log(res.data[0]) 1629900393492
              if (res.data[0].loginstatus === "1") {


                // ----------------------------------------- test
                // apilogin()

                // ----------------------------------------- test




                // localStorage.setItem('Username', this.state.Username)
                // localStorage.setItem('nameuser', res.data[0].frist_name + ' ' + res.data[0].last_name)
                // localStorage.setItem('organization_name', res.data[0].organization_name)
                // localStorage.setItem('rank_name', res.data[0].rank_name)
                // localStorage.setItem('prefix_name', res.data[0].prefix_name)
                // localStorage.setItem('telephone', res.data[0].telephone)
                // localStorage.setItem('frist_name', res.data[0].frist_name)
                // localStorage.setItem('last_name', res.data[0].last_name)

                // localStorage.setItem('id', res.data[0].employee_id)

                // let timeout = new Date().getTime()
                // let timeout4hr = (timeout + 14400000 * 2)
                // localStorage.setItem('JOB_USER_token', timeout4hr)

                // this.props.history.push({ pathname: '/' })


                // notification.open({
                //   message: 'เข้าสู่ระบบสำเร็จ',
                //   icon: <MDBIcon style={{ color: 'green' }} icon="check" />
                // })

              }

              else {
                notification.open({
                  message: 'เข้าสู่ระบบไม่สำเร็จ',
                  icon: <MDBIcon style={{ color: 'red' }} icon="times" />,
                  description:
                    'ชื่อผู้ใช้งาน หรือ รหัสผ่านผิด',
                });
              }

            })

          // setTimeout(() => {
          //   message.error("ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง กรุณาตรวจสอบใหม่อีกครั้ง")
          //   // window.location.href = '/'
          // }, 1000);
        }
      })



  };

  handleSubmit = event => {
    event.preventDefault()
    this.setState({
      loadinglogin: true,
      loading: false,
      login_error: true,
      login_error2: true,
      login_error3: true,
    })


    api.post('getdata/getuseradmin',
      ({
        Username: this.state.Username,
        Password: this.state.Password,
      })
    )
      .then((res) => {
        if (res.data.length !== 0) {
          // message.success("เข้าระบบสำเร็จ")

          let user = {
            user: {
              nameuser: res.data[0].admin_name + ' ' + res.data[0].admin_lname,
              admin_type: res.data[0].admin_type,
              id: res.data[0].admin_type,
              role: res.data[0].admin_type,
              employee_id: res.data[0].employee_id,
            }
          }
          apilogin.post('login/get_token_login_admin', user)
            .then((res) => {
              let tokenVerify = res.data
              localStorage.setItem('tokenVerify', tokenVerify)
              window.location.href = '/'
            })

        }//ปิดif
        else {

          api.post('https://mua.kpru.ac.th/FrontEnd_Mis/login/login/',
            ({
              txtemail: this.state.Username,
              txtpass: this.state.Password
            })
          )
            .then((res) => {
              // console.log(res.data[0]) 1629900393492
              if (res.data[0].loginstatus === "1") {

                // let timeout = new Date().getTime()
                // let timeout4hr = (timeout + 14400000 * 2)
                // localStorage.setItem('JOB_USER_token', timeout4hr)
                // ----------------------------------------- test
                let user = {
                  user: {
                    user: this.state.Username,
                    pass: this.state.Password,
                    role: "user",
                    prefix_name: res.data[0].prefix_name,
                    frist_name: res.data[0].frist_name,
                    last_name: res.data[0].last_name,
                    rank_name: res.data[0].rank_name,
                    organization_name: res.data[0].organization_name,
                    telephone: res.data[0].telephone,
                    Username: this.state.Username,
                    nameuser: res.data[0].frist_name + ' ' + res.data[0].last_name,
                    employee_id: res.data[0].employee_id,
                  }

                }
                apilogin.post('login', user)
                  .then((res) => {
                    let tokenVerify = res.data
                    localStorage.setItem('tokenVerify', tokenVerify)
                 //   window.location.href = '/'

                              Axios.get('https://arit.kpru.ac.th/ap/account/api.php?nu=pdpaGET&field=code&fieldValue='+this.state.Username+'&Token=S2FtcGhhZW5nIFBoZXQgUmFqYWJoYXQgVW5pdmVyc2l0eQ%3D%3D&fbclid=IwAR2AzlH362Dld2XOZhHJ9oN0u8pVKt0Yi1ONZzjeMRhqzz_FRHTi-wTFCzU')
                              .then(res => {
                                console.log(res);
                              //  this.setState({ bankname: res.data, loading: false })
                              // this.setState({ thschedule: res.data})
                                if (res.data.length !== 0) {
                                 // localStorage.setItem('admin_type', 2);
                              //    localStorage.setItem('admin_typepdpa', 2);
                                  window.location.href = '/'
                                } else {
                            //    this.setState({ admin_type: 2})
                              //   this.setState({ admin_typepdpa: 2})
                                  const encodedcitizen = base64.encode(this.state.Username);
                                  const admin_typepdpa = base64.encode(2);//บุคลากร
                                  const url = base64.encode('https://e-payslip.kpru.ac.th/');
                                  this.setState({ encodedcitizen: encodedcitizen})
                                  this.setState({ admin_typepdpa: admin_typepdpa})
                                  this.setState({ url: url})
                              //   console.log(encodedcitizen)
                              //   console.log(admin_typepdpa)
                              //   console.log(url)
                                  this.togglepdpa()   
                                  
                                }
                              }) 
                    
                    //  Toast.success('เข้าสู่ระบบสำเร็จ')
                    // setTimeout(() => {
                    //   window.location.href = '/'
                    //   // Toast.hide();
                    // }, 1000);


                  })
                // ----------------------------------------- test
              }

              else {
                notification.open({
                  message: 'เข้าสู่ระบบไม่สำเร็จ',
                  icon: <MDBIcon style={{ color: 'red' }} icon="times" />,
                  description:
                    'ชื่อผู้ใช้งาน หรือ รหัสผ่านผิด',
                });
                this.setState({ loadinglogin: false })
                // setTimeout(() => {
                //   Toast.hide();
                // }, 50);
              }

            })

          // setTimeout(() => {
          //   message.error("ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง กรุณาตรวจสอบใหม่อีกครั้ง")
          //   // window.location.href = '/'
          // }, 1000);
        }
      })



  };
  openNotificationWithIcon = type => {
    notification[type]({
      message: 'แจ้งเตือนการเข้าใช้งานระบบ',
      description:
        'ไม่สามารถเข้าระบบได้ เนื่องจากไม่พบข้อมูลผู้ใช้ในระบบ กรุณาตรวจสอบข้อมูลผู้ใช้ใหม่อีกครั้ง',
    });
    this.setState({ loadinglogin: false })
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const styles = {
      LoginPages: {
        minHeight: "100vh",
        width: "100%",
        // backgroundImage: `url(${Background})`,
        backgroundImage: theme === "dark" ? "" : `url(${Background})`,
        backgroundColor: theme === "dark" ? "#414242" : ``,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    };

    return (
      <Row style={styles.LoginPages}>
        {/* {console.log(this.props.history)} */}
        <Helmet>
          <meta charSet="utf-8" />
          <title>Login : ระบบออกใบสลิปเงินเดือน KPRU</title>
          <meta name="description" content="ระบบออกใบสลิปเงินเดือน KPRU" />
          <link rel="canonical" href="https://e-payslip.kpru.ac.th/login" />
        </Helmet>



        <Col>

          {/* <Card style={{ width: 300, textAlign: "center" }}> */}
          <MDBCard className={'dark-grey-text mt-2 ' + theme} style={{ width: 300, textAlign: "center" }}>
            <MDBCardBody className='z-depth-2 '>

              <h3 style={{ color: "#5fbff9" }}>Pay Slip KPRU</h3>
              <h5 style={{ color: "#2ec4b6" }}>ระบบออกใบสลิปเงินเดือน</h5>
              <Form onSubmit={this.handleSubmit} className="mt-4 login-form" style={{ textAlign: "left" }}>
                <Form.Item  >
                  {getFieldDecorator("Username", {
                    //  initialValue: "1629900393492",
                    rules: [
                      {
                        required: true, message: "กรุณากรอกข้อมูลชื่อผู้ใช้!"

                      },

                    ],
                  })(
                    <Input

                      // className={"select-" + theme}
                      // style={{
                      //   backgroundColor: theme === "dark" ? "#414242" : ``,
                      //   fontSize: '20px'
                      // }}
                      prefix={

                        <Icon type="user"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      type="text"
                      placeholder="Username"
                      // type="email"
                      // placeholder="Email"
                      onChange={this.handleChange}

                      name="Username"
                    />
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator("Password", {
                    // initialValue: "21082539",
                    rules: [
                      {
                        required: true,
                        message: "กรุณากรอกข้อมูลรหัสผ่านผู้ใช้!",
                      },
                    ],
                  })(
                    <Input.Password
                      prefix={
                        <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                      iconRender={visible => (visible ? <Icon type="eye-invisible" /> : <Icon type="eye" />)}
                      type="password"
                      onChange={this.handleChange}
                      placeholder="Password"
                      name="Password"
                    />
                  )}
                </Form.Item>
                <Form.Item>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    htmlType="submit"
                    className="login-form-button"
                    block
                  >
                    {this.state.loadinglogin === true ? <><Icon type="loading" />&nbsp; กำลังเข้าสู่ระบบ</> : <>เข้าสู่ระบบ</>}

                  </Button>
                </Form.Item>
              </Form>

              <div className="text-center mt-2">
                <Text className={"" + theme} style={{ fontSize: '12px' }}>Browser Support</Text>
                <br />
                <img src={support} width="20%" />
              </div>

            </MDBCardBody>

          </MDBCard>


          <MDBModal isOpen={this.state.modalpdpa} toggle={this.togglepdpa} size="lg" centered>
          <MDBModalHeader toggle={this.togglepdpa}>นโยบายข้อมูลส่วนบุคคล</MDBModalHeader>
          <MDBModalBody>
          <span style={{ fontSize: "16px" }}>  <a href="https://arit.kpru.ac.th/contents/Disclaimer/Disclaimer.pdf" target="_blank" >นโยบายข้อมูลส่วนบุคคล (PDPA) และข้อกำหนดในการให้บริการ</a>ของเรา <MDBBtn color="deep-purple" onClick={this.getpdpa}>ยอมรับ</MDBBtn></span>
          </MDBModalBody>
          <MDBModalFooter>
            {/* <MDBBtn color="secondary" onClick={this.togglepdpa}>Close</MDBBtn>
            <MDBBtn color="primary">Save changes</MDBBtn> */}
          </MDBModalFooter>
        </MDBModal>



          {/* </Card> */}

          {/* <MDBCard className='dark-grey-text mt-2'>
            <MDBCardBody className='z-depth-2 '>
              <div style={{ fontSize: '14px' }}>
                <MDBIcon icon="users" /> การเข้าสู่ระบบ<br />
                - ชื่อผู้ใช้งาน เช่น 17499001148XX<br />
                - รหัสผ่าน วัน เดือน ปีเกิด เช่น 111225XX<br />
              </div>
            </MDBCardBody>
          </MDBCard> */}

        </Col>
      </Row>
    );
  }
}

const WrappedNormalLoginForm = Form.create({ name: "LoginPages" })(LoginPages);
export default WrappedNormalLoginForm;
