import React from 'react';

import Toggle from '../../Toggle';
import useDarkMode from 'use-dark-mode';
import { Icon, Divider } from 'antd';
import { MDBIcon } from 'mdbreact/dist/mdbreact.esm';
import { IconButton, Tooltip } from '@material-ui/core';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import Brightness2Icon from '@material-ui/icons/Brightness2';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness5Icon from '@material-ui/icons/Brightness5';
import { theme } from '../../api';

const DarkModeToggle = () => {
  const darkMode = useDarkMode("false");


  return (
    <>
      <Tooltip title={theme==="dark"?"โหมดสว่าง":"โหมดสีเข้ม"}>
        <IconButton aria-label="show 17 new notifications" color="inherit"
          // onClick={darkMode.toggle}
          onClick={() => {
            let dark = false
            if (theme === "light") {
              dark = true
            }


            if (dark === true) {
              darkMode.toggle(dark)
              window.location.reload()
              // getTheme("dark")
            }
            else {
              darkMode.toggle(dark)
              window.location.reload()
              // getTheme("light")
            }
          }}
        >
          {theme === "light" ?  < Brightness4Icon />:< WbSunnyIcon /> }
        </IconButton>

      </Tooltip>

      {/* <div className="dark-mode-toggle">
        <button disabled type="button"  > <MDBIcon icon="sun" /> </button>
        <Toggle checked={darkMode.value} onChange={darkMode.toggle} />
        <button disabled type="button"  > <MDBIcon icon="moon" /> </button>
      </div> */}
    </>
  );
};

export default DarkModeToggle;
