import React, { Component } from 'react'
import { apilogin, theme, mouth, year, options_mounth, adminSuper, admin1, admin2, detail_user } from '../api'
import { Table, Input, Icon, Card, Button, InputNumber, message, notification, Spin, Switch } from 'antd'
import { MDBContainer, MDBCardBody, MDBCardHeader, MDBCard, MDBRow, MDBCol, MDBIcon } from 'mdbreact'
import { Checkbox } from 'antd';
import Swal from 'sweetalert2/dist/sweetalert2.js'

export default class PaySlipManageUpdateDataEx extends Component {

    constructor(props) {
        super(props)
        this.state = {
            insert_option: '1',
            out_status: '0',
            check: [],
            payslip_month_in: '',
            payslip_year_in: '',

            payslip_month_out: '',
            payslip_year_out: '',

            customers_type: "",

            options_years: [],
            customer_type: [],

            list_expenditure: [],
            loading: false,
            loadupdate: false,


        }
        this.get_data = this.get_data.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onCheck = this.onCheck.bind(this)
        this.ok = this.ok.bind(this)
        this.checkall = this.checkall.bind(this)
    }


    checkall(e) {

        if (e.target.checked === true) {
            //alert('ok')
            let list_expenditure = this.state.list_expenditure
            //    console.log(list_expenditure)
            let df = []

            for (let i = 0; i < list_expenditure.length; i++) {
                let dd = list_expenditure[i].value
                df.push(dd)
            }
            //console.log(df)
            this.setState({ check: df })
        } else {
            this.setState({ check: [] })
        }
    }

    ok() {
        if (this.state.check.length >= 1) {
            Swal.fire({
                title: 'ยืนยันการอัพเดต?',
                text: 'คุณแน่ใจใช่ไหมที่จะอัพเดตข้อมูลเดือนนี้',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'ใช่ เริ่มเลย',
                cancelButtonText: 'ยกเลิก'
            }).then((result) => {
                if (result.value) {

                    this.setState({ loadupdate: true })
                    let data = {
                        payslip_month_in: this.state.payslip_month_in,
                        payslip_year_in: this.state.payslip_year_in,
                        payslip_month_out: this.state.payslip_month_out,
                        payslip_year_out: this.state.payslip_year_out,
                        customers_type: this.state.customers_type,
                        list_expenditure: this.state.check,
                        insert_option: this.state.insert_option,
                        out_status: this.state.out_status,
                    }
                    apilogin.post('getdata/updatedataformaftermonth_ex', data)
                        .then((res) => {
                            // console.log(res.data)
                            this.setState({ loadupdate: false })
                            let update = 0
                            if (res.data !== "ok") {
                                update = res.data.affectedRows
                            }
                            Swal.fire(
                                'อัพเดตสำเร็จ!',
                                'ข้อมูลได้ถูกอัพเดตแล้ว ' + update + ' รายการ',
                                'success'
                            )
                        })
                        .catch((res) => {
                            Swal.fire(
                                'อัพเดตล้มเหลว',
                                'กรุณาลองใหม่ หรือติดต่อผู้ดูแลระบบ',
                                'error'
                            )
                        })
                }
            })

        }
        else {
            message.info('กรุณาเลือกรายการก่อน')
        }



    }
    onCheck(e) {
        // console.log(e)
        this.setState({ check: e })
    }

    get_data() {
       
        let data = this.state

        let cus3 = [60, 61, 71, 72, 73, 74, 76, 78] //พนักงานราชการ
        let cus4 = [38, 39, 49, 50, 51, 52, 54, 56] //พนักงานมหาวิทยาลัย
        let cus6 = [] //ข้าราชการ/ข้าราชการพลเรือน
        let df = []
        if (data.customers_type === "3") {
            df = cus3
        } else if (data.customers_type === '4') {
            df = cus4
        } else if (data.customers_type === "6") {
            df = cus6
        }
        // console.log(df)
        this.setState({ check: df })

        if (data.payslip_month_in !== "" && data.payslip_year_in !== "" && data.payslip_month_out !== "" && data.payslip_year_out !== "" && data.customers_type !== "") {
            // message.success("ok")
            this.setState({ loading: true })
            apilogin.post('getdata/get_list_expenditure', ({ customers_type: data.customers_type }))

                .then(res => {
                    let dd = res.data
                    let rr = []
                    for (let i = 0; i < dd.length; i++) {
                        let p = {
                            label: dd[i].expenditure_name,
                            value: dd[i].expenditure_id,
                        }
                        rr.push(p)
                    }
                    this.setState({
                        list_expenditure: rr,
                        loading_in: false,
                    })
                    this.setState({ loading: false })
                    if (dd.length === 0) {
                        message.info('ไม่พบรายการ')
                    }
                })
        } else {
            message.warning("กรุณาเลือกให้ครบ")
        }


    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }





    componentDidMount() {

        // this.get_customer_status()
        this.setState({
            // customers_type: '4',
            // payslip_month_in: mouth,
            // payslip_month_out: mouth,
            payslip_month_out: mouth,
            payslip_year_in: year,
            payslip_year_out: year,
        })

        if (detail_user.nameuser !== "") {
            this.setState({ nameuser: detail_user.nameuser })
            this.setState({ admin_type: detail_user.admin_type })

            apilogin.get('getdata/customer_type')
                .then(res => {

                    let customer_type = []
                    let customer_type_1 = []
                    let customer_type_2 = []

                    let cc = []
                    res.data.map((data) => {
                        let yy = {
                            value: data.customer_type_id,
                            label: data.customer_type_name,
                            admin_level: data.admin_level,
                        }
                        cc.push(yy)
                    })



                    cc.map((data) => {
                        customer_type.push(data)
                        if (data.admin_level === "1") {
                            customer_type_1.push(data)
                        }
                        else if (data.admin_level === "2") {
                            customer_type_2.push(data)
                        }
                    })

                    let list = []
                    let type = detail_user.admin_type
                    if (type === adminSuper) {
                        list = customer_type
                    } else if (type === admin1) {
                        list = customer_type_1
                    }
                    else if (type === admin2) {
                        list = customer_type_2
                    }
                    this.setState({
                        customer_type: list,
                    })

                })
        }
        else {
            message.error('ไม่พบข้อมูลผู้ใช้ กรุณาล็อคอินใหม่')
        }
        apilogin.get('getdata/getyear')
            .then(res => {
                let year = []
                res.data.map((data) => {
                    let yy = {
                        value: data.name_year,
                        label: "ปี " + data.name_year_th,
                    }
                    year.push(yy)
                })
                this.setState({
                    options_years: year
                });
            })
            .catch((err) => {
                notification.error({
                    message: 'โหลดรายการปีไม่สำเร็จ ' + err,
                })
            })




    }


    render() {



        return (

            <div className="container mt-5 text-center">

                <MDBCard style={{
                    minHeight: "40rem"
                }} className={theme} >
                    <MDBCardHeader className={"mt-2 form-header rgba-indigo-light rounded -hoverable " + theme} >
                        <h2 className={theme} style={{ textAlign: 'center', fontWeight: 'bold' }} >
                            <MDBIcon icon="sync-alt" /> อัพเดตข้อมูลรายจ่ายเดือนก่อนหน้า
                         </h2>
                    </MDBCardHeader>

                    <MDBCardBody className="bodytable">
                        <select
                            className={"form-control " + theme} name="customers_type"
                            onChange={(e) => {
                                this.setState({ customers_type: e.target.value })
                            }}
                            value={this.state.customers_type}
                        >
                            <option value={this.state.customer_type}>เลือกประเภทบุคลากร</option>
                            {this.state.customer_type.map((data) => {
                                return <option value={data.value}> {data.label}</option>
                            })}
                        </select>

                        <MDBRow className="text-center mt-1">

                            <MDBCol xl="3" className="mt-3 mb-0 text-left">

                                เลือกเดือนที่จะใช้ข้อมูลเข้าเดือนใหม่   <br />
                                <select
                                    className={"form-control " + theme} name="payslip_month_in"
                                    onChange={this.onChange} value={this.state.payslip_month_in}
                                >
                                    <option value="">เลือกเดือน</option>
                                    {options_mounth.map((data) => {
                                        return <option value={data.value}> {data.label}</option>
                                    })}
                                </select>
                                <br />
                                <select
                                    className={"form-control " + theme} name="payslip_year_in"
                                    onChange={this.onChange} value={this.state.payslip_year_in}
                                >
                                    <option value="">เลือกปี</option>
                                    {this.state.options_years.map((data) => {
                                        return <option value={data.value}> {data.label}</option>
                                    })}
                                </select>

                            </MDBCol>


                            <MDBCol xl="3" className="mt-3 mb-1 text-left">

                                เลือกเดือนที่จะนำข้อมูลเข้า   <br />
                                <select
                                    className={"form-control " + theme} name="payslip_month_out"
                                    onChange={this.onChange} value={this.state.payslip_month_out}
                                >
                                    <option value="">เลือกเดือน</option>
                                    {options_mounth.map((data) => {
                                        return <option value={data.value}> {data.label}</option>
                                    })}
                                </select>
                                <br />
                                <select
                                    className={"form-control " + theme} name="payslip_year_out"
                                    onChange={this.onChange} value={this.state.payslip_year_out}
                                >
                                    <option value="">เลือกปี</option>
                                    {this.state.options_years.map((data) => {
                                        return <option value={data.value}> {data.label}</option>
                                    })}
                                </select>

                            </MDBCol>
                            {/* <MDBCol xl="3" className="mb-1 text-center">



                            </MDBCol> */}
                            <MDBCol xl="3" className=" mt-0 text-center mb-1" style={{ paddingTop: '4.0rem' }}>
                                <Button block icon="search" size="large" type="primary" onClick={this.get_data}>
                                    ค้นหา
                                  </Button>
                            </MDBCol>
                        </MDBRow>



                        {this.state.loading === true ? <div className="text-center">
                            <Spin size="large" tip="loading.." />
                        </div> : <>

                                {this.state.list_expenditure.length >= 1 ? <>
                                    <MDBCard className={"mt-5 " + theme}>
                                        <MDBCardHeader>รายการอัพเดต</MDBCardHeader>
                                        <MDBCardBody>

                                            <div className="mb-3 mt-3 text-right">
                                                <Checkbox
                                                    style={{ fontSize: '20px', color: theme === 'dark' ? 'white' : 'black' }}
                                                    onChange={this.checkall}
                                                // checked={this.state.insert_option === '1' ? true : false}
                                                >
                                                    เลือกทั้งหมด
                                                </Checkbox><br />
                                            </div>



                                            <Checkbox.Group style={{ fontSize: '20px', color: theme === 'dark' ? 'white' : 'black' }}
                                                className={"text-left " + theme} options={this.state.list_expenditure}
                                                defaultValue={this.state.check}
                                                value={this.state.check}
                                                onChange={this.onCheck} />


                                            <div className="text-left mt-5">
                                                ตัวเลือกการอัพเดต<br />
                                                <Checkbox
                                                    style={{ fontSize: '20px', color: theme === 'dark' ? 'white' : 'black' }}
                                                    onChange={() => this.setState({ insert_option: '1' })}
                                                    checked={this.state.insert_option === '1' ? true : false}
                                                >
                                                    เพิ่มและแทนที่ข้อมูลทั้งหมด
                                                </Checkbox><br />

                                                <Checkbox
                                                    style={{ fontSize: '20px', color: theme === 'dark' ? 'white' : 'black' }}
                                                    onChange={() => this.setState({ insert_option: '2' })}
                                                    checked={this.state.insert_option === '2' ? true : false}
                                                >
                                                    เพิ่มเฉพาะข้อมูลที่ยังไม่มี
                                                            </Checkbox><br />

                                                <Checkbox
                                                    style={{ fontSize: '20px', color: theme === 'dark' ? 'white' : 'black' }}
                                                    onChange={() => this.setState({ insert_option: '3' })}
                                                    checked={this.state.insert_option === '3' ? true : false}
                                                >
                                                    อัพเดตเฉพาะข้อมูลที่มีอยู่แล้ว
                                                 </Checkbox><br />

                                                <br /><br />

                                                <Switch
                                                    checked={this.state.out_status === "1" ? true : false}
                                                    onChange={(e) => {
                                                        let check = '0'
                                                        if(e === true ){
                                                            check = '1'
                                                        }
                                                        else{
                                                            check = '0'
                                                        }
                                                        this.setState({out_status:check})
                                                    }}
                                                // checkedChildren={<CheckOutlined />}
                                                // unCheckedChildren={<CloseOutlined />}
                                                // defaultChecked
                                                /> อัพเดตข้อมูลสถานะจ่ายนอก


                                                {/* <Checkbox
                                                    style={{ fontSize: '20px', color: theme === 'dark' ? 'white' : 'black' }}
                                                    onChange={() => this.setState({ insert_option: '3' })}
                                                    checked={this.state.insert_option === '3' ? true : false}
                                                >
                                                    ดึงข้อมูลรายจ่ายนอกไปอัพเดตด้วย
                                                 </Checkbox> */}
                                                <br /><br />
                                            </div>


                                            {this.state.loadupdate === true ? <Button block
                                                size="large" type="primary" >
                                                <Icon type="loading" /> กำลังอัพเดตข้อมูล
                                            </Button>

                                                :
                                                <Button block icon="sync" size="large" type="primary" onClick={this.ok}>
                                                    อัพเดต
                                            </Button>}

                                        </MDBCardBody>
                                    </MDBCard>
                                </> : null}

                            </>}








                    </MDBCardBody>
                </MDBCard>

            </div>
        )
    }
}
