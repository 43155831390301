import React, { Component } from 'react'
import { apilogin, theme } from '../../api'
import { Table, Input, Icon, Card, Button, InputNumber, message, Divider, Select, Modal, Spin, notification } from 'antd'
import { MDBContainer, MDBCardBody, MDBCardHeader, MDBCard, MDBRow, MDBCol, MDBIcon, MDBTable, MDBTableHead, MDBTableBody } from 'mdbreact'
import Text from 'antd/lib/typography/Text';
import ReactExport from "react-export-excel";


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const InputGroup = Input.Group;
const { Option } = Select;



export default class Chat_salary_year_total extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            data_year: [],
            load: false,
            show: false,
            payslip_month: "",
            payslip_year: "",
            name_month: '',
            customer_type_id: "",
            expenditure: [],
            revenue: [],
            total: [],
            customer_type: [],

        }
        this.onSearch = this.onSearch.bind(this)
    }



    onSearch() {
        if (this.state.customer_type_id === "") {
            message.warning("กรุณาเลือกประเภทบุคลากร")
        }
        else if (this.state.payslip_year === "") {
            message.warning("กรุณาเลือกปี")
        }
        else {
            this.setState({ load: true })
            this.setState({ show: false })
            apilogin.post('getdata/excel_export_expenditure', ({ customers_type: this.state.customer_type_id, }))
                .then((res1) => {
                    let expenditure = res1.data
                    apilogin.post('getdata/excel_export_revenue', ({ customers_type: this.state.customer_type_id, }))
                        .then((res2) => {
                            let revenue = res2.data
                            this.setState({
                                revenue: revenue,
                                expenditure: expenditure,
                            })
                            apilogin.post('getdata/customer_manage_report_excel_year/', ({
                                payslip_year: String(this.state.payslip_year),
                                customer_type_id: this.state.customer_type_id,

                            }))
                                .then(res => {
                                    this.setState({ total: res.data })
                                    let data = res.data
                                    //console.log(data)
                                    let dd = []
                                    for (let i = 0; i < data.length; i++) {
                                        let bb = []
                                        for (let d = 0; d < data[i].detail.length; d++) {
                                            bb = {
                                                ...bb,
                                                [data[i].detail[d].payslip_name]: data[i].detail[d].payslip_total,
                                            }
                                        }
                                        let tt = {
                                            index: i + 1,
                                            payslip_citizent: data[i].payslip_citizent,
                                            payslip_year: data[i].payslip_year,
                                            customer_type_name: data[i].customer_type_name,
                                            customer_type_id: data[i].customer_type_id,
                                            customers_pname: data[i].customers_pname,
                                            customers_name: data[i].customers_name,
                                            customers_lname: data[i].customers_lname,
                                            ...bb,
                                        }
                                        dd.push(tt)
                                    }
                                    //message.success('โหลดสำเร็จ')
                                    notification.success({
                                        message: 'โหลดสรุปข้อมูลรายปีสำเร็จ',
                                        icon: <MDBIcon style={{ color: 'green' }} icon="check" />
                                    })
                                    this.setState({
                                        data: dd,
                                        show: true
                                    })
                                    this.setState({ load: false })
                                })
                                .catch(() => {
                                    try {
                                        notification.err({
                                            message: 'โหลดข้อมูลไม่สำเร็จ ',
                                        })
                                    } catch (error) {
                                        alert('โหลดไม่สำเร็จ')
                                        this.setState({ load: false })
                                    }

                                })
                        })
                })
        }

    }




    componentDidMount() {


        apilogin.get('getdata/getyear')
            .then(res => {
                this.setState({
                    data_year: res.data,
                    loadyear: false
                });
            })

        apilogin.get('getdata/customer_type')
            .then((res) => {
                this.setState({ customer_type: res.data })
            })

    }



    render() {

        let search_menu
        search_menu = (
            <>

                <div className="mt-5 text-left container">
                  
                    <MDBRow center>

                        <MDBCol xl="3">
                            ประเภท
                           <Select style={{ width: '100%' }} placeholder="เลือกประเภท"
                                onChange={(e) => {
                                    this.setState({ customer_type_id: e })
                                    this.state.customer_type.map((data) => { if (e === data.customer_type_id) { this.setState({ customer_type_name: data.customer_type_name }) } })
                                }}
                            >
                                {this.state.customer_type.map((data) => {
                                    return <Option key={data.customer_type_id} value={data.customer_type_id} >{data.customer_type_name} </Option>
                                })}
                            </Select>
                        </MDBCol>

                        <MDBCol xl="3">
                            ปี<Select style={{ width: '100%' }} placeholder="เลือกปี" onChange={(e) => { this.setState({ payslip_year: e }) }}  >
                                {this.state.data_year.map((data) => {
                                    return <Option key={data.id_year} value={data.name_year} >{data.name_year_th} </Option>
                                })}
                            </Select>
                        </MDBCol>

                        <MDBCol xl="2">
                            <br />    <Button icon="search" size="" color="primary" onClick={this.onSearch}> ค้นหา</Button>
                        </MDBCol>

                    </MDBRow>

                </div>

                {/* <div className="form-group mt-5">
                    <InputGroup compact >
                        <Button> ประเภท</Button>
                        <Select style={{ width: '20%' }} placeholder="เลือกประเภท"
                            onChange={(e) => {
                                this.setState({ customer_type_id: e })
                                this.state.customer_type.map((data) => { if (e === data.customer_type_id) { this.setState({ customer_type_name: data.customer_type_name }) } })
                            }}
                        >
                            {this.state.customer_type.map((data) => {
                                return <Option key={data.customer_type_id} value={data.customer_type_id} >{data.customer_type_name} </Option>
                            })}
                        </Select>&emsp;
                        <Button>ปี</Button>
                        <Select style={{ width: '15%' }} placeholder="เลือกปี" onChange={(e) => { this.setState({ payslip_year: e }) }}  >
                            {this.state.data_year.map((data) => {
                                return <Option key={data.id_year} value={data.name_year} >{data.name_year_th} </Option>
                            })}
                        </Select>&emsp;
                        <Button icon="search" size="" color="primary" onClick={this.onSearch}> ค้นหา</Button>
                    </InputGroup>
                </div> */}
            </>
        )


        return (
            <div>
                <MDBContainer className="mt-5 mb-5 text-center">
                    <MDBCard   className={""+theme} >


                        <MDBCardHeader className={"form-header rgba-indigo-light rounded -hoverable "+theme} >
                            <Text   className={""+theme} style={{ textAlign: 'center', fontSize: '20px' }} > <Icon type="line-chart" /> สรุปข้อมูลรายปี </Text>
                        </MDBCardHeader>
                        {search_menu}
                        <MDBCardBody className="bodytable mt-3">
                            <MDBContainer className="col-10">

                                {this.state.load === true ? <>
                                    <div className="text-center mt-2 mb-4"> <Spin tip="กำลังโหลดข้อมูล กรุณารอสักครู่" /> </div>
                                </> : null}
                                {this.state.show === true ? <ExcelFile filename={"สรุปข้อมูลรายปี " + this.state.payslip_year + "-" + this.state.customer_type_name} element={
                                    <div className="mb-4">
                                        <Button size="defalut"> <strong><MDBIcon className='animated headShake delay-0s infinite' far icon="hand-point-right" /> &nbsp;คลิกเพื่อดาวน์โหลด ข้อมูลรายปี</strong></Button>
                                    </div>
                                }>
                                    <ExcelSheet data={this.state.data} name={"สรุปข้อมูลรายปี " + this.state.payslip_year + "-" + this.state.customer_type_name}>
                                        <ExcelColumn label="ลำดับ" value="index" />
                                        <ExcelColumn label="เลขบัตรประชาชน" value="payslip_citizent" />
                                        <ExcelColumn label="คำนำหน้า" value="customers_pname" />
                                        <ExcelColumn label="ชื่อ-นามสกุล" value={(col) => col.customers_name + " " + col.customers_lname} />
                                        <ExcelColumn label="ประเภทบุคลากร" value="customer_type_id" />
                                        <ExcelColumn label="ID ประเภทบุคลากร" value="customer_type_name" />
                                         <ExcelColumn label="year" value={(col) => col.is_married ? "Married" : this.state.payslip_year} />
                                        <ExcelColumn label="เงินเดือน" value="เงินเดือน" />
                                        {this.state.expenditure.map((data => {
                                            return <ExcelColumn
                                                label={data.expenditure_name}
                                                value={String(data.expenditure_name)} />
                                        }))}
                                        {this.state.revenue.map((data => {
                                            return <ExcelColumn label={data.revenue_name}
                                                value={String(data.revenue_name)} />
                                        }))}
                                        <ExcelColumn label="รายรับ" value="รวมรายรับ" />
                                        <ExcelColumn label="รายจ่าย" value="รวมรายจ่าย" />
                                        <ExcelColumn label="ยอดสุทธิ" value="ยอดสุทธิ" />
                                    </ExcelSheet>
                                </ExcelFile>
                                    : null}
                            </MDBContainer>

                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>





            </div>
        )
    }
}
