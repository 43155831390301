import React, { Component } from 'react'
import { apilogin, mouth, theme } from '../../api'
import { Table, Input, Icon, Card, Button, InputNumber, message, Spin } from 'antd'
import { MDBContainer, MDBCardBody, MDBCardHeader, MDBCard, MDBRow, MDBCol, MDBIcon } from 'mdbreact'
import Highlighter from 'react-highlight-words';
import Bttn_customer_add_expenditure from '../../Components/Bttn_customer_add_expenditure';
import NumberFormat from 'react-number-format';
import Select from 'react-select'
import Bttn_customer_add_revenue from '../../Components/Bttn_customer_add_revenue';
import PrintPDF from './../PrintPDF';
import Chart from 'react-google-charts';
import ReactApexChart from 'react-apexcharts';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import Bttn_search_date from '../../Components/Bttn_search_date';


const options_mounth = [
    // { value: '1', label: 'มกราคม' },
    // { value: '2', label: 'กุมภาพันธ์' },
    // { value: '3', label: 'มีนาคม' },
    // { value: '4', label: 'เมษายน' },
    // { value: '5', label: 'พฤษภาคม' },
    // { value: '6', label: 'มิถุนายน' },
    // { value: '7', label: 'กรกฎาคม' },
    // { value: '8', label: 'สิงหาคม' },
    // { value: '9', label: 'กันยายน' },

    { value: '01', label: 'มกราคม' },
    { value: '02', label: 'กุมภาพันธ์' },
    { value: '03', label: 'มีนาคม' },
    { value: '04', label: 'เมษายน' },
    { value: '05', label: 'พฤษภาคม' },
    { value: '06', label: 'มิถุนายน' },
    { value: '07', label: 'กรกฎาคม' },
    { value: '08', label: 'สิงหาคม' },
    { value: '09', label: 'กันยายน' },
    { value: '10', label: 'ตุลาคม' },
    { value: '11', label: 'พฤศจิกายน' },
    { value: '12', label: 'ธันวาคม' },

]

export default class Chart_salary extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            data_year: [],
            loading: false,
            search: '',
            // payslip_month: '01',
            // payslip_year: '2019',
            // payslip_month: new Date().getMonth()>=2?new Date().getMonth():0+new Date().getMonth(),
            // payslip_month: moment().format('MM')-1,
            payslip_month: mouth,
            payslip_year: moment().format('MM') === '01' ? new Date().getFullYear() - 1 : new Date().getFullYear(),
            name_month: '',
            name_year: moment().format('MM') === '01' ? new Date().getFullYear() - 1 : new Date().getFullYear(),
            search_check: false,
            sum_type_id1: 0,
            sum_type_id2: 0,
            sum_type_id3: 0,
            sum_type_id4: 0,
            sum_type_id5: 0,
            sum_type_id6: 0,
            sum_type_id7: 0,

            series: [],
            total: 0,


        }
        this.get_customer_status = this.get_customer_status.bind(this)
        this.updatedata = this.updatedata.bind(this)
        // this.updatedata_props = this.updatedata_props.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onMouthChange = this.onMouthChange.bind(this)
        this.onYearsChange = this.onYearsChange.bind(this)
    }

    onChange(event) {
        //  console.log(event.target)

        this.setState({
            [event.target.name]: event.target.value,
            // month:
        })
    }
    onMouthChange(event) {
        this.setState({ payslip_month: event })
    }
    onYearsChange(event) {
        // console.log(event)
        this.setState({ payslip_year: event })
    }

    get_customer_status() {
        this.setState({ loading: true })

        if (this.state.payslip_month === "") {
            message.warning("กรุณาเลือกเดือน")
        }
        else if (this.state.payslip_year === "") {
            message.warning("กรุณาเลือกปี")
        }
        else {
            this.setState({ loading: true })

            apilogin.get('getdata/customer_manage_report_chart2/' + this.state.payslip_year + '/' + this.state.payslip_month)
                .then(res => {
                    let data = res.data
                 


                    let series = []
                    let total = 0
                    for (let i = 0; i < data.length; i++) {
                        total = total +data[i].total
                        let rr = {
                            name: data[i].customer_type_name,
                            data: [data[i].total],
                        }
                        series.push(rr)
                    }
                    this.setState({ series: series })
                     this.setState({ total: total })
                    this.setState({ loading: false })
                
                
                })
        }

    }

    get_year() {
        apilogin.get('getdata/getyear/' + this.state.payslip_year + '/' + this.state.payslip_month)
            .then(res => {
                this.setState({
                    data_year: res.data
                });
            })
    }
    updatedata(data) {

        //console.log(data)

        let sum_type_id1 = 0
        let sum_type_id2 = 0
        let sum_type_id3 = 0
        let sum_type_id4 = 0
        let sum_type_id5 = 0
        let sum_type_id6 = 0
        let sum_type_id7 = 0

        for (let i = 0; i < data.length; i++) {
            //  console.log(data[i].payslip_expenditure)
            // console.log(data[i].payslip_revenue)
            if (data[i].customers_type === "1") {
                //   console.log(data[i])
                sum_type_id1 = sum_type_id1 + Number(data[i].payslip_revenue) + Number(data[i].payslip_expenditure) + Number(data[i].payslip_expenditure === 0 ? 0 : data[i].history_salary_salary)
            }
            else if (data[i].customers_type === "2") {
                sum_type_id2 = sum_type_id2 + Number(data[i].payslip_revenue) + Number(data[i].payslip_expenditure) + Number(data[i].payslip_expenditure === 0 ? 0 : data[i].history_salary_salary)
            }
            else if (data[i].customers_type === "3") {
                sum_type_id3 = sum_type_id3 + Number(data[i].payslip_revenue) + Number(data[i].payslip_expenditure) + Number(data[i].payslip_expenditure === 0 ? 0 : data[i].history_salary_salary)
            }
            else if (data[i].customers_type === "4") {
                // console.log(data[i])
                sum_type_id4 = sum_type_id4 + Number(data[i].payslip_revenue) + Number(data[i].payslip_expenditure) + Number(data[i].payslip_expenditure === 0 ? 0 : data[i].history_salary_salary)
            }
            else if (data[i].customers_type === "5") {
                sum_type_id5 = sum_type_id5 + Number(data[i].payslip_revenue) + Number(data[i].payslip_expenditure) + Number(data[i].payslip_expenditure === 0 ? 0 : data[i].history_salary_salary)
            }
            else if (data[i].customers_type === "6") {
                sum_type_id6 = sum_type_id6 + Number(data[i].payslip_revenue) + Number(data[i].payslip_expenditure) + Number(data[i].payslip_expenditure === 0 ? 0 : data[i].history_salary_salary)
            }
            else if (data[i].customers_type === "7") {
                sum_type_id6 = sum_type_id6 + Number(data[i].payslip_revenue) + Number(data[i].payslip_expenditure) + Number(data[i].payslip_expenditure === 0 ? 0 : data[i].history_salary_salary)
            }
        }

        // for (let i = 0; i < data.length; i++) {
        //     if (data[i].customer_type_id === 1) {
        //         // console.log(i)
        //         sum_type_id1 = sum_type_id1 + Number(data[i].payslip_revenue) + Number(data[i].payslip_expenditure) + Number(data[i].payslip_expenditure === 0 ? 0 : data[i].Salary)
        //     }
        //     else if (data[i].customer_type_id === 2) {
        //         sum_type_id2 = sum_type_id2 + Number(data[i].payslip_revenue) + Number(data[i].payslip_expenditure) + Number(data[i].payslip_expenditure === 0 ? 0 : data[i].Salary)
        //     }
        //     else if (data[i].customer_type_id === 3) {
        //         sum_type_id3 = sum_type_id3 + Number(data[i].payslip_revenue) + Number(data[i].payslip_expenditure) + Number(data[i].payslip_expenditure === 0 ? 0 : data[i].Salary)
        //     }
        //     else if (data[i].customer_type_id === 4) {
        //         sum_type_id4 = sum_type_id4 + Number(data[i].payslip_revenue) + Number(data[i].payslip_expenditure) + Number(data[i].payslip_expenditure === 0 ? 0 : data[i].Salary)
        //     }
        //     else if (data[i].customer_type_id === 5) {
        //         sum_type_id5 = sum_type_id5 + Number(data[i].payslip_revenue) + Number(data[i].payslip_expenditure) + Number(data[i].payslip_expenditure === 0 ? 0 : data[i].Salary)
        //     }

        // }

        // console.log("1 " + sum_type_id1)
        // console.log("2 " + sum_type_id2)
        // console.log("3 " + sum_type_id3)
        // console.log("4 " + sum_type_id4)
        //console.log("5 " + sum_type_id5)

        this.setState({
            search_check: true,
            loading: false,
            sum_type_id1: sum_type_id1,
            sum_type_id2: sum_type_id2,
            sum_type_id3: sum_type_id3,
            sum_type_id4: sum_type_id4,
            sum_type_id5: sum_type_id5,
            sum_type_id5: sum_type_id6,
            sum_type_id5: sum_type_id7,

        })
        setTimeout(() => {
            options_mounth.map((mounth) => {
                if (mounth.value === this.state.payslip_month) {
                    this.setState({
                        name_month: mounth.label,
                        name_year: this.state.payslip_year
                    })
                }
            })
        }, 500);


    }


    componentDidMount() {
        this.setState({ loading: true })

        setTimeout(()=>{
this.get_customer_status()
        },1000);
        
        // this.get_year()

        options_mounth.map((mounth) => {
            if (mounth.value === mouth) {
                this.setState({ name_month: mounth.label })
            }
        })
    }



    render() {



        let options = {
            theme: {
                mode: theme, 
                palette: 'palette1', 
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    dataLabels: {
                        position: 'top',
                    },

                },

            },
            dataLabels: {
                enabled: true,
                offsetX: -6,
                offsetY: -15,
                style: {
                    fontSize: '12px',
                    colors: [theme==="dark"?"":"#000"]
                },
                formatter: function (value) {
                    return value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }) + "฿"
                }


            },
            stroke: {
                show: true,
                width: 1,
                colors: ['#fff'],
            },



            xaxis: {
                categories: ["เดือน " + this.state.name_month + " ปี " + Number(Number(this.state.name_year) + 543) + " รวมทั้งหมด "+this.state.total.toLocaleString(navigator.language, { minimumFractionDigits: 0 })],
            },
            yaxis: {
                labels: {
                    formatter: function (value) {
                        return value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }) + "฿"
                    }
                },
            },
        }






        return (
            <div>
                <MDBContainer className="mt-5 mb-5 text-center">


                    <MDBCard className={""+theme} >
                        <MDBCardHeader className={"form-header rgba-indigo-light rounded -hoverable "+theme} >
                            <Text className={""+theme} style={{ textAlign: 'center', fontSize: '20px' }} >
                                <Icon type="dollar" /> รายจ่ายเงินเดือนพนักงาน
                                </Text>
                        </MDBCardHeader>

                        <MDBCardBody className={"bodytable mt-3 "}>
                            <div className="form-group">
                                <Bttn_search_date
                                    onYearsChange={this.onYearsChange} //เลือกปี
                                    onMouthChange={this.onMouthChange}//เลือกเดือน
                                    get_customer_status={this.get_customer_status}//เลือกค้นหา
                                    payslip_year={this.state.payslip_year} // ค่าปี
                                    payslip_month={this.state.payslip_month} // ค่าเดือน
                                />
                            </div>



                            {this.state.loading === true ? <>
                                <Spin spinning={this.state.loading} tip="loading.." />
                            </> : <>
                                    <ReactApexChart options={options} series={this.state.series} type="bar" height="350" />
                                </>}

                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>



            </div>
        )
    }
}
