import React, { useState, useEffect, useContext, Fragment } from 'react'
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBCollapse, MDBNavItem, MDBIcon, MDBNavLink, MDBBtn, MDBModalHeader, MDBBadge } from 'mdbreact';
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
// import { Link } from 'react-router-dom';
import Toast from 'light-toast';
import { Redirect } from 'react-router-dom';
import useDarkMode from 'use-dark-mode';
import 'antd/dist/antd.css';
import { UnorderedListOutlined, UserOutlined, DownOutlined, CloseCircleOutlined, SyncOutlined, CheckOutlined, CloseOutlined, LoadingOutline, Loading3QuartersOutline } from '@ant-design/icons';
import ReactResizeDetector from 'react-resize-detector';
import { Menu, MenuItem, Badge, Dialog, DialogContent, DialogTitle, Avatar, Divider, Fab } from '@material-ui/core';
// import PageLoading from './pages/sections/PageLoading';

import { NavLink } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AccountCircle from '@material-ui/icons/AccountCircle';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import Brightness2Icon from '@material-ui/icons/Brightness2';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import dropdownStyle from "./style/dropdownStyle";
import CheckIcon from '@material-ui/icons/Check';
import { theme, detail_user, admin1, admin2, adminSuper } from '../api';
import DarkModeToggle from '../Components/DarkMode/DarkModeToggle';
import Bttn_alert from './Bttn_alert';
import { fontStyle } from '@material-ui/system';
import Link from '@material-ui/core/Link';
import { Icon, Dropdown } from 'antd';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import HomeIcon from '@material-ui/icons/Home';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import InfoIcon from '@material-ui/icons/Info';
import ScrollTop from './ScrollTop';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import PaymentIcon from '@material-ui/icons/Payment';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PostAddIcon from '@material-ui/icons/PostAdd';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import UpdateIcon from '@material-ui/icons/Update';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ContactsIcon from '@material-ui/icons/Contacts';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import EventNoteIcon from '@material-ui/icons/EventNote';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PrintIcon from '@material-ui/icons/Print';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { Lottie_money, Lottie_form, Lottie_personal } from '../Components/MyLottie';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import AssignmentIcon from '@material-ui/icons/Assignment';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';



let menuUserList = [
    // {
    //     name: 'หน้าหลัก',
    //     path: '/',
    //     menu: '1',
    //     // show: false,
    //     icon: <><HomeIcon /></>,
    //     lottie: <Lottie_money />
    // },
    {
        name: 'รายการเงินเดือน',
        path: '/',
        menu: '11',
        icon: <><LocalAtmIcon /></>,
        lottie: <Lottie_money />
    },

    // {
    //     name: 'ข้อมูลส่วนตัว',
    //     path: '/FormPage',
    //     menu: '2',
    //     icon: <><InfoIcon /></>,
    //     lottie:<Lottie_personal />
    // },

    {
        name: 'แบบแจ้งรับเงินผ่านระบบ',
        path: '/PageKTB',
        menu: '2',
        icon: <><AssignmentIcon /></>,
        lottie: <Lottie_form />
    },

    // {
    //     name: 'แบบแจ้งรายการเพื่อหักลดหย่อน',
    //     path: '/PageTAX',
    //     menu: '12',
    //     icon: <><AssignmentIcon /></>,
    //     lottie: <Lottie_form />
    // },
]

let menuAdminList = [
    {
        name: 'หน้าหลัก',
        path: '/admin',
        menu: '0',
        icon: <><HomeIcon /></>
    },

    {
        name: 'จัดการเงินเดือน',
        path: '#',
        menu: '1',
        icon: <><PaymentIcon icon="hand-holding-usd" /></>,
        subMenu: [
            {
                name: 'จัดการเงินเดือน',
                path: '/admin/payslip_manage',
                icon: <><LocalAtmIcon icon="dollar-sign" /></>
            },
            {
                name: 'รายการรายรับ-รายจ่าย',
                path: '/admin/payslip_list_manage',
                icon: <><ReceiptIcon type="file-text" /></>
            },
            {
                name: 'เพิ่มรายจ่ายตามประเภท',
                path: '/admin/PaySlipExpensetype',
                icon: <><PostAddIcon icon="calendar-plus" /></>
            },
            {
                name: 'เพิ่มรายรับตามประเภท',
                path: '/admin/PayslipRevenueType',
                icon: <> <NoteAddIcon icon="calendar-plus" /></>
            },
            {
                name: 'นำเข้า และ ส่งออกข้อมูล',
                path: '/admin/Payslip_export_import_data',
                icon: <><ImportExportIcon type="solution" /></>
            },
            {
                name: 'อัพเดตข้อมูลรายจ่าย จากเดือนก่อนหน้า',
                path: '/admin/PaySlipManageUpdateDataEx',
                icon: <><UpdateIcon icon="sync-alt" /></>
            },
            {
                name: 'อัพเดตข้อมูลรายรับ จากเดือนก่อนหน้า',
                path: '/admin/PaySlipManageUpdateDataRe',
                icon: <><UpdateIcon icon="sync-alt" /></>
            },
            {
                name: 'อัพเดตข้อมูลเงินเดือน จากเดือนก่อนหน้า',
                path: '/admin/PaySlipManageUpdateDataSalary',
                icon: <><UpdateIcon icon="sync-alt" /></>
            },

        ]
    },
    {
        name: 'จัดการบุคลากร',
        path: '#',
        menu: '2',
        icon: <><ContactsIcon icon="users" /></>,
        subMenu: [
            {
                name: 'เพิ่มข้อมูลบุคลากร',
                path: '/admin/customers',
                icon: <><PersonAddIcon icon="user-plus" /></>
            },
            {
                name: 'จัดการข้อมูลบุคลากร',
                path: '/admin/customers_manage',
                icon: <><RecentActorsIcon far icon="edit" /></>
            },
            {
                name: 'KTB Corporate Online',
                path: '/admin/FormPageList',
                icon: <><AccountBalanceIcon icon="university" /></>
            },

        ]
    },
    {
        name: 'รายงานและข้อมูล',
        path: '#',
        menu: '3',
        icon: <><DesktopWindowsIcon icon="info-circle" /></>,
        subMenu: [
            {
                name: 'รายงานรายจ่ายแยกประเภท',
                path: '/admin/PaySlipExpensetypeReport',
                icon: <><EventNoteIcon icon="user-plus" /></>
            },
            {
                name: 'Export IPAY',
                path: '/admin/Excelreportipay',
                icon: <><LocalAtmIcon icon="file-export" /></>
            },
            {
                name: 'แสดงรายการเงินเดือน',
                path: '/admin/PageShowListSalaryEdit',
                icon: <><AssessmentIcon far icon="eye-slash" /></>
            },
            {
                name: 'ประวัติการพิมพ์',
                path: '/admin/PageListHistoryPrintAdmin',
                icon: <> <PrintIcon icon="print" /></>
            },
            {
                name: 'การแจ้งเตือน',
                path: '/admin/PageNotify',
                icon: <> <NotificationsActiveIcon far icon="bell" /></>
            },
             {
                name: 'เทคนิคการแยกไฟล์ PDF',
                path: '/admin/PagesHowtoConvertPDF',
                icon: <> <PictureAsPdfIcon  /></>
            },
            {
                name: 'รายงานลดหย่อนภาษี',
                path: '/admin/customers_managetax',
                icon: <><EventNoteIcon icon="user-plus" /></>
            },

        ]
    },


]

let Menulist = []
if (detail_user) {
    if (detail_user.admin_type === undefined) {
        Menulist = menuUserList
    } else {
        Menulist = menuAdminList
    }
}


let menuColorList = [
    {
        name: 'BG',
        menu: '4',
        subMenu: [{
            name: 'default',
            color: '#fff',
            menu: '1',
        },
        {
            name: 'light grey',
            color: '#ececec',
            menu: '1',
        },
        {
            name: 'light yellow',
            color: '#ece8cdd4',
        },
        {
            name: 'light green',
            color: '#e7f3e0c7',
            menu: '1',
        },
        {
            name: 'grey',
            color: '#c1bebe',
            menu: '1',
        },
        {
            name: 'sky',
            color: '#1890ff33',
            menu: '1',
        },
        ]
    }



]

export { Menulist, menuColorList }