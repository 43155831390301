import React, { useState, useEffect, useContext, Fragment } from 'react'
import { Menu, MenuItem, Badge, Dialog, DialogContent, DialogTitle, Avatar, Divider, Fab, LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { theme, detail_user, admin1, admin2, adminSuper } from '../api';
// const useStyles = makeStyles(styles);
const StyledBadge = withStyles((theme) => ({
    badge: {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}))(Badge);




export default function MyAvatar(props) {


    return (

        <>

            <StyledBadge
                overlap="circle"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                variant="dot"
            >
                <Avatar sizes="10px" alt={detail_user.nameuser} src={"https://mis.kpru.ac.th/images/pic_emp_50/" + detail_user.employee_id + ".JPG"} />

            </StyledBadge>


        </>
    )
}