import React, { Component } from 'react'
import { Modal, Button, Input, InputNumber } from 'antd';
import { apilogin } from '../api';
import Text from 'antd/lib/typography/Text';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import Select from 'react-select'


export default class Bttn_customer_salary_edit_money extends Component {
    constructor(props) {
        super(props)
        this.state = { 
            visible: false,
            checkdata: false,
            customers_citizent: "",
            history_salary_month: "",
            history_salary_year: "",
            history_salary_salary: "",
        }
        this.onChange = this.onChange.bind(this)
        this.onMouthChange = this.onMouthChange.bind(this)
        this.onYearsChange = this.onYearsChange.bind(this)
    }

    onMouthChange(event) { this.setState({ history_salary_month: event.value }) }
    onYearsChange(event) { this.setState({ history_salary_year: event.value }) }

    showModal = () => {
        this.setState({
            visible: true,
            history_salary_salary:this.props.data.history_salary_month
        });
    };

    handleOk = e => {
      //  console.log(this.props.data)
      // alert(JSON.stringify(this.state))
        apilogin.post('insert_customers/update_salary', ({
            customers_citizent: this.props.data.customers_citizent,
            customers_type_id: this.props.customers_type_id,
            history_salary_month: this.props.data.history_salary_month,
            history_salary_year: this.props.data.history_salary_year,
            history_salary_salary: this.state.history_salary_salary,
        }))
            .then(res => {
                this.props.updatesalary()
            });
        this.setState({
            visible: false,
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };



    onChange(event) {
        this.setState({
            history_salary_salary: event
        })
    }

    render() {

     
        return (
            <>
                <Button icon="edit" size="small" onClick={this.showModal}>
                  
                </Button>
                <Modal
                    centered
                    // style={{ right: -300 }}
                    title={<><strong>แก้ไขเงินเดือน</strong> </>}
                    visible={this.state.visible}
                    okText="บันทึก"
                    cancelText="ยกเลิก"
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >



                
                    <MDBRow className="mt-5" className="text-center">
                        <MDBCol sm="12" className="mt-4" >
                            <Text strong style={{ textAlign: 'center', fontSize: '16px' }}> เงินเดือนที่จะแก้ไข </Text>
                            <InputNumber
                                placeholder="จำนวน"
                                defaultValue={this.props.data.history_salary_salary}
                                onChange={this.onChange}
                                min={0} max={500000}
                            //step="0.1"
                            />
                            <Text strong style={{ textAlign: 'center', fontSize: '16px' }}> บาท </Text>
                        </MDBCol>

                    </MDBRow>


                </Modal>
            </>
        )
    }
}
