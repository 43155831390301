import React, { Component } from 'react'
import { Modal, Button, Input, InputNumber, Select, Icon, Table, Popconfirm, notification, Spin } from 'antd';
import { apilogin } from '../api';
import Text from 'antd/lib/typography/Text';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import Highlighter from 'react-highlight-words';
import Bttn_customer_salary_add from './Bttn_customer_salary_add';
import NumberFormat from 'react-number-format';
import Bttn_customer_salary_edit_money from './Bttn_customer_salary_edit_money';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

const { Option } = Select;
export default class Bttn_customer_salary_edit extends Component {
    constructor(props) {
        super(props)
        this.state = {

            visible: false,
            checkdata: false,
            customers_citizent: "",
            history_salary_month: "",
            history_salary_salary: "",
            history_salary_year: "",
            history_salary: [],

            options_years: [],
            year: new Date().getFullYear(),
            //year: "2020",
            loading: true
        }
        this.onChange = this.onChange.bind(this)
        this.updatesalary = this.updatesalary.bind(this)

        this.postid = this.postid.bind(this)
    }



    showModal = () => {
        this.postid()
        this.setState({
            visible: true,
        });
    };

    handleOk = e => {
        this.props.updatedata_props()
        this.setState({
            visible: false,
        });
    };

    handleCancel = e => {
        // this.props.updatedata_props()
        this.setState({
            visible: false,
        });
    };

    postid(e) {

        this.setState({ loading: true })
        if (e === undefined) {
            e = new Date().getFullYear()
        }
        this.setState({ year: String(e) })
        // console.log(e)

        apilogin.get('getdata/getyear')
            .then(res => {
                let year = []
                res.data.map((data) => {
                    let yy = {
                        value: data.name_year,
                        label: "ปี " + data.name_year_th,
                    }
                    year.push(yy)
                })
                this.setState({
                    options_years: year
                });


                apilogin.post('insert_customers/getdata_for_edit_customers_salary',
                    ({
                        customers_citizent: this.props.customers_citizent,
                        year: String(e)

                    })
                )
                    .then(res => {
                        // console.log(res.data)
                        if (res.data.length >= 1) {
                            const getdata = res.data.map((data, index) =>
                                ({
                                    index: index + 1,
                                    customers_citizent: data.customers_citizent,
                                    history_salary_month: data.history_salary_month,
                                    history_salary_salary: data.history_salary_salary,
                                    history_salary_year: data.history_salary_year,
                                })
                            )
                            this.setState({
                                history_salary: getdata,
                                checkdata: true,
                            });
                            this.setState({ loading: false })
                        }
                        else {

                            this.setState({
                                checkdata: false,
                                history_salary: []
                            });
                            this.setState({ loading: false })
                        }

                    });

            })
            .catch((err) => {
                notification.error({
                    message: 'โหลดรายการปีไม่สำเร็จ ' + err,
                })
            })




    }

    onChange(event) {
        this.setState({
            history_salary_year: event
        })
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`ค้นหา`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
            </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        ),
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    updatesalary() {
        this.props.updatedata_props()
        setTimeout(() => {
            this.postid()
        }, 500);
    }
    render() {


        const columns = [
            {
                title: 'ลำดับ',
                dataIndex: 'index',
                key: 'index',

                render: text => <div className="text-center">{text}</div>,

            },
            {
                title: 'เดือน',
                dataIndex: 'history_salary_month',
                key: 'history_salary_month',
                render: text => <div className="text-center">
                    <select disabled className="browser-default custom-select" value={text} placeholder="เลือกเดือน"  >
                        <option value="00">เลือกเดือน </option>
                        <option value="01">มกราคม </option>
                        <option value="02">กุมภาพันธ์ </option>
                        <option value="03">มีนาคม </option>
                        <option value="04">เมษายน </option>
                        <option value="05">พฤษภาคม </option>
                        <option value="06">มิถุนายน </option>
                        <option value="07">กรกฎาคม </option>
                        <option value="08">สิงหาคม </option>
                        <option value="09">กันยายน </option>
                        <option value="10">ตุลาคม </option>
                        <option value="11">พฤศจิกายน </option>
                        <option value="12">ธันวาคม </option>
                    </select>
                </div>,
            },

            {
                title: 'ปี',
                dataIndex: 'history_salary_year',
                key: 'history_salary_year',
                render: text => <div className="text-center">
                    <select disabled className="browser-default custom-select" value={text} placeholder="เลือกเดือน"  >
                        <option value="00">เลือกเดือน </option>
                        <option value="2019">2562 </option>
                        <option value="2020">2563 </option>
                        <option value="2021">2564 </option>

                    </select>
                </div>,
            },
            {
                title: 'เงินเดือน',
                dataIndex: 'history_salary_salary',
                key: 'history_salary_salary',
                render: text => <div className="text-center">
                    <NumberFormat value={text} displayType={'text'} thousandSeparator={true} />
                </div>,

            },
            {
                title: 'จัดการ',
                dataIndex: 'history_salary_salary',
                key: 'history_salary_salary',
                render: (text, record) => <div className="text-center">
                    <Bttn_customer_salary_edit_money
                        updatesalary={this.updatesalary}
                        customers_citizent={this.props.customers_citizent} 
                        //data={this.props.data}
                        salary={record.history_salary_salary}
                        data={record} 
                        history_salary_month={this.state.history_salary_month}
                        history_salary_year={this.state.history_salary_year}
                        customers_type_id={this.props.customers_type_id}
                    />
                    <Popconfirm
                        title="คุณแน่ใจใช่ไหมที่จะลบข้อมูลนี้?"
                        onConfirm={() => {
                            apilogin.post('insert_customers/delete_salary', ({
                                customers_citizent: this.props.customers_citizent,
                                history_salary_month: record.history_salary_month,
                                history_salary_year: record.history_salary_year,
                                customers_type_id:this.props.customers_type_id,
                            }))
                                .then(res => {
                                    this.updatesalary()
                                });
                        }}
                        // onCancel={}
                        okText="แน่ใจ"
                        cancelText="ยกเลิก"
                    >
                        <Button icon="delete" size="small"></Button>
                    </Popconfirm>

                </div>,

            },




        ]

        return (
            <>
                <Button icon="dollar" size="small" onClick={this.showModal}>
                    เงินเดือน
                </Button>
                <Modal
                    // style={{ left: -300 }}
                    style={{ top: 10 }}
                    width="50%"

                    // centered
                    title={<><strong>แก้ไขเงินเดือน</strong> </>}
                    visible={this.state.visible}
                    okText="ปิด"
                    footer={false}
                    // cancelText="ยกเลิก"
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >

                    <div className="text-right mt-2 mb-2">
                        <Bttn_customer_salary_add customers_type_id={this.props.customers_type_id} data={this.props.data} updatedata_props={this.props.updatedata_props} updatesalary={this.updatesalary} customers_citizent={this.props.customers_citizent} />
                    </div>

                    {this.state.loading === true ? <>
                        <div className="mt-5 mb-5 text-center">
                            <Spin tip="loading.." />
                        </div>

                    </> : <>
                            {/* {console.log(this.state.year)} */}
                            <Tabs
                                defaultActiveKey={String(this.state.year)}
                                onChange={this.postid}
                            >

                                {this.state.options_years.map((data) => {
                                    return <TabPane tab={data.label} key={data.value}>

                                    </TabPane>

                                })}


                            </Tabs>

                            <Table dataSource={this.state.history_salary} columns={columns}
                                pagination={{ pageSize: 25 }}
                                bordered
                                size="small"
                                loading={this.state.loading}
                            />
                        </>}











                </Modal>
            </>
        )
    }
}
