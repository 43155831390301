
import React, { useEffect, useState, useContext } from "react";
// import { makeStyles } from '@material-ui/core/styles';
import * as  genpdf from '../img/genpdf.json'
import * as  success from '../img/success.json'
import * as  empty from '../img/empty.json'
import * as  notify from '../img/notify.json'
import * as  salary from '../img/salary.json'
import * as  money from '../img/money.json'
import * as  form from '../img/form.json'
import * as  personal from '../img/personal.json'
import * as  edit from '../img/edit.json'
import * as  printer from '../img/printer.json'
import * as  download from '../img/download.json'
import * as  view from '../img/view.json'
import * as  check from '../img/check.json'
import * as  error404 from '../img/error404.json'

import FadeIn from "react-fade-in";
import Lottie from "react-lottie";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";

// const useStyles = makeStyles((theme) => ({
//   backdrop: {
//     zIndex: theme.zIndex.drawer + 1,
//     color: '#fff',
//   },
// }));



const OptionsGenpdf = {
  loop: true,
  autoplay: true,
  animationData: genpdf.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

export function Com_login() {
  const [play, set_play] = useState(false)
  return (
    <>
      <FadeIn>
        <div
          onMouseOver={() => set_play(false)}
          onMouseLeave={() => set_play(true)}
        >
          <Lottie
            // isStopped={play}
            // isPaused={play}
            options={OptionsGenpdf} height={200} width={200} />
        </div>

      </FadeIn>
    </>
  );
}



const Optionssuccess = {
  loop: true,
  autoplay: true,
  animationData: success.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

export function Lottie_Success() {
  return (
    <>
      <FadeIn>
          <Lottie options={Optionssuccess} height={200} width={200} />
      </FadeIn>
    </>
  );
}

const Optionempty = {
  loop: true,
  autoplay: true,
  animationData: empty.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};
export function Lottie_empty() {
  return (
    <>
      <FadeIn>
          <Lottie options={Optionempty} height={200} width={200} />
      </FadeIn>
    </>
  );
}

const Optionnotify = {
  loop: true,
  autoplay: true,
  animationData: notify.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};
export function Lottie_notify() {
  return (
    <>
      <FadeIn>
          <Lottie options={Optionnotify} height={200} width={100} />
      </FadeIn>
    </>
  );
}

const Optionsalary = {
  loop: true,
  autoplay: true,
  animationData: salary.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};
export function Lottie_salary() {
  return (
    <>
      <FadeIn>
          <Lottie options={Optionsalary} height={100} width={100} />
      </FadeIn>
    </>
  );
}


const Optionmoney = {
  loop: true,
  autoplay: true,
  animationData: money.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};
export function Lottie_money() {
  return (
    <>
      <FadeIn>
          <Lottie options={Optionmoney} height={155} width={140} />
      </FadeIn>
    </>
  );
}

const Optionform= {
  loop: true,
  autoplay: true,
  animationData: form.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};
export function Lottie_form() {
  return (
    <>
      <FadeIn>
          <Lottie options={Optionform} height={150} width={100} />
      </FadeIn>
    </>
  );
}



const Optionpersonal= {
  loop: true,
  autoplay: true,
  animationData: personal.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};
export function Lottie_personal() {
  return (
    <>
      <FadeIn>
          <Lottie options={Optionpersonal} height={150} width={150} />
      </FadeIn>
    </>
  );
}


const Optionedit= {
  loop: true,
  autoplay: true,
  animationData: edit.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};
export function Lottie_edit() {
  return (
    <>
      <FadeIn>
          <Lottie options={Optionedit} height={150} width={150} />
      </FadeIn>
    </>
  );
}

const Optionprinter= {
  loop: true,
  autoplay: true,
  animationData: printer.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};
export function Lottie_printer() {
  return (
    <>
      <FadeIn>
          <Lottie options={Optionprinter} height={150} width={150} />
      </FadeIn>
    </>
  );
}


const Optiondownload= {
  loop: true,
  autoplay: true,
  animationData: download.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};
export function Lottie_download() {
  return (
    <>
      <FadeIn>
          <Lottie options={Optiondownload} height={150} width={150} />
      </FadeIn>
    </>
  );
}

const Optionview= {
  loop: true,
  autoplay: true,
  animationData: view.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};
export function Lottie_view() {
  return (
    <>
      <FadeIn>
          <Lottie options={Optionview} height={150} width={150} />
      </FadeIn>
    </>
  );
}


const Optioncheck= {
  loop: false,
  autoplay: true,
  animationData: check.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};
export function Lottie_check() {
  return (
    <>
      <FadeIn>
          <Lottie options={Optioncheck} height={150} width={150} />
      </FadeIn>
    </>
  );
}

const Optionerror404= {
  loop: true,
  autoplay: true,
  animationData: error404.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};
export function Lottie_error404() {
  return (
    <>
      <FadeIn>
          <Lottie options={Optionerror404} height={400} width={400} />
      </FadeIn>
    </>
  );
}
