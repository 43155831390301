import React, { Component } from 'react'
import ReactExport from "react-export-excel";
import { Modal, Button, Input, message, InputNumber, Icon, Form, Spin } from 'antd';
import { apilogin } from '../api'
import { MDBIcon } from 'mdbreact';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class Excelreportdeduction extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      load: false,
      deduction2: [],
      deduction: [],
      deductionall: [],
      list_add: [],

    }
    this.get_dataipay = this.get_dataipay.bind(this)
  }



  get_dataipay() {
    //  console.log(this.props.data);

    let total = 0;

    this.setState({ loading: true })
    this.setState({ load: true })



    apilogin.post('getdata/deduction',
      ({
        payslip_month: this.props.payslip_month,
        payslip_year: this.props.payslip_year,
        customers_group: this.props.customers_group,
      })
    )
      .then(res => {

        apilogin.post('getdata/list_add',
          ({
            payslip_month: this.props.payslip_month,
            payslip_year: this.props.payslip_year,
            customers_group: this.props.customers_group,
          })
        )
          .then(res2 => {


            this.setState({
              deductionall: res.data,
              list_add: res2.data,
              loading: false,
              load: false,
            })
          })



      })



    // setTimeout(() => {
    //   apilogin.post('getdata/deduction2',
    //     ({
    //       //    bank_id:this.props.data.bank_id,
    //       payslip_month: this.props.payslip_month,
    //       payslip_year: this.props.payslip_year,
    //       customers_group: this.props.customers_group,
    //     })
    //   )

    //     .then(res => {
    //       //console.log(res.data)
    //       const getdata = res.data.map((data, index) => ({
    //         order: index + 1,
    //         list: data.bank_name,
    //         sum: data.MonneyFull,

    //         // bank_name: data.bank_name,
    //       })
    //       )

    //       // this.setState({
    //       //     deductionall: getdata,
    //       //     loading: false,
    //       //     load: false,
    //       // })
    //       //  console.log(res.data);
    //       //  this.setState({ ipayall: res.data, loading: false, load: false })


    //       apilogin.post('getdata/deduction',
    //         ({
    //           //  bank_id:this.props.data.bank_id,
    //           payslip_month: this.props.payslip_month,
    //           payslip_year: this.props.payslip_year,
    //           customers_group: this.props.customers_group,
    //         })
    //       )

    //         .then(res2 => {
    //             //console.log(res2.data)
    //           let getdata2 = res2.data.map((data, index) => ({
    //             order: index + 1,
    //             list: data.expenditure_name,
    //             sum: data.sum,

    //           }
    //           )
    //             // getdata.push(getdata2);
    //           )
    //           // this.state.deductionall.push(getdata2)
    //           var rowss = getdata.length

    //           var count = getdata2.length
    //           for (let i = 0; i < count; i++) {
    //             rowss = rowss + 1
    //             getdata.push({ order: rowss, list: getdata2[i].list, sum: getdata2[i].sum });
    //           }


    //           for (let i = 0; i < getdata.length; i++) {
    //             total = total + getdata[i].sum
    //           }
    //           getdata.push({ list: 'รวมเงินรายจ่าย', sum: total });



    //           this.setState({

    //             deductionall: getdata,
    //             loading: false,
    //             load: false,
    //           })
    //           //const sumrr = Arrays.stream(getdata.sum).sum();
    //           // this.setState({ deductionall: this.state.deductionall.push(getdata2), loading: false,
    //           //     load: false, })
    //           //   console.log(res.data);
    //           //  this.setState({ ipayall: res.data, loading: false, load: false })
    //           //console.log(sumrr)  
    //          // console.log(total)
    //         })
    //      // console.log(getdata)


    //     })
    // }, 500);

  }





  render() {


    return (
      <>

        {this.state.loading === true ? <Button size="default" disabled={this.props.nameexcel === "" ? true : false} onClick={this.get_dataipay} > {this.state.load === true ? <><Spin />loading</> : <> <MDBIcon icon="download" /> &nbsp; โหลดข้อมูลสรุปรายการหักเงินเดือน ของ{this.props.nameexcel} </>}</Button> :
          <ExcelFile filename={"สรุปรายการหักเงินเดือน " + this.props.payslip_month + "-" + this.props.payslip_year + this.props.nameexcel} element={
            <Button size="default">
              <Icon type="check" style={{ color: '#009900' }} /> &nbsp; <MDBIcon className='animated headShake delay-0s infinite' far icon="hand-point-right" /> &nbsp; คลิกเพื่อโหลดสรุปรายการหักเงินเดือน
          </Button>
          }>
            <ExcelSheet data={this.state.deductionall} name={"รายหัก"}>
              <ExcelColumn label="ลำดับ" value="num" />
              <ExcelColumn label="จำนวนเงิน" value="" />
              <ExcelColumn label="รายการ" value="expenditure_name" />
              <ExcelColumn label="เงินรวม" value="sum" />
              <ExcelColumn label="เงินรวม นอก" value="sumout" />
              {/* <ExcelColumn label="เงินรวมจริง" value="total" /> */}
              <ExcelColumn label="ยอดส่ง" value="totalfinal" />
            </ExcelSheet>


            <ExcelSheet data={this.state.list_add} name={"รายได้"}>
              <ExcelColumn label="ลำดับ" value="num" />
              <ExcelColumn label="จำนวนเงิน" value="" />
              <ExcelColumn label="รายการ" value="revenue_name" />
              <ExcelColumn label="เงินรวม" value="sum" />
              <ExcelColumn label="ยอดส่ง" value="totalfinal" />
            </ExcelSheet>
          </ExcelFile>
        }



      </>
    )
  }
}
