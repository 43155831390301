import React, { Component } from 'react'
import { Modal, Button, Input } from 'antd';
import { apilogin } from '../api';
import Text from 'antd/lib/typography/Text';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';

export default class Bttn_list_revenue_add extends Component {
    constructor(props) {
        super(props)
        this.state = {
            customer_type: [],
            revenue_name: "",
            customer_type_id: ""
        }
        this.onChange = this.onChange.bind(this)
    }

    showModal = () => {
        this.get_customer_type()
        this.setState({
            visible: true,
        });
    };

    get_customer_type() {
        apilogin.get('getdata/customer_type')
            .then(res => {
                this.setState({ customer_type: res.data })
            })

    }

    handleOk = e => {

        apilogin.post('insert_salary/insert_list_revenue', ({
            revenue_name: this.state.revenue_name,
            customer_type_id: this.state.customer_type_id,
        }))
            .then(res => {
                this.props.updatedata_props()
            });
        this.setState({
            visible: false,
            revenue_name: "",
            customer_type_id: "",
            customer_type: [],
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };



    onChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        })
    }
    render() {
        return (
            <>
                <Button icon="file-add" size="default" onClick={this.showModal}>
                    เพิ่มรายรับ
                </Button>
                <Modal
                    centered
                    title={<><strong>เพิ่มรายรับ</strong> </>}
                    visible={this.state.visible}
                    okText="บันทึก"
                    cancelText="ยกเลิก"
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >

                    <div className="mt-0">
                        <Text strong style={{ textAlign: 'center', fontSize: '16px' }}> ชื่อรายการ </Text>
                        <input className="form-control" value={this.state.revenue_name} onChange={this.onChange}
                            type="text" name="revenue_name" placeholder="ระบุชื่อรายการ" />
                    </div>

                    <div className="mt-2">
                        <Text strong style={{ textAlign: 'center', fontSize: '16px' }}> ประเภทบุคลากร </Text>
                        <select className="form-control" placeholder="เลือกประเภทบุคลากร" onChange={this.onChange} name="customer_type_id">
                        <option value="00">เลือกประเภทบุคลากร</option>
                            {this.state.customer_type.map((data, key) => {
                                return <option value={data.customer_type_id} key={key}>{data.customer_type_name}</option>
                            })}
                        </select>
                    </div>

                </Modal>
            </>
        )
    }
}
