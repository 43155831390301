import React, { Component } from 'react'
import { apilogin, theme, mouth, year, detail_user } from '../api'
import { Table, Input, Icon, Card, Button, InputNumber, message, Tabs, notification } from 'antd'
import { MDBContainer, MDBCardBody, MDBCardHeader, MDBCard, MDBRow, MDBCol, MDBIcon } from 'mdbreact'
import Highlighter from 'react-highlight-words';
import Bttn_customer_add_expenditure from '../Components/Bttn_customer_add_expenditure';
import NumberFormat from 'react-number-format';
import Select from 'react-select'
import Bttn_customer_add_revenue from '../Components/Bttn_customer_add_revenue';
import PrintPDF from './PrintPDF';
import Bttn_customer_expendituretype from '../Components/Bttn_customer_expendituretype';
import PrintPDFexpenditure from './PrintPDFexpenditure';
import Bttn_Auto_Social_Security from '../Components/Bttn_Auto_Social_Security';
import MUIDataTable from "mui-datatables";
import Bttn_Auto_Fund from '../Components/Bttn_Auto_Fund';



const { TabPane } = Tabs;

export default class PaySlipExpensetype extends Component {

    constructor(props) {
        super(props)
        this.state = {
            options_years: [],
            type_list: [],
            data: [],
            datatypeid1: [],
            datatypeid5: [],
            datatypeid2: [],
            loading: false,
            search: '',

            search_check1: false,
            search_check5: false,
            search_check2: false,
            expenditurelevelmain: '',
            expenditurelevelmain2: '',

            expenditurelevelmainlabel: '',
            expenditurelevelmainlabel3: '',

            customers_type_id: '',
            payslip_month: '',
            payslip_year: '',
            expenditurelevelmain: '',

            // customers_type_id: '1',
            // payslip_month: '12',
            // payslip_year: '2020',
            // expenditurelevelmain:'83' ,

            customers_type_name: '',

            customer_type: [],
            customer_type_1: [],
            customer_type_2: [],
            type_id: '',
            sum: 0

        }
        this.get_customer_status = this.get_customer_status.bind(this)
        this.updatedata = this.updatedata.bind(this)
        this.updatedata_props = this.updatedata_props.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onMouthChange = this.onMouthChange.bind(this)
        this.onYearsChange = this.onYearsChange.bind(this)
        this.onExpenditure = this.onExpenditure.bind(this)
        this.onExpenditure2 = this.onExpenditure2.bind(this)
        this.onExpenditure3 = this.onExpenditure3.bind(this)
    }

    onChange(event) {
        console.log(event)
        // this.setState({ [event.target.name]: event.target.value })
    }

    onMouthChange(event) {
        // this.setState({ payslip_month: event.value })
        this.setState({ payslip_month: event.target.value })
    }
    onYearsChange(event) {
        //  this.setState({ payslip_year: event.value })
        this.setState({ payslip_year: event.target.value })
    }
    onExpenditure(event) {
        this.setState({
            expenditurelevelmain: event.value,
            expenditurelevelmain2: event.label,
            loading: true,
        })
        setTimeout(() => {
            this.get_customer_status();
        }, 1000);
    }

    onExpenditure2(event) {
        this.setState({
            expenditurelevelmain: event.value,
            expenditurelevelmainlabel: event.label,
            loading: true,
        })
        setTimeout(() => {
            this.get_customer_status();
        }, 1000);

    }


    onExpenditure3(event) {

        this.setState({
            expenditurelevelmain: event.value,
            expenditurelevelmainlabel3: event.label,
            loading: true,
        })

        setTimeout(() => {
            this.get_customer_status();
        }, 1000);
    }



    get_customer_status() { 
        this.setState({ type_id: "" });

        if (this.state.payslip_month === "") {
            message.warning("กรุณาเลือกเดือน")
        }
        else if (this.state.payslip_year === "") {
            message.warning("กรุณาเลือกปี")
        }
        else if (this.state.expenditurelevelmain === "") {
            message.warning("กรุณาเลือกประเภท")
        }
        else {
            this.setState({ loading: true })

            apilogin.post('getdata/PaySlipExpensetype_id/' + this.state.payslip_year + '/' + this.state.payslip_month + '/' + this.state.customers_type_id + '/' + this.state.expenditurelevelmain)
                .then(res => {

                    // this.setState({
                    //     datatypeid1: res.data
                    // });
                    this.updatedata(res.data)


                    apilogin.get('getdata/check_autoinsert/' + this.state.expenditurelevelmain)
                        .then(res => {
                            // console.log(res.data[0].type_id)
                            if (res.data.length >= 1) {
                                this.setState({ type_id: res.data[0].type_id });
                            }


                            apilogin.post('getdata/PaySlipExpensetype_id_sum/' + this.state.payslip_year + '/' + this.state.payslip_month + '/' + this.state.customers_type_id + '/' + this.state.expenditurelevelmain)
                                .then(res => {
                                    let sum = 0
                                    try {
                                        sum = Number(res.data[0].payslip_expenditure).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })

                                    } catch (error) {
                                        sum = 0
                                    }

                                    this.setState({ sum: sum })
                                })

                        })

                })
        }

      

    }

    updatedata(data) {
        const getdata = data.map((data, index) => ({
            key: data.customers_citizent,
            index: index + 1,
            customers_citizent: data.customers_citizent,
            customers_pname: data.customers_pname,
            customers_name: data.customers_name + " " + data.customers_lname,
            customers_lname: data.customers_lname,
            customer_type_name: data.customer_type_name,
            customer_type_id: data.customer_type_id,
            customer_type_name: data.customer_type_name,
            customer_status_name: data.customer_status_name,
            payslip_revenue: data.payslip_revenue + data.Salary,
            payslip_expenditure: data.payslip_expenditure,
            payslip_total: (data.payslip_revenue + data.Salary) - data.payslip_expenditure,
            bank_name: data.bank_name,
        })
        )
        if (getdata.length >= 1) {
            this.setState({
                data: getdata,
                search_check: true,
                loading: false,
            })
        }
        else {
            message.warning("ไม่พบข้อมูล")
        }


    }

    updatedata_props() {
        this.setState({ loading: true })
        setTimeout(() => {
            this.get_customer_status();
        }, 0);
    }

    componentDidMount() {
        this.setState({
            payslip_month: mouth,
            payslip_year: year,
        })
        // this.get_customer_status()3
        this.setState({ loading: true })

        apilogin.get('getdata/getyear')
            .then(res => {
                let year = []
                res.data.map((data) => {
                    let yy = {
                        value: data.name_year,
                        label: data.name_year_th,
                    }
                    year.push(yy)
                })
                this.setState({ options_years: year })
            })
            .catch((err) => { notification.error({ message: 'โหลดรายการปีไม่สำเร็จ ' + err, }) })


        if (detail_user.nameuser !== "") {
            this.setState({ nameuser: detail_user.nameuser })
            this.setState({ admin_type: detail_user.admin_type })
            apilogin.get('getdata/customer_type')
                .then(res => {

                    let customer_type = []
                    let customer_type_1 = []
                    let customer_type_2 = []

                    let cc = []
                    res.data.map((data) => {
                        let yy = {
                            value: data.customer_type_id,
                            label: data.customer_type_name,
                            admin_level: data.admin_level,
                        }
                        cc.push(yy)
                    })


                    cc.map((data) => {
                        customer_type.push(data)
                        if (data.admin_level === "1") {
                            customer_type_1.push(data)
                        }
                        else if (data.admin_level === "2") {
                            customer_type_2.push(data)
                        }
                    })

                    this.setState({
                        customer_type: customer_type,
                        customer_type_1: customer_type_1,
                        customer_type_2: customer_type_2,
                    })

                })
        }



      

    }


    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`ค้นหา`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
            </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        ),
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    render() {

        const options_mounth = [
            { value: '01', label: 'มกราคม' },
            { value: '02', label: 'กุมภาพันธ์' },
            { value: '03', label: 'มีนาคม' },
            { value: '04', label: 'เมษายน' },
            { value: '05', label: 'พฤษภาคม' },
            { value: '06', label: 'มิถุนายน' },
            { value: '07', label: 'กรกฎาคม' },
            { value: '08', label: 'สิงหาคม' },
            { value: '09', label: 'กันยายน' },
            { value: '10', label: 'ตุลาคม' },
            { value: '11', label: 'พฤศจิกายน' },
            { value: '12', label: 'ธันวาคม' },

        ]

        const columns_all = [
            {
                title: 'ลำดับ',
                dataIndex: 'index',
                key: 'index',
                className: theme,
                render: text => <div className="text-center">{text}</div>,

            },
            {
                title: 'ชื่อ-นามสกุล',
                dataIndex: 'customers_name',
                key: 'customers_name',
                className: theme,
                ...this.getColumnSearchProps('customers_name'),
                sorter: (a, b) => a.customers_name.length - b.customers_name.length,
                render: text => <div className="text-left">{text}</div>,
            },

            {
                title: 'ประเภทบุคลากร',
                dataIndex: 'customer_type_name',
                key: 'customer_type_name',
                className: theme,
                render: text => <div className="text-center">{text}</div>,
                ...this.getColumnSearchProps('customer_type_name'),
                sorter: (a, b) => a.customer_type_name.length - b.customer_type_name.length,
            },

            {
                title: 'รายการจ่าย',
                dataIndex: 'payslip_expenditure',
                key: 'payslip_expenditure',
                className: theme,
                render: text => <div className="text-center">{this.state.expenditurelevelmain2}</div>,

            },
            {
                title: 'จำนวน',
                dataIndex: 'payslip_expenditure',
                key: 'payslip_expenditure',
                className: theme,
                render: text =>
                    // <NumberFormat value={text} displayType={'text'} thousandSeparator={true} />
                    <div className="text-right">{Number(text).toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>,
                    sorter: (a, b) => a.payslip_expenditure - b.payslip_expenditure,

            },



            {
                title: 'จัดการ',
                key: 'manage',
                className: theme,
                render: (text, record) => (
                    <div className="text-center">
                        <Bttn_customer_expendituretype
                            pop={this.props}
                            data={this.state}
                            customers_citizent={record.customers_citizent}
                            customer_type_id={record.customer_type_id}
                            updatedata_props={this.updatedata_props}
                        /> &nbsp;
                    </div>
                ),
            },


        ]
 
        const columns_mui_all = [
            // {
            //     name: "payslip_month",
            //     label: "เดือน",
            //     options: {
            //         filter: true,
            //         sort: false,
            //     }
            // },

            {
                name: "index",
                label: <div className="text-center" >#</div>,
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        let i = tableMeta.rowIndex
                        return (
                            <>
                                <div className="text-center">
                                   {tableMeta.rowIndex+1}
                                </div>
                            </>
                        );
                    }
                }
            },

            {
                name: "customers_name",
                // label: "ปี",
                label: <div className="text-left" >ชื่อ-นามสกุล</div>,
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                                <div className="text-left">
                                    {value}
                                </div>
                        );
                    }
                }
            },

            {
                name: "customer_type_name",
                // label: "รายรับ",
                label: <div className="text-center" >ประเภทบุคลากร</div>,
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                                <div className="text-center">
                                   {value}
                                </div>
                        );
                    }
                }
            },

            {
                name: "payslip_expenditure",
                // label: "รายจ่าย",
                label: <div className="text-center" >รายการจ่าย</div>,
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                                <div className="text-center">
                                    {this.state.expenditurelevelmain2}
                                </div>
                        );
                    }
                }
            },

            {
                name: "payslip_expenditure",
                // label: "ยอดสุทธิ",
                label: <div className="text-right" >จำนวน</div>,
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                                <div className="text-right">
                                     {Number(value).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                                </div>
                        );
                    }
                }
            },

            {
                name: "manage",
                label: <div className="text-center" >จัดการ</div>,
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        let i = tableMeta.rowIndex
                        return (
                            <>
                                <div className="text-center">

                                <Bttn_customer_expendituretype pop={this.props} data={this.state} customers_citizent={this.state.data[i].customers_citizent} customer_type_id={this.state.data[i].customer_type_id} updatedata_props={this.updatedata_props} /> &nbsp;
                  

                                </div>
                            </>
                        );
                    }
                }
            },
        ];


        return (
            <div>
                <MDBContainer className="mt-5 mb-5">
                    <div className="d-flex justify-content-center mt-5 text-center">
                        <MDBCard style={{ width: "72rem", minHeight: "40rem" }} className={theme} >
                            <MDBCardHeader className={"form-header rgba-indigo-light rounded -hoverable " + theme} >
                                <h2 className={theme} style={{ textAlign: 'center', fontWeight: 'bold' }} >
                                    <MDBIcon icon="calendar-plus" />  เพิ่มรายจ่ายแยกประเภท
                                </h2>
                                {/* <h2 className="dark-text" style={{ textAlign: 'center' }} >
                                    level {this.state.admin_type}
                                </h2> */}
                            </MDBCardHeader>


                            <div className="container mt-5" >

                                <MDBRow className="text-left">
                                    <MDBCol xl="3" className="mb-1 text-left">
                                        <Select
                                            className={"light"}
                                            options={this.state.admin_type === "super" ? this.state.customer_type :
                                                this.state.admin_type === "adminlevel1" ? this.state.customer_type_1 :
                                                    this.state.admin_type === "adminlevel2" ? this.state.customer_type_2 : null}
                                            //defaultInputValue={this.state.customers_type_id}
                                            placeholder={<span><MDBIcon icon="users" /> &nbsp; เลือกประเภทบุคลากร</span>}
                                            onChange={(e) => {
                                                //console.log(e)

                                                this.setState({
                                                    loading: true,
                                                    type_list: [],
                                                    expenditurelevelmain: ''
                                                })
                                                apilogin.post('getdata/excel_export_expenditure/', ({ customers_type: e.value }))
                                                    .then(res => {
                                                        const getdata2 = res.data.map((data, index) => ({
                                                            value: data.expenditure_id,
                                                            label: data.expenditure_name,
                                                        })
                                                        )
                                                        this.setState({ type_list: getdata2, loading: false })
                                                    })

                                                this.setState({
                                                    customers_type_id: e.value,
                                                    customers_type_name: e.label
                                                })

                                            }}
                                            name="customers_type"
                                        // value={this.state.customers_type_id} 
                                        />
                                    </MDBCol>

                                    <MDBCol xl="2" className="mb-1">
                                        {/* <Select
                                            className={"light"}
                                            defaultInputValue={this.state.payslip_month}
                                            options={options_mounth} placeholder={<span><MDBIcon far icon="calendar-alt" /> &nbsp; เลือกเดือน</span>} 
                                            onChange={this.onMouthChange} name="payslip_month" /> */}
                                        <select
                                            className={"form-control " + theme} name="payslip_month"
                                            onChange={this.onMouthChange} value={this.state.payslip_month}
                                        >
                                            <option value="">เลือกเดือน</option>
                                            {options_mounth.map((data) => {
                                                return <option value={data.value}> {data.label}</option>
                                            })}
                                        </select>

                                    </MDBCol>

                                    <MDBCol xl="2" className="mb-1">
                                        {/* <Select
                                        className={"light"}
                                            defaultInputValue={this.state.payslip_year}
                                            options={this.state.options_years} placeholder={<span><MDBIcon icon="chart-line" /> &nbsp; เลือกปี</span>} 
                                            onChange={this.onYearsChange} name="payslip_year" /> */}
                                        <select
                                            className={"form-control " + theme} name="payslip_year"
                                            onChange={this.onYearsChange} value={this.state.payslip_year}
                                        >
                                            <option value="">เลือกปี</option>
                                            {this.state.options_years.map((data) => {
                                                return <option value={data.value}> {data.label}</option>
                                            })}
                                        </select>
                                    </MDBCol>

                                    <MDBCol xl="3" className="mb-1">
                                        <Select
                                            className={"light"}
                                            //value={this.state.expenditurelevelmain}
                                            defaultInputValue={this.state.expenditurelevelmain}
                                            options={this.state.type_list}
                                            placeholder={<span><MDBIcon icon="chart-line" /> &nbsp; เลือกประเภทรายจ่าย</span>}
                                            onChange={this.onExpenditure}
                                            name="expenditurelevelmain"
                                        />

                                    </MDBCol>
                                    <MDBCol xl="2" className="mb-1" style={{ marginTop: '0.0rem' }}>
                                        <Button icon="search" block size="large" type="primary" onClick={this.get_customer_status}>
                                            ค้นหา
                                                 </Button>
                                    </MDBCol>
                                </MDBRow>



                            </div>


                            {this.state.search_check === true ?
                                <>
                                    <MDBContainer>
                                        <div className="text-right mt-4 mb-3">

                                            {this.state.type_id === "1" ? <>
                                                <Bttn_Auto_Social_Security
                                                    get_customer_status={this.get_customer_status}
                                                    payslip_month={this.state.payslip_month}
                                                    payslip_year={this.state.payslip_year}
                                                    customers_type_id={this.state.customers_type_id}
                                                    nametype={this.state.customers_type_name}
                                                    expenditure_id={this.state.expenditurelevelmain}
                                                />
                                            </> : null}

                                            {this.state.type_id === "2" ? <>
                                             
                                              <Bttn_Auto_Fund
                                                    get_customer_status={this.get_customer_status}
                                                    payslip_month={this.state.payslip_month}
                                                    payslip_year={this.state.payslip_year}
                                                    customers_type_id={this.state.customers_type_id}
                                                    nametype={this.state.customers_type_name}
                                                    expenditure_id={this.state.expenditurelevelmain}
                                                />
                                            </> : null}




                                            {/* <PrintPDFexpenditure
                                                dataprint={this.state.customers_type_id}
                                                payslip_month={this.state.payslip_month}
                                                payslip_year={this.state.payslip_year}
                                                expenditure_id={this.state.expenditurelevelmain}
                                                labaltype={this.state.expenditurelevelmain2}
                                                nametype={this.state.customers_type_name} /> */}

                                            ยอดรวมรายการนี้ {this.state.sum} บาท
                                        </div>


                                        <Table dataSource={this.state.data} columns={columns_all}
                                            pagination={{ pageSize: 25 }}
                                            bordered
                                            size="small"
                                            loading={this.state.loading}
                                            rowClassName={theme}
                                            className={"mt-3 time-table-row-select " + theme}
                                        />

                                        {/* <MUIDataTable
                                            data={this.state.data}
                                            columns={columns_mui_all}
                                            options={{
                                                responsive: 'simple',
                                                filterType: 'dropdown',
                                                selectableRows: 'none',
                                                viewColumns: false,
                                                rowsPerPage: 20,
                                                rowsPerPageOptions: [10, 20, 50, 100],
                                                // filter: false,
                                                // pagination: false,
                                                // search: false,
                                                download: false,
                                                print: false,
                                            }}
                                        /> */}


                                    </MDBContainer>



                                </>
                                :
                                <div className="text-center">

                                </div>
                            }




                        </MDBCard>


                    </div>
                </MDBContainer>

                {
                    message.config({
                        top: 60,
                        duration: 1,
                    })
                }


            </div>
        )
    }
}
