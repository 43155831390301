import React, { Component } from 'react'
import { apilogin, theme, mouth, year, detail_user } from '../api'
import { Table, Input, Icon, Card, Button, InputNumber, message, Tabs, notification } from 'antd'
import { MDBContainer, MDBCardBody, MDBCardHeader, MDBCard, MDBRow, MDBCol, MDBIcon } from 'mdbreact'
import Highlighter from 'react-highlight-words';
import Bttn_customer_add_expenditure from '../Components/Bttn_customer_add_expenditure';
import NumberFormat from 'react-number-format';
import Select from 'react-select'
import Bttn_customer_add_revenue from '../Components/Bttn_customer_add_revenue';
import PrintPDF from './PrintPDF';
import Bttn_customer_expendituretype from '../Components/Bttn_customer_expendituretype';
import PrintPDFexpenditure from './PrintPDFexpenditure';
import Bttn_Auto_Social_Security from '../Components/Bttn_Auto_Social_Security';
import PrintPDFexpenditureGroup from './PrintPDFexpenditureGroup';
import ReactExport from "react-export-excel";


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const { TabPane } = Tabs;

export default class PaySlipExpensetypeReport extends Component {

    constructor(props) {
        super(props)
        this.state = {
            options_years: [],
            type_list: [],
            data: [],
            datatypeid1: [],
            datatypeid5: [],
            datatypeid2: [],
            loading: false,
            search: '',

            search_check1: false,
            search_check5: false,
            search_check2: false,
            expenditurelevelmain: '',
            expenditurelevelmain2: '',
            onExpenditureidlevel2: '1',

            expenditurelevelmainlabel: '',
            expenditurelevelmainlabel3: '',

            customers_type_id: '',
            payslip_month: '',
            payslip_year: '',
            expenditurelevelmain: '',

            // customers_type_id: '1',
            // payslip_month: '12',
            // payslip_year: '2020',
            // expenditurelevelmain:'83' ,

            customers_type_name: '',

            customer_type: [],
            customer_type_1: [],
            customer_type_2: [],
            type_id: '',

        }
        this.get_customer_status = this.get_customer_status.bind(this)
        this.updatedata = this.updatedata.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onMouthChange = this.onMouthChange.bind(this)
        this.onYearsChange = this.onYearsChange.bind(this)
        this.onExpenditure = this.onExpenditure.bind(this)

    }

    onChange(event) {
        console.log(event)
        // this.setState({ [event.target.name]: event.target.value })
    }
    onMouthChange(event) {
        // this.setState({ payslip_month: event.value })
        this.setState({ payslip_month: event.target.value })
    }
    onYearsChange(event) {
        //  this.setState({ payslip_year: event.value })
        this.setState({ payslip_year: event.target.value })
    }
    onExpenditure(event) {
        this.setState({
            expenditurelevelmain: event.label,
            expenditurelevelmain2: event.label,
            loading: true,
        })
        // setTimeout(() => {
        //     this.get_customer_status();
        // }, 1000);
    }




    get_customer_status() {
        this.setState({ type_id: "" });

        if (this.state.payslip_month === "") {
            message.warning("กรุณาเลือกเดือน")
        }
        else if (this.state.payslip_year === "") {
            message.warning("กรุณาเลือกปี")
        }
        else if (this.state.expenditurelevelmain === "") {
            message.warning("กรุณาเลือกประเภท")
        }
        else {
            this.setState({ loading: true })
            let data = {
                payslip_year: this.state.payslip_year,
                payslip_month: this.state.payslip_month,
                customers_type_id: this.state.customers_type_id,
                expenditurelevelmain: this.state.expenditurelevelmain,
            }
            // console.log(data)
            apilogin.post('getdata/PaySlipExpensetype_id_group', data)
                .then(res => {
                    this.updatedata(res.data)
                })
        }


    }



    updatedata(data) {
        const getdata = data.map((data, index) => ({
            key: data.customers_citizent,
            index: index + 1,
            customers_citizent: data.customers_citizent,
            customers_pname: data.customers_pname,
            customers_name: data.customers_name + " " + data.customers_lname,
            customers_lname: data.customers_lname,
            customer_type_name: data.customer_type_name,
            customer_type_id: data.customer_type_id,
            total_in: data.total_in,
            total_out: data.total_out,
            total: data.total,
            // payslip_expenditure: data.payslip_expenditure,
            // payslip_total: (data.payslip_revenue + data.Salary) - data.payslip_expenditure,
            // bank_name: data.bank_name,
        })
        )
        if (getdata.length >= 1) {
            this.setState({
                data: getdata,
                search_check: true,
                loading: false,
            })
        }
        else {
            message.warning("ไม่พบข้อมูล")
        }


    }


    componentDidMount() {
        this.setState({
            payslip_month: mouth,
            payslip_year: year,
        })
        
        this.setState({ loading: true })

        apilogin.get('getdata/getyear')
            .then(res => {
                let year = []
                res.data.map((data) => {
                    let yy = {
                        value: data.name_year,
                        label: data.name_year_th,
                    }
                    year.push(yy)
                })
                this.setState({ options_years: year })
            })
            .catch((err) => { notification.error({ message: 'โหลดรายการปีไม่สำเร็จ ' + err, }) })


        if (detail_user.nameuser !== "") {
            this.setState({ nameuser: detail_user.nameuser })
            this.setState({ admin_type: detail_user.admin_type })
            apilogin.get('getdata/customer_type')
                .then(res => {

                    let customer_type = []
                    let customer_type_1 = []
                    let customer_type_2 = []

                    let cc = []
                    res.data.map((data) => {
                        let yy = {
                            value: data.customer_type_id,
                            label: data.customer_type_name,
                            admin_level: data.admin_level,
                        }
                        cc.push(yy)
                    })


                    cc.map((data) => {
                        customer_type.push(data)
                        if (data.admin_level === "1") {
                            customer_type_1.push(data)
                        }
                        else if (data.admin_level === "2") {
                            customer_type_2.push(data)
                        }
                    })

                    this.setState({
                        customer_type: customer_type,
                        customer_type_1: customer_type_1,
                        customer_type_2: customer_type_2,
                    })

                })
        }


    }




    render() {

        const options_mounth = [
            { value: '01', label: 'มกราคม' },
            { value: '02', label: 'กุมภาพันธ์' },
            { value: '03', label: 'มีนาคม' },
            { value: '04', label: 'เมษายน' },
            { value: '05', label: 'พฤษภาคม' },
            { value: '06', label: 'มิถุนายน' },
            { value: '07', label: 'กรกฎาคม' },
            { value: '08', label: 'สิงหาคม' },
            { value: '09', label: 'กันยายน' },
            { value: '10', label: 'ตุลาคม' },
            { value: '11', label: 'พฤศจิกายน' },
            { value: '12', label: 'ธันวาคม' },

        ]

        return (
            <div>
                <MDBContainer className="mt-5 mb-5">
                    <div className="d-flex justify-content-center mt-5 text-center">
                        <MDBCard style={{ width: "72rem", minHeight: "40rem" }} className={theme} >
                            <MDBCardHeader className={"form-header rgba-indigo-light rounded -hoverable " + theme} >
                                <h2 className={theme} style={{ textAlign: 'center', fontWeight: 'bold' }} >
                                    <Icon type="container" />  รายงานรายจ่ายแยกประเภท
                                </h2>
                            </MDBCardHeader>


                            <div className="container mt-5" >

                                <MDBRow className="text-left">
                                    <MDBCol xl="3" className="mb-1 text-left">

                                        <Select
                                            className={"light"}
                                            isMulti
                                            options={this.state.admin_type === "super" ? this.state.customer_type :
                                                this.state.admin_type === "adminlevel1" ? this.state.customer_type_1 :
                                                    this.state.admin_type === "adminlevel2" ? this.state.customer_type_2 : null}
                                            placeholder={<span><MDBIcon icon="users" /> &nbsp; เลือกประเภทบุคลากร</span>}
                                            onChange={(e) => {
                                                //console.log(e)

                                                this.setState({
                                                    loading: true,
                                                    type_list: [],
                                                    expenditurelevelmain: ''
                                                })

                                                apilogin.post('getdata/excel_export_expenditure_group/', ({ customers_type: e }))
                                                    .then(res => {
                                                        const getdata2 = res.data.map((data, index) => ({
                                                            value: data.expenditure_name,
                                                            label: data.expenditure_name,
                                                        })
                                                        )
                                                        this.setState({ type_list: getdata2, loading: false })
                                                    })



                                                let name = ""
                                                let listname = e
                                                for (let i = 0; i < listname.length; i++) {
                                                    name = name + listname[i].label
                                                    if (i !== listname.length - 1) {
                                                        name = name + ", "
                                                    }

                                                }

                                                this.setState({
                                                    customers_type_id: e,
                                                    customers_type_name: name
                                                })
                                                // this.setState({
                                                //     customers_type_id: e,
                                                //     customers_type_name: e
                                                // })


                                            }} name="customers_type" />


                                    </MDBCol>

                                    <MDBCol xl="2" className="mb-1">
                                        <select
                                            className={"form-control " + theme} name="payslip_month"
                                            onChange={this.onMouthChange} value={this.state.payslip_month}
                                        >
                                            <option value="">เลือกเดือน</option>
                                            {options_mounth.map((data) => {
                                                return <option value={data.value}> {data.label}</option>
                                            })}
                                        </select>

                                    </MDBCol>

                                    <MDBCol xl="2" className="mb-1">
                                        <select
                                            className={"form-control " + theme} name="payslip_year"
                                            onChange={this.onYearsChange} value={this.state.payslip_year}
                                        >
                                            <option value="">เลือกปี</option>
                                            {this.state.options_years.map((data) => {
                                                return <option value={data.value}> {data.label}</option>
                                            })}
                                        </select>
                                    </MDBCol>

                                    <MDBCol xl="3" className="mb-1">
                                        <Select
                                            className={"light"}
                                            defaultInputValue={this.state.expenditurelevelmain}
                                            options={this.state.type_list}
                                            placeholder={<span><MDBIcon icon="chart-line" /> &nbsp; เลือกประเภทรายจ่าย</span>}
                                            onChange={this.onExpenditure}
                                            name="expenditurelevelmain"
                                        />

                                    </MDBCol>
                                    <MDBCol xl="2" className="mb-1" style={{ marginTop: '0.0rem' }}>
                                        <Button icon="search" block size="large" type="primary" onClick={this.get_customer_status}>
                                            ค้นหา
                                                 </Button>
                                    </MDBCol>
                                </MDBRow>



                            </div>


                            {this.state.search_check === true ?
                                <>
                            <MDBContainer>
                                <div className="text-center mt-5 mb-3">


                                    <PrintPDFexpenditureGroup
                                        data={this.state.data}
                                        dataprint={this.state.customers_type_id}
                                        payslip_month={this.state.payslip_month}
                                        payslip_year={this.state.payslip_year}
                                        expenditure_id={this.state.expenditurelevelmain}
                                        labaltype={this.state.expenditurelevelmain2}
                                        nametype={this.state.customers_type_name} />

                                    {/* <Button type="primary" style={{ height: '100%' }} size="large" onClick={this.loaddata}>
                                        <Icon type="file-excel" />
                                        <br />Excel
                                        <br />ใบสรุปยอด
                                    </Button> */}


                               
                                     
                                        <ExcelFile filename={"ใบสรุปยอด "+this.state.expenditurelevelmain2+"-"+this.state.payslip_year+"-"+this.state.payslip_month} element={<Button type="primary" style={{ height: '100%' }} size="large" onClick={this.loaddata}>
                                            <Icon type="file-excel" />
                                            <br />Excel
                                        <br />ใบสรุปยอด
                                       </Button>}>
                                            <ExcelSheet data={this.state.data} name="สรุปรายชื่อ KTB">
                                                <ExcelColumn label="รหัสประจำตัวประชาชน" value="customers_citizent" />
                                                <ExcelColumn label="คำนำหน้า" value="customers_pname" />
                                                <ExcelColumn label="ชื่อ-นามสกุล" value="customers_name" />
                                                <ExcelColumn label="ประเภทบุคลากร" value="customer_type_name" />
                                                <ExcelColumn label="จ่ายใน" value="total_in" />
                                                <ExcelColumn label="จ่านนอก" value="total_out" />
                                            </ExcelSheet>
                                        </ExcelFile>

                                  
                                </div>

                            </MDBContainer>



                            </>
                                :
                                <div className="text-center">

                                </div>
                            }




                        </MDBCard>


                    </div>
                </MDBContainer>

                {
                    message.config({
                        top: 60,
                        duration: 1,
                    })
                }


            </div>
        )
    }
}
