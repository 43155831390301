import React, { Component } from 'react'
import { apilogin, theme, options_mounth, detail_user, mouth, year } from '../api'
import { Table, Input, Icon, Card, Button, Tabs, Modal, notification, Spin } from 'antd'
import { MDBContainer, MDBCardBody, MDBCardHeader, MDBCard, MDBIcon } from 'mdbreact'
import { Checkbox } from 'antd'



export default class PageShowListSalaryEdit extends Component {

    state = {
        loading: false,
        search: '',
        show: [],
    }



    onCheck = (c) => e => {
        let data_update = {
            year: c.show_year,
            month: c.show_month,
            status: e.target.checked === true ? '1' : '0'
        }
        apilogin.post('getdata/update_get_show_data', data_update)
            .then(res2 => {
                this.get_show()
            })
    }

    get_show() {
        this.setState({ loading: true })
        apilogin.get('getdata/get_show_all')
            .then(res2 => {
                this.setState({ show: res2.data })
                this.setState({ loading: false })
            })
    }

    componentDidMount() {
        this.get_show()
        this.checkmonth()
    }

    checkmonth = () => {
        let datasent = {
            mouth: mouth,
            year: year
        }
        apilogin.post('getdata/checkmonth', datasent)
            .then((res) => {
                if (res.data === "insert") {
                    this.setState({ show: [] })
                    notification.success({
                        message: 'เพิ่มรายการเดือนใหม่แล้ว',
                    })
                    this.get_show()

                }
            })
    }


    render() {

        const columns = [
            {
                title: 'ปี',
                dataIndex: 'show_year',
                key: 'show_year',
                width: 60,
                className: theme,
                render: text => <div className="text-center">{Number(text) + 543}</div>,
            },
            {
                title: 'เดือน',
                dataIndex: 'show_month',
                key: 'show_month',
                width: 60,
                className: theme,
                render: text => <div className="text-center">{options_mounth.map((data) => text === data.value ? data.label : "")} </div>,
            },
            {
                title: 'สถานะ',
                dataIndex: 'show_status',
                key: 'show_status',
                width: 60,
                className: theme,
                render: (text, record) => (
                    <div className="text-center">
                        <Checkbox
                            defaultChecked={record.show_status === "1" ? true : false}
                            name={record.show_status}
                            onChange={this.onCheck(record)}
                        >
                        </Checkbox>

                    </div>
                ),
            },




        ]

        return (
            <>
                <MDBContainer className="mt-5 mb-5">
                    <div className="d-flex justify-content-center mt-5 text-center">
                        <MDBCard style={{ width: "72rem", minHeight: "40rem" }} className={theme} >
                            <MDBCardHeader className={"form-header rgba-indigo-light rounded -hoverable " + theme} >
                                <h2 className={theme} style={{ textAlign: 'center', fontWeight: 'bold' }} >
                                    <Icon type="file-text" /> เปิด-ปิด รายการเงินเดือนในแต่ละเดือน
                                </h2>
                            </MDBCardHeader>

                            <MDBCardBody className="bodytable text-left">

                                <Table dataSource={this.state.show} columns={columns}
                                    pagination={{ pageSize: 25 }}
                                    bordered
                                    size="small"
                                    loading={this.state.loading}
                                    rowClassName={theme}
                                    className={"mt-3 time-table-row-select " + theme}
                                />
                                <div className="text-right">* หมายเหตุ ถ้าเข้าเดือนใหม่ เมื่อโหลดหน้านี้ ระบบจะเพิ่มเดือนให้อัตโนมัติ</div>
                            </MDBCardBody>

                        </MDBCard>
                    </div>
                </MDBContainer>



            </>
        )
    }
}
