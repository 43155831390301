import React, { Component } from 'react'
import { Dialog, DialogTitle, IconButton, DialogContent, DialogActions, Button, TextField } from '@material-ui/core';
import { theme, apilogin, detail_user, admin1, admin2, adminSuper } from '../api';
import { Upload, message, Popconfirm, notification, Spin, Icon } from 'antd';
import Bttn_upload_file from './Bttn_upload_file';
import MUIDataTable from "mui-datatables";
import Bttn_view_pdf from './Bttn_view_pdf';
import { MDBIcon } from 'mdbreact';
import DeleteIcon from '@material-ui/icons/Delete';
import Toast from 'light-toast';
import { Lottie_empty } from './MyLottie';

export default class Bttn_upload_file_main extends Component {
    state = {
        data: [],
        modal: false,
        load: true,
    }

    onshowmodal = () => {
        this.setState({ modal: !this.state.modal })


        if (this.state.modal === false) {
            this.getdata()

        }
    }

    getdata = () => {
        Toast.loading('Loading..')
        this.setState({ load: true })

        let datasent = {
            customers_citizent: this.props.customers_citizent,
            customer_type: this.props.customer_type,
            payslip_month: this.props.payslip_month,
            payslip_year: this.props.payslip_year,
        }

        apilogin.post('getdata/getpdffileuser', datasent)
            .then((res) => {
                this.setState({ data: res.data })
                this.setState({ load: false })
                setTimeout(() => {
                    Toast.hide();
                }, 200);
            })
    }


    render() {

        const columns = [


            {
                name: "file_name",
                label: <div className="text-center" >ชื่อเอกสาร</div>,
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        let i = tableMeta.rowIndex
                        return (
                            <>
                                <div className="text-center">
                                    {value}
                                </div>
                            </>
                        );
                    }
                }
            },


            {
                name: "payslip_total",
                label: <div className="text-center" >ไฟล์เอกสาร</div>,
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        let i = tableMeta.rowIndex
                        return (
                            <>
                                <div className="text-center">
                                    <Bttn_view_pdf

                                        name={this.state.data[i].file_name}
                                        data={this.state.data[i].file_base64}

                                    />

                                    <a
                                        download={this.state.data[i].file_name + "-" + this.props.customers_name.replace(" ", "-") + "-" + this.props.payslip_month + "-" + this.props.payslip_year}
                                        href={this.state.data[i].file_base64}
                                    >
                                        <Button variant="outlined" >Download</Button>

                                    </a>


                                    {detail_user.admin_type === admin1 || detail_user.admin_type === admin2 || detail_user.admin_type === adminSuper ?
                                        <Popconfirm
                                            title="คุณแน่ใจใช่ไหมที่จะลบข้อมูลนี้?"
                                            onConfirm={() => {
                                                let datasend = {
                                                    file_id: this.state.data[i].file_id,
                                                }
                                                apilogin.post('getdata/delete_uploadpdffile', datasend)
                                                    .then(res => {
                                                        this.getdata()
                                                        message.success('ลบสำเร็จ')
                                                    })
                                                    .catch((res) => {
                                                        message.error('ลบไม่สำเร็จ')
                                                    })
                                            }}
                                            // onCancel={cancel}
                                            okText="ใช่"
                                            cancelText="ไม่"
                                        >
                                            <Button variant="outlined"><DeleteIcon /></Button>
                                        </Popconfirm>
                                        : null}




                                </div>
                            </>
                        );
                    }
                }
            },
        ];


        return (
            <>
                <Button
                    size="small"
                    onClick={this.onshowmodal}
                    variant="outlined"
                // endIcon={<InfoIcon />}
                >
                    <MDBIcon far icon="file-pdf" />&nbsp;ไฟล์เอกสารอื่นๆ
                </Button>


                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"} //xs
                    open={this.state.modal}
                    onClose={this.onshowmodal}
                    aria-labelledby="max-width-dialog-title"
                >
                    <div className={" d-flex justify-content-between font-" + theme}>
                        <DialogTitle id="alert-dialog-title">{<text className={"font-" + theme}>
                            {this.props.user === true ? "ไฟล์เอกสารอื่นๆ" : "อัพโหลดไฟล์เอกสาร"}

                        </text>}</DialogTitle>
                        <IconButton className={"font-" + theme} onClick={this.onshowmodal} color="primary" >x </IconButton>
                    </div>

                    <DialogContent dividers >


                        {detail_user.admin_type === admin1 || detail_user.admin_type === admin2 || detail_user.admin_type === adminSuper ?
                            <>
                                <Bttn_upload_file

                                    getdata={this.getdata}
                                    customers_citizent={this.props.customers_citizent}
                                    customer_type={this.props.customer_type}
                                    payslip_month={this.props.payslip_month}
                                    payslip_year={this.props.payslip_year}
                                />
                                <br />
                            </> : null}

                        {/* {this.state.load === true ? <>
                            <div className="text-center" >
                                <Spin size="large" indicator={<Icon type="loading" />} />
                            </div>
                        </> : <> */}

                        {this.state.data.length === 0 && this.state.load === false ?
                            <>
                                <div className="text-center mb-3" >
                                    <Lottie_empty />
                                    ไม่พบไฟล์เอกสาร
                                </div>
                            </> : <>
                                {this.state.load === true ? <>
                                    <div className="text-center" >
                                        {/* <Spin size="large" indicator={<Icon type="loading" />} /> */}
                                        กำลังโหลด..
                                    </div>
                                </> : <>
                                        <MUIDataTable
                                            data={this.state.data}
                                            columns={columns}

                                            options={{
                                                textLabels: {
                                                    body: {
                                                        noMatch: "ไม่พบไฟล์เอกสารอื่นๆ",
                                                    },
                                                },
                                                filterType: 'dropdown',
                                                selectableRows: 'none',
                                                viewColumns: false,
                                                filter: false,
                                                pagination: false,
                                                search: false,
                                                download: false,
                                                print: false,
                                            }}
                                        />
                                    </>}


                            </>
                        }
                        {/* </>} */}


                    </DialogContent>

                </Dialog>


            </>
        )
    }
}
