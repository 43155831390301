import React, { Component } from 'react'
import { Modal, Button, Input, InputNumber, notification } from 'antd';
import { apilogin } from '../api';
import Text from 'antd/lib/typography/Text';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import Select from 'react-select'

 
export default class Bttn_customer_salary_add extends Component {
    constructor(props) {
        super(props)
        this.state = {
            options_years: [],
            visible: false,
            checkdata: false,
            customers_citizent: "",
            history_salary_month: "",
            history_salary_year: "",
            history_salary_salary: "",
        }
        this.onChange = this.onChange.bind(this)
        this.onMouthChange = this.onMouthChange.bind(this)
        this.onYearsChange = this.onYearsChange.bind(this)
    }

    onMouthChange(event) { this.setState({ history_salary_month: event.value }) }
    onYearsChange(event) { this.setState({ history_salary_year: event.value }) }

    showModal = () => {
        this.setState({
            visible: true,
        });
        apilogin.get('getdata/getyear')
            .then(res => {
                let year = []
                res.data.map((data) => {
                    let yy = {
                        value: data.name_year,
                        label: data.name_year_th,
                    }
                    year.push(yy)
                })
                this.setState({ options_years: year })
            })
            .catch((err) => { notification.error({ message: 'โหลดรายการปีไม่สำเร็จ ' + err, }) })
    };

    handleOk = e => {
        //console.log(this.props.customers_type_id)
        //alert(JSON.stringify(this.state))
        apilogin.post('insert_customers/insert_salary', ({
            customers_citizent: this.props.customers_citizent,
            history_salary_month: this.state.history_salary_month,
            history_salary_year: this.state.history_salary_year,
            history_salary_salary: this.state.history_salary_salary,
            customers_type_id: this.props.customers_type_id,
        }))
            .then(res => {
                this.props.updatedata_props()
                setTimeout(() => {
                    this.props.updatesalary()
                }, 200);

            });
        this.setState({
            visible: false,
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };



    onChange(event) {
        this.setState({
            history_salary_salary: event
        })
    }

    render() {

        const options_mounth = [
            { value: '01', label: 'มกราคม' },
            { value: '02', label: 'กุมภาพันธ์' },
            { value: '03', label: 'มีนาคม' },
            { value: '04', label: 'เมษายน' },
            { value: '05', label: 'พฤษภาคม' },
            { value: '06', label: 'มิถุนายน' },
            { value: '07', label: 'กรกฎาคม' },
            { value: '08', label: 'สิงหาคม' },
            { value: '09', label: 'กันยายน' },
            { value: '10', label: 'ตุลาคม' },
            { value: '11', label: 'พฤศจิกายน' },
            { value: '12', label: 'ธันวาคม' },

        ]

        const options_years = [
            { value: '2019', label: 'ปี 2562' },
            { value: '2020', label: 'ปี 2563' },
        ]
        return (
            <>
                <Button icon="plus-square" size="default" onClick={this.showModal}>
                    เพิ่มเงินเดือน
                </Button>
                <Modal
                    centered
                    // style={{ right: -300 }}
                    title={<><strong>เพิ่มเงินเดือน</strong> </>}
                    visible={this.state.visible}
                    okText="บันทึก"
                    cancelText="ยกเลิก"
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >



                    <MDBRow >
                        <MDBCol sm="12" className="mt-0" >
                            <Text strong style={{ textAlign: 'center', fontSize: '16px' }}> เดือนปัจจุบัน </Text>
                            <Select options={options_mounth} placeholder={<span><MDBIcon far icon="calendar-alt" /> &nbsp; เลือกเดือน</span>} onChange={this.onMouthChange} name="payslip_month" />

                        </MDBCol>
                        <MDBCol sm="12" className="mt-2" >
                            <Text strong style={{ textAlign: 'center', fontSize: '16px' }}> ปีปัจจุบัน  </Text>
                            <Select
                                options={this.state.options_years}
                                placeholder={<span><MDBIcon icon="chart-line" /> &nbsp; เลือกปี</span>} onChange={this.onYearsChange} name="payslip_year" />

                        </MDBCol>
                    </MDBRow>

                    <MDBRow className="mt-5" className="text-center">
                        <MDBCol sm="12" className="mt-4" >
                            <Text strong style={{ textAlign: 'center', fontSize: '16px' }}> เงินเดือนล่าสุด </Text>
                            <InputNumber
                                placeholder="จำนวน"
                                // defaultValue={data.revenue_price}
                                onChange={this.onChange}
                                min={0} max={500000}
                            //step="0.1"
                            />
                            <Text strong style={{ textAlign: 'center', fontSize: '16px' }}> บาท </Text>
                        </MDBCol>

                    </MDBRow>


                </Modal>
            </>
        )
    }
}
