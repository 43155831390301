import React, { Component } from 'react'
import { Modal,  Input, message, InputNumber, Icon, Tabs } from 'antd';
import { apilogin } from '../api';
import Text from 'antd/lib/typography/Text';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import { BrowserRouter as Link } from "react-router-dom";
import { Button } from '@material-ui/core';

export default class Bttn_customer_revenuetype extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            list_revenue_price: [],
            list_expenditure: [],
            list: [],
            value: [],
            revenue_id: null,
            revenue_price: null,
        }
        this.onChange = this.onChange.bind(this)
        this.updateList = this.updateList.bind(this)
        this.goto = this.goto.bind(this)
    }

    showModal = () => {
        this.setState({ visible: true })
        this.postid()

    };

    handleOk = e => {
        // alert(JSON.stringify(this.props.customers_citizent))
        let datasent = {
            payslip_year: this.props.data.payslip_year,
            payslip_month: this.props.data.payslip_month,
            payslip_citizent: this.props.customers_citizent,
            list_revenue_price: this.state.list_revenue_price,
            customer_type_id: this.props.customer_type_id,
            // payslip_revenue: 0,
            payslip_expenditure: 0,

        }
        //console.log(datasent)
        apilogin.post('insert_salary/insert_list_slip_revenue', datasent)
            .then(res => {
                // console.log(res.data)
                if (res.data === true) {
                    this.setState({ visible: false });
                    this.props.updatedata_props()
                }
                else { message.error("บันทึกไม่สำเร็จ") }
            });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    postid() {
        this.setState({
            list_expenditure: [],
            list_revenue_price: [],
            loading: true
        })
        apilogin.post('insert_salary/get_list_revenue', ({
            customer_type_id: this.props.customer_type_id,
            payslip_year: this.props.data.payslip_year,
            payslip_month: this.props.data.payslip_month,
            payslip_citizent: this.props.customers_citizent,
            revenue_id: this.props.data.expenditurelevelmain
        }))
            .then(res => {

                if (res.data.length >= 1) {
                    //   console.log("dfdf")
                    const getdata = res.data.map((data, index) => ({
                        key: data.revenue_id,
                        index: index + 1,
                        revenue_id: data.revenue_id,
                        revenue_name: data.revenue_name,
                        revenue_price: Number(data.payslip_total).toFixed(2),
                    }))
                    this.setState({ list_expenditure: getdata })
                }
                this.setState({ loading: false })
            })
    }

    onChange = name => value => {
        // console.log(name, value)
        this.setState({
            revenue_id: name,
            revenue_price: value
        })
    }

    updateList(e) {

        const lists = this.state.list_revenue_price;
        var crck = false;
        if (lists.length !== 0) {
            const lists = this.state.list_revenue_price;
            lists.map((values, index) => {
                if (values.revenue_id === this.state.revenue_id) {
                    crck = !crck;
                    let items = this.state.list_revenue_price;
                    let item = { ...items[index] };
                    item.revenue_price = this.state.revenue_price;
                    items[index] = item;
                    this.setState({ list_revenue_price: items });
                }
            })

            if (!crck) {
                lists.push({
                    revenue_id: this.state.revenue_id,
                    revenue_price: this.state.revenue_price
                });
                this.setState({ list_revenue_price: lists })
            }

        } else {
            const lists = this.state.list_revenue_price;
            lists.push({
                revenue_id: this.state.revenue_id,
                revenue_price: this.state.revenue_price
            });
            this.setState({ list_revenue_price: lists })
        }
        this.setState({ loading: false })
    }
    goto() {
        try {
            this.props.pop.history.replace({ pathname: '/admin/payslip_list_manage' });
        } catch (error) {
            window.location.href = "/admin/payslip_list_manage"
        }
    }

    render() {
        return (
            <>
                <Button variant="outlined" size="small" onClick={this.showModal}>
                    <MDBIcon icon="dollar-sign" /> &nbsp; เพิ่มรายรับ
                </Button>
                <Modal
                    centered
                    title={<><strong> <Icon type="plus-square" />  เพิ่มรายรับ</strong> </>}
                    visible={this.state.visible}
                    okText="บันทึก"
                    cancelText="ยกเลิก"
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    {this.state.loading === true ? <div className="text-center">
                        กำลังโหลดรายการ...
                        </div> : <>
                            {this.state.list_expenditure.map((data) => {
                                return <>
                                    <div className="mt-2">
                                        <MDBRow>
                                            <MDBCol sm="1" style={{ paddingTop: '0.4rem' }}> </MDBCol>
                                            <MDBCol sm="6" style={{ paddingTop: '0.0rem' }}>
                                                <Text strong style={{ textAlign: 'center', fontSize: '16px' }}> {data.revenue_name} </Text>
                                            </MDBCol>
                                            <MDBCol sm="3">
                                                <InputNumber
                                                    defaultValue={data.revenue_price}
                                                    // defaultValue={Number(data.revenue_price).toLocaleString(navigator.language, { minimumFractionDigits: 2,maximumFractionDigits: 2 })} 
                                                    onChange={this.onChange(data.revenue_id)}
                                                    onBlur={this.updateList}
                                                    min={0} max={500000}
                                                //step="0.1"
                                                />

                                            </MDBCol>
                                            <MDBCol sm="2" style={{ paddingTop: '0.0rem' }}>
                                                <Text strong style={{ textAlign: 'center', fontSize: '16px' }}> บาท </Text>
                                            </MDBCol>
                                        </MDBRow>
                                    </div>

                                </>
                            })}
                            {this.state.list_expenditure.length === 0 ? <>
                                <div className="mt-2 text-center">
                                    <Text strong style={{ fontSize: '16px' }}> ไม่มีรายการรายจ่าย <br />
                                        <Button size="small" color="primary" onClick={this.goto} ><Icon type="arrow-right" /> ไปหน้าเพิ่มรายการ</Button>
                                    </Text>
                                </div>
                            </> : null}
                        </>}
                </Modal>
            </>
        )
    }
}
