import React, { Component } from 'react'
import { apilogin, theme, detail_user, options_mounth } from '../api'
import { Table, Input, Icon, Card, Button, InputNumber, message, Spin, Alert } from 'antd'
import { MDBContainer, MDBCardBody, MDBCardHeader, MDBCard, MDBRow, MDBCol, MDBIcon, MDBBox, MDBAlert } from 'mdbreact'
import Highlighter from 'react-highlight-words';
import Bttn_customer_add_expenditure from '../Components/Bttn_customer_add_expenditure';
import NumberFormat from 'react-number-format';
import Select from 'react-select'
import Bttn_customer_add_revenue from '../Components/Bttn_customer_add_revenue';
import PrintPDF from './PrintPDF';
import moment from 'moment';
import Axios from 'axios';
import Avatar from 'react-avatar';
import person from '../img/person.png';
import ReactImageFallback from "react-image-fallback";
import { Helmet } from "react-helmet";
import List, { ListItem, ListItemGraphic, ListItemText, ListItemMeta } from '@material/react-list';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import jwtDecode from 'jwt-decode'

import '@material/react-list/dist/list.css';
import Search from 'antd/lib/transfer/search';



export default class PageListHistoryPrintAdmin extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            datafound: null,
            loading: true,
            search: '',
        }
        this.updateSearch = this.updateSearch.bind(this)
    }





    updateSearch(event) {
        this.setState({ search: event.target.value.substr(0, 20) });
    }



    componentDidMount() {
      
        apilogin.get('getdata/historyprintforadmincheck')
            .then((res) => {
                let data = res.data
                if (data.length >= 1) {
                    this.setState({
                        data: data,
                        datafound: true,
                        loading: false
                    })

                } else {
                    this.setState({
                        datafound: false,
                        loading: false
                    })
                }
                // console.log(res.data)
            })

    }


    render() {

        let filteredPage = this.state.data.filter(
            (data) => {
                return (data.print_file_name.indexOf(this.state.search) !== -1)
                +(data.print_by.indexOf(this.state.search) !== -1)
                +(data.print_date.indexOf(this.state.search) !== -1)
            }
        )



        const columns = [
            {
                title: 'สลิปปี',
                dataIndex: 'payslip_year',
                key: 'payslip_year',
                className: theme,
                render: (text, record) => <div className="text-center">{Number(record.print_payslip_year) + 543} </div>,

            },
            {
                title: 'สลิปเดือน',
                dataIndex: 'payslip_year',
                key: 'payslip_year',
                className: theme,
                render: (text, record) => <div className="text-center">
                    {options_mounth.map((m) => {
                        if (m.value === String(record.print_payslip_month)) {
                            return m.label
                        }
                    })}
                </div>,
            },
            {
                title: 'เลขอ้างอิง',
                dataIndex: 'print_file_name',
                key: 'print_file_name',
                className: theme,
                render: (text, record) => <div className="text-center">{record.print_file_name} </div>,

            },
            {
                title: 'วัน-เวลาที่พิมพ์',
                dataIndex: 'payslip_year',
                key: 'payslip_year',
                className: theme,
                render: (text, record) => <div className="text-center">{record.print_date} {record.print_time}</div>,

            },
            {
                title: 'พิมพ์โดย',
                dataIndex: 'print_by',
                key: 'print_by',
                className: theme,
                render: (text, record) => <div className="text-center">{record.print_by}</div>,

            },
            {
                title: 'ใบเงินเดือน',
                dataIndex: 'payslip_year',
                key: 'payslip_year',
                className: theme,
                render: (text, record) => <div className="text-center">
                    <a className={theme} target="_blank" href={"https://e-payslip.kpru.ac.th/ref-slip/" + record.print_file_name + ".pdf"} ><MDBIcon icon="file-pdf" /> PDF</a>
                </div>,

            },
        ]

        return (
            <div>

                <Helmet>
                    <meta charSet="utf-8" />
                    <title>ประวัติการพิมพ์ใบสลิปเงินเดือน KPRU</title>
                    <meta name="description" content="ประวัติการพิมพ์ใบสลิปเงินเดือน KPRU" />
                    <link rel="canonical" href="https://e-payslip.kpru.ac.th/PageListHistoryPrint" />
                </Helmet>

                <MDBContainer className="mt-5 mb-5 animated fadeIn">
                    <div className="d-flex justify-content-center mt-5 text-center">
                        <MDBCard style={{ width: "72rem", minHeight: "40rem" }} className={theme} >
                            <MDBCardHeader className={"mt-5  mb-5 form-header rgba-indigo-light rounded -hoverable " + theme} >
                                {/* rgba-indigo-light */}
                                <h2 className={theme} style={{ textAlign: 'center', fontWeight: 'bold' }} >
                                    <MDBIcon icon="print" /> ประวัติการพิมพ์ใบสลิปเงินเดือน
                                </h2>
                            </MDBCardHeader>



                            <MDBContainer>
                                <div className="mt-2 mb-3">
                                    <MDBRow>
                                        <MDBCol md="6"></MDBCol>
                                        {/* <Icon type="search" /> */}
                                        <MDBCol md="6">  <input
                                            // className="shadow-box-example hoverable"
                                            className="form-control"
                                            size="small"
                                            placeholder={"ค้นหาเลขอ้างอิง, ชื่อผู้พิมพ์, วันที่พิมพ์"}
                                            value={this.state.search}
                                            onChange={this.updateSearch.bind(this)}
                                        /></MDBCol>
                                    </MDBRow>

                                </div>

                                <Table className="mt-0" dataSource={filteredPage} columns={columns}
                                    scroll={{ x: 700 }}
                                    pagination={{ pageSize: 125 }}
                                    bordered
                                    size="small"
                                    loading={this.state.loading}
                                    rowClassName={theme}
                                    className={"mt-3 time-table-row-select " + theme}
                                />
                            </MDBContainer>



                        </MDBCard>
                    </div>
                </MDBContainer>


            </div>
        )
    }
}
