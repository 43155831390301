import React, { Component } from 'react'
import { apilogin, theme, mouth, year, options_mounth, adminSuper, admin1, admin2, detail_user } from '../api'
import { Table, Input, Icon, Card, Button, InputNumber, message, notification, Spin } from 'antd'
import { MDBContainer, MDBCardBody, MDBCardHeader, MDBCard, MDBRow, MDBCol, MDBIcon } from 'mdbreact'
import { Checkbox } from 'antd';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import Select from 'react-select'
export default class PaySlipManageUpdateDataSalary extends Component {

    constructor(props) {
        super(props)
        this.state = {
            insert_option: '1',
            check: [],
            payslip_month_in: '',
            payslip_year_in: '',

            payslip_month_out: '',
            payslip_year_out: '',

            customers_type: [],

            options_years: [],
            customer_type: [],

            list_salary_count: 0,
            loading: false,
            loadupdate: false,


        }
        this.get_data = this.get_data.bind(this)
        this.onChange = this.onChange.bind(this)
        this.ok = this.ok.bind(this)
    }



    ok() {
        if (this.state.customers_type.length >= 1) {
            Swal.fire({
                title: 'ยืนยันการอัพเดต?',
                text: 'คุณแน่ใจใช่ไหมที่จะอัพเดตข้อมูลเดือนนี้',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'ใช่ เริ่มเลย',
                cancelButtonText: 'ยกเลิก'
            }).then((result) => {
                if (result.value) {

                    this.setState({ loadupdate: true })
                    let data = {
                        payslip_month_in: this.state.payslip_month_in,
                        payslip_year_in: this.state.payslip_year_in,
                        payslip_month_out: this.state.payslip_month_out,
                        payslip_year_out: this.state.payslip_year_out,
                        customers_type: this.state.customers_type,
                        insert_option: this.state.insert_option,
                    }
                    apilogin.post('getdata/updatedataformaftermonth_Salary', data)
                        .then((res) => {
                            // console.log(res.data)
                            this.setState({ loadupdate: false })
                            let update = 0
                            if (res.data !== "ok") {
                                update = res.data.affectedRows
                            }
                            Swal.fire(
                                'อัพเดตสำเร็จ!',
                                'ข้อมูลได้ถูกอัพเดตแล้ว ' + update + ' รายการ',
                                'success'
                            )
                        })
                        .catch((res) => {
                            Swal.fire(
                                'อัพเดตล้มเหลว',
                                'กรุณาลองใหม่ หรือติดต่อผู้ดูแลระบบ',
                                'error'
                            )
                            this.setState({ loadupdate: false })
                        })
                }
            })

        }
        else {
            message.info('กรุณาเลือกประเภทบุคลากรก่อน')
        }



    }

   

    get_data() {
        this.setState({ loading: true })
        let data = this.state


        if (data.payslip_month_in !== "" && data.payslip_year_in !== "" && data.payslip_month_out !== "" && data.payslip_year_out !== "" && data.customers_type.length >= 1) {
            let data_send = {
                customers_type: data.customers_type,
                payslip_month_in: data.payslip_month_in,
                payslip_year_in: data.payslip_year_in,
            }
            apilogin.post('getdata/get_salary_forupdatedata', data_send)

                .then(res => {
                    let dd = res.data
                   
                    this.setState({
                        list_salary_count: dd.length,
                        loading_in: false,
                    })
                    this.setState({ loading: false })
                    if(dd.length >= 1){
                         message.success('พบข้อมูล '+dd.length+' รายการ')
                    }
                    else{
                        message.info('ไม่พบรายการ')
                    }
                })
        } else {
            message.warning("กรุณาเลือกให้ครบ")
            this.setState({ loading: false })
            this.setState({
                list_salary_count: 0,
                loading_in: false,
            })
        }


    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }





    componentDidMount() {

        // this.get_customer_status()
        this.setState({
            // customers_type: '4',
            // payslip_month_in: mouth,
            // payslip_month_out: mouth,
            payslip_month_out: mouth,
            payslip_year_in: year,
            payslip_year_out: year,
        })

        if (detail_user.nameuser !== "") {
            this.setState({ nameuser: detail_user.nameuser })
            this.setState({ admin_type: detail_user.admin_type })

            apilogin.get('getdata/customer_type')
                .then(res => {

                    let customer_type = []
                    let customer_type_1 = []
                    let customer_type_2 = []

                    let cc = []
                    res.data.map((data) => {
                        let yy = {
                            value: data.customer_type_id,
                            label: data.customer_type_name,
                            admin_level: data.admin_level,
                        }
                        cc.push(yy)
                    })



                    cc.map((data) => {
                        customer_type.push(data)
                        if (data.admin_level === "1") {
                            customer_type_1.push(data)
                        }
                        else if (data.admin_level === "2") {
                            customer_type_2.push(data)
                        }
                    })

                    let list = []
                    let type = detail_user.admin_type
                    if (type === adminSuper) {
                        list = customer_type
                    } else if (type === admin1) {
                        list = customer_type_1
                    }
                    else if (type === admin2) {
                        list = customer_type_2
                    }
                    this.setState({
                        customer_type: list,
                    })

                })
        }
        else {
            message.error('ไม่พบข้อมูลผู้ใช้ กรุณาล็อคอินใหม่')
        }
        apilogin.get('getdata/getyear')
            .then(res => {
                let year = []
                res.data.map((data) => {
                    let yy = {
                        value: data.name_year,
                        label: "ปี " + data.name_year_th,
                    }
                    year.push(yy)
                })
                this.setState({
                    options_years: year
                });
            })
            .catch((err) => {
                notification.error({
                    message: 'โหลดรายการปีไม่สำเร็จ ' + err,
                })
            })




    }


    render() {



        return (

            <div className="container mt-5 text-center">

                <MDBCard style={{
                    minHeight: "40rem"
                }} className={theme} >
                    <MDBCardHeader className={"mt-2 form-header rgba-indigo-light rounded -hoverable " + theme} >
                        <h2 className={theme} style={{ textAlign: 'center', fontWeight: 'bold' }} >
                            <MDBIcon icon="sync-alt" /> อัพเดตข้อมูลเงินเดือน จากเดือนก่อนหน้า
                         </h2>
                    </MDBCardHeader>

                    <MDBCardBody className="bodytable">

                        <Select
                            className={"text-left light"}
                            isMulti
                            options={this.state.customer_type}
                            placeholder={<span><MDBIcon icon="users" /> &nbsp; เลือกประเภทบุคลากร</span>}
                            onChange={(e) => {
                                if (e) {
                                    if (e.length >= 1) {
                                        this.setState({ customers_type: e })
                                    }
                                    else {
                                        this.setState({
                                            customers_type: [],
                                        })
                                    }
                                } else {
                                    this.setState({
                                        customers_type: [],
                                    })
                                }
                            }} 
                            name="customers_type" />


                      
                        <MDBRow className="text-center mt-1">

                            <MDBCol xl="3" className="mt-3 mb-0 text-left">

                                เลือกเดือนที่จะใช้ข้อมูลเข้าเดือนใหม่   <br />
                                <select
                                    className={"form-control " + theme} name="payslip_month_in"
                                    onChange={this.onChange} value={this.state.payslip_month_in}
                                >
                                    <option value="">เลือกเดือน</option>
                                    {options_mounth.map((data) => {
                                        return <option value={data.value}> {data.label}</option>
                                    })}
                                </select>
                                <br />
                                <select
                                    className={"form-control " + theme} name="payslip_year_in"
                                    onChange={this.onChange} value={this.state.payslip_year_in}
                                >
                                    <option value="">เลือกปี</option>
                                    {this.state.options_years.map((data) => {
                                        return <option value={data.value}> {data.label}</option>
                                    })}
                                </select>

                            </MDBCol>


                            <MDBCol xl="3" className="mt-3 mb-1 text-left">

                                เลือกเดือนที่จะนำข้อมูลเข้า   <br />
                                <select
                                    className={"form-control " + theme} name="payslip_month_out"
                                    onChange={this.onChange} value={this.state.payslip_month_out}
                                >
                                    <option value="">เลือกเดือน</option>
                                    {options_mounth.map((data) => {
                                        return <option value={data.value}> {data.label}</option>
                                    })}
                                </select>
                                <br />
                                <select
                                    className={"form-control " + theme} name="payslip_year_out"
                                    onChange={this.onChange} value={this.state.payslip_year_out}
                                >
                                    <option value="">เลือกปี</option>
                                    {this.state.options_years.map((data) => {
                                        return <option value={data.value}> {data.label}</option>
                                    })}
                                </select>

                            </MDBCol>
                            <MDBCol xl="3" className=" mt-0 text-center mb-1" style={{ paddingTop: '4.0rem' }}>
                                <Button block icon="search" size="large" type="primary" onClick={this.get_data}>
                                    ค้นหา
                                  </Button>
                            </MDBCol>
                        </MDBRow>



                        {this.state.loading === true ? <div className="text-center">
                            <Spin size="large" tip="loading.." />
                        </div> : <>

                                {this.state.list_salary_count >= 1 ? <>
                                    <MDBCard className={"mt-5 " + theme}>
                                        <MDBCardBody>

                                        <div className="text-center mt-3">
                                        <h5 className={theme}>พบข้อมูลเงินเดือน {this.state.list_salary_count} รายการ</h5>
                                        </div>


                                            <div className="text-left mt-2">
                                                ตัวเลือกการอัพเดต<br />
                                                <Checkbox
                                                    style={{ fontSize: '20px', color: theme === 'dark' ? 'white' : 'black' }}
                                                    onChange={() => this.setState({ insert_option: '1' })}
                                                    checked={this.state.insert_option === '1' ? true : false}
                                                >
                                                    เพิ่มและแทนที่ข้อมูลทั้งหมด
                                                </Checkbox><br />

                                                <Checkbox
                                                    style={{ fontSize: '20px', color: theme === 'dark' ? 'white' : 'black' }}
                                                    onChange={() => this.setState({ insert_option: '2' })}
                                                    checked={this.state.insert_option === '2' ? true : false}
                                                >
                                                    เพิ่มเฉพาะข้อมูลที่ยังไม่มี
                                                            </Checkbox><br />

                                                <Checkbox
                                                    style={{ fontSize: '20px', color: theme === 'dark' ? 'white' : 'black' }}
                                                    onChange={() => this.setState({ insert_option: '3' })}
                                                    checked={this.state.insert_option === '3' ? true : false}
                                                >
                                                    อัพเดตเฉพาะข้อมูลที่มีอยู่แล้ว
                                                 </Checkbox><br />
                                            </div>


                                            {this.state.loadupdate === true ? <Button block
                                                size="large" type="primary" >
                                                <Icon type="loading" /> กำลังอัพเดตข้อมูล
                                            </Button>

                                                :
                                                <Button block icon="sync" size="large" type="primary" onClick={this.ok}>
                                                    อัพเดต
                                            </Button>}

                                        </MDBCardBody>
                                    </MDBCard>
                                </> : null}

                            </>}








                    </MDBCardBody>
                </MDBCard>

            </div>
        )
    }
}
