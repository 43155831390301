import React, { Component } from 'react'
import { Modal, Button, Input } from 'antd';
import { apilogin } from '../api';
import Text from 'antd/lib/typography/Text';
import { MDBRow, MDBCol } from 'mdbreact';

export default class Bttn_customer_account_bank extends Component {
    constructor(props) {
        super(props)
        this.state = {
            bank: [],
            customer_status: [],
            customer_type: [],
            account_type: [],

            visible: false,
            customers_citizent: "",

            bank_id: "",
            account_number: "",
            id_account_type: "",
            check_bank: true,


        }
        this.onChange = this.onChange.bind(this)
    }
    getbank() {
        apilogin.get('getdata/getbank')
            .then(res => {
                this.setState({ bank: res.data })
            })
    }
    get_customer_status() {
        apilogin.get('getdata/customer_status')
            .then(res => {
                this.setState({ customer_status: res.data })
            })

    }

    get_customer_type() {
        apilogin.get('getdata/customer_type')
            .then(res => {
                this.setState({ customer_type: res.data })
            })
    }
    get_account_type() {
        apilogin.get('getdata/account_type')
            .then(res => {
                this.setState({ account_type: res.data })
            })
    }
    showModal = () => {
        this.postid()
        this.getbank()
        this.get_customer_status()
        this.get_customer_type()
        this.get_account_type()
        this.setState({
            visible: true,
        });
    };

    handleOk = e => {
        // alert(JSON.stringify(this.state)) 
        if (this.state.check_bank === false) {
            apilogin.post('insert_customers/insert_bank_detail', ({
                customers_citizent: this.state.customers_citizent,
                // customers_type_id: this.props.customers_type_id,
                bank_id: this.state.bank_id,
                account_number: this.state.account_number,
                id_account_type: this.state.id_account_type,
            }))
                .then(res => {

                    this.props.updatedata_props()

                    // apilogin.post('insert_customers/update_bank_customers', ({
                    //     customers_citizent: this.state.customers_citizent,
                    //     // customers_type_id: this.props.customers_type_id,
                    //     bank_id: this.state.bank_id,
                    // }))
                    //     .then(res => {
                    //         this.props.updatedata_props()
                    //     });

                });
        }
        else {
            apilogin.post('insert_customers/update_bank_detail', ({
                customers_citizent: this.state.customers_citizent,
                // customers_type_id: this.props.customers_type_id,
                bank_id: this.state.bank_id,
                account_number: this.state.account_number,
                id_account_type: this.state.id_account_type,
            }))
                .then(res => {
                    this.props.updatedata_props()
                    // apilogin.post('insert_customers/update_bank_customers', ({
                    //     customers_citizent: this.state.customers_citizent,
                    //     // customers_type_id: this.props.customers_type_id,
                    //     bank_id: this.state.bank_id,
                    // }))
                    //     .then(res => {
                    //         this.props.updatedata_props()
                    //     });
                });
        }
        this.setState({
            visible: false,
        });
        // alert(JSON.stringify(this.state))

    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    postid() {
        apilogin.post('insert_customers/check_bank_for_edit_customers', ({
            customers_citizent: this.props.customers_citizent,
            // customers_type_id: this.props.customers_type_id,
        }))
            .then(res => {


                if (res.data.length >= 1) {
                    this.setState({
                        customers_citizent: res.data[0].customers_citizent,
                        bank_id: res.data[0].bank_id,
                        account_number: res.data[0].account_number,
                        id_account_type: res.data[0].account_type,
                        check_bank: true,

                    });

                } else {
                    this.setState({
                        customers_citizent: this.props.customers_citizent,
                        bank_id: "",
                        check_bank: false,
                    });
                }
            });

    }

    onChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        })
    }
    render() {
        return (
            <>
                <Button icon="account-book" size="small" onClick={this.showModal}>
                    บัญชี
                </Button>
                <Modal
                    centered
                    title={<><strong>จัดการบัญชี</strong> </>}
                    visible={this.state.visible}
                    okText="บันทึก"
                    cancelText="ยกเลิก"
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >

                    <div className="mt-0">
                        <Text strong style={{ textAlign: 'center', fontSize: '16px' }}> รหัสประจำตัวประชาชน </Text>
                        <input disabled className="form-control" value={this.state.customers_citizent} onChange={this.onChange}
                            type="text" name="customers_citizent" placeholder="กรุณาระบุรหัสประจำตัวประชาชน" />
                    </div>

                    <div className="mt-2">
                        <Text strong style={{ textAlign: 'center', fontSize: '16px' }}> ธนาคาร</Text>
                        <select className="form-control" value={this.state.bank_id} name="bank_id" onChange={this.onChange} >
                            <option >เลือกธนาคาร</option>
                            {this.state.bank.map((data) => {
                                return <option value={data.bank_id}>{data.bank_name}</option>
                            })}
                        </select>
                    </div>

                    <div className="mt-2">
                        <Text strong style={{ textAlign: 'center', fontSize: '16px' }}> เลขบัญชี </Text>
                        <input className="form-control" value={this.state.account_number} onChange={this.onChange}
                            type="text" name="account_number" placeholder="กรุณาระบุเลขบัญชี" />
                    </div>

                    <div className="mt-2">
                        <Text strong style={{ textAlign: 'center', fontSize: '16px' }}> ประเภทบัญชี </Text>
                        <select className="form-control" value={this.state.id_account_type} name="id_account_type" onChange={this.onChange} >
                            <option >เลือกประเภทบัญชี</option>
                            {this.state.account_type.map((data) => {
                                return <option value={data.id_account_type}>{data.name_account_type}</option>
                            })}
                        </select>
                    </div>


                </Modal>
            </>
        )
    }
}
