import React, { useState, useContext, useEffect } from 'react'
import { Dialog, DialogTitle, IconButton, DialogContent, DialogActions, Button } from '@material-ui/core';
import Viewer, { Worker, RenderToolbar } from '@phuocng/react-pdf-viewer';

import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import { theme } from '../api';

//import PDFViewer from 'pdf-viewer-reactjs'
import PDFViewer from 'mgr-pdf-viewer-react'

/*
 ถ้าขึ้น The API version "2.5.207" does not match the Worker version "2.4.456".
 แก้โดย 
 npm uninstall @phuocng/react-pdf-viewer
 npm uninstall pdfjs-dist
 npm install @phuocng/react-pdf-viewer
 npm install pdfjs-dist@2.4.456
 ----- จากนั้น start node ใหม่


*/
export default function Bttn_view_pdf(props) {
    const [modal, setmodal] = useState(false)


    let onshowmodal = () => {
        setmodal(!modal)
    }

    useEffect(() => {

        if (props.load_view === false) {
            // setmodal(true)
            onshowmodal()

        }
        // console.log(props.load_view)
    }, [props.load_view])


    let data = props.data

    // console.log(data)
    const layout = (
        isSidebarOpened: boolean,
        container: Slot,
        main: Slot,
        toolbar: RenderToolbar,
        sidebar: Slot,
    ): React.ReactElement => {
        return (
            <div
                {...container.attrs}
                style={Object.assign({}, {
                    border: '1px solid rgba(0, 0, 0, .3)',
                    height: '100%',
                    overflow: 'hidden',
                    width: '100%',
                }, container.attrs.style)}
            >
                {container.children}
                <div
                    {...main.attrs}
                    style={Object.assign({}, {
                        height: '100%',
                        overflow: 'scroll',
                    }, main.attrs.style)}
                >
                    {main.children}
                </div>
            </div>
        );
    }

    return (



        <>
            {props.noButton === true ? <></> : <>
                <Button
                    disabled={data !== "" && data !== undefined ? false : true}
                    onClick={onshowmodal}
                    variant="outlined"
                // endIcon={<FilePdfOutlined />}
                >
                    ดูไฟล์เอกสาร
            </Button>
            </>}



            <Dialog
                fullWidth={true}
                maxWidth={"xl"} //xs
                open={modal}
                onClose={onshowmodal}
                aria-labelledby="max-width-dialog-title"
            >
                <div className={" d-flex justify-content-between font-" + theme}>
                    <DialogTitle id="alert-dialog-title">{<text className={"font-" + theme}>ดูไฟล์เอกสาร </text>}</DialogTitle>
                    <IconButton className={"font-" + theme} onClick={() => setmodal(false)} color="primary" >x </IconButton>
                </div>

                <DialogContent dividers >
                    {props.name}
                    {data !== "" && data !== undefined ? <>

                        <PDFViewer document={{
                            url: data,
                            scale:1
                        }} />

                        {/* <PDFViewer
                            document={{
                                // url: 'https://arxiv.org/pdf/quant-ph/0410100.pdf',
                                url: data,
                                scale: "100%"
                            }}
                        /> */}



                        {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js" >
                            <Viewer fileUrl={data}
                                layout={layout}
                            // isSidebarOpened={true}


                            // fileUrl={"https://e-student.kpru.ac.th/webgeduate2563/file/form.pdf"}
                            />
                        </Worker> */}
                    </> : <> - </>}

                </DialogContent>

            </Dialog>

        </>
    )
}
