import React, { Component } from 'react'
import { Modal, Input, message, InputNumber, Icon } from 'antd';
import { apilogin, theme } from '../api';
import Text from 'antd/lib/typography/Text';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
// import { Button, Typography } from '@material-ui/core';
import { Button, Dialog, DialogTitle, DialogContent, IconButton, Typography, DialogActions } from '@material-ui/core';

export default class Bttn_customer_add_revenue extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            list_revenue_price: [],
            list_revenue: [],
            list: [],
            value: [],
            revenue_id: null,
            revenue_price: null,
        }
        this.onChange = this.onChange.bind(this)
        this.updateList = this.updateList.bind(this)
        this.goto = this.goto.bind(this)
    }

    showModal = () => {
        this.setState({ visible: true })
        this.postid()

    };

    handleOk = e => {
        // alert(JSON.stringify(this.props.insert_list_slip_revenue))
        apilogin.post('insert_salary/insert_list_slip_revenue', ({
            payslip_year: this.props.data.payslip_year,
            payslip_month: this.props.data.payslip_month,
            payslip_citizent: this.props.customers_citizent,
            list_revenue_price: this.state.list_revenue_price,
            customer_type_id: this.props.customer_type_id,
            payslip_expenditure: 0
        }))
            .then(res => {
                // console.log(res.data)
                if (res.data === true) {
                    this.setState({ visible: false });
                    this.props.updatedata_props()
                }
                else { message.error("บันทึกไม่สำเร็จ") }
            });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    postid() {
        this.setState({
            list_revenue: [],
            list_revenue_price: [],
            loading: true
        })
        apilogin.post('insert_salary/get_list_for_add_revenue', ({ customer_type_id: this.props.customer_type_id, payslip_year: this.props.data.payslip_year, payslip_month: this.props.data.payslip_month, payslip_citizent: this.props.customers_citizent }))
            .then(res => {
                if (res.data.length >= 1) {
                    const getdata = res.data.map((data, index) => ({
                        key: data.revenue_id,
                        index: index + 1,
                        revenue_id: data.revenue_id,
                        revenue_name: data.revenue_name,
                        revenue_price: data.payslip_total,
                    }))
                    this.setState({ list_revenue: getdata })
                }
                this.setState({ loading: false })
            })
    }

    onChange = name => value => {
        // console.log(name, value)
        this.setState({
            revenue_id: name,
            revenue_price: value
        })
    }

    updateList(e) {

        const lists = this.state.list_revenue_price;
        var crck = false;
        if (lists.length !== 0) {
            const lists = this.state.list_revenue_price;
            lists.map((values, index) => {
                if (values.revenue_id === this.state.revenue_id) {
                    crck = !crck;
                    let items = this.state.list_revenue_price;
                    let item = { ...items[index] };
                    item.revenue_price = this.state.revenue_price;
                    items[index] = item;
                    this.setState({ list_revenue_price: items });
                }
            })

            if (!crck) {
                lists.push({
                    revenue_id: this.state.revenue_id,
                    revenue_price: this.state.revenue_price
                });
                this.setState({ list_revenue_price: lists })
            }

        } else {
            const lists = this.state.list_revenue_price;
            lists.push({
                revenue_id: this.state.revenue_id,
                revenue_price: this.state.revenue_price
            });
            this.setState({ list_revenue_price: lists })
        }
        this.setState({ loading: false })
    }

    goto() {
        try {
            this.props.pop.history.replace({ pathname: '/admin/payslip_list_manage' });
        } catch (error) {
            window.location.href = "/admin/payslip_list_manage"
        }
    }

    render() {
        return (
            <>
                <Button variant="outlined" size="small" onClick={this.showModal}>
                    <MDBIcon icon="hand-holding-usd" /> &nbsp; เพิ่มรายรับ
                </Button>



                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"} //xs
                    open={this.state.visible}
                    onClose={this.handleCancel}
                    aria-labelledby="max-width-dialog-title"
                >
                    <div className={" d-flex justify-content-between font-" + theme}>
                        <DialogTitle id="alert-dialog-title">{<text className={"font-" + theme}><Icon type="plus-square" />  เพิ่มรายรับ </text>}</DialogTitle>
                        <IconButton className={"font-" + theme} onClick={this.handleCancel} color="primary" >x </IconButton>
                    </div>

                    <DialogContent dividers >

                        {this.state.loading === true ? <div className="text-center">
                            กำลังโหลดรายการ...
                        </div> : <>
                                {this.state.list_revenue.map((data) => {
                                    return <>
                                        <div className="mt-2">
                                            <MDBRow>
                                                <MDBCol sm="1" style={{ paddingTop: '0.4rem' }}> </MDBCol>
                                                <MDBCol sm="6" style={{ paddingTop: '0.0rem' }}>
                                                    <Typography strong style={{ textAlign: 'left', fontSize: '16px' }}> {data.revenue_name} </Typography>
                                                </MDBCol>
                                                <MDBCol sm="3">
                                                    <InputNumber
                                                        // defaultValue={data.revenue_price}
                                                        defaultValue={Number(data.revenue_price).toFixed(2)}
                                                        onChange={this.onChange(data.revenue_id)}
                                                        onBlur={this.updateList}
                                                        min={0} max={500000}
                                                    />

                                                </MDBCol>
                                                <MDBCol sm="2" style={{ paddingTop: '0.0rem' }}>
                                                    <Typography strong style={{ textAlign: 'center', fontSize: '16px' }}> บาท </Typography>
                                                </MDBCol>
                                            </MDBRow>
                                        </div>

                                    </>
                                })}
                                {this.state.list_revenue.length === 0 ? <>
                                    <div className="mt-2 text-center">
                                        <Typography strong style={{ fontSize: '16px' }}> ไม่มีรายการรายรับ <br />
                                            <Button size="small" color="primary" onClick={this.goto} ><Icon type="arrow-right" /> ไปหน้าเพิ่มรายการ</Button>
                                        </Typography>
                                    </div>
                                </> : null}
                            </>}
                    </DialogContent>
                    <DialogActions>

                        <Button variant="outlined" onClick={this.handleCancel} >ยกเลิก</Button>
                        <Button variant="contained" color="primary" onClick={this.handleOk} >บันทึก</Button>
                    </DialogActions>

                </Dialog>



                {/* <Modal
                    centered
                    title={<><strong> <Icon type="plus-square" />  เพิ่มรายรับ</strong> </>}
                    visible={this.state.visible}
                    okText="บันทึก"
                    cancelText="ยกเลิก"
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >

                </Modal> */}
            </>
        )
    }
}
