import React, { Component } from 'react'
import { apilogin, theme, detail_user, options_mounth } from '../api'
import { Table, Input, Icon, Card, Button, InputNumber, message, Spin, Alert, Empty } from 'antd'
import { MDBContainer, MDBCardBody, MDBCardHeader, MDBCard, MDBRow, MDBCol, MDBIcon, MDBBox, MDBAlert } from 'mdbreact'
import { Helmet } from "react-helmet";

import moment from 'moment';
import 'moment/locale/th';
import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
// import Viewer from '@phuocng/react-pdf-viewer';

import DarkModeToggle from '../Components/DarkMode/DarkModeToggle';
import PDFViewer from 'mgr-pdf-viewer-react'



const { Search } = Input;






export default class CheckREF extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            datafound: null,
            loading: true,
            search: '',
            monthname: '',
        }
        this.onChange = this.onChange.bind(this)
        this.onSearch = this.onSearch.bind(this)
        this.url = this.url.bind(this)
        // this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this)
    }

    // onDocumentLoadSuccess({ numPages }) {
    //     setNumPages(numPages);
    //   }

    onChange(e) {
        // console.log(e.target.value)
        this.setState({ search: e.target.value })
    }

    url(url) {
        this.props.history.push({ pathname: '/CheckREF/' + url })
        this.onSearch(url)
    }

    onSearch(e) {
        this.setState({ search: e })
        // console.log(e)
        let sent = {
            search: e,
        }

        apilogin.post('checkref/checkhistoryprint', sent)
            .then((res) => {
                let data = res.data
                if (data.length >= 1) {

                    let monthname = ""
                    {
                        options_mounth.map((m) => {

                            if (m.value === String(data[0].print_payslip_month)) {
                                monthname = m.label
                            }
                        })
                    }

                    this.setState({
                        data: data[0],
                        datafound: true,
                        monthname: monthname,
                        loading: false
                    })

                } else {
                    this.setState({
                        datafound: false,
                        loading: false
                    })
                }
                // console.log(res.data)
            })
    }

    componentDidMount() {

        // localStorage.setItem("darkMode", "true")
        let ref = this.props.match.params.ref
        if (ref) {
            this.onSearch(ref)
        }
        // console.log(ref)

    }





    render() {


        // const layout = (
        //     isSidebarOpened: boolean,
        //     container: Slot,
        //     main: Slot,
        //     toolbar: RenderToolbar,
        //     sidebar: Slot,
        // ): React.ReactElement => {
        //     return (
        //         <div
        //             {...container.attrs}
        //             style={Object.assign({}, {
        //                 border: '1px solid rgba(0, 0, 0, .3)',
        //                 height: '100%',
        //                 overflow: 'hidden',
        //                 width: '100%',
        //             }, container.attrs.style)}
        //         >
        //             {container.children}
        //             <div
        //                 {...main.attrs}
        //                 style={Object.assign({}, {
        //                     height: '100%',
        //                     overflow: 'scroll',
        //                 }, main.attrs.style)}
        //             >
        //                 {main.children}
        //             </div>
        //         </div>
        //     );
        // }


        return (
            <div>

                <Helmet>
                    <meta charSet="utf-8" />
                    <title>ค้นหาเลขอ้างอิง สลิปเงินเดือน KPRU</title>
                    <meta name="description" content="ค้นหาเลขอ้างอิง สลิปเงินเดือน KPRU" />
                    <link rel="canonical" href="https://e-payslip.kpru.ac.th/CheckREF" />
                </Helmet>


                <MDBContainer className="mt-0 mb-5 animated fadeIn text-center">

                    <div className="text-right mt-2 mb-2"> <DarkModeToggle /> <a href="/"><Button><Icon type="home" /> หน้าหลัก</Button></a> </div>
                    <MDBCard style={{ minHeight: "40rem" }} className={theme} >
                        <MDBCardHeader className={"mt-2  mb-5 form-header rgba-indigo-light rounded -hoverable " + theme} >
                            {/* rgba-indigo-light */}
                            <h2 className={theme} style={{ textAlign: 'center', fontWeight: 'bold' }} >
                                <Icon type="file-search" /> ค้นหาเลขอ้างอิง สลิปเงินเดือน
                                </h2>
                        </MDBCardHeader>



                        <MDBContainer>
                            <div className="mt-2 mb-3">
                                <Search
                                    onChange={this.onChange}
                                    // FKGFW-WBCT2EZR-91DRY3-K73RSQ
                                    value={this.state.search}
                                    defaultValue={this.state.search}
                                    placeholder="ระบุเลขอ้างอิง"
                                    enterButton="ค้นหา"
                                    size="large"
                                    // onChange={this.onChange}
                                    onSearch={this.url}
                                />



                            </div>
                            {/* FKGFW-WBCT2EZR-91DRY3-K73RSQ */}

                            {this.state.datafound === true ? <>
                                <div className="text-left">
                                    ชื่อ-นามสกุล {this.state.data.print_by}<br />
                                    เงินเดือนปี {Number(this.state.data.print_payslip_year) + 543} เดือน {this.state.monthname}<br />
                                    {/* ของ {this.state.data.print_by}<br /> */}
                                    {/* พิมพ์ไว้เมื่อ {this.state.data.print_date} เวลา {this.state.data.print_time}<br /> */}
                                    พิมพ์ไว้เมื่อ {moment(this.state.data.print_date).format('LL')} เวลา {this.state.data.print_time}<br />
                                    <a target="_blank" href={'https://e-payslip.kpru.ac.th/ref-slip/' + this.state.data.print_file_name + '.pdf'}><Button type="primary"><Icon type="download" /> Download</Button></a>
                                    <br /><br />
                                </div>



                                {/* <iframe src={'https://e-payslip.kpru.ac.th/ref-slip/' + this.state.data.print_file_name + '.pdf'} title="title"
                                    width="100%" height="500px"
                                /> */}


                                {/* <PDFViewer
                                    css="container"
                                    scale={1} 
                                    document={{
                                        url: 'https://e-payslip.kpru.ac.th/ref-slip/' + this.state.data.print_file_name + '.pdf',
                                        // scale: 1
                                    }} /> */}

                                {/* <Viewer  fileUrl={'https://e-payslip.kpru.ac.th/ref-slip/' + this.state.data.print_file_name + '.pdf'} /> */}

                                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js" >
                                    <Viewer fileUrl={'https://e-payslip.kpru.ac.th/ref-slip/' + this.state.data.print_file_name + '.pdf'}
                                        // layout={layout}
                                    />
                                </Worker> 



                            </> : <>
                                    {this.state.datafound === false ? <>
                                        <Empty description="ไม่พบข้อมูล" />

                                    </> : <></>}
                                </>}

                        </MDBContainer>



                    </MDBCard>

                </MDBContainer>


            </div>
        )
    }
}
