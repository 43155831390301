import React, { Component } from 'react'
import { apilogin, theme } from '../api'
import { Table, Input, Icon, Card, Button, InputNumber, message, Popconfirm, Checkbox } from 'antd'
import { MDBContainer, MDBCardBody, MDBCardHeader, MDBCard, MDBRow, MDBCol, MDBIcon } from 'mdbreact'
import Highlighter from 'react-highlight-words';
import Bttn_customer_add_expenditure from '../Components/Bttn_customer_add_expenditure';
import NumberFormat from 'react-number-format';
import Select from 'react-select'
import Bttn_customer_add_revenue from '../Components/Bttn_customer_add_revenue';
import PrintPDF from './PrintPDF';


import ReactExport from "react-export-excel";


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class FormPageList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            loading: false,
            search: '',
            payslip_month: '',
            payslip_year: '',
            search_check: false,
        }
        this.get_customer_status = this.get_customer_status.bind(this)
        this.onOK = this.onOK.bind(this)
     //   this.updatedata = this.updatedata.bind(this)
      //  this.updatedata_props = this.updatedata_props.bind(this)
    }
    onOK(user_citizent){
        apilogin.post('getdata/delete_data_form', ({
            user_citizent: user_citizent,
        }))
            .then(res => {
                    this.get_customer_status()
            });
    }

    get_customer_status(){
        this.setState({loading:true})
         apilogin.get('getdata/get_user_detail_data_all')
        .then((res=>{
            this.setState({data:res.data})
            this.setState({loading:false})
        }))
    }
   
    componentDidMount() {
         localStorage.removeItem('Username')
         this.get_customer_status()
       
    }


    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`ค้นหา`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
            </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        ),
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    render() {

    


        const columns = [
            {
                title: 'ลำดับ',
                dataIndex: 'index',
                key: 'index',
                className:theme,
                render: (text, record) =><div className={"text-center "}>{this.state.data.indexOf(record) + 1}</div>

            },
            {
                title: 'ชื่อ-นามสกุล',
                dataIndex: 'fullname',
                key: 'fullname',
                className:theme,
                ...this.getColumnSearchProps('fullname'),
                 render: text => <div className={"text-left "}>{text}</div>,
            },


            {
                title: 'ธนาคาร',
                dataIndex: 'user_bank_name',
                key: 'user_bank_name',
                className:theme,
                  render: text => <div className={"text-center "}>{text}</div>,
            },

            {
                title: 'สาขา',
                dataIndex: 'user_bank_branch',
                key: 'user_bank_branch',
                className:theme,
                 render: text => <div className={"text-center "}>{text}</div>,

            },
            {
                title: 'เลขบัญชี',
                dataIndex: 'user_bank_number',
                key: 'user_bank_number',
                className:theme,
                 render: text => <div className={"text-center "}>{text}</div>,
            },

            {
                title: 'ส่งแล้ว',
                dataIndex: 'user_status_sent',
                key: 'user_status_sent',
                className:theme,
                 render: (text,record) => <div className={"text-center "}>
                 {/* {text} */}
                 <Checkbox name="user_status_sent" checked={text==="1"?true:false} 
                 onChange={(e)=>{
                     let cc= "0"
                     if(e.target.checked===true){
                         cc="1"
                     }
                     let datasent={
                        user_status_sent:cc,
                        user_citizent:record.user_citizent
                     }
                     apilogin.post('getdata/update_status_ktb',datasent)
                     .then((res)=>{
                        this.get_customer_status()
                     })
                   

                    }}
                  />
                 </div>,
            },
           

            {
                title: 'จัดการ',
                key: 'manage',
                className:theme,
                render: (text, record) => (
                    <div className="text-center">
                        <Button onClick={()=> {
                            localStorage.setItem('Username', record.user_citizent)
                            this.props.history.push({ pathname: '/FormPage' }) 
                        }}
                         size="small"> <MDBIcon icon="pencil-alt" /> &nbsp; แก้ไข </Button>&nbsp; 
                      
                      <Popconfirm
                    title="คุณแน่ใจใช่ไหมที่จะลบข้อมูลนี้?"
                    onConfirm={()=>this.onOK(record.user_citizent)}
                    okText="ยืนยัน"
                    cancelText="ยกเลิก"
                >
                    <Button  icon="delete" type="dashed" size="small" >ลบ</Button>
                </Popconfirm>

                    </div>
                ),
            },


        ]

        return (
            <div>
                <MDBContainer className="mt-5 mb-5">
                    <div className="d-flex justify-content-center mt-5 text-center">
                        <MDBCard style={{ width: "72rem", minHeight: "40rem" }}className={theme} >
                            <MDBCardHeader className={"form-header rgba-indigo-light rounded -hoverable "+theme} >
                                <h2 className={theme} style={{ textAlign: 'center', fontWeight: 'bold' }} >
                                <Icon type="money-collect" /> จัดการข้อมูลการรับเงินผ่านระบบ KTB Corporate Online
                                </h2>
                            </MDBCardHeader>

                            <MDBCardBody className="bodytable">
                            <div className="text-right">
                                <Button onClick={()=>  this.props.history.push({ pathname: '/FormPage' }) }>   เพิ่มข้อมูล&nbsp;<MDBIcon icon="user-plus" /> </Button>

                                <ExcelFile filename={"สรุปรายชื่อ KTB"} element={<Button > <MDBIcon far icon="file-excel" /> &nbsp;Excel</Button>}>
                        <ExcelSheet data={this.state.data} name="สรุปรายชื่อ KTB">
                            <ExcelColumn label="user_citizent"  value="user_citizent" />
                            <ExcelColumn label="user_pname"  value="user_pname" />
                            <ExcelColumn label="user_fname"  value="user_fname" />
                            <ExcelColumn label="user_lname"  value="user_lname" />
                            <ExcelColumn label="user_office"  value="user_office" />
                            <ExcelColumn label="user_belong"  value="user_belong" />
                            <ExcelColumn label="user_department"  value="user_department" />
                           
                            <ExcelColumn label="user_emp"  value="user_emp" />
                            <ExcelColumn label="user_bank_name"  value="user_bank_name" />
                            <ExcelColumn label="user_bank_branch"  value="user_bank_branch" />
                            <ExcelColumn label="user_bank_type"  value="user_bank_type" />
                            <ExcelColumn label="user_bank_number"  value="user_bank_number" />
                            <ExcelColumn label="user_phone_number"  value="user_phone_number" />
                            <ExcelColumn label="user_email"  value="user_email" />
                          

                            <ExcelColumn label="user_add_no"  value="user_add_no" />
                            <ExcelColumn label="user_add_soi"  value="user_add_soi" />
                            <ExcelColumn label="user_add_road"  value="user_add_road" />
                            <ExcelColumn label="user_add_tumbon"  value="user_add_tumbon" />
                            <ExcelColumn label="user_add_amphoe"  value="user_add_amphoe" />
                            <ExcelColumn label="user_add_province"  value="user_add_province" />
                            <ExcelColumn label="user_add_code"  value="user_add_code" />
                            <ExcelColumn label="user_add_phone"  value="user_add_phone" />

                            <ExcelColumn label="user_date_add"  value="user_date_add" />
                            <ExcelColumn label="user_time_add"  value="user_time_add" />
                        </ExcelSheet>
                    </ExcelFile>
                    
                            </div>
                           
                                    <Table  dataSource={this.state.data} columns={columns}
                                        pagination={{ pageSize: 25 }}
                                        bordered
                                        size="small"
                                        loading={this.state.loading}

                                        rowClassName={theme}
                                        className={"mt-3 time-table-row-select "+theme}
                                    />
                                  

                            </MDBCardBody>
                        </MDBCard>
                    </div>
                </MDBContainer>

                {
                    message.config({
                        top: 60,
                        duration: 1,
                    })
                }


            </div>
        )
    }
}
