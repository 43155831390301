import React, { Component } from 'react'
import { Popconfirm, message, Button } from 'antd';
import { apilogin } from '../api';


export default class Bttn_list_revenue_del extends Component {
    constructor(props) {
        super(props)
        this.state = {
           
        }
       this.onOK=this.onOK.bind(this)
    }
    onOK(){
        apilogin.post('insert_salary/delete_list_revenue', ({
            revenue_id: this.props.revenue_id,
        }))
            .then(res => {
                    this.props.updatedata_props()
            });
       
    }
    render() {
        return (
            <>
                <Popconfirm
                    title="คุณแน่ใจใช่ไหมที่จะลบข้อมูลนี้?"
                    onConfirm={this.onOK}
                    okText="ยืนยัน"
                    cancelText="ยกเลิก"
                >
                    <Button  icon="delete" type="dashed" color="danger" size="defult" >ลบ</Button>
                </Popconfirm>
            </>
        )
    }
}
