import React, { Component } from 'react'
import MessengerCustomerChat from 'react-messenger-customer-chat';

export default class MessengerFacebook extends Component {
    render() {
        return (
            <>
                <MessengerCustomerChat
                    shouldShowDialog={false}
                    pageId={712626885445207}
                    //218693378291704 = ส่งเสริม 
                    // https://www.facebook.com/งานการเงินและบัญชีมหาวิทยาลัยราชภัฏกำแพงเพชร-712626885445207/ = 712626885445207
                    //https://lookup-id.com/ สำหรับค้นหาไอดีเพจ
                    appId="2227159480915177"
                    // greetingDialogDisplay="show"
                    greetingDialogDisplay="hide"
                    logged_in_greeting="สวัสดีครับ งานการเงินและบัญชีมหาวิทยาลัยราชภัฏกำแพงเพชรยินดีต้อนรับ"
                    className="fb-customerchat"
                    attribution="setup_tool"
                    htmlRef="https://e-payslip.kpru.ac.th/"
                />
            </>
        )
    }
}
