import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import GrainIcon from '@material-ui/icons/Grain';
import SyncIcon from '@material-ui/icons/Sync';
import { Chip } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    link: {
        display: 'flex',
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
}));


export default function Mybreadcrumbs(props) {
    const classes = useStyles();


    let handleClick = (event) => {
        props.history.push({ pathname: event })
    }


    return (
        <div className="mt-4 mb-4" >

            <Breadcrumbs aria-label="breadcrumb">

                <Chip
                    icon={<>&emsp;<HomeIcon /></>}
                    label="Home"
                    // onClick={() => handleClick('/')}
                    onClick={props.b1active ? false : () => handleClick('/')}
                    variant="outlined"
                />

                {props.b2 ? <>
                  
                    <Chip
                        icon={props.b2icon}
                        label={props.b2label}
                        variant="outlined"
                        onClick={props.b2active ? false : () => handleClick(props.b2link)}
                    />
                </> : null}




            </Breadcrumbs>

        </div>
    );
}
