import React, { Component } from 'react'
import { apilogin, theme, detail_user } from '../api'
import { Table, Input, Icon, Button, InputNumber, message, Spin } from 'antd'
import { MDBContainer, MDBCardBody, MDBCardHeader, MDBCard, MDBRow, MDBCol, MDBIcon, MDBBox, MDBAlert,   MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact'
import PrintPDF from './PrintPDF';
import person from '../img/person.png';
import ReactImageFallback from "react-image-fallback";
import { Helmet } from "react-helmet";
import MUIDataTable from "mui-datatables";
import Alert from '@material-ui/lab/Alert';
import Toast from 'light-toast';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import '@material/react-list/dist/list.css';
import Bttn_upload_file_main from '../Components/Bttn_upload_file_main';
import { CardHeader, Card, Divider } from '@material-ui/core';
import ReactResizeDetector from 'react-resize-detector';
import Modeltax from './Modeltax';



const options_mounth = [
    { value: '01', label: 'มกราคม' },
    { value: '02', label: 'กุมภาพันธ์' },
    { value: '03', label: 'มีนาคม' },
    { value: '04', label: 'เมษายน' },
    { value: '05', label: 'พฤษภาคม' },
    { value: '06', label: 'มิถุนายน' },
    { value: '07', label: 'กรกฎาคม' },
    { value: '08', label: 'สิงหาคม' },
    { value: '09', label: 'กันยายน' },
    { value: '10', label: 'ตุลาคม' },
    { value: '11', label: 'พฤศจิกายน' },
    { value: '12', label: 'ธันวาคม' },
    { value: '1', label: 'มกราคม' },
    { value: '2', label: 'กุมภาพันธ์' },
    { value: '3', label: 'มีนาคม' },
    { value: '4', label: 'เมษายน' },
    { value: '5', label: 'พฤษภาคม' },
    { value: '6', label: 'มิถุนายน' },
    { value: '7', label: 'กรกฎาคม' },
    { value: '8', label: 'สิงหาคม' },
    { value: '9', label: 'กันยายน' },

]


export default class Page_list_salary extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            data_emp: [],
            loading: false,
            search: '',
            name: '',
            payslip_month: '',
            payslip_year: '',
            search_check: false,
            load: true,
            loademp: true,
            count_load: 0,
            img_user: "",
            customers_type: "",
            get_show: [],
            user_id: "",
            customer_type_name: "",
            customer_type_name2: "",
            customer_type_select: null,
            list_customer_type: null,
            modal: false,
            resize: 0,
            idc:'',
        }

    }


    onResize = (e) => {

        this.setState({ resize: e })
    }

    set_customer_type_select = (event, newValue) => {
        this.setState({ customer_type_select: newValue })
        this.setState({ customers_type: newValue })
        this.get_show(newValue)
    }

    get_show(customer_type_id) {
        Toast.loading('Loading..')
        this.setState({ loading: true })
        let sent = {
            id: detail_user.Username,
            customers_type: customer_type_id
        }
        apilogin.get('getdata/get_show', sent)
            .then(res2 => {
                // console.log(res2)
                this.setState({ get_show: res2.data })

                apilogin.post('getdata/get_salary_list', sent)
                    .then(res2 => {
                        this.setState({ data: res2.data })
                        this.setState({ loading: false })

                        setTimeout(() => {
                            Toast.hide();
                        }, 400);
                    })
            })


    }

    toggle = () => {
        this.setState({
          modal: !this.state.modal
        });
      }


    load_salary = () => {
        apilogin.post('getdata/get_type_customers', ({ Username: detail_user.Username }))
            .then((res) => {
                if (res.data.length >= 1) {
                    this.setState({ customer_type_name: res.data[0].customer_type_name })
                    try {
                        this.setState({ customer_type_name2: res.data[1].customer_type_name })
                    } catch (error) {
                    }

                    this.setState({ customer_type_select: res.data[0].customer_type_id })
                    this.setState({ customers_type: res.data[0].customer_type_id })
                    this.setState({ list_customer_type: res.data })
                    this.setState({ idc: detail_user.Username })
                    // this.get_show(detail_user)
                    this.get_show(res.data[0].customer_type_id)
                }
                this.setState({ loademp: false })
            })
            .catch(err => {
                message.info("เครือข่ายไม่รองรับ")
                this.setState({
                    loademp: false,
                })
            })
    }


    componentDidMount() {

        if (detail_user !== null) {
            this.setState(detail_user)

            const admin_check = detail_user.admin_type
            if (admin_check) {
                this.props.history.push({ pathname: '/admin' })

            } else {

                let user = { user_citizent: detail_user.Username }
                this.setState({ user_id: detail_user.Username })
                apilogin.post('getdata/get_user_detail_data', user)
                    .then((res => {
                        if (res) {
                            if (res.data.length === 0) {
                                this.props.history.push({ pathname: '/FormPage' })
                            } else {
                                // this.setState({
                                //     customers_type: res.data[0].customers_type,
                                // })
                                this.load_salary()
                            }
                        }

                    }))







            }

        }
        else {


            // message.error('ไม่พบผู้ใช้')
            this.props.history.push({ pathname: '/login' })
            // localStorage.clear()
            localStorage.removeItem('token')
            localStorage.removeItem('tokenVerify')



        }

      //  this.toggle();


    }
    render() {

        console.log(this.state.idc)
        console.log(this.state.nameuser)
        
        let align = this.state.resize <= 1199 ? "text-left" : "text-center"


        const columns = [
            // {
            //     title: 'ลำดับ',
            //     dataIndex: 'index',
            //     key: 'index',
            //     width: '100px',
            //     className: theme,
            //     render: text => <div className="text-center">{text + 1}</div>,
            //     defaultSortOrder: 'ascend',
            //     sorter: (a, b) => a.index - b.index,

            // },
            {
                title: 'เดือน',
                dataIndex: 'payslip_month',
                key: 'payslip_month',
                className: theme,
                render: text => <div className={align}>
                    {options_mounth.map((m) => {
                        if (m.value === String(text)) {
                            return m.label
                        }
                    })}</div>,


            },
            {
                title: 'ปี',
                dataIndex: 'payslip_year',
                key: 'payslip_year',
                className: theme,
                render: text => <div className={align}>{Number(text) + 543}</div>,

            },
            {
                title: 'รายรับ',
                dataIndex: 'rub_total',
                key: 'rub_total',
                className: theme,
                render: text =>
                    <>
                        {Number(text).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                    </>

            },
            {
                title: 'รายจ่าย',
                dataIndex: 'jai',
                key: 'jai',
                className: theme,
                render: text =>
                    text.toLocaleString(navigator.language, { minimumFractionDigits: 2 })
                //Number(text).toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 2 })
            },

            {
                title: 'ยอดสุทธิ',
                dataIndex: 'payslip_total',
                key: 'payslip_total',
                className: theme,
                render: text => text.toLocaleString(navigator.language, { minimumFractionDigits: 2 })
                //Number(text).toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 2 })
            },
            // {

            {
                title: 'พิมพ์ใบเงินเดือน',
                key: 'manage',
                width: '200px',
                className: theme,
                render: (text, record) => (
                    <div className="text-center">

                        <PrintPDF
                            dataprint={record}
                            // payslip_month={this.state.payslip_month.length >= 2 ? this.state.payslip_month : "0" + this.state.payslip_month} 
                            // payslip_year={this.state.payslip_year} 
                            payslip_month={record.payslip_month}
                            payslip_year={record.payslip_year}
                        />

                    </div>
                ),
            },


        ]


        const columns2 = [
            // {
            //     title: 'ลำดับ',
            //     dataIndex: 'index',
            //     key: 'index',
            //     width: '100px',
            //     className: theme,
            //     render: text => <div className="text-center">{text + 1}</div>,
            //     defaultSortOrder: 'ascend',
            //     sorter: (a, b) => a.index - b.index,

            // },
            {
                title: 'เดือน',
                dataIndex: 'payslip_month',
                key: 'payslip_month',
                className: theme,
                render: text => <div className="text-center">
                    {options_mounth.map((m) => {
                        if (m.value === String(text)) {
                            return m.label
                        }
                    })}</div>,


            },
            {
                title: 'ปี',
                dataIndex: 'payslip_year',
                key: 'payslip_year',
                className: theme,
                render: text => <div className="text-center">{Number(text) + 543}</div>,

            },

            {
                title: 'พิมพ์ใบเงินเดือน',
                key: 'manage',
                width: '200px',
                className: theme,
                render: (text, record) => (
                    <div className="text-center">

                        <a target="_blank" href={"https://e-payslip.kpru.ac.th/print_slip/" + this.state.user_id + "-" + record.show_year + "-" + record.show_month + ".pdf"}>
                            <Button size="small"><Icon type="file-pdf" /> PDF</Button>
                        </a>

                    </div>
                ),
            },


        ]


        const columns11 = [
            // {
            //     name: "payslip_month",
            //     label: "เดือน",
            //     options: {
            //         filter: true,
            //         sort: false,
            //     }
            // },
            {
                name: "payslip_year",
                // label: "ปี",
                label: <div className={align} >ปี</div>,
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div className={align}>
                                {Number(value) + 543}
                            </div>
                        );
                    }
                }
            },

            {
                name: "payslip_month",
                label: <div className={align} >เดือน</div>,
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        let i = tableMeta.rowIndex
                        return (
                            <>
                                <div className={align}>
                                    {options_mounth.map((m) => {
                                        if (m.value === String(value)) {
                                            return m.label
                                        }
                                    })}
                                </div>
                            </>
                        );
                    }
                }
            },



            {
                name: "rub_total",
                // label: "รายรับ",
                label: <div className={align} >รายรับ</div>,
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div className={align}>
                                {Number(value).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                            </div>
                        );
                    }
                }
            },

            {
                name: "jai",
                // label: "รายจ่าย",
                label: <div className={align} >รายจ่าย</div>,
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div className={align}>
                                {Number(value).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                            </div>
                        );
                    }
                }
            },

            {
                name: "payslip_total",
                // label: "ยอดสุทธิ",
                label: <div className={align} >ยอดสุทธิ</div>,
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <div className={align}>
                                {Number(value).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                            </div>
                        );
                    }
                }
            },
            {
                name: "payslip_total",
                label: <div className="text-center" >ใบเงินเดือน</div>,
                options: {
                    filter: false,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        let i = tableMeta.rowIndex
                        return (
                            <>
                                <div className="text-center">
                                    <PrintPDF
                                        dataprint={this.state.data[i]}
                                        // payslip_month={this.state.payslip_month.length >= 2 ? this.state.payslip_month : "0" + this.state.payslip_month} 
                                        // payslip_year={this.state.payslip_year} 
                                        payslip_month={this.state.data[i].payslip_month}
                                        payslip_year={this.state.data[i].payslip_year}
                                    />
                                    &nbsp;
                                    <Bttn_upload_file_main
                                        user={true}
                                        customers_name={this.state.nameuser}
                                        customers_citizent={detail_user.Username}
                                        customer_type={this.state.customers_type}
                                        payslip_month={this.state.data[i].payslip_month}
                                        payslip_year={this.state.data[i].payslip_year}
                                    />
                                </div>
                            </>
                        );
                    }
                }
            },
        ];

      
        let detail
        detail = (
            <>
                {/* <div className={"text-center mt-3 mb-2  " + theme}>
                    <MDBContainer className="col-12" fluid> */}
                <Card size="small" className={theme} >
                    <CardHeader className={"mb-0 font-" + theme} title={"รายการเงินเดือน"} />
                    <Divider />
                    <MDBCardBody>
                        <Spin spinning={this.state.loademp}>
                            <MDBRow>
                                <MDBCol md="2"
                                    className="text-center  mt-2"
                                >

                                    <ReactImageFallback
                                        src={this.state.img_user}
                                        width={120}
                                        fallbackImage={person}
                                        // initialImage={<Spin />}
                                        initialImage="Loading.."
                                        alt="Loading.."
                                        className="rounded img-fluid z-depth-1 mt-0 img-thumbnail"
                                    />

                                    <br />
                                </MDBCol>

                                <MDBCol md="7">
                                    <div className={"mt-3 text-left " + theme}>
                                        <strong>ชื่อ - นามสกุล</strong> {this.state.nameuser} <br />
                                        {this.state.idc === '1629900314177' || this.state.idc === '1640900035130' || this.state.idc === '3501300314991' || this.state.idc === '1620600002813' || this.state.idc === '3640300266374'  || this.state.idc === '3649900007971' || this.state.idc === '3150400517012' ?<> <strong>ประเภท</strong> พนักงานมหาวิทยาลัย</> :<>
                                         <strong>ประเภท</strong> {this.state.customer_type_name}
                                        </>}
                                       
                                        {/* {this.state.customer_type_name2 !== "" ? <>
                                                    &nbsp; และ {this.state.customer_type_name2}
                                                </> : null} */}
                                        <br />
                                        <strong>ตำแหน่ง</strong> {this.state.rank_name} <br />

                                        <strong>สังกัด</strong> {this.state.organization_name} <br />
                                    </div>



                                </MDBCol>

                            </MDBRow>
                        </Spin>
                    </MDBCardBody>
                </Card>
                {/* </MDBContainer> 
                </div>*/}
            </>
        )

        let table_data
        table_data = (
            <MUIDataTable
                data={this.state.data}
                columns={columns11}
                // columns={this.state.customers_type === "6" || this.state.customers_type === "2" ? columns22 : columns11}

                options={{
                    responsive: 'simple',
                    filterType: 'dropdown',
                    selectableRows: 'none',
                    viewColumns: false,
                    filter: false,
                    pagination: false,
                    search: false,
                    download: false,
                    print: false,
                }}
            />
        )

        return (
            <div className="" >

                <ReactResizeDetector handleWidth handleHeight onResize={this.onResize} />
                {/* {console.log(this.state.customer_type_select)} */}
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>ระบบออกใบสลิปเงินเดือน KPRU</title>
                    <meta name="description" content="ระบบออกใบสลิปเงินเดือน KPRU" />
                    <link rel="canonical" href="https://e-payslip.kpru.ac.th/" />
                </Helmet>

              

                <div className="text-center mt-5 container animated fadeIn" >
                   <MDBAlert color="danger" >
                   สำหรับผู้ที่มีรายได้ประจำปี 2565 สามารถยื่นภาษีได้ตั้งแต่บัดนี้เป็นต้นไป สามารถยื่นด้วยตนเองที่สำนักงานสรรพกรภายใน 31 มี.ค.2566 หรือออนไลน์ ภายในวันที่ 4 เม.ย.2566
                   </MDBAlert>
                   {/* <MDBAlert color="danger" >
                   สามารถแจ้งรายการเพื่อการหักลดหย่อน ได้ตั้งแต่บัดนี้ถึงวันที่ 9 กุมภาพันธ์ 2565  <br/>
                   
                   </MDBAlert> */}

                    {/* <CardHeader className={"mb-4 font-" + theme} title={"รายการเงินเดือน"} /> */}
                    {/* <Modeltax idc={this.state.idc} nameuser={this.state.nameuser}  /> */}

                    {detail}
                    <br />

                    <Paper square>
                        {this.state.list_customer_type !== null ? <>
                            {this.state.list_customer_type.length !== 1 ? <>
                                <Tabs
                                    value={this.state.customer_type_select}
                                    indicatorColor={theme === "dark" ? "secondary" : "primary"}
                                    textColor={theme === "dark" ? "" : "primary"}
                                    onChange={this.set_customer_type_select}
                                    aria-label="disabled tabs example"
                                >

                                    {this.state.list_customer_type.map((data) => {
                                        return <Tab value={data.customer_type_id} label={data.customer_type_name} />
                                    })}
                                </Tabs>
                            </> : <></>}
                        </> : <></>}
                    </Paper>


                    {<>{table_data}</>}
                    {/* {console.log(this.state.customers_type)} */}
                    {this.state.customers_type === 6 || this.state.customers_type === 2 ? <>
                        <div className="mt-3">
                            <Alert severity="info">สำหรับ ข้าราชการ และลูกจ้างประจำ สลิปเงินเดือนจะอยู่ไฟล์เอกสารอื่นๆ</Alert>
                        </div>
                        </> : null}

                    {/* <div className="mt-3">
                        <Alert severity="info">ขณะนี้มีรายการเงินเดือนเฉพาะ พนักงานราชการ พนักงานมหาวิทยาลัย ข้าราชการ และลูกจ้างประจำ นอกเหนือจากนี้ยังอยู่ในระหว่างดำเนินการ</Alert>
                    </div> */}
                            <MDBContainer>
                            {/* <MDBBtn onClick={this.toggle}>Modal</MDBBtn> */}
                            <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
                                <MDBModalHeader className="text-left" toggle={this.toggle} >ดาวน์โหลดเอกสารการหักลดหย่อนภาษี ประจำปี 2564</MDBModalHeader>
                                <MDBModalBody>
                                 
                                      <MDBTable>
                                            <MDBTableHead>
                                                <tr>
                                                <th>ลำดับ</th>
                                                <th>รายการ</th>
                                                <th>ดาวน์โหลด</th>
                                                </tr>
                                            </MDBTableHead>
                                            <MDBTableBody>
                                                <tr>
                                                <td>1</td>
                                                <td className="text-left" >เอกสารหลักฐานแสดงสิทธิการหักลดหย่อนภาษีหัก ณ ที่จ่าย ประจำปี 2564</td>
                                                <td><a href="https://e-payslip.kpru.ac.th/download/d241263.pdf" target="_blank" ><MDBBtn>download</MDBBtn></a></td>
                                                </tr>

                                                <tr>
                                                <td>2</td>
                                                <td className="text-left">แบบแจ้งรายการเพื่อการหักลดหย่อนภาษี</td>
                                                <td><a href="https://e-payslip.kpru.ac.th/download/e241263.pdf" target="_blank"><MDBBtn>download</MDBBtn></a></td>
                                                </tr>
                                               
                                            </MDBTableBody>
                                            </MDBTable>
                                            <MDBAlert color="danger" >
                                                เพื่อความถูกต้องการหักลดหย่อนภาษี จึงขอความอนุเคราะห์ส่งแบบแจ้งรายการเพื่อการหักลดหย่อน ล.ย.01 ประจำปี 2564 พร้อมหลักฐานลดหย่อน ภายในวันที่ 8 มกราคม 2564 ณ งานการเงิน สำนักงานอธิการบดี
                                            </MDBAlert>

                                </MDBModalBody>
                                <MDBModalFooter>
                                {/* <MDBBtn color="secondary" onClick={this.toggle}>ปิด</MDBBtn> */}
                                </MDBModalFooter>
                            </MDBModal>
                            </MDBContainer>
                              
                </div>



                {/* <MDBContainer className="mt-5 mb-5 animated fadeIn">
                    <div className="d-flex justify-content-center mt-5 text-center">
                        <MDBCard style={{ width: "72rem", minHeight: "40rem" }} className={theme} >
                            <MDBCardHeader className={"form-header rgba-indigo-light rounded -hoverable " + theme} >
                                <h2 className={theme} style={{ textAlign: 'center', fontWeight: 'bold' }} >
                                    รายการเงินเดือน
                                </h2>
                            </MDBCardHeader>
                            {detail}
                            <MDBCardBody className="bodytable">


                                <Paper square>
                                    {this.state.list_customer_type !== null ? <>
                                        <Tabs
                                            value={this.state.customer_type_select}
                                            indicatorColor={theme === "dark" ? "secondary" : "primary"}
                                            textColor={theme === "dark" ? "" : "primary"}
                                            onChange={this.set_customer_type_select}
                                            aria-label="disabled tabs example"
                                        >

                                            {this.state.list_customer_type.map((data) => {
                                                return <Tab value={data.customer_type_id} label={data.customer_type_name} />
                                            })}


                                        </Tabs>
                                    </> : <></>}

                                </Paper>

                                {<>{table_data}</>}


                                {this.state.customers_type === "6" || this.state.customers_type === "2" ? <>
                                    <div className="mt-2">
                                        <Alert severity="info">สำหรับ ข้าราชการ และลูกจ้างประจำ สลิปเงินเดือนจะอยู่ไฟล์เอกสารอื่นๆ</Alert>
                                    </div>
                                </> : null}



                                <div className="mt-2">
                                    <Alert severity="info">ขณะนี้มีรายการเงินเดือนเฉพาะ พนักงานราชการ พนักงานมหาวิทยาลัย ข้าราชการ และลูกจ้างประจำ นอกเหนือจากนี้ยังอยู่ในระหว่างดำเนินการ</Alert>
                                </div>

                            </MDBCardBody>

                        </MDBCard>
                    </div>
                </MDBContainer> */}


            </div>

            
        )
    }
}
