import React, { useState, useEffect, useContext, Fragment } from 'react'
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBCollapse, MDBNavItem, MDBIcon, MDBNavLink, MDBBtn, MDBModalHeader, MDBBadge } from 'mdbreact';
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
// import { Link } from 'react-router-dom';
import Toast from 'light-toast';
import { Redirect } from 'react-router-dom';
import useDarkMode from 'use-dark-mode';
import 'antd/dist/antd.css';
import { UnorderedListOutlined, UserOutlined, DownOutlined, CloseCircleOutlined, SyncOutlined, CheckOutlined, CloseOutlined, LoadingOutline, Loading3QuartersOutline } from '@ant-design/icons';
import ReactResizeDetector from 'react-resize-detector';
import { Menu, MenuItem, Badge, Dialog, DialogContent, DialogTitle, Avatar, Divider, Fab, LinearProgress } from '@material-ui/core';
// import PageLoading from './pages/sections/PageLoading';

import { NavLink } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AccountCircle from '@material-ui/icons/AccountCircle';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import Brightness2Icon from '@material-ui/icons/Brightness2';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import dropdownStyle from "./style/dropdownStyle";
import CheckIcon from '@material-ui/icons/Check';
import { theme, detail_user, admin1, admin2, adminSuper } from '../api';
import DarkModeToggle from '../Components/DarkMode/DarkModeToggle';
import Bttn_alert from './Bttn_alert';
import { fontStyle } from '@material-ui/system';
import Link from '@material-ui/core/Link';
import { Icon, Dropdown } from 'antd';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import HomeIcon from '@material-ui/icons/Home';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import InfoIcon from '@material-ui/icons/Info';
import ScrollTop from './ScrollTop';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { Menulist, menuColorList } from './ListMenu';
import MyAvatar from './MyAvatar';




const useStyles = makeStyles((theme) => ({
    ...dropdownStyle(theme),
    // box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    root: {
        flexGrow: 1,
        // boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)'

    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },


}));


export default function NavUser(props) {
  

    const [resize, setresize] = useState(false)

    const darkMode = useDarkMode(false);

    const classes = useStyles();

    const [open1, setopen1] = React.useState(null);
    const [open2, setopen2] = React.useState(null);
    const [openList, setopenList] = React.useState([
        {
            menu1: null
        },
        {
            menu2: null
        },
    ]);
  
    const closeMenu = () => {
        // setanchorEl(null)
        setopenList([
            {
                menu1: null
            },
            {
                menu2: null
            },
            {
                menu3: null
            },
            {
                menu4: null
            },
            {
                menu5: null
            },
        ])
    };

    const handleClose = () => {
        setopen1(null)
        setopen2(null)
        setopenList(null)
    };
    

    let onResize = (e) => {
        setresize(e)
    }


    let onGo = (to) => {
        props.history.push(to)
        closeMenu()
    }

    let href = window.location.href.split('/')
    let href_main = href[3]
    let href_admin = href[4]
    let menu = ""
    if (href_admin === "customers"
        || href_admin === "customers_manage"
        || href_main === "FormPageList") {
        menu = "2"

    } else if (href_admin === "payslip_manage"
        || href_admin === "payslip_list_manage"
        || href_admin === "PaySlipExpensetype"
        || href_admin === "PayslipRevenueType"
        || href_admin === "Payslip_export_import_data"
        || href_admin === "PaySlipManageUpdateDataEx"
        || href_admin === "PaySlipManageUpdateDataRe"
        || href_admin === "PaySlipManageUpdateDataSalary"
    ) {
        menu = "1"
    }

    else if (href_admin === "PaySlipExpensetypeReport"
        || href_admin === "Excelreportipay"
        || href_admin === "PageShowListSalaryEdit"
        || href_admin === "PageListHistoryPrintAdmin"
        || href_admin === "PageNotify"
    ) {
        menu = "3"
    }



    let fontcolor = theme === "dark" ? "white" : "black"



    let setSalary_BG = (c) => {
        localStorage.setItem('Salary_BG', c)
        window.location.reload()
    }

    let BGColor = (<Fragment>
        {menuColorList.map((data) => {
            return <>
                <Button
                    onClick={(event) => {
                        let dselect = { ["menu" + data.menu]: event.currentTarget }
                        setopenList(openList[Number(data.menu - 1)] = dselect)
                    }} >
                    <MDBIcon icon="fill" /> {data.name}
                </Button> &nbsp;



                <Menu
                    style={{ marginTop: '2rem' }}
                    anchorEl={eval("openList.menu" + data.menu)}
                    getContentAnchorEl={null}
                    // keepMounted
                    anchorOrigin={{ vertical: 'top', horizontal: 'left', }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left', }}
                    open={Boolean(eval("openList.menu" + data.menu))}
                    onClose={closeMenu}
                >

                    {data.subMenu.map((data2) => {
                        return <>
                            <MenuItem
                                selected={data2.color === localStorage.getItem('Salary_BG') ? true : false}
                                className={classes.dropdownItem}
                                onClick={() => setSalary_BG(data2.color)} >
                                <CheckBoxOutlineBlankIcon style={{
                                    backgroundColor: data2.color
                                }} />
                                {data2.name}
                            </MenuItem>
                        </>
                    })}


                </Menu>

            </>
        })}
    </Fragment>)



    let ItemListMenu = (
        <>
            {Menulist.map((data) => {
                return <>

                    {data.subMenu ? <>
                        <Button

                            variant={menu === data.menu ? "outlined" : ""}
                            color={menu === data.menu ? "" : "primary"}
                            onClick={(event) => {
                                let dselect = { ["menu" + data.menu]: event.currentTarget }
                                setopenList(openList[Number(data.menu - 1)] = dselect)
                            }} >
                            {data.icon}&nbsp;{data.name} &nbsp;<MDBIcon icon="caret-down" />
                        </Button> &nbsp;



                          <Menu
                            style={{ marginTop: '2rem' }}
                            anchorEl={eval("openList.menu" + data.menu)}
                            getContentAnchorEl={null}
                            anchorOrigin={{ vertical: 'top', horizontal: 'left', }}
                            transformOrigin={{ vertical: 'top', horizontal: 'left', }}
                            open={Boolean(eval("openList.menu" + data.menu))}
                            onClose={closeMenu}
                        >

                            {data.subMenu.map((data2) => {
                                return <>
                                    <MenuItem
                                        selected={href_admin === data2.path.replace('/admin/', '').replace('/', '') ? true : false}
                                        className={classes.dropdownItem}
                                        onClick={() => onGo(data2.path)} >
                                        {data2.icon} {data2.name}
                                    </MenuItem>
                                </>
                            })}


                        </Menu>
                    </> : <>



                            {resize >= 1199 ? <>
                                {detail_user.admin_type === undefined ? <>
                                    <NavLink
                                        activeClassName="btnNav"
                                        style={{ color: href_main === data.path.replace('/admin/', '').replace('/', '') ? "white" : fontcolor }}

                                        exact={true}
                                        to={data.path}
                                    >
                                        <MenuItem  >   {data.icon} {data.name}</MenuItem>
                                    </NavLink>


                                </> : <>
                                        <Button
                                            variant={(href_main + "/" + (href_admin !== undefined ? href_admin : '')).replace('/', '') === data.path.replace('/', '') ? "outlined" : ""}
                                            onClick={() => onGo(data.path)}
                                        >
                                            {data.icon}&nbsp;{data.name}
                                        </Button> &nbsp;
                                    </>}

                            </> : <>
                                    <MenuItem
                                        className={classes.dropdownItem}
                                        onClick={() => onGo(data.path)} >
                                        {data.icon} {data.name}
                                    </MenuItem>
                                </>}

                        </>}


                </>
            })}
        </>
    )



    return (

        <>
            <ReactResizeDetector handleWidth handleHeight onResize={onResize} />

            <div className={classes.root}>
                <AppBar
                    color="white"
                    position="static"
                    className={" flexible-navbar "} >
                    <Toolbar>
                        {resize <= 1199 ? <>
                            <IconButton onClick={props.onChangeSideBar} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                                <MenuIcon />
                            </IconButton>
                        </> : null}


                        <Typography >
                            <strong style={{ color: theme === "dark" ? '#fff' : '#0099CC' ,fontWeight:'bold'}} > <MDBIcon far icon="credit-card" /> PaySlip KPRU  &emsp;</strong>
                        </Typography>


                        {resize >= 1199 ? <>
                            {ItemListMenu}
                        </> : null}

                        <div className={classes.grow} />
                        <DarkModeToggle />

                        {props.list_alert_all.loadstatus === true ? <><Icon type="loading" /> </> :
                            <Bttn_alert list_alert_all={props.list_alert_all} get_alert={props.get_alert} />}




                        {resize >= 1199 ? <>
                            {detail_user.admin_type === admin1 || detail_user.admin_type === admin2 || detail_user.admin_type === adminSuper ? <>
                                {BGColor}
                            </> : null}

                            <Button onClick={(event) => setopen1(event.currentTarget)} >
                              
                                < MyAvatar />
                                &nbsp;
                                 <text className="font16">{resize >= 1199 ? detail_user.nameuser : null} </text>
                                <ExpandMoreIcon />
                            </Button>



                            <Menu
                                style={{ marginTop: '2rem' }}
                                anchorEl={open1}
                                keepMounted
                                open={Boolean(open1)}
                                onClose={() => setopen1(null)}
                                anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                                transformOrigin={{ vertical: 'top', horizontal: 'right', }}
                            >


                                <NavLink className={"font-" + theme} exact={true} to="/"  >
                                    <MenuItem
                                        className={classes.dropdownItem}
                                        onClick={() => {
                                            handleClose()
                                            localStorage.removeItem('tokenVerify')
                                            Toast.success('ออกจากระบบสำเร็จ', 400)
                                        }}
                                    ><ExitToAppIcon /> ออกจากระบบ</MenuItem>
                                </NavLink>


                            </Menu>
                        </> : null}
               







                    </Toolbar>
                </AppBar>
            </div>


        </>
    )
}