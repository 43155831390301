import React, { Component } from 'react'
import { MDBCard, MDBContainer, MDBCol, MDBRow, MDBCardHeader } from 'mdbreact';
import Chart_salary from './Chart_salary';
import Chart_count_employee from './Chart_count_employee';
import Chat_salary_year_total from './Chat_salary_year_total';
import Chat_salary_month_total from './Chat_salary_month_total';
export default class Bar extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    render() {
        return (
            <MDBContainer className="mt-5 mb-5">

                <Chart_salary />
                <Chart_count_employee />

                <Chat_salary_year_total />
                <Chat_salary_month_total />
            </MDBContainer>
        );
    }

}
