import React, { Component } from 'react'
import { Modal, Button, Input } from 'antd';
import { apilogin } from '../api';
import Text from 'antd/lib/typography/Text';
import { MDBRow, MDBCol } from 'mdbreact';

export default class Bttn_customer_edit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            bank: [],
            customer_status: [],
            customer_type: [],

            visible: false,
            customers_citizent: "",
            customers_pname: "",
            customers_name: "",
            customers_lname: "",
            customers_type: "",
            customers_bank: "",
            customers_status: "",
        }
        this.onChange = this.onChange.bind(this)
    }
    getbank() {
        apilogin.get('getdata/getbank')
            .then(res => {
                this.setState({ bank: res.data })
            })
    }
    get_customer_status() {
        apilogin.get('getdata/customer_status')
            .then(res => {
                this.setState({ customer_status: res.data })
            })

    }

    get_customer_type() {
        apilogin.get('getdata/customer_type')
            .then(res => {
                this.setState({ customer_type: res.data })
            })
    }

    showModal = () => {
        this.postid()
        this.getbank()
        this.get_customer_status()
        this.get_customer_type()
        this.setState({
            visible: true,
        });
    };

    handleOk = e => {
        // alert(JSON.stringify(this.state))
        apilogin.post('insert_customers/updatedata_for_edit_customers', ({
            customers_citizent: this.state.customers_citizent,
            prename: this.state.customers_pname,
            name: this.state.customers_name,
            lname: this.state.customers_lname,
            type_customer: this.state.customers_type,
            // bank: this.state.customers_bank,
            customers_status: this.state.customers_status,
        }))
            .then(res => {
                this.props.updatedata_props()
                
                // apilogin.post('insert_customers/update_bank_account_bank', ({
                //     customers_citizent: this.state.customers_citizent,
                //     bank_id: this.state.customers_bank,
                // }))
                //     .then(res => {
                //         this.props.updatedata_props()
                //     });
                
            });
        this.setState({
            visible: false,
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    postid() {
        apilogin.post('insert_customers/getdata_for_edit_customers', ({ customers_citizent: this.props.customers_citizent, customers_type_id: this.props.customers_type_id }))
            .then(res => {
                //console.log(res.data[0].customers_citizent)
                this.setState({
                    customers_citizent: res.data[0].customers_citizent,
                    customers_pname: res.data[0].customers_pname,
                    customers_name: res.data[0].customers_name,
                    customers_lname: res.data[0].customers_lname,
                    customers_type: res.data[0].customers_type,
                    // customers_bank: res.data[0].customers_bank,
                    customers_status: res.data[0].customers_status,
                });
            });

    }

    onChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        })
    }
    render() {
        return (
            <>
                <Button icon="edit" size="small"   onClick={this.showModal}>
                    แก้ไข
                </Button>
                <Modal
                    centered
                    title={<><strong>แก้ไขข้อมูลบุคลากร</strong> </>}
                    visible={this.state.visible}
                    okText="บันทึก"
                    cancelText="ยกเลิก"
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >

                    <MDBRow >
                        <MDBCol sm="6" className="mt-2" >
                            <Text strong style={{ textAlign: 'center', fontSize: '16px' }}> รหัสประจำตัวประชาชน </Text>
                            <input disabled className="form-control" value={this.state.customers_citizent} onChange={this.onChange}
                                type="text" name="customers_citizent" placeholder="กรุณาระบุรหัสประจำตัวประชาชน" />
                        </MDBCol>
                        <MDBCol sm="6" className="mt-2" >
                            <Text strong style={{ textAlign: 'center', fontSize: '16px' }}> คำนำหน้าชื่อ </Text>
                            <input className="form-control" value={this.state.customers_pname} onChange={this.onChange}
                                type="text" name="customers_pname" placeholder="กรุณาระบุคำนำหน้าชื่อ" />
                        </MDBCol>
                    </MDBRow>

                    <MDBRow >
                        <MDBCol sm="6" className="mt-2" >
                            <Text strong style={{ textAlign: 'center', fontSize: '16px' }}> ชื่อจริง </Text>
                            <input className="form-control" value={this.state.customers_name} onChange={this.onChange}
                                type="text" name="customers_name" placeholder="กรุณาระบุชื่อจริง" />
                        </MDBCol>
                        <MDBCol sm="6" className="mt-2" >
                            <Text strong style={{ textAlign: 'center', fontSize: '16px' }}> นามสกุล </Text>
                            <input className="form-control" value={this.state.customers_lname} onChange={this.onChange}
                                type="text" name="customers_lname" placeholder="กรุณาระบุนามสกุล" />
                        </MDBCol>
                    </MDBRow>

                    <MDBRow >
                        <MDBCol sm="6" className="mt-2" >
                            <Text strong style={{ textAlign: 'center', fontSize: '16px' }}> ประเภทบุคลากร </Text>
                            <select disabled className="form-control" value={this.state.customers_type} name="customers_type" onChange={this.onChange} >
                                <option >เลือกประเภทบุคลากร</option>
                                {this.state.customer_type.map((data) => {
                                    return <option value={data.customer_type_id}>{data.customer_type_name}</option>
                                })}

                            </select>
                        </MDBCol>
                        <MDBCol sm="6" className="mt-2" >
                            <Text strong style={{ textAlign: 'center', fontSize: '16px' }}> สถานะการทำงาน </Text>
                            <select className="form-control" value={this.state.customers_status} name="customers_status" onChange={this.onChange} >
                                <option >เลือกสถานะการทำงาน</option>
                                {this.state.customer_status.map((data) => {
                                    return <option value={data.customer_status_id}>{data.customer_status_name}</option>
                                })}

                            </select>
                        </MDBCol>
                    </MDBRow>

                    {/* <MDBRow >
                        <MDBCol sm="12" className="mt-2" >
                            <Text strong style={{ textAlign: 'center', fontSize: '16px' }}> ธนาคาร</Text>
                            <select className="form-control" value={this.state.customers_bank} name="customers_bank" onChange={this.onChange} >
                                <option >เลือกธนาคาร</option>
                                {this.state.bank.map((data) => {
                                    return <option value={data.bank_id}>{data.bank_name}</option>
                                })}

                            </select>
                        </MDBCol>
                    </MDBRow> */}
                </Modal>
            </>
        )
    }
}
