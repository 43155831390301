import React, { Component } from 'react'
import { admin1, admin2, adminSuper, detail_user, apilogin, year, mouth, options_mounth } from '../api';
import { message, Button } from 'antd';
import { MDBAlert, MDBModalBody } from 'mdbreact';
import { MDBTable, MDBTableBody, MDBModal, MDBModalHeader, MDBTableHead } from 'mdbreact/dist/mdbreact.esm';

export default class Check_error extends Component {
    state = {
        error_count: 0,
        error_data: [],
        modal: false,
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    check = () => {
        const admin = detail_user;
        if (admin) {
            if (admin.admin_type) {
                let token = admin.admin_type
                if (token === admin1 || token === admin2 || token === adminSuper) {
                    let list_customers = []

                    apilogin.get('getdata/customer_type')
                        .then(res => {

                            let customer_type = []
                            let customer_type_1 = []
                            let customer_type_2 = []


                            res.data.map((data) => {
                                customer_type.push(data)
                                if (data.admin_level === "1") {
                                    customer_type_1.push(data)
                                }
                                else if (data.admin_level === "2") {
                                    customer_type_2.push(data)
                                } else {
                                    customer_type.push(data)
                                }
                            })

                            if (admin.admin_type === "super") {
                                list_customers = customer_type
                            } else if (admin.admin_type === "adminlevel1") {
                                list_customers = customer_type_1
                            } else if (admin.admin_type === "adminlevel2") {
                                list_customers = customer_type_2
                            }


                            let datasent = {
                                payslip_year: year,
                                payslip_month: mouth,
                                list_customers: list_customers,
                            }
                            apilogin.post('getdata/check_error', datasent)
                                .then((res) => {
                                    this.setState({
                                        error_count: res.data.length,
                                        error_data: res.data
                                    })
                                })
                                .catch((err) => {
                                    message.error('ล้มเหลว ' + err)
                                })

                        })
                    // }





                    // /////////////////////////////

                }
            }
        }
    }
    componentDidMount() {
        this.check()
        
        setInterval(() => {
            this.check()
        }, 300000); // 5นาที


    }

    render() {
        return (
            <>
                {this.state.error_count >= 1 ? <>
                    <div className="mt-5 mb-1 container">
                        <MDBAlert color="danger" >
                            พบข้อมูลที่ติดลบ {this.state.error_count} รายการ <Button onClick={this.toggle} size="small">ตรวจสอบ</Button>
                        </MDBAlert>


                        <MDBModal size="lg" isOpen={this.state.modal} toggle={this.toggle}>
                            <MDBModalHeader toggle={this.toggle}>ตรวจสอบรายการที่ผิดพลาด</MDBModalHeader>
                            <MDBModalBody>
                                เดือน {options_mounth.map((data) => data.value === mouth ? data.label : null)} ปี {Number(year) + 543}
                                <MDBTable>
                                    <MDBTableHead>
                                        <tr>
                                            <th>#</th>
                                            <th>ชื่อ-นามสกุล</th>
                                            <th>ประเภท</th>
                                            <th>รายรับ</th>
                                            <th>รายจ่าย</th>
                                            <th>ยอดรวม</th>

                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {this.state.error_data.map((data, index) => [
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{data.customers_name} {data.customers_lname}</td>
                                                <td>{data.customer_type_name}</td>
                                                <td>{(data.history_salary_salary - data.payslip_revenue).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</td>
                                                <td>{data.payslip_expenditure.toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</td>
                                                <td>{data.total.toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</td>
                                            </tr>
                                        ])}
                                    </MDBTableBody>
                                </MDBTable>
                            </MDBModalBody>
                        </MDBModal>
                    </div>

                </> : <>

                    </>}
            </>
        )
    }
}
