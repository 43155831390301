import React, { Component } from 'react'
import { Paper, CardHeader, Divider } from '@material-ui/core';
import { CardBody } from 'mdbreact/dist/mdbreact.esm';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import CropIcon from '@material-ui/icons/Crop';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';


import i1_01_1 from '../img/howtoconvertpdf/i1_01_1.JPG'
import i1_02_1 from '../img/howtoconvertpdf/i1_02_1.JPG'
import i1_03_1 from '../img/howtoconvertpdf/i1_03_1.JPG'
import i1_04_1 from '../img/howtoconvertpdf/i1_04_1.JPG'
import i1_05_1 from '../img/howtoconvertpdf/i1_05_1.JPG'



import i2_01_1 from '../img/howtoconvertpdf/i2_01_1.JPG'
import i2_02_1 from '../img/howtoconvertpdf/i2_02_1.JPG'
import i2_03_1 from '../img/howtoconvertpdf/i2_03_1.JPG'
import i2_04_1 from '../img/howtoconvertpdf/i2_04_1.JPG'
import i2_05_1 from '../img/howtoconvertpdf/i2_05_1.JPG'
import i2_05_2 from '../img/howtoconvertpdf/i2_05_2.JPG'
import i2_06_1 from '../img/howtoconvertpdf/i2_06_1.JPG'
import { MDBIcon } from 'mdbreact';




export default class PagesHowtoConvertPDF extends Component {
    render() {

        let dataList = [
            {
                title: <><CropIcon /> ขั้นตอนที่ 1 การแบ่งส่วน ตัดครึ่ง ( Crop )</>,
                description: 'ใช้สำหรับต้องการแบ่ง PDF เป็นส่วนๆ เช่นสลิปเงินเดือนข้าราชการที่ต้องทำมาตัดหรือแยกออกก่อน',
                detail: [{
                    id: '1',
                    img: [i1_01_1],
                    title: <>เข้าเว็บ <a target="_blank" href="https://pdfresizer.com/crop"  >https://pdfresizer.com/crop</a></>,
                    detail: <></>
                },
                {
                    id: '2',
                    img: [i1_02_1],
                    title: <>เลือกไฟล์ จากนั้นคลิกอัพโหลด</>,
                    detail: <></>
                },
                {
                    id: '3',
                    img: [i1_03_1],
                    title: <>ลากตามขนาดที่ต้องการ จากนั้นคลิก Crop It</>,
                    detail: <></>
                },
                {
                    id: '4',
                    img: [i1_04_1],
                    title: <>จากนั้นคลิก download จะได้ไฟล์ที่ตัดแล้ว</>,
                    detail: <>ทำอีกครั้งนึง โดย crop ด้านล่างต่อ</>
                },
                {
                    id: '4',
                    img: [i1_05_1],
                    title: <>จะได้ไฟล์ทั้งหมดมา 2 ไฟล์ แล้วตั้งชื่อให้เรียบร้อย</>,
                    detail: <></>
                }
                ]
            },

            {
                title: <><CallSplitIcon /> ขั้นตอนที่ 2 การแยกไฟล์ PDF จากไฟล์เดียว ให้เป็นหลายๆไฟล์ ( Split )</>,
                description: 'เมื่อได้ไฟล์มาแล้ว ต่อไปก็มาแยกไฟล์ ',
                detail: [
                    {
                        id: '1',
                        img: [i2_01_1],
                        title: <> คลิกที่ลิ้ง <a target="_blank" href="https://pdfresizer.com/split/"  >https://pdfresizer.com/split/</a></>,
                        detail: <></>
                    },
                    {
                        id: '2',
                        img: [i2_02_1],
                        title: <>เลือกไฟล์ที่โหลดจากขั้นตอนก่อนหน้า </>,
                        detail: <></>
                    },
                    {
                        id: '3',
                        img: [i2_03_1],
                        title: <>คลิก " Looks good, do the magic "</>,
                        detail: <></>
                    },
                    {
                        id: '4',
                        img: [i2_04_1],
                        title: <>จากนั้น คลิก Download</>,
                        detail: <></>
                    },
                    {
                        id: '5',
                        img: [i2_05_1, i2_05_2],
                        title: <>จะได้เป็นไฟล์ zip จากนั้น คลิกขวาไฟล์นี้ แล้วแตกไฟล์ตามภาพ</>,
                        detail: <></>
                    },
                    {
                        id: '6',
                        img: [i2_06_1],
                        title: <>จะได้ไฟล์ pdf ที่พร้อมอัพโหลด สำหรับเอกสารต่างๆแล้ว</>,
                        detail: <>จากนั้นทำแบบนี้อีกครั้งกับไฟล์ที่เหลือจากขั้นตอนที่ 1</>
                    },

                ]
            },


        ]
        return (
            <>
                <Paper className="container" elevation="3" >
                    <CardHeader title={<span><MDBIcon far icon="lightbulb" /> เทคนิคการแยกไฟล์ PDF แบบง่ายๆ</span>} />
                    <Divider />
                   

                </Paper>

                <div className="mt-3 container" >


                    {dataList.map((data1) => (
                        <>
                            <Accordion
                            //defaultExpanded={1}
                            >

                                <AccordionSummary
                                    //key={1}
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography style={{ fontSize: '24px' }} >{data1.title}</Typography>
                                </AccordionSummary>


                                <div className="container" >
                                  <text style={{ fontSize: '22px' }} >&emsp;{data1.description}</text> <br /><br />
                                    {data1.detail.map((data2) => (
                                        <>
                                            <text style={{ fontSize: '22px' }} >- {data2.title}</text> <br />
                                            <text style={{ fontSize: '22px' }} >{data2.detail}</text>
                                            {data2.img.map((img) => (
                                                <>
                                                    <img src={img} width="100%" />
                                                </>
                                            ))}
                                            <br />  <br />  <br />

                                        </>
                                    ))}
                                </div>



                                <br />
                            </Accordion>
                        </>
                    ))}


                    <br />  <br />  <br />
                </div>
            </>
        )
    }
}
