import axios from 'axios';
import moment from 'moment';
import jwtDecode from 'jwt-decode'
import { message } from 'antd';




let tokenVerify = ""
if (localStorage.getItem('tokenVerify')) {
  tokenVerify = localStorage.getItem('tokenVerify')
}


let url = 'https://e-payslip.kpru.ac.th/api/'
// let url = 'http://10.1.210.68:5000/api/'

//*********************************** */
// const api = axios.create({
//   baseURL: 'https://e-payslip.kpru.ac.th/api/'
// });

// const apilogin = axios.create({
//   baseURL: 'https://e-payslip.kpru.ac.th/api/',
//   headers: {
//     'Authorization': tokenVerify
//   }
// });
//*********************************** */


let admin1 = "adminlevel1"
let admin2 = "adminlevel2"
let adminSuper = "super"


//********************************** */
const api = axios.create({
  baseURL: url
});
const apilogin = axios.create({
  baseURL: url,
  headers: {
    'Authorization': tokenVerify
    // 'Authorization': 'Bearer ' + window.localStorage.getItem('auth')
  }
});
apilogin.interceptors.response.use(
  response => response,
  error => {

    const { status } = error.response;
    if (status === 500 || status === 401) {
      // if (status === 401) {
      // localStorage.clear()
      localStorage.removeItem('token')
      localStorage.removeItem('tokenVerify')
      window.location.href = '/login'
      //alert('no')
      // dispatch(userSignOut());
    }
    else {
      message.info('มีปัญหาในการโหลดข้อมูล')
    }
    //return Promise.reject(error);
  }
);

// *****************


//-------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------

let theme_check = localStorage.getItem('darkMode')
let theme = "light"
if (theme_check === "true") {
  theme = "dark"
}
else {
  theme = "light"
}

let Salary_BG_check = localStorage.getItem('Salary_BG')
let Salary_BG = ""
if (Salary_BG_check !== undefined) {
  Salary_BG = localStorage.getItem('Salary_BG')
}
//*********** */
let mouth = Number(moment().format('MM'))
mouth = String(mouth).length <= 1 ? "0" + mouth : String(mouth)
let year = String(Number(new Date().getFullYear()))


let options_mounth = [
  { value: '01', label: 'มกราคม' },
  { value: '02', label: 'กุมภาพันธ์' },
  { value: '03', label: 'มีนาคม' },
  { value: '04', label: 'เมษายน' },
  { value: '05', label: 'พฤษภาคม' },
  { value: '06', label: 'มิถุนายน' },
  { value: '07', label: 'กรกฎาคม' },
  { value: '08', label: 'สิงหาคม' },
  { value: '09', label: 'กันยายน' },
  { value: '10', label: 'ตุลาคม' },
  { value: '11', label: 'พฤศจิกายน' },
  { value: '12', label: 'ธันวาคม' },
]

let mouth_name = ""
// for (let i = 0; i < options_mounth.length; i++) {
//   if (mouth === options_mounth[i].value)
//   mouth_name = options_mounth[i].label
// }



let user = localStorage.getItem('tokenVerify')
let detail_user = null
if (user) {
  let decode = jwtDecode(user)
  if (decode.admin_type) {
    detail_user = {
      admin_type: decode.admin_type,
      nameuser: decode.nameuser,
      employee_id: decode.employee_id,
      exp: decode.exp,
      id: decode.id,
      login_status: '1'
    }

  } else if (decode.Username) {
    detail_user = {
      Username: decode.Username,
      nameuser: decode.nameuser,
      prefix_name: decode.prefix_name,
      frist_name: decode.frist_name,
      last_name: decode.last_name,
      rank_name: decode.rank_name,
      organization_name: decode.organization_name,
      employee_id: decode.employee_id,
      img_user: 'https://mis.kpru.ac.th/images/pic_emp_50/' + decode.employee_id + '.jpg',
      telephone: decode.telephone,
      id: decode.id, //สำหรับส่ง view
      exp: decode.exp, //เวลาที่โทเคนหมดอายุ
      login_status: '1'
    }
  } else {
    // detail_user = null
    // detail_user = {}
    // window.location.href="/login"
  }

}

let fontpdf = {
  THSarabunNew: {
    normal: 'https://e-payslip.kpru.ac.th/fonts/THSarabunNew.ttf',
    bold: 'https://e-payslip.kpru.ac.th/fonts/THSarabunNew_Bold.ttf',
    italics: 'https://e-payslip.kpru.ac.th/fonts/THSarabunNew_Italic.ttf',
    bolditalics: 'https://e-payslip.kpru.ac.th/fonts/THSarabunNew_BoldItalic.ttf'
  },
  FontAwesome: {
    normal: 'https://e-payslip.kpru.ac.th/fonts/fontawesome-webfont.ttf',
    bold:  'https://e-payslip.kpru.ac.th/fonts/fontawesome-webfont.ttf',
    italics:  'https://e-payslip.kpru.ac.th/fonts/fontawesome-webfont.ttf',
    bolditalics:  'https://e-payslip.kpru.ac.th/fonts/fontawesome-webfont.ttf',
  },
  Roboto: {
    normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
    bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
    italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
    bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
  },
}
// console.log(detail_user)


export { api, apilogin, admin1, admin2, adminSuper, theme, mouth, year, options_mounth, detail_user, url, Salary_BG, mouth_name, fontpdf };
