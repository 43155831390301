import React, { Component } from 'react'
import { apilogin, theme } from '../../api'
import { Table, Input, Icon, Card, Button, InputNumber, message, Spin } from 'antd'
import { MDBContainer, MDBCardBody, MDBCardHeader, MDBCard, MDBRow, MDBCol, MDBIcon } from 'mdbreact'
import Highlighter from 'react-highlight-words';
import Bttn_customer_add_expenditure from '../../Components/Bttn_customer_add_expenditure';
import NumberFormat from 'react-number-format';
import Select from 'react-select'
import Bttn_customer_add_revenue from '../../Components/Bttn_customer_add_revenue';
import PrintPDF from '../PrintPDF';
import Chart from 'react-google-charts';
import ReactApexChart from 'react-apexcharts';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import Bttn_search_date from '../../Components/Bttn_search_date';



export default class Chart_count_employee extends Component {

    constructor(props) {
        super(props)
        this.state = {

            // sum_type_id1: 1,
            // sum_type_id2: 1,
            // sum_type_id3: 1,
            // sum_type_id4: 1,
            // sum_type_id5: 1,
            loading: true,

        }
        this.updatedata = this.updatedata.bind(this)
        this.get_customer_status = this.get_customer_status.bind(this)

    }

    onChange(event) {
        //  console.log(event.target)

        this.setState({
            [event.target.name]: event.target.value,
            // month:
        })
    }


    get_customer_status() {


        apilogin.get('getdata/get_count_employee/')
            .then(res => {
                // this.setState({
                //     data: []
                // });
                this.updatedata(res.data)
            })


    }

    updatedata(data) {

        // console.log(data)

        let sum_type_id1 = 0
        let sum_type_id2 = 0
        let sum_type_id3 = 0
        let sum_type_id4 = 0
        let sum_type_id5 = 0
        let sum_type_id6 = 0
        let sum_type_id7 = 0

        for (let i = 0; i < data.length; i++) {
            // console.log(data[i].customers_type)
            if (data[i].customers_type === "1") {
                sum_type_id1 = sum_type_id1 + 1
            }
            else if (data[i].customers_type === "2") {
                sum_type_id2 = sum_type_id2 + 1
            }
            else if (data[i].customers_type === "3") {
                sum_type_id3 = sum_type_id3 + 1
            }
            else if (data[i].customers_type === "4") {
                sum_type_id4 = sum_type_id4 + 1
            }
            else if (data[i].customers_type === "5") {
                sum_type_id5 = sum_type_id5 + 1
            }
            else if (data[i].customers_type === "6") {
                sum_type_id6 = sum_type_id6 + 1
            }
            else if (data[i].customers_type === "7") {
                sum_type_id7 = sum_type_id7 + 1
            }

        }


        this.setState({
            search_check: true,
            loading: false,
            sum_type_id1: sum_type_id1,
            sum_type_id2: sum_type_id2,
            sum_type_id3: sum_type_id3,
            sum_type_id4: sum_type_id4,
            sum_type_id5: sum_type_id5,
            sum_type_id6: sum_type_id6,
            sum_type_id7: sum_type_id7,

        })



    }


    componentDidMount() {
        this.get_customer_status()
        // this.get_year()

    }



    render() {


        let optionspie = {
            labels: ['ลูกจ้างชั่วคราว', 'ลูกจ้างประจำ', 'พนักงานราชการ', 'พนักงานมหาวิทยาลัย', 'อาจารย์ประจำสัญญาจ้าง', 'ข้าราชการ/ข้าราชการพลเรือน', 'อาจารย์ต่างชาติ'],
            colors: ['#f44336', '#ffc107'  , '#2B9EDF'  , '#7D2BDF'    , '#2B39DF' , '#DF2BAE' , '#DF2B44'  ],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
            theme: {
                mode: theme, 
                palette: 'palette1', 
            },
        }
     
        let seriespie = [this.state.sum_type_id1, this.state.sum_type_id2, this.state.sum_type_id3, this.state.sum_type_id4, this.state.sum_type_id5, this.state.sum_type_id6, this.state.sum_type_id7]

      
        let options = {
            colors: ['#f44336', '#ffc107'  , '#2B9EDF'  , '#7D2BDF'    , '#2B39DF' , '#DF2BAE' , '#DF2B44'  ],
            theme: {
                mode: theme, 
                palette: 'palette1', 
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    dataLabels: {
                        position: 'top',
                    },


                },



            },
            dataLabels: {
                enabled: true,
                offsetX: 0,
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: [theme==="dark"?"":"#000"]
                },
                formatter: function (value) {
                    return value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }) + " "
                }


            },
            stroke: {
                show: true,
                width: 1,
                colors: ['#fff'],


            },



            xaxis: {
                categories: ["จำนวน (คน)"],
            },
            yaxis: {
                labels: {
                    formatter: function (value) {
                        return value.toLocaleString(navigator.language, { minimumFractionDigits: 0 }) + " "
                    }
                },
            },
        }
        let series = [{
            name: 'ลูกจ้างชั่วคราว',
            data: [this.state.sum_type_id1]

        }, {
            name: 'ลูกจ้างประจำ',
            data: [this.state.sum_type_id2]
        }, {
            name: 'พนักงานราชการ',
            data: [this.state.sum_type_id3]

        }, {
            name: 'พนักงานมหาวิทยาลัย',
            data: [this.state.sum_type_id4]
        }, {
            name: 'อาจารย์ประจำสัญญาจ้าง',
            data: [this.state.sum_type_id5]
        },{
            name: 'ข้าราชการ/ข้าราชการพลเรือน',
            data: [this.state.sum_type_id6]
        },{
            name: 'อาจารย์ต่างชาติ',
            data: [this.state.sum_type_id7]
        },
    ]

        return (
            <div>
               
                <MDBContainer className="mt-5 mb-5 text-center">
                    <MDBCard
                    className={""+theme}
                    // className={theme}
                        style={{

                            // width: "65rem"
                            //   , minHeight: "40rem"
                        }}
                    >
                        <MDBCardHeader className={"form-header rgba-indigo-light rounded -hoverable "+theme} >
                            <Text className={""+theme} style={{ textAlign: 'center', fontSize: '20px' }} >
                            <MDBIcon icon="users" /> สรุปจำนวนพนักงาน
                                </Text>
                        </MDBCardHeader>

                        <MDBCardBody className="bodytable mt-3">
                            <MDBContainer className="col-10 text-left">
                                
                              
                                    {this.state.loading ? <div className="text-center d-flex justify-content-center"> 
                                     <Spin tip="Loading..." spinning={this.state.loading}/>
                                    
                                    </div>:<>
                                        <MDBRow>
                                            <MDBCol lg="6" className="mt-4">
                                                <ReactApexChart  options={optionspie} series={seriespie} type="pie" width="110%"/>
                                            </MDBCol>
                                            <MDBCol lg="6" className="mt-4">
                                                <ReactApexChart  options={options} series={series} type="bar" />
                                            </MDBCol>
                                        </MDBRow>
                                     
                                     
                                    </>}
                                   
                              


                            </MDBContainer>

                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>



            </div>
        )
    }
}
