import React, { Component } from 'react'
import ReactExport from "react-export-excel";
import { Modal, Button, Input, message, InputNumber, Icon, Form, Spin } from 'antd';
import { apilogin } from '../api'
import { MDBIcon } from 'mdbreact';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class Bttn_export_excel_all_person extends Component {
    constructor(props) {
        super(props)
        this.state = {
            load: false,
            load_check: false,
            data: [],
            expenditure: [],
            revenue: [],
            list_expenditure: [],
            list_revenue: [],
            test: "1",

        }
        this.get_data = this.get_data.bind(this)
    }

 
    get_data() {
        this.setState({ load: true })


        apilogin.post('getdata/excel_export_person', ({
            payslip_year: this.props.payslip_year,
            payslip_month: this.props.payslip_month,
            customers_type: this.props.customers_type,
        }))
            .then((res) => {
                let data_person = res.data
                let person = []

                for (let i = 0; i < data_person.length; i++) {
                    let person1 = {
                        index: i + 1,
                        customers_citizent: data_person[i].customers_citizent,
                        pname: data_person[i].customers_pname,
                        fname: data_person[i].customers_name,
                        lname: data_person[i].customers_lname,
                        account_number: data_person[i].account_number,
                        bank_name: data_person[i].bank_name,
                        bank_id: data_person[i].bank_id,
                        customer_type_name: data_person[i].customer_type_name,
                        customers_type: data_person[i].customers_type,
                        Salary: data_person[i].Salary,
                    }
                    person.push(person1)

                }

                let list_expenditure = []
                for (let p = 0; p < person.length; p++) {
                    apilogin.get(`getdata/excel_export_person_expenditure/${this.props.payslip_year}/${this.props.payslip_month}/${this.props.customers_type}/${person[p].customers_citizent}`)
                        .then((res) => {
                          //  console.log(res.data)
                            let data_ex = res.data
                            let expenditure = []
                            for (let i = 0; i < data_ex.length; i++) {
                                let name = data_ex[i].expenditure_name.replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                                    .replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                                    .replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                                    .replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                                    .replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                              if ( data_ex[i].payslip_total >= 0) {
                                   let rr = {
                                    name: name,
                                     
                                    total: data_ex[i].payslip_total 
                                }
                                expenditure.push(rr)
                              } else {
                                let rr = {
                                    name: name,
                                    total: 0
                                }
                                expenditure.push(rr)
                              }
                                   
                           

                                if (i === data_ex.length - 1) {
                                    list_expenditure[p] = expenditure
                                }
                            }


                            if (p === person.length - 1) {
                                setTimeout(() => {
                                    for (let l = 0; l < person.length; l++) {

                                        let count = 0
                                        try {
                                            count = list_expenditure[l].length
                                        } catch (error) {
                                            count = 0
                                        }

                                        for (let r = 0; r < count; r++) {
                                            let name2 = list_expenditure[l][r].name
                                            person[l] = {
                                                ...person[l],
                                                [name2]: list_expenditure[l][r].total,
                                            }
                                        }

                                        if (l === person.length - 1) {
                                            this.setState({ list_expenditure: list_expenditure })
                                            let list_revenue = []
                                         
                                            for (let p = 0; p < person.length; p++) {
                                                apilogin.get(`getdata/excel_export_person_revenue/${this.props.payslip_year}/${this.props.payslip_month}/${this.props.customers_type}/${person[p].customers_citizent}`)
                                                    .then((res) => {
                                                      //  console.log(res.data)
                                                        data_ex = res.data
                                                        let revenue = []
                                                        for (let i = 0; i < data_ex.length; i++) {
                                                            let name = data_ex[i].revenue_name.replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                                                                .replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                                                                .replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                                                                .replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                                                                .replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                                                          
                                                          if (data_ex[i].payslip_total >= 0) {
                                                            let rr = {
                                                                name: name,
                                                                total: data_ex[i].payslip_total
                                                            }
                                                            revenue.push(rr)
                                                          } else {
                                                            let rr = {
                                                                name: name,
                                                                total: 0
                                                            }
                                                            revenue.push(rr)
                                                          }
                                                             
                                                            

                                                            if (i === data_ex.length - 1) {
                                                                list_revenue[p] = revenue
                                                            }
                                                        }


                                                        if (p === person.length - 1) {
                                                            setTimeout(() => {
                                                                for (let l = 0; l < person.length; l++) {

                                                                    let count = 0
                                                                    try {
                                                                        count = list_revenue[l].length
                                                                    } catch (error) {
                                                                        count = 0
                                                                    }
                                                                    for (let r = 0; r < count; r++) {
                                                                        let name2 = list_revenue[l][r].name
                                                                        person[l] = {
                                                                            ...person[l],
                                                                            [name2]: list_revenue[l][r].total,
                                                                        }
                                                                    }

                                                                    if (l === person.length - 1) {
                                                                        this.setState({ list_revenue: list_revenue })
                                                                        this.setState({ data: person })
                                                                        this.setState({ load_check: true })
                                                                        this.setState({ load: false })
                                                                    }
                                                                }

                                                            }, 2000)
                                                        }
                                                    })

                                            }

                                        }
                                    }



                                }, 2000)
                            }
                        })


                }


            })
            .catch((err) => {
                console.log(err)
                this.setState({ load: false })

            })

        apilogin.post('getdata/excel_export_expenditure', ({ customers_type: this.props.customers_type, }))
            .then((res) => {
                let expenditure = res.data
                this.setState({ expenditure: expenditure })
            })

        apilogin.post('getdata/excel_export_revenue', ({ customers_type: this.props.customers_type, }))
            .then((res) => {
                let revenue = res.data
                this.setState({ revenue: revenue })
            })
    }




    render() {

//console.log(this.state.list_expenditure)
console.log(this.state.expenditure)
//console.log(this.state.revenue)



        return (
            <>

                {this.state.load === true ?
                    <Button ><Icon type="loading" /> กำลังโหลด..</Button>
                    :
                    <>

                        {this.state.load_check === true ? <>
                            <ExcelFile filename={this.props.nameexcel + this.props.payslip_month + this.props.payslip_year + " " + this.props.data[0].customer_type_name}
                                element={<Button onClick={() => { this.setState({ load_check: false }) }}>
                                    <MDBIcon className='animated headShake delay-0s infinite' far icon="hand-point-right" /> &nbsp;คลิกเพื่อดาวน์โหลด</Button>}
                            >
                                <ExcelSheet data={this.state.data} name={"ข้อมูลทั้งหมด"}>
                                    <ExcelColumn label="ลำดับ" value="index" />
                                    <ExcelColumn label="ID_citizen" value="customers_citizent" />
                                    <ExcelColumn label="คำนำหน้า" value="pname" />
                                    <ExcelColumn label="ชื่อจริง" value="fname" />
                                    <ExcelColumn label="นามสกุล" value="lname" />
                                    <ExcelColumn label="account_number" value="account_number" />
                                    <ExcelColumn label="bank_name" value="bank_name" />
                                    <ExcelColumn label="bank_id" value="bank_id" />
                                    <ExcelColumn label="month"
                                        value={(col) => col.is_married ? "Married" : this.props.payslip_month}
                                    />
                                     <ExcelColumn label="year"
                                        value={(col) => col.is_married ? "Married" : this.props.payslip_year}
                                    />
                                    <ExcelColumn label="ประเภทบุคลากร" value="customer_type_name" />
                                    <ExcelColumn label="ID_TYPE" value="customers_type" />
                                    <ExcelColumn label="เงินเดือน" value="Salary" />


                                    {this.state.expenditure.map(((data1, index) => {
                                    
                                        return <ExcelColumn
                                            label={data1.expenditure_name.replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                                                .replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                                                .replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                                                .replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                                                .replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                                            }

                                            value={data1.expenditure_name.replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                                            .replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                                            .replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                                            .replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                                            .replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")}
                                          
                                        />
                                    }))}


                                    {this.state.revenue.map((data => {
                                        return <ExcelColumn label={data.revenue_name.replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                                            .replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                                            .replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                                            .replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                                            .replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                                        }
                                            value={data.revenue_name.replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                                                .replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                                                .replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                                                .replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                                                .replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "").replace(".", "").replace("/", "").replace("%", "").replace(" ", "").replace("-", "").replace(",", "")
                                            } />
                                    }))}

                                </ExcelSheet>
                            </ExcelFile>
                        </> : <>
                                <Button onClick={this.get_data}> <Icon type="download" /> ส่งออกข้อมูล Excel</Button>
                            </>}

                    </>

                }



                {(() => {
                    try {
                        // console.log(this.state.list_expenditure[0][8].total)
                    } catch (error) {

                    }
                })()}





            </>
        )
    }
}
