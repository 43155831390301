import React from "react";
import { MDBRow, MDBCol, MDBBtn, MDBCard, MDBAlert } from "mdbreact";
import { MDBInput, MDBIcon } from "mdbreact/dist/mdbreact.esm";
import { apilogin, admin1, admin2, adminSuper, theme, detail_user } from "../api";
import { message, notification } from "antd";
import PDF_print_form from "../Components/PDF_print_form";
import MaskedInput from 'antd-mask-input'
import { Alert } from "@material-ui/lab";
import { Grid, Paper, Card, CardHeader, CardContent, CircularProgress, Backdrop, Typography, Button } from '@material-ui/core';
import { Lottie_Success, Lottie_check } from "../Components/MyLottie";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import Toast from 'light-toast';


class FormPage extends React.Component {
    state = {
        loadSave: false,
        check_have: false,
        user_citizent: '',
        user_pname: '',
        user_fname: '',
        user_lname: '',
        user_position: '',
        user_office: '',
        user_belong: '',
        user_department: '',
        user_add_no: '',
        user_add_soi: '',
        user_add_road: '',
        user_add_tumbon: '',
        user_add_amphoe: '',
        user_add_province: '',
        user_add_code: '',
        user_add_phone: '',
        user_emp: '',
        user_bank_name: '',
        user_bank_branch: '',
        user_bank_type: '',
        user_bank_number: '',
        user_phone_number: '',
        user_email: '',
        user_date_add: '',
        user_time_add: '',


    };

    onClick = (nr) => () => {
        this.setState({
            user_emp: nr
        });
    }

    ongo() {
        if (detail_user.admin_type === admin1 || detail_user.admin_type === admin2 || detail_user.admin_type === adminSuper) {
            this.props.history.push({ pathname: '/FormPageList' })
        }
        else {
            this.props.history.push({ pathname: '/PageKTB' })
        }
    }

    submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";

        var date = new Date().getDate(); //Current Date
        var month = new Date().getMonth() + 1; //Current Month
        var year = new Date().getFullYear(); //Current Year
        var hours = new Date().getHours(); //Current Hours
        var min = new Date().getMinutes(); //Current Minutes
        var sec = new Date().getSeconds(); //Current Seconds


        let user_date_add = year + '-' + month + '-' + date
        let user_time_add = hours + ':' + min + ':' + sec
        let data = {
            user_citizent: this.state.user_citizent,
            user_pname: this.state.user_pname,
            user_fname: this.state.user_fname,
            user_lname: this.state.user_lname,
            user_position: this.state.user_position,
            user_office: this.state.user_office,
            user_belong: this.state.user_belong,
            user_department: this.state.user_department,
            user_add_no: this.state.user_add_no,
            user_add_soi: this.state.user_add_soi,
            user_add_road: this.state.user_add_road,
            user_add_tumbon: this.state.user_add_tumbon,
            user_add_amphoe: this.state.user_add_amphoe,
            user_add_province: this.state.user_add_province,
            user_add_code: this.state.user_add_code,
            user_add_phone: this.state.user_add_phone,
            user_emp: this.state.user_emp,
            user_bank_name: this.state.user_bank_name,
            user_bank_branch: this.state.user_bank_branch,
            user_bank_type: this.state.user_bank_type,
            user_bank_number: this.state.user_bank_number,
            user_phone_number: this.state.user_phone_number,
            user_email: this.state.user_email,
            user_date_add: user_date_add,
            user_time_add: user_time_add,
        }

        if (this.state.check_have === true) {
            this.setState({ loadSave: true })
            apilogin.post('getdata/update_user_detail_data', data)
                .then((res => {
                    this.setState({ loadSave: false })
                    Swal.fire({
                        type: 'success',
                        title: 'อัพเดตสำเร็จ!',
                        text: 'ข้อมูลของคุณได้ถูกอัพเดตแล้ว',
                        icon: 'success',
                    }).then((result) => {
                        if (result.value) {

                            this.ongo()
                        }
                    })
                }))
                .catch((res => {
                    this.setState({ loadSave: false })
                    Swal.fire({
                        type: 'error',
                        title: 'อัพเดตไม่สำเร็จ!',
                        text: 'เกิดข้อผิดพลาดระหว่างการอัพเดตข้อมูล',
                        icon: 'error',
                    }).then((result) => {
                        if (result.value) {

                        }
                    })
                }))
        } else {
            this.setState({ loadSave: true })
            apilogin.post('getdata/insert_user_detail', data)
                .then((res => {
                    this.setState({ loadSave: false })
                    // notification.success({
                    //     message: 'บันทึกข้อมูลสำเร็จ',
                    //     icon: <MDBIcon style={{ color: 'green' }} icon="check" />
                    // })
                    // this.ongo()

                    Swal.fire({
                        type: 'success',
                        title: 'บันทึกข้อมูลสำเร็จ!',
                        text: 'ข้อมูลของคุณได้ถูกบันทึกแล้ว',
                        icon: 'success',
                    }).then((result) => {
                        if (result.value) {

                            this.ongo()
                        }
                    })
                }))
                .catch((res => {
                    this.setState({ loadSave: false })
                    Swal.fire({
                        type: 'error',
                        title: 'บันทึกข้อมูลไม่สำเร็จ!',
                        text: 'เกิดข้อผิดพลาดระหว่างการบันทึกข้อมูล',
                        icon: 'error',
                    }).then((result) => {
                        if (result.value) {

                        }
                    })
                }))
        }

    };

    changeHandler = event => {
        // console.log(event.target)
        this.setState({ [event.target.name]: event.target.value });
    };

    componentDidMount() {
        Toast.loading('Loading..')
        window.scrollTo({ top: 0, behavior: 'smooth' })
        let user = {
            user_citizent: detail_user.Username
        }
        let admin = detail_user.admin_type
        if (admin) {
            user = {
                user_citizent: localStorage.getItem('Username')
            }
        }
        apilogin.post('getdata/get_user_detail_data', user)
            .then((res => {
                // console.log(res)

                if (res.data.length >= 1) {
                    // message.success('ok')
                    this.setState(res.data[0])
                    this.setState({ check_have: true })
                    setTimeout(() => {Toast.hide()}, 200);
                }
                else {
                    try {

                        this.setState({
                            user_citizent: detail_user.Username,
                            user_pname: detail_user.prefix_name,
                            user_fname: detail_user.frist_name,
                            user_lname: detail_user.last_name,
                            user_position: detail_user.rank_name,
                            user_office: detail_user.organization_name,
                            user_add_phone: detail_user.telephone,
                            user_phone_number: detail_user.telephone,
                            user_emp: "1",
                        })
                        setTimeout(() => {Toast.hide()}, 200);
                    } catch (error) {
                        setTimeout(() => {Toast.hide()}, 200);
                    }
                    // message.info('กรุณากรอกข้อมูลให้ครบถ้วนก่อนใช้งาน')
                    notification.info({
                        message: 'กรุณากรอกข้อมูลนี้ให้ครบก่อนใช้งาน',
                        icon: <MDBIcon style={{ color: 'blue' }} icon="user-edit" />
                    })
                }
            }))
            .catch((res => {
                message.error('โหลดไม่สำเร็จ')
                setTimeout(() => {Toast.hide()}, 200);
            }))

    }

    render() {
        return (
            <div className="container mt-5 mb-5 animated fadeIn">



                {/* <MDBAlert color="primary mt-2 mb-2" >
                    หากกรอกและบันทึกข้อมูลนี้แล้ว ให้พิมพ์แบบฟอร์มนี้ แล้วเตรียมสำเนาหน้าสมุดบัญชี จากนั้นนำมาส่งที่งานการเงิน
                </MDBAlert> */}

                {/* <Alert className="mt-2 mb-2" severity="error">  หากกรอกและบันทึกข้อมูลนี้แล้ว ให้พิมพ์แบบฟอร์มนี้ แล้วเตรียมสำเนาหน้าสมุดบัญชี จากนั้นนำมาส่งที่งานการเงิน</Alert> */}

                <Card className={"container mt-2 "}>


                    <form
                        // className="needs-validation"
                        onSubmit={this.submitHandler}
                    //noValidate
                    >

                        <div className="text-center mt-5">
                            <h4 className={theme}><MDBIcon fab icon="wpforms" /> แบบแจ้งข้อมูลการรับเงินผ่านระบบ KTB Corporate Online </h4>
                        </div>

                        {/* <PDF_print_form data={this.state} check_have={this.state.check_have} /> */}




                        <h5 className={"mt-5 " + theme}><MDBIcon icon="user-alt" /> ข้อมูลทั่วไป </h5>
                        <MDBRow>
                            <MDBCol md="4" className="mb-3">
                                <label> คำนำหน้า</label>
                                <input
                                    value={this.state.user_pname}
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    name="user_pname"
                                    placeholder="ระบุคำนำหน้า"
                                    required
                                />
                            </MDBCol>

                            <MDBCol md="4" className="mb-3">
                                <label > ชื่อ</label>
                                <input
                                    value={this.state.user_fname}
                                    name="user_fname"
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    placeholder="ระบุชื่อจริง"
                                    required
                                />
                            </MDBCol>

                            <MDBCol md="4" className="mb-3">
                                <label > นามสกุล</label>
                                <input
                                    value={this.state.user_lname}
                                    name="user_lname"
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    placeholder="ระบุนามสกุล"
                                    required
                                />
                            </MDBCol>
                        </MDBRow>




                        <MDBRow>
                            <MDBCol md="4" className="mb-3">
                                <label> ตำแหน่ง</label>
                                <input
                                    value={this.state.user_position}
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    name="user_position"
                                    placeholder="ระบุตำแหน่ง"
                                    required
                                />
                            </MDBCol>

                            <MDBCol md="4" className="mb-3">
                                <label > สำนัก/กอง/ศูนย์</label>
                                <input
                                    value={this.state.user_office}
                                    name="user_office"
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    placeholder="ระบุสำนัก/กอง/ศูนย์"
                                    required
                                />

                            </MDBCol>
                            <MDBCol md="4" className="mb-3">
                                <label > สังกัดกรม</label>
                                <input
                                    value={this.state.user_belong}
                                    name="user_belong"
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    placeholder="ระบุสังกัดกรม"
                                    required
                                />
                            </MDBCol>
                        </MDBRow>





                        <MDBRow>

                            <MDBCol md="4" className="mb-3">
                                <label> เลขประจำตัวประชาชน </label>
                                <input
                                    disabled={this.state.check_have === true ? true : false}
                                    value={this.state.user_citizent}
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    name="user_citizent"
                                    placeholder="ระบุเลขประจำตัวประชาชน"
                                    required
                                />
                            </MDBCol>


                            <MDBCol md="4" className="mb-3">
                                <label> กระทรวง</label>
                                <input
                                    value={this.state.user_department}
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    name="user_department"
                                    placeholder="ระบุกระทรวง"
                                    required
                                />
                            </MDBCol>

                        </MDBRow>




                        <h5 className={"mt-5 " + theme}><MDBIcon icon="map-marked-alt" /> ที่อยู่ </h5>
                        <MDBRow>
                            <MDBCol md="4" className="mb-3">
                                <label>บ้านเลขที่</label>
                                <input
                                    value={this.state.user_add_no}
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    name="user_add_no"
                                    placeholder="ระบุบ้านเลขที่"
                                    required
                                />
                            </MDBCol>

                            <MDBCol md="4" className="mb-3">
                                <label >ซอย</label>
                                <input
                                    value={this.state.user_add_soi}
                                    name="user_add_soi"
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    placeholder="ระบุซอย"
                                    required
                                />
                            </MDBCol>

                            <MDBCol md="4" className="mb-3">
                                <label>ถนน</label>
                                <input
                                    value={this.state.user_add_road}
                                    name="user_add_road"
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    placeholder="ระบุถนน"
                                    required
                                />
                            </MDBCol>
                        </MDBRow>







                        <MDBRow>
                            <MDBCol md="4" className="mb-3">
                                <label> ตำบล</label>
                                <input
                                    value={this.state.user_add_tumbon}
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    name="user_add_tumbon"
                                    placeholder="ระบุตำบล"
                                    required
                                />
                            </MDBCol>

                            <MDBCol md="4" className="mb-3">
                                <label >อำเภอ</label>
                                <input
                                    value={this.state.user_add_amphoe}
                                    name="user_add_amphoe"
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    placeholder="ระบุอำเภอ"
                                    required
                                />
                            </MDBCol>

                            <MDBCol md="4" className="mb-3">
                                <label > จังหวัด</label>
                                <input
                                    value={this.state.user_add_province}
                                    name="user_add_province"
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    placeholder="ระบุจังหวัด"
                                    required
                                />
                            </MDBCol>
                        </MDBRow>



                        <MDBRow>
                            <MDBCol md="4" className="mb-3">
                                <label>รหัสไปรษณีย์</label>
                                <input
                                    value={this.state.user_add_code}
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    name="user_add_code"
                                    placeholder="ระบุรหัสไปรษณีย์"
                                    required
                                />
                            </MDBCol>

                            <MDBCol md="4" className="mb-3">
                                <label>เบอร์โทรศัพท์</label>
                                <input
                                    value={this.state.user_add_phone}
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    name="user_add_phone"
                                    placeholder="ระบุเบอร์โทรศัพท์"
                                    required
                                />
                            </MDBCol>


                        </MDBRow>


                        <h5 className={"mt-5 " + theme}> <MDBIcon icon="university" /> ข้อมูลธนาคาร </h5>

                        <div className={"mt-2 mb-2 " + theme}>
                            <MDBInput gap onClick={this.onClick("1")} checked={this.state.user_emp === "1" ? true : false} label="เป็นข้าราชการ ลูกจ้าง พนักงานราชการของหน่วยงาน" type="radio"
                                id="radio1" />
                            <MDBInput gap onClick={this.onClick("2")} checked={this.state.user_emp === "2" ? true : false} label="เป็นบุคคลภายนอก เพื่อเข้าบัญชีเงินฝากธนคาร" type="radio"
                                id="radio2" />
                        </div>




                        <MDBRow>
                            <MDBCol md="4" className="mb-1">
                                <label>บัญชีเงินฝากธนาคาร</label>
                                <input
                                    value={this.state.user_bank_name}
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    name="user_bank_name"
                                    placeholder="ระบุบัญชีเงินฝากธนาคาร"
                                    required
                                />
                            </MDBCol>

                            <MDBCol md="4" className="mb-3">
                                <label > สาขา</label>
                                <input
                                    value={this.state.user_bank_branch}
                                    name="user_bank_branch"
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    placeholder="ระบุสาขา"
                                    required
                                />
                            </MDBCol>

                            <MDBCol md="4" className="mb-3">
                                <label > ประเภทธนาคาร</label>
                                <input
                                    value={this.state.user_bank_type}
                                    name="user_bank_type"
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    placeholder="ระบุประเภทธนาคาร"
                                    required
                                />
                            </MDBCol>
                        </MDBRow>






                        <MDBRow>
                            <MDBCol md="4" className="mb-3">
                                <label> เลขที่บัญชีเงินฝากธนาคาร</label>
                                <MaskedInput

                                    mask="111-1-11111-1"
                                    // name="card" 
                                    //  size="50" 
                                    placeholder="ระบุเลขที่บัญชีเงินฝากธนาคาร"
                                    className={"form-control " + theme}
                                    name="user_bank_number"
                                    onChange={this.changeHandler}
                                    value={this.state.user_bank_number} />


                                {/* <input
                                    value={this.state.user_bank_number}
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control "+theme}
                                    name="user_bank_number"
                                    placeholder="ระบุเลขที่บัญชีเงินฝากธนาคาร"
                                    required
                                /> */}
                            </MDBCol>

                            <MDBCol md="4" className="mb-3">
                                <label > เบอร์โทรศัพท์ สำหรับแจ้งเตือนผ่านมือถือ</label>
                                <input
                                    value={this.state.user_phone_number}
                                    name="user_phone_number"
                                    onChange={this.changeHandler}
                                    type="text"
                                    className={"form-control " + theme}
                                    placeholder="ระบุเบอร์โทรศัพท์ สำหรับแจ้งเตือนผ่านมือถือ"
                                    required
                                />
                            </MDBCol>

                            <MDBCol md="4" className="mb-3">
                                <label > E-mail</label>
                                <input
                                    value={this.state.user_email}
                                    name="user_email"
                                    onChange={this.changeHandler}
                                    type="email"
                                    className={"form-control " + theme}
                                    placeholder="ระบุ E-mail"
                                    required
                                />
                            </MDBCol>
                        </MDBRow>

                        {/* <MDBAlert color="primary mt-5" >
                            หากกรอกและบันทึกข้อมูลนี้แล้ว ให้พิมพ์แบบฟอร์มนี้ แล้วเตรียมสำเนาหน้าสมุดบัญชี จากนั้นนำมาส่งที่งานการเงิน
                        </MDBAlert> */}
                        <Alert className="mt-5" severity="info">  หากกรอกและบันทึกข้อมูลนี้แล้ว ให้พิมพ์แบบฟอร์มนี้ แล้วเตรียมสำเนาหน้าสมุดบัญชี จากนั้นนำมาส่งที่งานการเงิน</Alert>

                        <div className="text-center mt-5 mb-5">

                            <Button variant="contained" color="primary" type="submit">
                                {this.state.loadSave === true ? <>
                                    กำลังบันทึกข้อมูล..
                            </> : <>
                                        บันทึกข้อมูล
                            </>}

                            </Button>
                        </div>

                    </form>
                </Card>



            </div>
        );
    }
}

export default FormPage;