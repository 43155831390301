import React, { Component } from 'react'
import pdfMake from "pdfmake/build/pdfmake";
import ThaiBaht from 'thai-baht-text'
import { Button, MDBIcon } from 'mdbreact/dist/mdbreact.esm';
import ktb from '../img/ktb.jpg'
import moment from 'moment';
import { theme, detail_user, apilogin, fontpdf, options_mounth } from '../api';
import { notification, message } from 'antd';
import { Card, CardContent } from '@material-ui/core';
import { Lottie_printer, Lottie_download, Lottie_view } from './MyLottie';
import { Grid, Paper,  CardHeader } from '@material-ui/core';
import Bttn_view_pdf from './Bttn_view_pdf';
import Toast from 'light-toast';
import img_ktb_base64 from '../img/img_ktb_base64';


pdfMake.fonts = fontpdf



export default class PDF_print_form extends Component {
    state = {
        decode: "",
        load_view:true,
        fullbase64:"",
    }
    componentDidMount() {
        // this.decode()
    }
 
    // data = []
    pdf = (check, dataget) => {
        Toast.loading('Loading..')
        this.setState({load_view:true})
        if (check !== "load") {
            this.setState({ loading: true });
        }

        let data = this.props.data
        if (dataget) {
            data = dataget
        }

        let date = moment().format('D')
        let month = moment().format('MM')
        let year = moment().format('YYYY')
        for (let d = 0; d < options_mounth.length; d++) {
            if (options_mounth[d].value === month) {
                month = options_mounth[d].label
            }
        }
        let fulldate = "วันที่ " + date + " เดือน " + month + " พ.ศ." + Number(Number(year) + 543)
        //console.log("วันที่ " + date + " เดือน " + month + " ปี " + Number(Number(year)+ 543))

        let user_citizent_all = data.user_citizent.split("")
        let user_citizent = ""
        for (let i = 0; i < user_citizent_all.length; i++) {
            let t = " " + " " + " "
            if (i === 3) { t = " " + " " }              //4 9
            else if (i === 4) { t = " " + " " + " " }    //5 0
            else if (i === 5) { t = " " + " " }         //6 0
            else if (i === 6) { t = " " + " " + " " }    //7 3
            else if (i === 7) { t = " " + " " + " " }    //8 9
            else if (i === 8) { t = " " + " " }         //9 3
            else if (i === 9) { t = " " + " " + " " }    //10 4
            else if (i === 10) { t = " " + " " + " " }   //11 9
            else if (i === 11) { t = " " + " " + " " }   //12 2
            user_citizent = user_citizent + user_citizent_all[i] + t
        }

        let user_bank_name = data.user_bank_name
        let user_bank_branch = data.user_bank_branch
        let user_bank_type = data.user_bank_type
        let user_bank_number = data.user_bank_number
        let emp1 = ''
        let emp2 = ''
        if (data.user_emp === "1") {
            emp1 = '\uf00c'
            user_bank_name = ''
            user_bank_branch = ''
            user_bank_type = ''
            user_bank_number = ''

        } else {
            emp2 = '\uf00c'
            data.user_bank_name = ''
            data.user_bank_branch = ''
            data.user_bank_type = ''
            data.user_bank_number = ''
        }





        //data.user_office
        //console.log(data.user_office.length )
        let font_office = 12
        try {
            if (data.user_office.length >= 25) {
                font_office = 10
            }
        } catch (error) {

        }



        // var count = 0

        var docDefinition = {
            info: {
                title: 'แบบแจ้งข้อมูลการรับเงินโอนเข้าระบบ',
            },

            content: [

                {
                    // image: 'data:image/jpeg;base64,' + this.state.decode,
                    image:img_ktb_base64,
                    width: 600,
                    margin: [-50, -50, 0, 0],
                    alignment: 'left'
                },

                {
                    text: fulldate,
                    style: 'invoiceSubTitleLeft',
                    alignment: 'right',
                    width: 100,
                    absolutePosition: { x: 0, y: 145 }
                },
                {
                    text: data.user_pname + data.user_fname + " " + data.user_lname,
                    style: 'small',
                    alignment: 'center',
                    width: 100,
                    absolutePosition: { x: -120, y: 202 }
                },
                {
                    text: data.user_position,
                    style: 'small',
                    alignment: 'center',
                    width: 100,
                    absolutePosition: { x: 350, y: 202 }
                },
                {
                    text: user_citizent,
                    style: 'invoiceSubTitleLeft',
                    alignment: 'justify',
                    lineHeight: 2,
                    width: 500,
                    absolutePosition: { x: 170, y: 215 }
                },
                {
                    text: data.user_office,
                    fontSize: font_office,
                    style: 'small',
                    width: 500,
                    absolutePosition: { x: 135, y: 238 }
                },
                {
                    text: data.user_belong,
                    style: 'small',
                    width: 500,
                    absolutePosition: { x: 290, y: 238 }
                },
                {
                    text: data.user_department,
                    style: 'small',
                    width: 500,
                    absolutePosition: { x: 425, y: 238 }
                },

                // ---------------
                {
                    text: data.user_add_no,
                    style: 'small',
                    width: 500,
                    absolutePosition: { x: 125, y: 258 }
                },
                {
                    text: data.user_add_soi,
                    style: 'small',
                    width: 500,
                    absolutePosition: { x: 190, y: 258 }
                },
                {
                    text: data.user_add_road,
                    style: 'small',
                    width: 500,
                    absolutePosition: { x: 290, y: 258 }
                },
                {
                    text: data.user_add_tumbon,
                    style: 'small',
                    width: 500,
                    absolutePosition: { x: 429, y: 258 }
                },
                // ---------------

                // ---------------
                {
                    text: data.user_add_amphoe,
                    style: 'small',
                    width: 500,
                    absolutePosition: { x: 125, y: 275 }
                },
                {
                    text: data.user_add_province,
                    style: 'small',
                    width: 500,
                    absolutePosition: { x: 310, y: 275 }
                },
                {
                    text: data.user_add_code,
                    style: 'small',
                    width: 500,
                    absolutePosition: { x: 460, y: 275 }
                },

                {
                    text: data.user_add_phone,
                    style: 'small',
                    width: 500,
                    absolutePosition: { x: 125, y: 292 }
                },



                {
                    text: emp1,
                    alignment: 'left',
                    font: 'FontAwesome',
                    absolutePosition: { x: 110, y: 380 }
                },
                {
                    text: emp2,
                    alignment: 'left',
                    font: 'FontAwesome',
                    absolutePosition: { x: 110, y: 460 }
                },
                {
                    text: "\uf00c",
                    alignment: 'left',
                    font: 'FontAwesome',
                    absolutePosition: { x: 110, y: 545 }
                },
                {
                    text: "\uf00c",
                    alignment: 'left',
                    font: 'FontAwesome',
                    absolutePosition: { x: 110, y: 572 }
                },




                //// หน่วยงาน
                {
                    text: data.user_bank_name,
                    style: 'small',
                    alignment: 'left',
                    absolutePosition: { x: 240, y: 413 }
                },

                {
                    text: data.user_bank_branch,
                    style: 'small',
                    alignment: 'left',
                    absolutePosition: { x: 370, y: 413 }
                },
                {
                    text: data.user_bank_type,
                    style: 'small',
                    alignment: 'left',
                    absolutePosition: { x: 480, y: 413 }
                },
                {
                    text: data.user_bank_number,
                    style: 'small',
                    alignment: 'left',
                    absolutePosition: { x: 280, y: 432 }
                },



                //// บุคคลทั่วไป
                {
                    text: user_bank_name,
                    style: 'small',
                    alignment: 'left',
                    absolutePosition: { x: 380, y: 461 }
                },

                {
                    text: user_bank_branch,
                    style: 'small',
                    alignment: 'left',
                    absolutePosition: { x: 490, y: 461 }
                },
                {
                    text: user_bank_type,
                    style: 'small',
                    alignment: 'left',
                    absolutePosition: { x: 187, y: 480 }
                },
                {
                    text: user_bank_number,
                    style: 'small',
                    alignment: 'left',
                    absolutePosition: { x: 400, y: 480 }
                },



                {
                    text: data.user_phone_number,
                    style: 'small',
                    alignment: 'center',
                    // width: 100,
                    absolutePosition: { x: 290, y: 545 }
                },
                {
                    text: data.user_email,
                    style: 'small',
                    alignment: 'center',
                    // width: 100,
                    absolutePosition: { x: 320, y: 575 }
                },
                {
                    text: "( " + data.user_pname + data.user_fname + "   " + data.user_lname + " )",
                    style: 'invoiceSubTitleLeft',
                    alignment: 'center',
                    // width: 100,
                    absolutePosition: { x: 290, y: 663 }
                },

            ],
            styles: {
                small: {
                    fontSize: 12,
                    alignment: 'left',
                    bold: true,
                },

                invoiceTitle: {
                    fontSize: 22,
                    bold: true,
                    alignment: 'left',
                    lineHeight: 0.60,
                    margin: [0, 0, 0, 15]
                },

                invoiceSubTitleLeft: {
                    fontSize: 16,
                    alignment: 'left',
                    bold: true,
                },
            },
            defaultStyle: {
                font: 'THSarabunNew', fontSize: 16,
               
                //columnGap: 5,
            }
        }
        // pdfMake.createPdf(docDefinition).open();

        if (check === "load") {
            pdfMake.createPdf(docDefinition).download('payslip-แบบแจ้งข้อมูลการรับเงินผ่านระบบ KTB Corporate Online ' + data.user_pname + data.user_fname + " " + data.user_lname)
             setTimeout(() => {
                      Toast.hide();
              }, 200);
        }
        else if(check==="view"){
            var Base64PDF = pdfMake.createPdf(docDefinition).getBase64((data64) => {
                let fullbase64 = "data:application/pdf;base64,"+data64
                this.setState({
                    fullbase64:fullbase64,
                })
                // console.log(fullbase64)
                setTimeout(() => {
                    this.setState({load_view:false})
                    setTimeout(() => {
                        Toast.hide();
                }, 200);
                }, 200);
            })
        }
         else {
            pdfMake.createPdf(docDefinition).open('')
            setTimeout(() => {
                Toast.hide();
        }, 200);
        }

    }

    getdata = (check) => {
        if (detail_user) {
            let user = {
                user_citizent: detail_user.Username
            }
            apilogin.post('getdata/get_user_detail_data', user)
                .then((res => {
                    // console.log(res)

                    if (res.data.length >= 1) {
                        this.pdf(check, res.data[0])
                    }
                    else {
                        notification.info({
                            message: 'กรุณากรอกข้อมูลนี้ให้ครบก่อนใช้งาน',
                            icon: <MDBIcon style={{ color: 'blue' }} icon="user-edit" />
                        })
                    }
                }))
                .catch((res => {
                    message.error('ไม่สำเร็จ')
                }))
        }
    }
    render() {

        const styleCard = {
            marginTop: "1rem",
            minHeight: '250px',
            minWidth: '350px',
        }
        let classCard = "animated fadeIn mt-5 cascading-admin-card card-custom custom-text card-"// + theme

         let check = this.props.check
        return (
           
            <>
           
                {this.props.main === true ? <>
                {check === "load"?<>
                 <a onClick={() => { this.getdata(check) }}>
                        <Card elevation="3"  className={classCard} style={styleCard}>
                            <div className="text-center mt-2 " >
                                <CardContent>
                                    <Lottie_download />
                                </CardContent>
                                <CardHeader className="mb-4" title={"โหลดแบบฟอร์ม"} />
                            </div>
                        </Card>
                    </a>
                </>:<>
                 <a onClick={() => { this.getdata(check) }}>
                        <Card elevation="3"  className={classCard} style={styleCard} >
                            <div className="text-center mt-2 " >
                                <CardContent>
                                    <Lottie_view />
                                </CardContent>
                                <CardHeader className="mb-4" title={"ดูแบบฟอร์ม"} />
                            </div>
                        </Card>
                    </a>
                    {this.state.load_view === true ? <></>:<>
                     <Bttn_view_pdf load_view={this.state.load_view} 
                     noButton={true}
                     data={this.state.fullbase64} />
                    </>}
                   
                </>}
                   
                </> : <>
                <div className="text-right mt-5">
                        {this.props.check_have === true ? <>
                            <Button outline color={theme === "light" ? "info" : "light"} size="sm"
                                disabled={this.props.check_have === true ? false : true}
                                //    onClick={this.pdf}
                                onClick={() => { this.pdf('open') }}
                            > <MDBIcon icon="print" /> &nbsp; พิมพ์</Button>&nbsp;


               <Button outline color={theme === "light" ? "info" : "light"} size="sm"
                                disabled={this.props.check_have === true ? false : true}
                                //    onClick={this.pdf}
                                onClick={() => { this.pdf('load') }}
                            > <MDBIcon icon="download" /> &nbsp; ดาวน์โหลด</Button>&nbsp;

                          
            </> : null}

            </div>   
                    </>}

           
            </>
        )
    }
}
