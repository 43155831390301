

import React, { Component } from 'react'
import { apilogin, theme, mouth, year, detail_user } from '../api'
import { Table, Input, Icon, Card, Button, InputNumber, message, Tabs, Switch, notification, Spin } from 'antd'
import { MDBContainer, MDBCardBody, MDBCardHeader, MDBCard, MDBRow, MDBCol, MDBIcon } from 'mdbreact'
import Highlighter from 'react-highlight-words';
import Bttn_customer_add_expenditure from '../Components/Bttn_customer_add_expenditure';
import NumberFormat from 'react-number-format';
import Select from 'react-select'
import Bttn_customer_add_revenue from '../Components/Bttn_customer_add_revenue';
import PrintPDF from './PrintPDF';
import Bttn_customer_expendituretype from '../Components/Bttn_customer_expendituretype';
import Excelreportdata from './Excelreportdata';
import Excelreportdeduction from './Excelreportdeduction';
import Excelreportdeductionall from './Excelreportdeductionall';



const { TabPane } = Tabs;

export default class Excelreportipay extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            options_years: [],
            data1: [],
            data2: [],
            loading: false,
            search: '',
            payslip_month: '', //------
            payslip_year: '',
            search_check: false,
            search_check2: false,
            search_check5: false,
            expenditurelevelmain: '',
            expenditurelevelmain2: '',
            onExpenditureidlevel2: '1',

            expenditurelevelmainlabel: '',

            customers_group: [],
            customer_type: [],
            customer_type_1: [],
            customer_type_2: [],

            customers_type_name: '',
        }
        this.get_customer_status = this.get_customer_status.bind(this)
        this.updatedata = this.updatedata.bind(this)
        this.updatedata_props = this.updatedata_props.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onMouthChange = this.onMouthChange.bind(this)
        this.onYearsChange = this.onYearsChange.bind(this)
        this.onExpenditure = this.onExpenditure.bind(this)
        this.onExpenditure2 = this.onExpenditure2.bind(this)
        this.onExpenditureid = this.onExpenditureid.bind(this)
        this.onChangeswitch = this.onChangeswitch.bind(this)
    }

    onChangeswitch(checked) {
        // console.log(`switch to ${checked}`);
        this.setState({
            checked: !this.state.checked,
        })

    }

    onChange(event) {
        //  console.log(event)
        // this.setState({ [event.target.name]: event.target.value })
    }
    onMouthChange(event) {
        // this.setState({ payslip_month: event.value })
        this.setState({ payslip_month: event.target.value })
    }

    onYearsChange(event) {

        // this.setState({ payslip_year: event.value })
        this.setState({ payslip_year: event.target.value })
    }
    onExpenditure(event) {
        this.setState({
            expenditurelevelmain: event.value,
            expenditurelevelmain2: event.label
        })
    }
    onExpenditure2(event) {
        this.setState({
            expenditurelevelmain: event.value,
            expenditurelevelmainlabel: event.label
        })
    }
    onExpenditureid(key) {
        //console.log(key)
        this.setState({ onExpenditureidlevel2: key })
    }


    get_customer_status() {

        if (this.state.customers_group.length === 0) {
            message.warning("กรุณาประเภทพนักงาน")
        }

        else if (this.state.payslip_month === "") {
            message.warning("กรุณาเลือกเดือน")
        }
        else if (this.state.payslip_year === "") {
            message.warning("กรุณาเลือกปี")
        }
        else {

            this.setState({ loading: true })
            apilogin.post('getdata/reportipay_group/' + this.state.payslip_year + '/' + this.state.payslip_month, ({ customers_group: this.state.customers_group }))
                .then(res => {
                    let customers_group = this.state.customers_group
                    let customers_type_name = ""

                    for (let i = 0; i < customers_group.length; i++) {
                        customers_type_name = customers_type_name + customers_group[i].label + " "
                    }
                    this.setState({ customers_type_name: customers_type_name })
                    this.updatedata(res.data)
                })
        }



    }

    updatedata(data) {
        let getdata = data.map((data, index) => ({
            key: data.customers_citizent,
            index: index + 1,
            bank_id: data.bank_id,
            bank_name: data.bank_name,
            countall: data.countall,
            total: data.total,
        })
        )

        this.setState({
            data: getdata,
            search_check: true,
            loading: false,
        })
    }

    updatedata_props() {
        this.setState({ loading: true })
        setTimeout(() => {
            this.get_customer_status();
        }, 1000);
    }

    componentDidMount() {
        // this.get_customer_status()3
        this.setState({
            payslip_month: mouth,
            payslip_year: year,
        })

        this.setState({ loading: true })
        apilogin.get('getdata/getyear')
            .then(res => {
                let year = []
                res.data.map((data) => {
                    let yy = {
                        value: data.name_year,
                        label: data.name_year_th,
                    }
                    year.push(yy)
                })
                this.setState({ options_years: year })
            })
            .catch((err) => { notification.error({ message: 'โหลดรายการปีไม่สำเร็จ ' + err, }) })


        this.setState({ nameuser: detail_user.nameuser })
        this.setState({ admin_type: detail_user.admin_type })
        apilogin.get('getdata/customer_type')
            .then(res => {

                let customer_type = []
                let customer_type_1 = []
                let customer_type_2 = []

                let cc = []
                res.data.map((data) => {
                    let yy = {
                        value: data.customer_type_id,
                        label: data.customer_type_name,
                        admin_level: data.admin_level,
                    }
                    cc.push(yy)
                })


                cc.map((data) => {
                    customer_type.push(data)
                    if (data.admin_level === "1") {
                        customer_type_1.push(data)
                    }
                    else if (data.admin_level === "2") {
                        customer_type_2.push(data)
                    }
                })

                this.setState({
                    customer_type: customer_type,
                    customer_type_1: customer_type_1,
                    customer_type_2: customer_type_2,
                })

            })

    }


    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`ค้นหา`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
            </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
            //  textToHighlight={text.toString()}
            />
        ),
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    render() {

        const options_mounth = [
            { value: '01', label: 'มกราคม' },
            { value: '02', label: 'กุมภาพันธ์' },
            { value: '03', label: 'มีนาคม' },
            { value: '04', label: 'เมษายน' },
            { value: '05', label: 'พฤษภาคม' },
            { value: '06', label: 'มิถุนายน' },
            { value: '07', label: 'กรกฎาคม' },
            { value: '08', label: 'สิงหาคม' },
            { value: '09', label: 'กันยายน' },
            { value: '10', label: 'ตุลาคม' },
            { value: '11', label: 'พฤศจิกายน' },
            { value: '12', label: 'ธันวาคม' },

        ]

        const options_years = [
            { value: '2019', label: 'ปี 2562' },
            { value: '2020', label: 'ปี 2563' },
        ]

        //ทั้งหมด
        const columns_all = [
            {
                title: 'ลำดับ',
                dataIndex: 'index',
                key: 'index',
                className: theme,
                render: text => <div className="text-center">{text}</div>,

            },
            {
                title: 'BANK_ID',
                dataIndex: 'bank_id',
                key: 'bank_id',
                className: theme,
            },
            {
                title: 'ชื่อธนาคาร',
                dataIndex: 'bank_name',
                key: 'bank_name',
                className: theme,

            },
            {
                title: 'จำนวน/คน',
                dataIndex: 'countall',
                key: 'countall',
                className: theme,
                render: text =>
                    <NumberFormat value={text} displayType={'text'} thousandSeparator={true} />

            },
             {
                title: 'ยอดรวม',
                dataIndex: 'total',
                key: 'total',
                className: theme,
                render: text =>
                  <div className="text-right"><NumberFormat value={text} displayType={'text'} thousandSeparator={true} /></div>  

            },
            {
                title: 'Export IPAY',
                key: 'manage',
                className: theme,
                render: (text, record) => (
                    <div className="text-center">
                        <Excelreportdata data={record} typeid={this.state.customers_type_id} payslip_year={this.state.payslip_year} payslip_month={this.state.payslip_month} customers_group={this.state.customers_group} />


                    </div>
                ),
            },


        ]



        //ลูกจ้างประจำ
        const columns = [
            {
                title: 'ลำดับ',
                dataIndex: 'index',
                key: 'index',

                render: text => <div className="text-center">{text}</div>,

            },
            {
                title: 'BANK_ID',
                dataIndex: 'bank_id',
                key: 'bank_id',

            },
            {
                title: 'ชื่อธนาคาร',
                dataIndex: 'bank_name',
                key: 'bank_name',

            },
            {
                title: 'จำนวน/คน',
                dataIndex: 'countall',
                key: 'countall',
                render: text =>
                    <NumberFormat value={text} displayType={'text'} thousandSeparator={true} />

            },
            {
                title: 'Export IPAY',
                key: 'manage',
                render: (text, record) => (
                    <div className="text-center">
                        <Excelreportdata data={record} typeid={this.state.onExpenditureidlevel2} payslip_year={this.state.payslip_year} payslip_month={this.state.payslip_month} />


                    </div>
                ),
            },


        ]



        //ลูกจ้างชั่วคราว
        const columns1 = [
            {
                title: 'ลำดับ',
                dataIndex: 'index',
                key: 'index',

                render: text => <div className="text-center">{text}</div>,

            },
            {
                title: 'BANK_ID',
                dataIndex: 'bank_id',
                key: 'bank_id',

            },
            {
                title: 'ชื่อธนาคาร',
                dataIndex: 'bank_name',
                key: 'bank_name',


            },
            {
                title: 'จำนวน/คน',
                dataIndex: 'countall',
                key: 'countall',



                render: text =>
                    <NumberFormat value={text} displayType={'text'} thousandSeparator={true} />

            },

            {
                title: 'Export IPAY',
                key: 'manage',
                render: (text, record) => (
                    <div className="text-center">
                        <Excelreportdata data={record} typeid={this.state.onExpenditureidlevel2} payslip_year={this.state.payslip_year} payslip_month={this.state.payslip_month} />


                    </div>
                ),
            },


        ]

        //อาจารย์ประจำสัญญาจ้าง
        const columns5 = [
            {
                title: 'ลำดับ',
                dataIndex: 'index',
                key: 'index',

                render: text => <div className="text-center">{text}</div>,

            },
            {
                title: 'BANK_ID',
                dataIndex: 'bank_id',
                key: 'bank_id',

            },
            {
                title: 'ชื่อธนาคาร',
                dataIndex: 'bank_name',
                key: 'bank_name',


            },
            {
                title: 'จำนวน/คน',
                dataIndex: 'countall',
                key: 'countall',



                render: text =>
                    <NumberFormat value={text} displayType={'text'} thousandSeparator={true} />

            },

            {
                title: 'Export IPAY',
                key: 'manage',
                render: (text, record) => (
                    <div className="text-center">
                        <Excelreportdata data={record} typeid={this.state.onExpenditureidlevel2} payslip_year={this.state.payslip_year} payslip_month={this.state.payslip_month} />


                    </div>
                ),
            },


        ]

        return (
            <div>
                <MDBContainer className="mt-5 mb-5">
                    <div className="d-flex justify-content-center mt-5 text-center">
                        <MDBCard style={{ width: "72rem", minHeight: "40rem" }} className={theme} >
                            <MDBCardHeader className={"form-header rgba-indigo-light rounded -hoverable "+theme} >
                                <h2 className={theme} style={{ textAlign: 'center', fontWeight: 'bold' }} >
                                    <MDBIcon icon="table" /> พิมพ์ใบสรุป IPAY
                                </h2>

                            </MDBCardHeader>

                            <div className="container mt-5">

                                <MDBRow className="text-left">
                                    <MDBCol xl="3" className="mb-1">
                                        <Select
                                            className={"light"}
                                            isMulti
                                            options={this.state.admin_type === "super" ? this.state.customer_type :
                                                this.state.admin_type === "adminlevel1" ? this.state.customer_type_1 :
                                                    this.state.admin_type === "adminlevel2" ? this.state.customer_type_2 : null}
                                            placeholder={<span><MDBIcon icon="users" /> &nbsp; เลือกประเภทบุคลากร</span>}
                                            onChange={(e) => {
                                                //console.log(e)
                                                // console.log(e.length)
                                                if (e) {
                                                    if (e.length >= 1) {
                                                        this.setState({ customers_group: e })
                                                    }
                                                    // else if (e.length === 1) {
                                                    //     this.setState({
                                                    //         customers_type_id: e[0].value,
                                                    //         customers_type_name: e[0].label
                                                    //     })
                                                    //     this.setState({ customers_group: e })
                                                    // }
                                                    else {
                                                        this.setState({
                                                            customers_type_name: "",
                                                            customers_group: [],
                                                        })
                                                    }
                                                } else {
                                                    this.setState({
                                                        customers_type_name: "",
                                                        customers_group: [],
                                                    })
                                                }




                                            }} name="customers_type" />
                                    </MDBCol>

                                    <MDBCol xl="3" className="mb-1">
                                        {/* <Select
                                            className={"light"}
                                            defaultInputValue={this.state.payslip_month}
                                            options={options_mounth} placeholder={<span><MDBIcon far icon="calendar-alt" /> &nbsp; เลือกเดือน</span>}
                                            onChange={this.onMouthChange} name="payslip_month" /> */}

                                        <select
                                            className={"form-control " + theme} name="payslip_month"
                                            onChange={this.onMouthChange} value={this.state.payslip_month}
                                        >
                                            <option value="">เลือกเดือน</option>
                                            {options_mounth.map((data) => {
                                                return <option value={data.value}> {data.label}</option>
                                            })}
                                        </select>

                                    </MDBCol>

                                    <MDBCol xl="3" className="mb-1">
                                        {/* <Select
                                            className={"light"}
                                            defaultInputValue={this.state.payslip_year}
                                            options={this.state.options_years} placeholder={<span><MDBIcon icon="chart-line" /> &nbsp; เลือกปี</span>} 
                                            onChange={this.onYearsChange} name="payslip_year" /> */}

                                        <select
                                            className={"form-control " + theme} name="payslip_year"
                                            onChange={this.onYearsChange} value={this.state.payslip_year}
                                        >
                                            <option value="">เลือกปี</option>
                                            {this.state.options_years.map((data) => {
                                                return <option value={data.value}> {data.label}</option>
                                            })}
                                        </select>

                                    </MDBCol>

                                    <MDBCol xl="3" className="mb-1" style={{ marginTop: '0.0rem' }}>
                                        <Button block icon="search" size="large" type="primary" onClick={this.get_customer_status}>
                                            ค้นหา
                                           </Button>
                                    </MDBCol>
                                </MDBRow>




                            </div>

                            {this.state.loading === true ? <>
                                <div className="mt-5 mb-5 text-center">
                                    <Spin tip="loading.." />
                                </div>
                            </> : <>
                                    {this.state.search_check === true ?
                                        <> <Table className="mt-3" dataSource={this.state.data} columns={columns_all}
                                            pagination={{ pageSize: 25 }}
                                            bordered
                                            size="small"
                                            loading={this.state.loading}
                                            rowClassName={theme}
                                            className={"mt-3 time-table-row-select " + theme}
                                        />
                                            <div className="container text-left">
                                                <h5 className={theme}><MDBIcon icon="file-download" /> &nbsp;  Download <br /></h5>

                                                <Excelreportdeduction payslip_year={this.state.payslip_year} payslip_month={this.state.payslip_month} nameexcel={this.state.customers_type_name} customers_group={this.state.customers_group} /><br />
                                                {/* <Excelreportdeductionall payslip_year={this.state.payslip_year} payslip_month={this.state.payslip_month} nameexcel='ทั้งหมด' /><br /> */}
                                            </div>

                                        </>
                                        : <div className="text-center"> </div>}
                                </>}
                        </MDBCard>


                    </div>
                </MDBContainer>

                {
                    message.config({
                        top: 60,
                        duration: 1,
                    })
                }


            </div>
        )
    }
}
