import React, { Component } from "react";
import RoutersLogin from "../Routers/RoutersLogin";
import { notification } from "antd";
import { apilogin, detail_user, theme, admin1, admin2, adminSuper } from "../api";
import Check_error from "../Components/Check_error";
import { Dialog, DialogTitle, DialogContent, IconButton, Fab } from "@material-ui/core";
import NavUser from "../Navs/NavUser";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ScrollTop from "../Navs/ScrollTop";
import SideBar from "../Navs/SideBar";



class MainPages extends Component {
  state = {
    open: false,
    openSideBar: false,
    openFirst: false,
    showNotify: false,

    list_alert_all: {
      loadstatus: true,
      list_alert: [],
      alert_cont: 0,
    },

  }

  onChangeSideBar = (e) => {
    this.setState({ openSideBar: !this.state.openSideBar })
  }



  onSnackbarClose = () => {
    this.setState({ open: false })
  }

  get_alert = () => {
    apilogin.get('getdata/get_alert/' + detail_user.Username)
      .then((res) => {
        this.setState({
          list_alert_all: {
            ...res.data,
            loadstatus: false,
          },
        })

        if (this.state.openFirst === false) {
          if (res.data.alert_cont >= 1) {
            this.setState({ open: true })
            this.setState({ openFirst: true })
          }
        }

      })
  }
  
  componentDidMount() {
    if (detail_user) {
      this.get_alert()
    }
  }

  render() {

    return (
      <div >

       
        <NavUser
          history={this.props.history}
          list_alert_all={this.state.list_alert_all}
          get_alert={this.get_alert}
          onChangeSideBar={this.onChangeSideBar}
        />

        <Check_error />
        <p></p>
        <RoutersLogin />
        <br /><br /><br />
        {
          notification.config({
            placement: 'topRight',
            top: 80,
            duration: 2,
          })
        }



        <SideBar    history={this.props.history} onChangeSideBar={this.onChangeSideBar} openSideBar={this.state.openSideBar} />
      

        <ScrollTop >
          <Fab onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            })
          }} color="primary" size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>

      </div>

    );
  }
}

export default MainPages;
