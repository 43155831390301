import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';


import CustomersPages from '../Pages/CustomersPages';
import CustomersPagesManage from '../Pages/CustomersPagesManage';
import PaySlipManage from '../Pages/PaySlipManage';
import Payslip_list_manage from '../Pages/Payslip_list_manage';
import PrintPDF from '../Pages/PrintPDF';

import Bar from '../Pages/Chart/Bar';
import Page_list_salary from '../Pages/Page_list_salary';
import PrivateRoute from '../guard/auth';
import Payslip_export_import_data from '../Pages/Payslip_export_import_data';
import PaySlipExpensetype from '../Pages/PaySlipExpensetype';
import Excelreportipay from '../Pages/Excelreportipay';
import FormPage from '../Pages/FormPage';
import FormPageList from '../Pages/FormPageList';
import PageShowListSalaryEdit from '../Pages/PageShowListSalaryEdit';
import PaySlipManageUpdateDataRe from '../Pages/PaySlipManageUpdateDataRe';
import PaySlipManageUpdateDataSalary from '../Pages/PaySlipManageUpdateDataSalary';
import PaySlipManageUpdateDataEx from '../Pages/PaySlipManageUpdateDataEx';
import PaySlipExpensetypeReport from '../Pages/PaySlipExpensetypeReport';
import PageListHistoryPrint from '../Pages/PageListHistoryPrint';
import PageListHistoryPrintAdmin from '../Pages/PageListHistoryPrintAdmin';
import CheckREF from '../Pages/CheckREF';
import PayslipRevenueType from '../Pages/PayslipRevenueType';
import PageNotify from '../Pages/PageNotify';
import PageKTB from '../Pages/PageKTB';
import PagesHowtoConvertPDF from '../Pages/PagesHowtoConvertPDF';
import CustomersPagesManagetax from '../Pages/CustomersPagesManagetax';
import PageTAX from '../Pages/PageTAX';
import FormPagetax from '../Pages/FormPagetax';








class RoutersLogin extends Component {
    render() {
        return (
            <Switch>
                <PrivateRoute exact path="/admin" component={Bar} />
                <PrivateRoute exact path="/admin/customers" component={CustomersPages} />
                <PrivateRoute exact path="/admin/bar" component={Bar} />
                <PrivateRoute exact path="/admin/customers_manage" component={CustomersPagesManage} />
                <PrivateRoute exact path="/admin/payslip_manage" component={PaySlipManage} />
                <PrivateRoute exact path="/admin/payslip_list_manage" component={Payslip_list_manage} />
                <PrivateRoute exact path="/admin/PaySlipExpensetype" component={PaySlipExpensetype} />
                <PrivateRoute exact path="/admin/PayslipRevenueType" component={PayslipRevenueType} />
                <PrivateRoute exact path="/admin/Excelreportipay" component={Excelreportipay} />
                <PrivateRoute exact path="/admin/PageShowListSalaryEdit" component={PageShowListSalaryEdit} />
                <PrivateRoute exact path="/admin/PaySlipManageUpdateDataEx" component={PaySlipManageUpdateDataEx} />
                <PrivateRoute exact path="/admin/PaySlipManageUpdateDataRe" component={PaySlipManageUpdateDataRe} />
                <PrivateRoute exact path="/admin/PaySlipManageUpdateDataSalary" component={PaySlipManageUpdateDataSalary} />
                <PrivateRoute exact path="/admin/PaySlipExpensetypeReport" component={PaySlipExpensetypeReport} />
                <PrivateRoute exact path="/admin/PageListHistoryPrintAdmin" component={PageListHistoryPrintAdmin} />
                <PrivateRoute exact path="/admin/PageNotify" component={PageNotify} />
                <PrivateRoute exact path="/admin/PrintPDF" component={PrintPDF} />
                <PrivateRoute exact path="/admin/Payslip_export_import_data" component={Payslip_export_import_data} />
                <PrivateRoute exact path="/admin/FormPageList" component={FormPageList} />
                <PrivateRoute exact path="/admin/PagesHowtoConvertPDF" component={PagesHowtoConvertPDF} />
                <PrivateRoute exact path="/admin/customers_managetax" component={CustomersPagesManagetax} />


                <Route exact path="/" component={Page_list_salary} />
                <Route exact path="/Page_list_salary" component={Page_list_salary} />
                <Route exact path="/FormPage" component={FormPage} />
                <Route exact path="/FormPagetax" component={FormPagetax} />
                <Route exact path="/PageKTB" component={PageKTB} />
                <Route exact path="/PageTAX" component={PageTAX} />
                <Route exact path="/PageListHistoryPrint" component={PageListHistoryPrint} />


                <Route exact path="/CheckREF/:ref" component={CheckREF} />
            </Switch>
        );
    }
}

export default RoutersLogin;