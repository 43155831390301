import React, { Component } from 'react'
import ReactExport from "react-export-excel";
import  { Modal, Button, Input, message, InputNumber, Icon, Form, Spin } from 'antd';
import { apilogin } from '../api'
import { MDBIcon } from 'mdbreact/dist/mdbreact.esm';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class Excelreportdeductionall extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            load:false,
            deduction2:[],
            deduction:[],
            deductionall:[],
           
        }
        this.get_dataipay = this.get_dataipay.bind(this)
    }

  

    get_dataipay() {
      //  console.log(this.props.data);
           
                let total = 0;
               
                this.setState({ loading: true })
                this.setState({ load: true })
                 setTimeout(() => {
                apilogin.post('getdata/deduction2all',
                ({
              //    bank_id:this.props.data.bank_id,
                  payslip_month:this.props.payslip_month,
                  payslip_year:this.props.payslip_year,
                 // typeid:this.props.typeid,
              })
            )
               
              .then(res => {
                console.log(res.data)
                const getdata = res.data.map((data, index) => ({
                    order:index+1,
                    list: data.bank_name,
                    sum: data.MonneyFull,
                   
                   // bank_name: data.bank_name,
                })
               )
              
                // this.setState({
                //     deductionall: getdata,
                //     loading: false,
                //     load: false,
                // })
               //  console.log(res.data);
              //  this.setState({ ipayall: res.data, loading: false, load: false })


              apilogin.post('getdata/deductionall',
              ({
              //  bank_id:this.props.data.bank_id,
                payslip_month:this.props.payslip_month,
                payslip_year:this.props.payslip_year,
              //  typeid:this.props.typeid,
            })
          )
             
            .then(res => {
              //  console.log(res.data)
              const getdata2 = res.data.map((data, index) => ({
                 order:index+1,
                 list:data.expenditure_name,
                 sum: data.sum,
                
                 }
              )
               // getdata.push(getdata2);
             )
            // this.state.deductionall.push(getdata2)
            var rowss = getdata.length

            var count = getdata2.length
            for (let i = 0; i < count; i++) { 
                rowss = rowss+1
                getdata.push( {order:rowss,list:getdata2[i].list,sum:getdata2[i].sum} );
            }
            

            for (let i = 0; i < getdata.length; i++) { 
                total=total+getdata[i].sum 
                }
           getdata.push( {list:'รวมเงินรายจ่าย' ,sum:total } );



              this.setState({
                  
                  deductionall: getdata,
                  loading: false,
                  load: false,
              })  
              //const sumrr = Arrays.stream(getdata.sum).sum();
              // this.setState({ deductionall: this.state.deductionall.push(getdata2), loading: false,
          //     load: false, })
            //   console.log(res.data);
            //  this.setState({ ipayall: res.data, loading: false, load: false })
              //console.log(sumrr)  
              console.log(total)
            })
            console.log(getdata)
            
                
              }) 
            }, 500);

         }

       

    
    
    render() {
  
 
        
        // const dataSet2 = [
        //     {
        //         name: "Johnson",
        //         total: 25,
        //         remainig: 16
        //     },
        //     {
        //         name: "Josef",
        //         total: 25,
        //         remainig: 7
        //     }
        // ];
        return (
         <>   
           
     {this.state.loading === true ? <Button size="default"  onClick={this.get_dataipay} > {this.state.load === true ? <><Spin/>loading</> :<> <MDBIcon icon="download" />&nbsp; โหลดข้อมูลสรุปรายการหักเงินเดือนบุคลากรทั้งหมด </>  }</Button> :
 <ExcelFile filename={this.props.nameexcel+this.props.payslip_month+this.props.payslip_year} element={<Button size="default"> 
 <Icon type="check" style={{ color: '#009900' }} /> &nbsp; <MDBIcon className='animated headShake delay-0s infinite' far icon="hand-point-right" /> &nbsp; คลิกเพื่อโหลดสรุปรายการหักเงินเดือนทั้งหมด</Button>}>
          <ExcelSheet data={this.state.deductionall} name={this.props.nameexcel+this.props.payslip_month+this.props.payslip_year}>
          <ExcelColumn label="ลำดับ" value="order" />
          <ExcelColumn label="จำนวนเงิน" value="" />
          <ExcelColumn label="รายการ" value="list"/>
          <ExcelColumn label="เงินรวม" value="sum"/>
          <ExcelColumn label="ยอดส่ง" value=""/>
         </ExcelSheet> 
 </ExcelFile>
          }
            {/* <ExcelSheet data={dataSet2} name="Leaves">
                <ExcelColumn label="Name" value="name"/>
                <ExcelColumn label="Total Leaves" value="total"/>
                <ExcelColumn label="Remaining Leaves" value="remaining"/>
            </ExcelSheet> */}
          
       
      </>
        )
    }
}
