import { Result } from "antd";
import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { Lottie_error404 } from "../Components/MyLottie";
import HomeIcon from '@material-ui/icons/Home';

class PageNotFoundPages extends Component {
  back = () => {
    window.location.href = "/";
  };
  render() {
    return (
      <>
        <div className="text-center mt-5" >
          <Lottie_error404 />
          <text  style={{fontSize:'18px'}} >
            ขออภัย ไม่พบหน้าที่ต้องการ
          <br />

            Sorry, the page you visited does not exist.
          <br />

          </text>

          <Button className="mt-5 mb-5" variant="contained" color="primary" onClick={this.back}>
            <HomeIcon />  กลับหน้าหลัก
          </Button>
        </div>
        {/* <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Button type="primary" onClick={this.back}>
              กลับหน้าหลัก
          </Button>
          }
        /> */}
      </>
    );
  }
}

export default PageNotFoundPages;
