import React, { Component } from 'react'
import { apilogin, theme, mouth, year, detail_user } from '../api'
import { Table, Input, Icon, Card, Button, InputNumber, message, notification } from 'antd'
import { MDBContainer, MDBCardBody, MDBCardHeader, MDBCard, MDBRow, MDBCol, MDBIcon, MDBCollapse } from 'mdbreact'
import Highlighter from 'react-highlight-words';
import Bttn_customer_add_expenditure from '../Components/Bttn_customer_add_expenditure';
import NumberFormat from 'react-number-format';
import Select from 'react-select'
import Bttn_customer_add_revenue from '../Components/Bttn_customer_add_revenue';
import PrintPDF from './PrintPDF';
import Bttn_export_excel_all_person from '../Components/Bttn_export_excel_all_person';
import Bttn_import_excel_all_person from '../Components/Bttn_import_excel_all_person';
import { MDBBtn } from 'mdbreact/dist/mdbreact.esm';
import Bttn_export_excel_all_salary from '../Components/Bttn_export_excel_all_salary';
import Bttn_import_excel_all_salary from '../Components/Bttn_import_excel_all_salary';




export default class Payslip_export_import_data extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            options_years: [],
            loading: false,
            search: '',

            payslip_month: '',
            payslip_year: '',
            customers_type: "",

            // payslip_month: '05',
            // payslip_year: '2020',
            // customers_type: "4",

            search_check: false,

            nameuser: '',
            admin_type: '',
            collapseID: "",
        }
        this.get_customer_status = this.get_customer_status.bind(this)
        this.updatedata = this.updatedata.bind(this)
        this.updatedata_props = this.updatedata_props.bind(this)
        this.onMouthChange = this.onMouthChange.bind(this)
        this.onYearsChange = this.onYearsChange.bind(this)
        this.toggleCollapse = this.toggleCollapse.bind(this)
    }


    onMouthChange(event) {
        // this.setState({ payslip_month: event.value })
        this.setState({ payslip_month: event.target.value })
    }
    onYearsChange(event) {
        //  this.setState({ payslip_year: event.value })
        this.setState({ payslip_year: event.target.value })
    }

    toggleCollapse = collapseID => () => {
        this.setState(prevState => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }));
    }

    get_customer_status() {
        if (this.state.payslip_month === "") {
            message.warning("กรุณาเลือกเดือน")
        }
        else if (this.state.payslip_year === "") {
            message.warning("กรุณาเลือกปี")
        }
        else {
            this.setState({ loading: true })

            apilogin.get('getdata/customer_manage_level/' + this.state.payslip_year + '/' + this.state.payslip_month + '/' + this.state.customers_type)
                .then(res => {
                    if (res.data === "") {
                        message.warning("ไม่พบข้อมูล")
                    }
                    else {
                        this.updatedata(res.data)
                    }
                })
        }

    }

    updatedata(data) {
        const getdata = data.map((data, index) => ({
            key: data.customers_citizent,
            index: index + 1,
            customers_citizent: data.customers_citizent,
            customers_pname: data.customers_pname,
            customers_name: data.customers_name + " " + data.customers_lname,
            customers_fname: data.customers_name,
            customers_lname: data.customers_lname,
            customer_type_name: data.customer_type_name,
            customer_type_id: data.customer_type_id,
            customer_type_name: data.customer_type_name,
            customer_status_name: data.customer_status_name,
            payslip_revenue: Number(data.payslip_revenue + data.Salary),
            payslip_expenditure: data.payslip_expenditure,
            payslip_total: (data.payslip_revenue + data.Salary) - data.payslip_expenditure,
            bank_name: data.bank_name,
            Salary: data.Salary,
        })
        )
        this.setState({
            data: getdata,
            search_check: true,
            loading: false,
        })
    }

    updatedata_props() {
        this.setState({ loading: true })
        setTimeout(() => {
            this.get_customer_status();
        }, 1000);
    }

    componentDidMount() {
        this.setState({
            payslip_month: mouth,
            payslip_year: year,
        })

        // this.get_customer_status()
        if (detail_user.nameuser !== "") {
            this.setState({ nameuser: detail_user.nameuser })
            this.setState({ admin_type: detail_user.admin_type })
        }
        else {
            message.error('ไม่พบข้อมูลผู้ใช้ กรุณาล็อคอินใหม่')
        }

        apilogin.get('getdata/getyear')
            .then(res => {
                let year = []
                res.data.map((data) => {
                    let yy = {
                        value: data.name_year,
                        label: data.name_year_th,
                    }
                    year.push(yy)
                })
                this.setState({ options_years: year })
            })
            .catch((err) => { notification.error({ message: 'โหลดรายการปีไม่สำเร็จ ' + err, }) })
    }


    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`ค้นหา`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
            </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text.toString()}
            />
        ),
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    render() {

        const options_mounth = [
            { value: '01', label: 'มกราคม' },
            { value: '02', label: 'กุมภาพันธ์' },
            { value: '03', label: 'มีนาคม' },
            { value: '04', label: 'เมษายน' },
            { value: '05', label: 'พฤษภาคม' },
            { value: '06', label: 'มิถุนายน' },
            { value: '07', label: 'กรกฎาคม' },
            { value: '08', label: 'สิงหาคม' },
            { value: '09', label: 'กันยายน' },
            { value: '10', label: 'ตุลาคม' },
            { value: '11', label: 'พฤศจิกายน' },
            { value: '12', label: 'ธันวาคม' },

        ]



        const customer_type_all = [
            { value: '1', label: 'ลูกจ้างชั่วคราว' },
            { value: '2', label: 'ลูกจ้างประจำ' },
            { value: '3', label: 'พนักงานราชการ' },
            { value: '4', label: 'พนักงานมหาวิทยาลัย' },
            { value: '5', label: 'อาจารย์ประจำตามสัญญาจ้าง' },
            { value: '6', label: 'ข้าราชการ' },
        ]

        const customer_type_level1 = [
            { value: '1', label: 'ลูกจ้างชั่วคราว' },
            { value: '2', label: 'ลูกจ้างประจำ' },
            { value: '5', label: 'อาจารย์ประจำตามสัญญาจ้าง' },
        ]

        const customer_type_level2 = [
            { value: '3', label: 'พนักงานราชการ' },
            { value: '4', label: 'พนักงานมหาวิทยาลัย' },
            { value: '6', label: 'ข้าราชการ' },
        ]

        let { sortedInfo, filteredInfo } = this.state;
        sortedInfo = sortedInfo || {};
        filteredInfo = filteredInfo || {};

        const columns = [
            {
                title: 'ลำดับ',
                dataIndex: 'index',
                key: 'index',
                width: 60,
                render: text => <div className="text-center">{text}</div>,
            },
            {
                title: 'ชื่อ-นามสกุล',
                dataIndex: 'customers_name',
                key: 'customers_name',
                width: 200,
                ...this.getColumnSearchProps('customers_name'),
                render: text => <div className="text-left">{text}</div>,
            },

            {
                title: 'ประเภทบุคลากร',
                dataIndex: 'customer_type_name',
                key: 'customer_type_name',
                width: 200,
                // filters: [
                //     {
                //         text: 'ลูกจ้างชั่วคราว',
                //         value: 'ลูกจ้างชั่วคราว',
                //     },
                //     {
                //         text: 'ลูกจ้างประจำ',
                //         value: 'ลูกจ้างประจำ',
                //     },
                //     {
                //         text: 'พนักงานราชการ',
                //         value: 'พนักงานราชการ',
                //     },
                //     {
                //         text: 'พนักงานมหาวิทยาลัย',
                //         value: 'พนักงานมหาวิทยาลัย',
                //     },
                //     {
                //         text: 'อาจารย์ประจำตามสัญญาจ้าง',
                //         value: 'อาจารย์ประจำตามสัญญาจ้าง',
                //     },
                //     {
                //         text: 'ข้าราชการ',
                //         value: 'ข้าราชการ',
                //     },

                // ],
                // filterMultiple: false,
                onFilter: (value, record) => record.customer_type_name.indexOf(value) === 0,
                render: text => <div className="text-center">{text}</div>,
            },
            {
                title: <div className="green-text"><Icon type="arrow-up" /> รายรับ</div>,
                dataIndex: 'payslip_revenue',
                key: 'payslip_revenue',
                width: 100,
                sorter: (a, b) => a.payslip_revenue - b.payslip_revenue,
                render: text =>
                    <div className="green-text">
                        {Number(text).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                    </div>
            },
            {
                title: <div className="red-text"><Icon type="arrow-down" /> รายจ่าย</div>,
                dataIndex: 'payslip_expenditure',
                key: 'payslip_expenditure',
                width: 110,
                sorter: (a, b) => a.payslip_expenditure - b.payslip_expenditure,
                render: text =>
                    <div className="red-text">
                        {Number(text).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                    </div>
            },

            {
                title: <div className="blue-text"><Icon type="snippets" /> ยอดสุทธิ</div>,
                dataIndex: 'payslip_total',
                key: 'payslip_total',
                sorter: (a, b) => a.payslip_total - b.payslip_total,
                width: 110,
                render: text =>
                    <div className="blue-text ">
                        {Number(text).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
                    </div>
            },
            // {

            {
                title: 'จัดการ',
                key: 'manage',
                width: 310,
                render: (text, record) => (
                    <div className="text-center">
                        {/* {console.log(record)} */}
                        <Bttn_customer_add_expenditure pop={this.props} data={this.state} customers_citizent={record.customers_citizent} customer_type_id={record.customer_type_id} updatedata_props={this.updatedata_props} /> &nbsp;
                        <Bttn_customer_add_revenue pop={this.props} data={this.state} customers_citizent={record.customers_citizent} customer_type_id={record.customer_type_id} updatedata_props={this.updatedata_props} /> &nbsp;
                        {/* {record.payslip_total === 0 || record.payslip_expenditure === 0? */}
                        {record.payslip_total === 0 ?
                            <Button size="small" disabled> <MDBIcon icon="print" /> &nbsp; {this.state.loading === true ? <Icon type="loading" /> : "พิมพ์"} </Button>
                            : <PrintPDF
                                dataprint={record}
                                payslip_month={this.state.payslip_month}
                                payslip_year={this.state.payslip_year}

                            />}
                    </div>
                ),
            },


        ]

        return (
            <div>
                <MDBContainer className="mt-5 mb-5">
                    <div className="d-flex justify-content-center mt-5 text-center">
                        <MDBCard style={{ width: "72rem", minHeight: "25rem" }} className={theme} >

                            <MDBCardHeader className={"form-header rgba-indigo-light rounded -hoverable "+theme} >
                                <h2 className={theme} style={{ textAlign: 'center', fontWeight: 'bold' }} >
                                    <Icon type="solution" /> นำเข้าและส่งออกข้อมูลเงินเดือน
                                </h2>
                            </MDBCardHeader>

                            <MDBCardBody className="bodytable">
                                <div>

                                    <div >

                                        <MDBRow className="text-center">
                                            <MDBCol xl="3" className="mb-1 text-left">

                                                <Select
                                                    className={"light"}
                                                    options={this.state.admin_type === "super" ? customer_type_all :
                                                        this.state.admin_type === "adminlevel1" ? customer_type_level1 :
                                                            this.state.admin_type === "adminlevel2" ? customer_type_level2 : null}
                                                    defaultInputValue={this.state.customers_type}
                                                    placeholder={<span><MDBIcon icon="users" /> &nbsp; เลือกประเภทบุคลากร</span>} onChange={(e) => {
                                                        // console.log(e)
                                                        this.setState({ customers_type: e.value })
                                                    }} name="customers_type" />
                                            </MDBCol>
                                            <MDBCol xl="3" className="mb-1 text-left">
                                                {/* <Select options={options_mounth}
                                                    className={"light"}
                                                    defaultInputValue={this.state.payslip_month}
                                                    placeholder={<span><MDBIcon far icon="calendar-alt" /> &nbsp; เลือกเดือน</span>} 
                                                    onChange={this.onMouthChange} name="payslip_month" /> */}

                                                <select
                                                    className={"form-control " + theme} name="payslip_month"
                                                    onChange={this.onMouthChange} value={this.state.payslip_month}
                                                >
                                                    <option value="">เลือกเดือน</option>
                                                    {options_mounth.map((data) => {
                                                        return <option value={data.value}> {data.label}</option>
                                                    })}
                                                </select>


                                            </MDBCol>
                                            <MDBCol xl="3" className="mb-1 text-left">
                                                {/* <Select
                                                    className={"light"}
                                                    options={this.state.options_years}
                                                    defaultInputValue={this.state.payslip_year}
                                                    placeholder={<span><MDBIcon icon="chart-line" /> &nbsp; เลือกปี</span>} 
                                                    onChange={this.onYearsChange} name="payslip_year" /> */}

                                                <select
                                                    className={"form-control " + theme} name="payslip_year"
                                                    onChange={this.onYearsChange} value={this.state.payslip_year}
                                                >
                                                    <option value="">เลือกปี</option>
                                                    {this.state.options_years.map((data) => {
                                                        return <option value={data.value}> {data.label}</option>
                                                    })}
                                                </select>

                                            </MDBCol>
                                            <MDBCol xl="3" className="text-center mb-1" style={{ marginTop: '0.0rem' }}>
                                                <Button block icon="search" size="large" type="primary" onClick={this.get_customer_status}>
                                                    ค้นหา
                                                 </Button>
                                            </MDBCol>
                                        </MDBRow>
                                    </div>
                                </div>


                                {this.state.search_check === true ? <>
                                    <div className={"mt-3 text-left " + theme}>
                                        {this.state.loading === true ? <><Button size="">Loading..</Button></> : <>
                                            <br /> <br />
                                            โหลดไฟล์ Excel : <Bttn_export_excel_all_person
                                                nameexcel="test"
                                                payslip_month={this.state.payslip_month}
                                                payslip_year={this.state.payslip_year}
                                                data={this.state.data}
                                                customers_type={this.state.customers_type}
                                            />
                                            <br /> <br />
                                            อัพโหลดไฟล์ Excel : <Bttn_import_excel_all_person
                                                nameexcel="test"
                                                payslip_month={this.state.payslip_month}
                                                payslip_year={this.state.payslip_year}
                                                data={this.state.data}
                                                customers_type={this.state.customers_type}
                                            />
                                             <br /> <br />
                                             <hr/>
                                             โหลดไฟล์ Excel เฉพาะเงินเดือน : <Bttn_export_excel_all_salary
                                                nameexcel="testsalary"
                                                payslip_month={this.state.payslip_month}
                                                payslip_year={this.state.payslip_year}
                                                data={this.state.data}
                                                customers_type={this.state.customers_type}
                                            />
                                             <br /> <br />
                                            อัพโหลดไฟล์ Excel เฉพาะเงินเดือน : <Bttn_import_excel_all_salary
                                                nameexcel="test"
                                                payslip_month={this.state.payslip_month}
                                                payslip_year={this.state.payslip_year}
                                                data={this.state.data}
                                                customers_type={this.state.customers_type}
                                            />




                                            <div className="text-left mt-5">





                                                <>

                                                    <MDBBtn
                                                        color={theme === "light" ? "primary" : "light"}
                                                        onClick={this.toggleCollapse("basicCollapse")}
                                                        size="sm"
                                                        outline
                                                        className={theme}
                                                    >
                                                        วิธีใช้งาน <MDBIcon icon="question" />
                                                    </MDBBtn>
                                                    <MDBCollapse id="basicCollapse" isOpen={this.state.collapseID}>
                                                        <p className="mt-2">
                                                            นำเข้าและส่งออกข้อมูลเงินเดือน<br />
                                                            - การโหลดไฟล์ Excel จะเป็นการโหลดข้อมูลเงินเดือน รายรับ และรายจ่ายของพนักงาน จำแนกเป็น ประเภทพนักงาน เดือน และปีนั้น เพื่อดูหรือแก้ไข <br />
                                                            - การอัพโหลดไฟล์ Excel เป็นการนำไฟล์ที่โหลดมาอัพเดตข้อมูลได้ที่ช่องทางนี้ แต่ไม่ได้อัพเดตข้อมูลเงินเดือน อัพเดตแค่รายการรายรับรายจ่าย<br /><br />
                                                            *** ข้อสำคัญ ***<br />
                                                            ห้ามแก้ไขชื่อรายการในไฟล์ Excel โดยเด็ดขาดเพราะจะทำให้ระบบมองไม่เห็นรายการนั้น ทำให้ไม่สามารถอัพเดตรายการนั้นได้<br />
                                                            ในไฟล์ excel นี้จะอัพเดตเฉพาะรายการรายรับรายจ่ายเท่านั้น เงินเดือนไม่สามารถอัพเดตที่ช่องทางนี้ได้
                                                        </p>
                                                    </MDBCollapse>
                                                </>







                                            </div>


                                        </>}
                                    </div>

                                    {/* <Table className="mt-3" dataSource={this.state.data} columns={columns}
                                        pagination={{ pageSize: 25 }}
                                        bordered
                                        size="small"
                                        loading={this.state.loading}
                                    /> */}
                                </>
                                    :
                                    <div className="text-center">

                                    </div>
                                }

                            </MDBCardBody>
                        </MDBCard>
                    </div>
                </MDBContainer >

                {
                    message.config({
                        top: 60,
                        duration: 1,
                    })
                }


            </div >
        )
    }
}
