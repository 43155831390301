import React from 'react';
import 'antd/dist/antd.css';
import { Form, Input, Button, Select, AutoComplete, Icon } from 'antd';
import { MDBContainer, MDBCard, MDBCardBody, MDBCardHeader } from 'mdbreact';
import Axios from 'axios'
import { apilogin, theme } from '../api';
import Text from 'antd/lib/typography/Text';

// import Select from 'react-select'
const { Option } = Select;
class CustomersPages extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      employee: [],
      bank: [],
      customer_status: [],
      customer_type: [],
      name_employee: "",
      bank_id: "",
      customer_status_id: "",
      name: "",
      lname: "",
    }
    this.onChange = this.onChange.bind(this)
  }

  async getuser() {
    let data
    const employee1 = await Axios.get('https://mis.kpru.ac.th/api/EmployeeInOrg/000')
    const employee = employee1.data.map(data =>(data.first_name_tha + " " + data.last_name_tha) )
    this.setState({ employee: employee })

    // const employee = employee1.data.map(data =>
    //   this.setState({ employee: this.state.employee.concat(data.first_name_tha + " " + data.last_name_tha) }))

    //   ({
    //     value: data.first_name_tha + " " + data.last_name_tha,
    //     label: data.first_name_tha + ' ' + data.last_name_tha
    //   })
    //   )
    // this.setState({ employee: employee })
  }

  getbank() {
    apilogin.get('getdata/getbank')
      .then(res => {
        //console.log(res);
        const bank = res.data.map(data =>
          ({
            value: data.bank_id,
            label: data.bank_name
          }));
        this.setState({ bank: bank })
      })
  }
  get_customer_status() {
    apilogin.get('getdata/customer_status')
      .then(res => {
        // console.log(res);
        const customer_status = res.data.map(data =>
          ({
            value: data.customer_status_id,
            label: data.customer_status_name
          }));
        this.setState({ customer_status: customer_status })
      })

  }

  get_customer_type() {
    apilogin.get('getdata/customer_type')
      .then(res => {
        // console.log(res);
        const customer_type = res.data.map(data =>
          ({
            value: data.customer_type_id,
            label: data.customer_type_name
          }));
        this.setState({ customer_type: customer_type })
      })
  }

  componentDidMount() {
    this.getuser()
    this.getbank()
    this.get_customer_status()
    this.get_customer_type()
  }


  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {

      if (!err) {
        //alert(JSON.stringify(values))
        apilogin.post('insert_customers',
          ({
            customers_citizent: values.customers_citizent,
            prename: values.prename,
            customers_name: this.state.name,
            customers_lname: this.state.lname,
            type_customer: values.type_customer,
            // bank: values.bank,
            customer_status: values.customer_status,

          })
        )
          .then(res => {
            console.log(res);
            if (res.status === 200) {
              alert("บันทึกสำเร็จ")
              this.props.form.resetFields()
            }
          })
          .catch(function (error) {
            alert(error)
            console.log(error);
          })
      }
    });
  };



  onChange(value) {
    const res = value.split(' ')
    this.setState({
      name: res[0],
      lname: res[1]
    })
    // console.log(res[0])
    // console.log(res[1])
  }


  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };
    let form
    form = (
      <>
        <Form {...formItemLayout} onSubmit={this.handleSubmit} >
          <Form.Item className={theme} label="รหัสประจำตัวประชาชน">
            {getFieldDecorator('customers_citizent', {
              rules: [{ required: true, message: 'กรุณากรอกรหัสประจำตัวประชาชน!' }],
            })(<Input placeholder="ใส่รหัสประจำตัวประชาชน" maxLength={13} />)}
          </Form.Item>


          <Form.Item className={theme} label="คำนำหน้าชื่อ">
            {getFieldDecorator('prename', {
              rules: [{ required: true, message: 'กรุณากรอกคำนำหน้าชื่อ!' }],
            })(<Input size="default" placeholder="ใส่คำนำหน้าชื่อ" />)}
          </Form.Item>

          <Form.Item className={theme}
            label={"ชื่อ-นามสกุล " + this.state.name_employee}
          >
            {getFieldDecorator('employee', {
              rules: [{ required: true, message: 'กรุณาเลือกชื่อบุคลากร' }],
            })(
              // <Select placeholder="เลือกบุคลากร" showSearch onChange={this.onChange} >
              //   {this.state.employee.map((data, key) => {
              //     return <Option value={data.value} key={key}>{data.label}</Option>
              //   })}
              // </Select>


              <AutoComplete
              className={theme}
                onChange={this.onChange}
                style={{ width: "100%" }}
                dataSource={this.state.employee}
                placeholder="กรุณาใส่ชื่อบุคลากร"
                filterOption={(inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
              />

            )}
          </Form.Item>


          <Form.Item
          className={theme}
            label={"เลือกประเภทบุคลากร "}
          >
            {getFieldDecorator('type_customer', {
              rules: [{ required: true, message: 'กรุณาเลือกประเภทบุคลากร' }],
            })(
              <Select placeholder="เลือกประเภทบุคลากร">
                {this.state.customer_type.map((data, key) => {
                  return <Option value={data.value} key={key}>{data.label}</Option>
                })}
              </Select>


            )}
          </Form.Item>




          {/* <Form.Item
          className={theme}
            label={"ธนาคาร"}
          >
            {getFieldDecorator('bank', {
              rules: [{ required: true, message: 'กรุณาเลือกธนาคาร' }],
            })(
              <Select placeholder="เลือกธนาคาร">
                {this.state.bank.map((data, key) => {
                  return <Option value={data.value} key={key}>{data.label}</Option>
                })}
              </Select>

            )}
          </Form.Item> */}

          <Form.Item
          className={theme}
            label={"สถานะการทำงาน"}
          >
            {getFieldDecorator('customer_status', {
              rules: [{ required: true, message: 'กรุณาเลือกสถานะการทำงาน' }],
            })(
              <Select placeholder="เลือกสถานะการทำงาน">
                {this.state.customer_status.map((data, key) => {
                  return <Option value={data.value} key={key}>{data.label}</Option>
                })}
              </Select>

            )}
          </Form.Item>
          <div className="text-center">
            <Button type="primary" htmlType="submit">
              บันทึก
          </Button>
          </div>

        </Form>
      </>
    )
    return (
      <>
        <MDBContainer className="mt-5 mb-5">
          <MDBCard className={theme}>
            <MDBCardHeader className={"form-header rgba-indigo-light rounded -hoverable text-center "+theme} >
              <Text className={theme} style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '28px'}}>
              <Icon type="usergroup-add" /> เพิ่มข้อมูลบุคลากร
              </Text>
            </MDBCardHeader>
            <MDBCardBody className="mt-5 mb-5">
              {form}
            </MDBCardBody>
          </MDBCard>
        </MDBContainer>
      </>
    );
  }
}

const WrappedNormalLoginForm = Form.create({ name: 'CustomersPages' })(CustomersPages);
export default WrappedNormalLoginForm;
