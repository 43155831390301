import React, { Component } from 'react'
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { Button, Icon } from 'antd';
import ExcelReader from '../Components/excel_to_json/ExcelReader';

export default class Bttn_import_excel_all_person extends Component {
    state = {
        modal: false
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    render() {
        return (
            <>

                <Button onClick={this.toggle}><Icon type="fund" /> นำเข้าข้อมูล</Button>

                <MDBModal size="lg" isOpen={this.state.modal} toggle={this.toggle} centered>
                    <MDBModalHeader toggle={this.toggle}><strong><Icon type="fund" /> นำเข้าข้อมูลเงินเดือน</strong></MDBModalHeader>
                    <MDBModalBody className="text-left">
                             <ExcelReader 
                               payslip_month={this.props.payslip_month}
                               payslip_year={this.props.payslip_year}
                               data={this.props.data}
                               customers_type={this.props.customers_type}
                             />
                    </MDBModalBody>
                </MDBModal>

            </>
        )
    }
}
