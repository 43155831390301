
import React from 'react';
import { Select, Icon, Divider, Modal, Button, Input, Popconfirm } from 'antd';
import Text from 'antd/lib/typography/Text';
import { apilogin, theme } from '../api';
import { MDBInputGroup, MDBBtn, MDBTable, MDBTableHead, MDBTableBody } from 'mdbreact';

const { Option } = Select;


const options_mounth = [
    // { value: '1', label: 'มกราคม' },
    // { value: '2', label: 'กุมภาพันธ์' },
    // { value: '3', label: 'มีนาคม' },
    // { value: '4', label: 'เมษายน' },
    // { value: '5', label: 'พฤษภาคม' },
    // { value: '6', label: 'มิถุนายน' },
    // { value: '7', label: 'กรกฎาคม' },
    // { value: '8', label: 'สิงหาคม' },
    // { value: '9', label: 'กันยายน' },

    { value: '01', label: 'มกราคม' },
    { value: '02', label: 'กุมภาพันธ์' },
    { value: '03', label: 'มีนาคม' },
    { value: '04', label: 'เมษายน' },
    { value: '05', label: 'พฤษภาคม' },
    { value: '06', label: 'มิถุนายน' },
    { value: '07', label: 'กรกฎาคม' },
    { value: '08', label: 'สิงหาคม' },
    { value: '09', label: 'กันยายน' },
    { value: '10', label: 'ตุลาคม' },
    { value: '11', label: 'พฤศจิกายน' },
    { value: '12', label: 'ธันวาคม' },

]

const InputGroup = Input.Group;
// const { Option } = Select;

export default class Bttn_search_date extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data_year: [],
            year: '',
            visible: false,
            visible2: false,
            loadyear: true,

        }
        this.onChange = this.onChange.bind(this)
        this.handleCancel = this.handleCancel.bind(this) 
        this.handleShow = this.handleShow.bind(this)
        this.handleShow2 = this.handleShow2.bind(this)
        this.get_year = this.get_year.bind(this)
    }

    delete(id) {
        //console.log(id)
        apilogin.post('getdata/delete_year', ({
            id_year: id,
        }))
            .then(res => {
                console.log(res.status)
                if (res.status === 200) {
                    this.get_year()
                }
            });
    }

    get_year() {
        apilogin.get('getdata/getyear')
            .then(res => {
                this.setState({
                    data_year: res.data,
                    loadyear: false
                });
            })
    }

    handleCancel() {
        this.setState({
            visible: false,
            visible2: false,
        })
    }

    handleShow() {
        this.setState({ visible: true })
        this.get_year()
    }
    handleShow2() {
        this.setState({ visible2: true })
        this.get_year()
    }

    componentDidMount() {
        this.get_year()
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }
    addItem = () => {
        apilogin.post('getdata/insert_year', ({
            year: this.state.year,
        }))
            .then(res => {
                this.get_year()
                this.setState({ visible: false });
            });

    };

    render() {


        return (
            <>
                <InputGroup compact   
                // className={"select-"+theme}
                 >
                    {this.state.loadyear === false ? <>

                        <Button> เดือน</Button>
                        <Select
                        
                            style={{ width: '15%' }}
                            onChange={this.props.onMouthChange}
                            placeholder="เลือกเดือน"
                            value={String(this.props.payslip_month)}
                        >
                            {options_mounth.map(item => (
                                <Option key={item.value} >
                                    <text className="float-left"> {item.label}</text>
                                </Option>
                            ))}
                        </Select>&emsp;
                    <Button>ปี</Button>
                        <Select
                            style={{ width: '15%' }}
                            onChange={this.props.onYearsChange}
                            placeholder="เลือกปี"
                            value={String(this.props.payslip_year)}
                            dropdownRender={menu => (
                                <div onMouseDown={e => e.preventDefault()}>
                                    {menu}
                                    <Divider style={{ margin: '4px 0' }} />
                                    <div className="text-center" style={{ padding: '4px 8px', cursor: 'pointer' }}  >
                                        <Button size="small" onClick={this.handleShow} >  <Icon type="plus" /> เพิ่ม </Button>&nbsp;
                                    <Button size="small" onClick={this.handleShow2} >  <Icon type="delete" /> ลบ </Button>
                                    </div>
                                </div>
                            )}
                        >
                            {this.state.data_year.map(item => (
                                <Option key={item.name_year} >
                                    <text className="float-left">{item.name_year_th}</text>
                                </Option>
                            ))}


                        </Select>&emsp;
    
                    <Button icon="search" size="" color="primary" onClick={this.props.get_customer_status}>
                            ค้นหา
                     </Button>
                    </> : <>
                
                        <Button> เดือน</Button>
                        <Select
                            style={{ width: '15%' }}
                            onChange={this.props.onMouthChange}
                            placeholder="กำลังโหลด.."
                        >
                            <Option key="1">กำลังโหลด..</Option>
                        </Select>&emsp; 
                    <Button>ปี</Button>
                        <Select
                            style={{ width: '15%' }}
                            onChange={this.props.onYearsChange}
                            placeholder="กำลังโหลด.."
                           
                        >
                           <Option key="1">กำลังโหลด..</Option>


                        </Select>&emsp;
    
                    <Button icon="search" size="" color="primary" onClick={this.props.get_customer_status}>
                            ค้นหา
                     </Button>
                     

                        </>}

                </InputGroup>



                 {/* //////////////////////////////////////////////  เพิ่มปี*/}
                <Modal
                    title="เพิ่มปี (พ.ศ.)"
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    footer={false}
                >

                    <MDBInputGroup
                        containerClassName="mb-3"
                        inputs={
                            <>
                                <input
                                    className="form-control"
                                    placeholder="ระบุปี พ.ศ."
                                    // value={this.state.year}
                                    onChange={this.onChange}
                                    name="year"
                                />
                                <MDBBtn color="secondary" onClick={this.addItem} outline className="m-0 px-3 py-2 z-depth-0">
                                    <Icon type="save" /> บันทึก
                            </MDBBtn>
                            </>
                        }
                    />
                </Modal>



                 {/* //////////////////////////////////////////////  ลบปี*/}
                <Modal
                    title="รายการปีที่ต้องการลบ"
                    visible={this.state.visible2}
                    onCancel={this.handleCancel}
                    footer={false}
                >
                    <div className="text-center">
                        <MDBTable bordered responsive small >
                            <MDBTableHead color="white" >
                                <tr >
                                    <th>ปี</th>
                                    <th>จัดการ</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody >

                                {this.state.data_year.map((data) => {
                                    return <>
                                        <tr>
                                            <td>{data.name_year_th}</td>
                                            <td><Button size="small" onClick={() => this.delete(data.id_year)} ><Icon type="delete" /></Button></td>
                                        </tr>
                                    </>
                                })}

                            </MDBTableBody>
                        </MDBTable>
                    </div>

                </Modal>
            </>
        );
    }
}


