import React, { Component } from 'react'
import { MDBNavItem, MDBContainer, MDBCol, MDBRow } from 'mdbreact/dist/mdbreact.esm';
import { IconButton, Badge, MenuItem, Menu, Divider, Typography, Box, Paper, Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid } from "@material-ui/core";
import NotificationsIcon from '@material-ui/icons/Notifications';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { theme, detail_user, apilogin } from '../api';
import { Lottie_notify } from '../Components/MyLottie';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import VisibilityIcon from '@material-ui/icons/Visibility';
import parse from 'html-react-parser';


export default class Bttn_view_alert extends Component {
    state = {
        modal: false,
        open1: '',
        data: {}
    };

    onAccept = (data) => {
        if (data.status_read !== "1") {
            let datasent = {
                alert_id: data.alert_id,
                customers_citizent: detail_user.Username
            }
            apilogin.post('getdata/alert_user_read', datasent)
                .then((res) => {
                    this.props.get_alert()
                    this.closemodal()
                    this.setState({ open1: null })

                })
        } else {
            this.props.closemodal()
        }
    }

    showmodal = (v) => {
        this.setState({ modal: true })
        this.setState({ data: v })
    }
    closemodal = (v) => {
        this.setState({ modal: false })
        if (this.props.user == true) {
            this.props.closemodal()
        }
    }

    componentDidMount() {
        let props = this.props
        if (props.user == true) {
            let list_alert = props.list_alert
            this.showmodal(list_alert)

         
        }



    }

    render() {

        let list_alert = this.props.list_alert
        return (
            <>

                {this.props.view_admin === true ? <>
                    <IconButton
                        size="small"
                        onClick={() => this.showmodal(this.props.list_alert)}
                    >
                        <VisibilityIcon />
                    </IconButton>
                </> : <></>}


                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"} //xs
                    open={this.state.modal}
                    onClose={this.closemodal}
                    aria-labelledby="max-width-dialog-title"
                    // disableBackdropClick
                    // disableEscapeKeyDown
                >
                    <div className={" d-flex justify-content-between font-" + theme}>
                        <DialogTitle id="alert-dialog-title">{<text className={"font-" + theme}><NotificationsNoneIcon /> การแจ้งตือน </text>}</DialogTitle>
                    </div>

                    <DialogContent dividers >
                        <MDBRow>
                            <MDBCol style={{ padding: '0rem' }} md="3" ><Lottie_notify /></MDBCol>
                            <MDBCol md="9" className="mt-3" >
                                <div className="font18" >{list_alert.alert_name} </div>

                                <br />
                                {/* {list_alert.alert_text} */}
                                {parse(list_alert.alert_text)}
                            </MDBCol>
                        </MDBRow>

                        {/* <a href="https://e-payslip.kpru.ac.th/admin/PageNotify"> ลิ้งค์ </a> */}

                    </DialogContent>

                    <DialogActions>
                        {this.props.view_admin === true ? <>
                            <Button onClick={this.closemodal} variant="contained" color="primary" >รับทราบ</Button>
                        </> : <>
                                <DialogActions>
                                    <Button onClick={() => this.onAccept(this.state.data)} variant="contained" color="primary" >รับทราบ</Button>
                                </DialogActions>
                            </>}



                    </DialogActions>
                </Dialog>
            </>
        )
    }
}
